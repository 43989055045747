import React, { useEffect, useState } from "react";
import { ModalBody } from "react-bootstrap";
import Draggable from "react-draggable";
import { Button, Col, Input, Label, Modal, ModalFooter, Row } from "reactstrap";
import * as api from "../../api/api";
import Select from "react-select";
import {
  alphabetwithcomma,
  insulatorConditionOptions,
  locationOptions,
  noZeroDecimal,
  nodecimal,
  severityOptions,
  stringingOption,
  testingOptions,
  vegetationOptions,
} from "../Components/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const TxObservation = (props) => {
  const { isOpen, close, setDraw, url, setLoading } = props;
  const location = useLocation().state;
  const navigate = useNavigate();
  const [obsOptions, setObsOption] = useState([]);
  const [formData, setFormData] = useState({});

  const onclose = () => {
    close();
  };

  const handleInputChange = (e, name, val) => {
    if (
      name === "componentName" ||
      name === "observationName" ||
      name === "partName"
    ) {
      if (e.match(alphabetwithcomma)) {
        setFormData({ ...formData, [name]: e });
      }
    } else if (
      name === "size" ||
      name === "height" ||
      name === "girth" ||
      name === "clearenceFromBottom" ||
      name === "jumpToTower" ||
      name === "phaseToPhase" ||
      name === "groundClearence" ||
      name === "overHead" ||
      name === "quantity"
    ) {
      if (e.match(nodecimal) || e === "") {
        setFormData({ ...formData, [name]: e });
      }
    } else {
      setFormData({ ...formData, [name]: e });
    }
  };

  useEffect(async () => {
    let res = await api.getReq("getEnums?enumType=TL_OBSERVATION");
    if (res?.data?.appEnum?.length > 0) {
      let ObsArr = res?.data?.appEnum?.map((ele) => {
        return { label: ele.enumValue, value: ele.enumValue };
      });
      setObsOption(ObsArr);
    }
    if (props?.data) {
      let id = props?.data?.component?.id;
      let componentName = props?.data?.component?.componentName;
      let comments = props?.data?.component?.comments;
      let recommendation = props?.data?.component?.recommendation;
      let location = {
        label: props?.data?.component?.location,
        value: props?.data?.component?.location,
      };
      let observation = {
        label: props?.data?.component?.observation,
        value: props?.data?.component?.observation,
      };
      let componentStatus = {
        label: props?.data?.component?.componentStatus,
        value: props?.data?.component?.componentStatus,
      };
      let obj = {
        id,
        componentName,
        location,
        observation,
        componentStatus,
        comments,
        recommendation,
      };
      if (
        observation?.value === "Bend Parts" ||
        observation?.value === "Loose Parts" ||
        observation?.value === "Missing Parts"
      ) {
        obj["partName"] = props?.data?.component?.observationData?.partName;
        obj["quantity"] = props?.data?.component?.observationData?.quantity;
        obj["size"] = props?.data?.component?.observationData?.size;
      } else if (observation?.value === "Vegetation") {
        obj["vegetationType"] = {
          label: props?.data?.component?.observationData?.vegetationType,
          value: props?.data?.component?.observationData?.vegetationType,
        };
        obj["height"] = props?.data?.component?.observationData?.height;
        obj["girth"] = props?.data?.component?.observationData?.girth;
        obj["quantity"] = props?.data?.component?.observationData?.quantity;
        obj["clearenceFromBottom"] =
          props?.data?.component?.observationData?.clearenceFromBottom;
      } else if (observation?.value === "Clearance") {
        obj["jumpToTower"] =
          props?.data?.component?.observationData?.jumpToTower;
        obj["phaseToPhase"] =
          props?.data?.component?.observationData?.phaseToPhase;
        obj["groundClearence"] =
          props?.data?.component?.observationData?.groundClearence;
        obj["overHead"] = props?.data?.component?.observationData?.overHead;
      } else if (observation?.value === "Insulators Condition") {
        obj["insulatorsCondition"] = {
          label: props?.data?.component?.observationData?.insulatorsCondition,
          value: props?.data?.component?.observationData?.insulatorsCondition,
        };
      } else if (observation?.value === "Testing") {
        obj["testing"] = {
          label: props?.data?.component?.observationData?.testing,
          value: props?.data?.component?.observationData?.testing,
        };
      } else if (
        observation?.value === "Condition Of Tower Foundation (Legs)"
      ) {
        obj["legA"] = props?.data?.component?.observationData?.legA;
        obj["legB"] = props?.data?.component?.observationData?.legB;
        obj["legC"] = props?.data?.component?.observationData?.legC;
        obj["legD"] = props?.data?.component?.observationData?.legD;
      } else if (observation?.value === "Stringing") {
        obj["stringing"] = {
          label: props?.data?.component?.observationData?.stringing,
          value: props?.data?.component?.observationData?.stringing,
        };
      } else if (observation?.value === "Others") {
        obj["observationName"] =
          props?.data?.component?.observationData?.observationName;
      }
      setFormData(obj);
    }
  }, []);

  const prepareObservationData = (val) => {
    switch (val) {
      case "Bend Parts":
      case "Missing Parts":
      case "Loose Parts":
        return {
          partName: formData?.partName || "",
          quantity: formData?.quantity || "",
          size: formData?.size || "",
        };
      case "Vegetation":
        return {
          vegetationType: formData?.vegetationType?.value,
          height: formData?.height || "",
          girth: formData?.girth || "",
          quantity: formData?.quantity || "",
          clearenceFromBottom: formData?.clearenceFromBottom || "",
        };
      case "Clearance":
        return {
          jumpToTower: formData?.jumpToTower || "",
          phaseToPhase: formData?.phaseToPhase || "",
          groundClearence: formData?.groundClearence || "",
          overHead: formData?.overHead || "",
        };
      case "Insulators Condition":
        return {
          insulatorsCondition: formData?.insulatorsCondition?.value,
        };
      case "Testing":
        return {
          testing: formData?.testing?.value,
        };
      case "Condition Of Tower Foundation (Legs)":
        return {
          legA: formData?.legA || "",
          legB: formData?.legB || "",
          legC: formData?.legC || "",
          legD: formData?.legD || "",
        };
      case "Stringing":
        return {
          stringing: formData?.stringing?.value,
        };
      case "Others":
        return {
          observationName: formData?.observationName || "",
        };
      default:
        return {};
    }
  };

  const handleObservationData = (val) => {
    switch (val) {
      case "Bend Parts":
      case "Missing Parts":
      case "Loose Parts":
        return (
          <>
            <div className="mb-3">
              <Label className="color-grayl">Part Name</Label>
              <Input
                type="text"
                placeholder="Enter"
                value={formData?.partName || ""}
                onChange={(e) =>
                  handleInputChange(e.target.value, "partName", e)
                }
              />
            </div>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="color-grayl">
                    Quantity <span className="astriek"> *</span>
                  </Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={formData?.quantity || ""}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "quantity", e)
                    }
                    onKeyUp={(e) =>
                      handleInputChange(e.target.value, "quantity", e)
                    }
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label className="color-grayl">Size (in mtr)</Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={formData?.size || ""}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "size", e)
                    }
                  />
                </div>
              </Col>
            </Row>
          </>
        );
      case "Vegetation":
        return (
          <>
            <div className="mb-3">
              <Label className="color-grayl">
                Type Of Vegetation <span className="astriek"> *</span>
              </Label>
              <Select
                options={vegetationOptions}
                placeholder="Select"
                value={formData?.vegetationType}
                onChange={(e) => handleInputChange(e, "vegetationType")}
              />
            </div>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="color-grayl">Height (in mtr)</Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={formData?.height || ""}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "height", e)
                    }
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label className="color-grayl">Girth (in mtr)</Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={formData?.girth || ""}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "girth", e)
                    }
                  />
                </div>
              </Col>
            </Row>
            <div className="mb-3">
              <Label className="color-grayl">Quantity</Label>
              <Input
                type="text"
                placeholder="Enter"
                value={formData?.quantity || ""}
                onChange={(e) =>
                  handleInputChange(e.target.value, "quantity", e)
                }
              />
            </div>
            <div className="mb-3">
              <Label className="color-grayl">
                Clearence From Bottom Conductor (in mtr)
              </Label>
              <Input
                type="text"
                placeholder="Enter"
                value={formData?.clearenceFromBottom || ""}
                onChange={(e) =>
                  handleInputChange(e.target.value, "clearenceFromBottom", e)
                }
              />
            </div>
          </>
        );
      case "Clearance":
        return (
          <>
            <div className="mb-3">
              <Label className="color-grayl">
                Jumper to Tower (in mtr) <span className="astriek"> *</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter"
                value={formData?.jumpToTower || ""}
                onChange={(e) =>
                  handleInputChange(e.target.value, "jumpToTower", e)
                }
              />
            </div>
            <div className="mb-3">
              <Label className="color-grayl">
                Phase To Phase (in mtr) <span className="astriek"> *</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter"
                value={formData?.phaseToPhase || ""}
                onChange={(e) =>
                  handleInputChange(e.target.value, "phaseToPhase", e)
                }
              />
            </div>
            <div className="mb-3">
              <Label className="color-grayl">
                Ground Clearence (in mtr) <span className="astriek"> *</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter"
                value={formData?.groundClearence || ""}
                onChange={(e) =>
                  handleInputChange(e.target.value, "groundClearence", e)
                }
              />
            </div>
            <div className="mb-3">
              <Label className="color-grayl">
                Overhead/Underneath Line Crossing (in mtr){" "}
                <span className="astriek"> *</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter"
                value={formData?.overHead || ""}
                onChange={(e) =>
                  handleInputChange(e.target.value, "overHead", e)
                }
              />
            </div>
          </>
        );
      case "Insulators Condition":
        return (
          <>
            <div className="mb-3">
              <Label className="color-grayl">
                Insulators Condition <span className="astriek"> *</span>
              </Label>
              <Select
                options={insulatorConditionOptions}
                placeholder="Select"
                value={formData?.insulatorsCondition}
                onChange={(e) => handleInputChange(e, "insulatorsCondition", e)}
              />
            </div>
          </>
        );
      case "Testing":
        return (
          <>
            <div className="mb-3">
              <Label className="color-grayl">
                Testing <span className="astriek"> *</span>
              </Label>
              <Select
                options={testingOptions}
                placeholder="Select"
                value={formData?.testing}
                onChange={(e) => handleInputChange(e, "testing", e)}
              />
            </div>
          </>
        );
      case "Condition Of Tower Foundation (Legs)":
        return (
          <>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="color-grayl">
                    Leg A <span className="astriek"> *</span>
                  </Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={formData?.legA || ""}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "legA", e)
                    }
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label className="color-grayl">
                    Leg B <span className="astriek"> *</span>
                  </Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={formData?.legB || ""}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "legB", e)
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="color-grayl">
                    Leg C <span className="astriek"> *</span>
                  </Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={formData?.legC || ""}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "legC", e)
                    }
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label className="color-grayl">
                    Leg D <span className="astriek"> *</span>
                  </Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={formData?.legD || ""}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "legD", e)
                    }
                  />
                </div>
              </Col>
            </Row>
          </>
        );
      case "Stringing":
        return (
          <div className="mb-3">
            <Label className="color-grayl">
              Stringing <span className="astriek"> *</span>
            </Label>
            <Select
              options={stringingOption}
              placeholder="Select"
              value={formData?.stringing}
              onChange={(e) => handleInputChange(e, "stringing", e)}
            />
          </div>
        );
      case "Others":
        return (
          <div className="mb-3">
            <Label className="color-grayl">
              Observation Name <span className="astriek"> *</span>
            </Label>
            <Input
              type="text"
              placeholder="Enter"
              value={formData?.observationName || ""}
              onChange={(e) =>
                handleInputChange(e.target.value, "observationName", e)
              }
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  const checkDisable = () => {
    if (
      !formData?.componentName ||
      !formData?.componentName?.trim() ||
      !formData?.location ||
      !formData?.componentStatus ||
      !formData?.comments ||
      !formData?.observation ||
      !url
    ) {
      return true;
    } else if (
      formData?.observation?.value === "Bend Parts" ||
      formData?.observation?.value === "Missing Parts" ||
      formData?.observation?.value === "Loose Parts"
    ) {
      if (!formData.quantity) {
        return true;
      } else {
        return false;
      }
    } else if (formData?.observation?.value === "Vegetation") {
      if (!formData.vegetationType) {
        return true;
      } else {
        return false;
      }
    } else if (formData?.observation?.value === "Clearance") {
      if (
        !formData.jumpToTower ||
        !formData.phaseToPhase ||
        !formData.groundClearence ||
        !formData.overHead
      ) {
        return true;
      } else {
        return false;
      }
    } else if (formData?.observation?.value === "Insulators Condition") {
      if (!formData.insulatorsCondition) {
        return true;
      } else {
        return false;
      }
    } else if (formData?.observation?.value === "Testing") {
      if (!formData.testing) {
        return true;
      } else {
        return false;
      }
    } else if (
      formData?.observation?.value === "Condition Of Tower Foundation (Legs)"
    ) {
      if (
        !formData.legA ||
        !formData.legB ||
        !formData.legC ||
        !formData.legD
      ) {
        return true;
      } else {
        return false;
      }
    } else if (formData?.observation?.value === "Stringing") {
      if (!formData.stringing) {
        return true;
      } else {
        return false;
      }
    } else if (formData?.observation?.value === "Others") {
      if (!formData.observationName) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleSave = async () => {
    setLoading(true);
    let observationData = prepareObservationData(formData?.observation?.value);
    let obj = {
      towerId: location?.location?.data?.id,
      componentName: formData?.componentName,
      location: formData?.location?.value,
      componentStatus: formData?.componentStatus?.value,
      comments: formData?.comments,
      observation: formData?.observation?.value,
      observationData: observationData,
    };

    if (props?.data) {
      obj = {
        ...obj,
        id: formData?.id,
        towerComponentDetails: {
          create: [
            {
              defectFile: props?.data?.data[0]?.defectFile,
              modifiedFile: url,
              comments: formData?.comments,
              recommendation: formData?.recommendation,
            },
          ],
        },
      };
      let res = await api.postReq("updatetowercomponent", obj);
      if (res?.data) {
        toast.success("Component updated successfully");
        setLoading(false);
        navigate("/sme-tower-component", { state: location.location });
      } else {
        toast.error(res?.data?.errormessage);
        setLoading(false);
      }
    } else {
      obj = {
        ...obj,
        towerComponentDetails: {
          create: [
            {
              defectFile: props?.image?.defectFile,
              modifiedFile: url,
              comments: formData?.comments,
              recommendation: formData?.recommendation,
            },
          ],
        },
      };
      let res = await api.postReq("addTowerComponent", obj);
      if (res?.data?.status === "Success") {
        toast.success("Component added successfully");
        setLoading(false);
        setDraw(false);
        close();
      } else {
        toast.error(res?.data?.errormessage);
        setLoading(false);
      }
    }
  };

  return (
    <Draggable>
      <Modal isOpen={isOpen} onClosed={() => onclose()} size="sm" centered>
        <ModalBody className="p-3 obs-modal">
          <div className="mb-3">
            <Label className="color-grayl">
              Component Name <span className="astriek"> *</span>{" "}
            </Label>
            <Input
              type="text"
              placeholder="Enter"
              value={formData?.componentName || ""}
              onChange={(e) => {
                if (e.target.value.match(alphabetwithcomma))
                  handleInputChange(e.target.value, "componentName", e);
              }}
            />
          </div>
          <div className="mb-3">
            <Label className="color-grayl">
              Location <span className="astriek"> *</span>
            </Label>
            <Select
              options={locationOptions}
              placeholder="Select"
              value={formData?.location}
              onChange={(e) => handleInputChange(e, "location", e)}
            />
          </div>
          <div className="mb-3">
            <Label className="color-grayl">
              Observation <span className="astriek"> *</span>
            </Label>
            <Select
              options={obsOptions}
              placeholder="Select"
              value={formData?.observation}
              onChange={(e) => handleInputChange(e, "observation", e)}
            />
          </div>
          {handleObservationData(formData?.observation?.value)}
          <div className="mb-3">
            <Label className="color-grayl">
              Severity <span className="astriek"> *</span>
            </Label>
            <Select
              options={severityOptions}
              placeholder="Select"
              value={formData?.componentStatus}
              onChange={(e) => handleInputChange(e, "componentStatus", e)}
            />
          </div>
          <div className="mb-3">
            <Label className="color-grayl">
              Comments <span className="astriek"> *</span>
            </Label>
            <Input
              type="text"
              placeholder="Enter"
              value={formData?.comments}
              onChange={(e) => handleInputChange(e.target.value, "comments", e)}
            />
          </div>
          <div className="mb-3">
            <Label className="color-grayl">Recommendation</Label>
            <Input
              type="text"
              placeholder="Enter"
              value={formData?.recommendation}
              onChange={(e) =>
                handleInputChange(e.target.value, "recommendation", e)
              }
            />
          </div>
        </ModalBody>
        <ModalFooter className="pt-2">
          <Button className="w-sm" color="primary" outline onClick={close}>
            Cancel
          </Button>
          <Button
            className="w-sm bg-primary"
            type="button"
            onClick={handleSave}
            disabled={checkDisable()}
          >
            {props?.data ? "Update" : "Save"}
          </Button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};

export default TxObservation;
