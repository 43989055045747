import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

const DashboardCard = (props: any) => {
  let colors = ["danger", "warning", "info", "success", "light"];
  return (
    <React.Fragment>
      {props?.card1 && (
        <Card>
          <CardBody>
            <div
              className={`p-3  bg-soft-${
                colors[Math.floor(Math.random() * 4)]
              } rounded-top`}
            >
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <h5 className="mb-0 fs-14">
                    <Link to="/apps-projects-overview" className="text-dark">
                      {props?.title}
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="p-3">
              <Row className="gy-3">
                <Col xs={6}>
                  <p className="text-muted mb-1">Number of Corridor</p>
                  <h5 className="fs-13">{props?.noOfCorridor}</h5>
                </Col>
                <Col xs={6}>
                  <p className="text-muted mb-1">Location</p>
                  <h5 className="fs-13">{props?.location}</h5>
                </Col>
              </Row>
            </div>
            <div></div>
          </CardBody>
          <div className="card-footer bg-transparent border-top-dashed py-2">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <div className="d-flex">
                  <p className="text-muted mb-1 me-2">Status :</p>
                  <div className="fs-12 badge badge-soft-success">
                    {props?.status}
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0">
                <div className="text-muted fs-13">
                  <i className="ri-calendar-event-fill me-1 align-bottom"></i>
                  {props?.date}
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
      {props?.card2 && (
        <Card>
          <CardBody>
            <div
              className={`p-3  bg-soft-${
                colors[Math.floor(Math.random() * 4)]
              } rounded-top`}
            >
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <h5 className="mb-0 fs-14">
                    <Link to="/apps-projects-overview" className="text-dark">
                      {props?.title}
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="p-3">
              <Row className="gy-3">
                <Col xs={6}>
                  <p className="text-muted mb-1">Number of Assets</p>
                  <h5 className="fs-13">{props?.noOfAsset}</h5>
                </Col>
                <Col xs={6}>
                  <p className="text-muted mb-1">Location</p>
                  <h5 className="fs-13">{props?.location}</h5>
                </Col>
              </Row>
            </div>
            <div></div>
          </CardBody>
          <div className="card-footer bg-transparent border-top-dashed py-2">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <div className="d-flex">
                  <p className="text-muted mb-1 me-2">Status :</p>
                  <div className="fs-12 badge badge-soft-success">
                    {props?.status}
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0">
                <div className="text-muted fs-13">
                  <i className="ri-calendar-event-fill me-1 align-bottom"></i>
                  {props?.date}
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
      {props?.card3 && (
        <Card>
          <CardBody>
            <div
              className={`p-3  bg-soft-${
                colors[Math.floor(Math.random() * 4)]
              } rounded-top`}
            >
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <h5 className="mb-0 fs-14">
                    <Link to="/apps-projects-overview" className="text-dark">
                      {props?.title}
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="p-3">
              <Row className="gy-3">
                <Col xs={6}>
                  <p className="text-muted mb-1">Number of Survey</p>
                  <h5 className="fs-13">{props?.noOfSurvey}</h5>
                </Col>
                <Col xs={6}>
                  <p className="text-muted mb-1">Location</p>
                  <h5 className="fs-13">{props?.location}</h5>
                </Col>
              </Row>
            </div>
            <div></div>
          </CardBody>
          <div className="card-footer bg-transparent border-top-dashed py-2">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <div className="d-flex">
                  {/* <p className="text-muted mb-1 me-2">Status :</p>
                  <div className="fs-12 badge badge-soft-success">
                    {props?.status}
                  </div> */}
                </div>
              </div>
              <div className="flex-shrink-0">
                <div className="text-muted fs-13">
                  <i className="ri-calendar-event-fill me-1 align-bottom"></i>
                  {props?.date}
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
      {props?.card4 && (
        <Card>
          <CardBody>
            <div
              className={`p-3  bg-soft-${
                colors[Math.floor(Math.random() * 4)]
              } rounded-top`}
            >
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <h5 className="mb-0 fs-14">
                    <Link to="/apps-projects-overview" className="text-dark">
                      {props?.title}
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="p-3">
              <Row className="gy-3">
                <Col xs={6}>
                  <p className="text-muted mb-1">Number of Corridor</p>
                  <h5 className="fs-13">{props?.noOfCorridor}</h5>
                </Col>
                <Col xs={6}>
                  <p className="text-muted mb-1">Location</p>
                  <h5 className="fs-13">{props?.location}</h5>
                </Col>
                <Col xs={6}>
                  <p className="text-muted mb-1">Completed</p>
                  <h5 className="fs-13">{props?.completed}</h5>
                </Col>
                <Col xs={6}>
                  <p className="text-muted mb-1">In Progress</p>
                  <h5 className="fs-13">{props?.inprogress}</h5>
                </Col>
              </Row>
            </div>
            <div></div>
          </CardBody>
          <div className="card-footer bg-transparent border-top-dashed py-2">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <div className="d-flex">
                  <p className="text-muted mb-1 me-2">Status :</p>
                  <div className="fs-12 badge badge-soft-success">
                    {props?.status}
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0">
                <div className="text-muted fs-13">
                  <i className="ri-calendar-event-fill me-1 align-bottom"></i>
                  {props?.date}
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
      {props?.card5 && (
        <Card>
          <CardBody>
            <div
              className={`p-3  bg-soft-${
                colors[Math.floor(Math.random() * 4)]
              } rounded-top`}
            >
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <h5 className="mb-0 fs-14">
                    <Link to="/apps-projects-overview" className="text-dark">
                      {props?.title}
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="p-3">
              <Row className="gy-3">
                <Col xs={6}>
                  <p className="text-muted mb-1">Number of Assets</p>
                  <h5 className="fs-13">{props?.noOfAsset}</h5>
                </Col>
                <Col xs={6}>
                  <p className="text-muted mb-1">Location</p>
                  <h5 className="fs-13">{props?.location}</h5>
                </Col>
                <Col xs={6}>
                  <p className="text-muted mb-1">Completed</p>
                  <h5 className="fs-13">{props?.completed}</h5>
                </Col>
                <Col xs={6}>
                  <p className="text-muted mb-1">In Progress</p>
                  <h5 className="fs-13">{props?.inprogress}</h5>
                </Col>
              </Row>
            </div>
            <div></div>
          </CardBody>
          <div className="card-footer bg-transparent border-top-dashed py-2">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <div className="d-flex">
                  <p className="text-muted mb-1 me-2">Status :</p>
                  <div className="fs-12 badge badge-soft-success">
                    {props?.status}
                  </div>
                </div>
              </div>
              {props?.date && (
                <div className="flex-shrink-0">
                  <div className="text-muted fs-13">
                    <i className="ri-calendar-event-fill me-1 align-bottom"></i>
                    {props?.date}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Card>
      )}
    </React.Fragment>
  );
};

export default DashboardCard;
