import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Badge, Card, Col, Row } from "reactstrap";
import * as api from "../../api/rest.api";
import { createSelectOption } from "../Components/Utils";
import CorridorCard from "./CorridorCard";
import { useSelector } from "react-redux";
import Loader from "../../components/Common/Loader";

const Corridor = () => {
  const navigate = useNavigate();
  const location = useLocation().state;
  const projects = useSelector((state) => state.proj);
  const [loading, setLoading] = useState(false);
  const [corridors, setCorridors] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);
  const [options, setOptions] = useState([]);

  const getCorridors = async (id) => {
    setLoading(true);
    let res = await api.getData(
      `getCorridor?smeStatus=Inspection Done,Completed,In Progress&projectId=${id}`
    );
    if (res?.data) {
      setCorridors(res?.data?.rows);
    } else {
      setCorridors([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    let opt = createSelectOption(projects, "projectName");
    setOptions(opt);
    setSelectedProject({
      label: location.projectName,
      value: location.projectName,
    });
  }, []);

  useEffect(() => {
    if (selectedProject !== null) {
      let project = projects.find(
        (e) => e.projectName === selectedProject.value
      );
      setSelectedProjectDetails(project);
      getCorridors(project.id);
    }
  }, [selectedProject]);


  return (
    <div className="content">
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <Row className="mt-2 mx-3">
        <Col>
          <Select
            className="w-5"
            options={options}
            placeholder="Select Project"
            value={selectedProject}
            onChange={(val) => setSelectedProject(val)}
            isClearable={false}
          ></Select>
        </Col>
        <Col className="text-end text-muted">
          <span
            className="bread-last"
            onClick={() => navigate("/sme-dashboard")}
          >
            Dashboard
          </span>
          {" > "}
          <span className="fs-14">{selectedProject?.value} </span>
        </Col>
      </Row>
      <Row className="mx-4">
        <Card className="p-3">
          <div className="d-flex">
            <div className="text-muted me-5">
              Projects :{" "}
              <span className="fw-500">
                {selectedProjectDetails?.projectName}
              </span>
            </div>
            <div className="text-muted me-5">
              No. Of Corridor :{" "}
              <span className="fw-500">
                {selectedProjectDetails?.totalCorridors}
              </span>
            </div>
            <div className="text-muted me-5">
              Completed :{" "}
              <span className="fw-500">
                {selectedProjectDetails?.completedCorridors}
              </span>
            </div>
            <div className="text-muted me-5">
              Inprogress :{" "}
              <span className="fw-500">
                {selectedProjectDetails?.inProgressCorridors}
              </span>
            </div>
            <div className="text-muted me-5 d-flex align-items-center">
              <span className="me-2">Status : </span>
              {selectedProjectDetails?.projectStatus === "Completed" ? (
                <span className="badge border border-success text-success">
                  {selectedProjectDetails?.projectStatus}
                </span>
              ) : (
                <span className="badge border border-warning text-warning">
                  {selectedProjectDetails?.projectStatus}
                </span>
              )}
            </div>
          </div>
        </Card>
      </Row>
      <Row className="mx-3">
        {corridors.map((corridor, id) => {
          return (
            <Col key={id} md={6}>
              <CorridorCard data={corridor} project={selectedProjectDetails} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Corridor;
