import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthSlider from "../commons/AuthSlider";
import * as globalApi from "../../api/global.api";
import Loader from "../CommonComponent/Loader";
import { ToastContainer } from "react-toastify";
import { addToast, classNameChangeFunction } from "../commons/common";

const ForgotPasswordDetails = () => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [confrimPasswordShow, setConfrimPasswordShow] = useState(false);
  let navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      confrim_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      confrim_password: Yup.string()
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        })
        .required("Confirm Password Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleSubmit = (values) => {
    let resetToken = localStorage.getItem("resetToken");
    let param = { password: values.newpassword, token: resetToken };
    globalApi
      .post("login/resetPassword", param)
      .then((data) => {
        if (data?.status?.toLowerCase() === "failed") {
          addToast("Unable to reset the password", "error");
        } else {
          addToast("Password updated successfully", "success");

          setTimeout(() => {
            localStorage.removeItem("resetToken");
            navigate("/login");
          }, 3000);
        }
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        addToast("Unable to reset the password", "error");
      });
  };

  const togglePasswordChange = (event) => {
    if (event) {
      let numberPattern = new RegExp(/(?=.*?[0-9])/);
      let specialSymbolPattern = new RegExp(/(?=.*?[#?!@$%^&*-])/);
      let lowercasePatteren = new RegExp(/(?=.*?[a-z])/);
      let uppercasePattern = new RegExp(/(?=.*?[A-Z])/);
      let maxlengthPatteren = new RegExp(/.{8,}/);

      if (specialSymbolPattern.test(event))
        classNameChangeFunction("pass-special", "invalid", "valid");
      else classNameChangeFunction("pass-special", "valid", "invalid");
      if (numberPattern.test(event))
        classNameChangeFunction("pass-number", "invalid", "valid");
      else classNameChangeFunction("pass-number", "valid", "invalid");
      if (lowercasePatteren.test(event))
        classNameChangeFunction("pass-lower", "invalid", "valid");
      else classNameChangeFunction("pass-lower", "valid", "invalid");
      if (uppercasePattern.test(event))
        classNameChangeFunction("pass-upper", "invalid", "valid");
      else classNameChangeFunction("pass-upper", "valid", "invalid");
      if (maxlengthPatteren.test(event))
        classNameChangeFunction("pass-length", "invalid", "valid");
      else classNameChangeFunction("pass-length", "valid", "invalid");
    } else {
      classNameChangeFunction("pass-number", "valid", "invalid");
      classNameChangeFunction("pass-special", "valid", "invalid");
      classNameChangeFunction("pass-lower", "valid", "invalid");
      classNameChangeFunction("pass-upper", "valid", "invalid");
      classNameChangeFunction("pass-length", "valid", "invalid");
    }
  };

  const togglePasswordChangeVal = (event) => {
    let flag = [];
    if (event) {
      let numberPattern = new RegExp(/(?=.*?[0-9])/);
      let specialSymbolPattern = new RegExp(/(?=.*?[#?!@$%^&*-])/);
      let lowercasePatteren = new RegExp(/(?=.*?[a-z])/);
      let uppercasePattern = new RegExp(/(?=.*?[A-Z])/);
      let maxlengthPatteren = new RegExp(/.{8,}/);

      if (specialSymbolPattern.test(event)) flag[0] = true;
      else flag[0] = false;
      if (numberPattern.test(event)) flag[1] = true;
      else flag[1] = false;
      if (lowercasePatteren.test(event)) flag[2] = true;
      else flag[2] = false;
      if (uppercasePattern.test(event)) flag[3] = true;
      else flag[3] = false;
      if (maxlengthPatteren.test(event)) flag[4] = true;
      else flag[4] = false;
    } else {
      flag[0] = false;
      flag[1] = false;
      flag[2] = false;
      flag[3] = false;
      flag[4] = false;
    }
    return flag.every((ele) => ele);
  };

  const tooglePasswordStrengthIn = (divID) => {
    var passwordStrengthValidation = document.getElementById(divID);
    passwordStrengthValidation.style.display = "block";
  };
  const tooglePasswordStrengthOut = (divID) => {
    var passwordStrengthValidation = document.getElementById(divID);
    passwordStrengthValidation.style.display = "none";
  };

  return (
    <React.Fragment>
      <Loader loader={spinner} />
      <ToastContainer />
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden">
                  <Row className="justify-content-center g-0">
                    <AuthSlider />
                    <Col lg={6}>
                      <div className="p-lg-5 p-4">
                        <h5 className="text-primary">Create new password</h5>
                        {/* <p className="text-muted">
                          Your new password must be different from previous used
                          password.
                        </p> */}

                        <div className="p-2">
                          <Form
                            onSubmit={validation.handleSubmit}
                            action="/login"
                          >
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="password-input"
                              >
                                Password
                              </Label>
                              <div className="position-relative auth-pass-inputgroup">
                                <Input
                                  onFocus={() =>
                                    tooglePasswordStrengthIn("password-contain")
                                  }
                                  onBlur={(e) => {
                                    validation.handleBlur(e);
                                    togglePasswordChangeVal(
                                      validation.values.newPassword
                                    ) &&
                                      tooglePasswordStrengthOut(
                                        "password-contain"
                                      );
                                  }}
                                  type={passwordShow ? "text" : "password"}
                                  className="form-control pe-5 password-input"
                                  placeholder="Enter password"
                                  id="password-input"
                                  name="password"
                                  value={validation.values.password}
                                  onChange={(e) => {
                                    validation.handleChange(e);
                                    togglePasswordChange(e.target.value);
                                  }}
                                  invalid={
                                    validation.errors.password &&
                                    validation.touched.password
                                      ? true
                                      : false
                                  }
                                />
                                {validation.errors.password &&
                                validation.touched.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                                <Button
                                  color="link"
                                  onClick={() => setPasswordShow(!passwordShow)}
                                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                  id="password-addon"
                                >
                                  <i
                                    className={
                                      "ri-eye-fill align-middle" +
                                      (validation.errors.password
                                        ? " margin-right-15"
                                        : "")
                                    }
                                  ></i>
                                </Button>
                              </div>
                              <div id="passwordInput" className="form-text">
                                Must be at least 8 characters.
                              </div>
                            </div>

                            <div
                              id="password-contain"
                              className="p-3 bg-light mb-2 rounded"
                            >
                              <h5 className="fs-13">Password must contain:</h5>
                              <p
                                id="pass-length"
                                className="invalid fs-12 mb-2"
                              >
                                Minimum <b>8 characters</b>
                              </p>
                              <p id="pass-lower" className="invalid fs-12 mb-2">
                                At least 1 <b>lowercase</b> letter (a-z)
                              </p>
                              <p id="pass-upper" className="invalid fs-12 mb-2">
                                At least 1 <b>uppercase</b> letter (A-Z)
                              </p>
                              <p
                                id="pass-number"
                                className="invalid fs-12 mb-2"
                              >
                                At least 1 <b>number</b> (0-9)
                              </p>
                              <p
                                id="pass-special"
                                className="invalid fs-12 mb-0"
                              >
                                At least 1 <b>Special</b> Symbol
                              </p>
                            </div>

                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="confirm-password-input"
                              >
                                Confirm Password
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  type={
                                    confrimPasswordShow ? "text" : "password"
                                  }
                                  className="form-control pe-5 password-input"
                                  placeholder="Confirm password"
                                  id="confirm-password-input"
                                  name="confrim_password"
                                  value={validation.values.confrim_password}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.confrim_password &&
                                    validation.touched.confrim_password
                                      ? true
                                      : false
                                  }
                                />
                                {validation.errors.confrim_password &&
                                validation.touched.confrim_password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.confrim_password}
                                  </FormFeedback>
                                ) : null}
                                <Button
                                  color="link"
                                  onClick={() =>
                                    setConfrimPasswordShow(!confrimPasswordShow)
                                  }
                                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                >
                                  <i
                                    className={
                                      "ri-eye-fill align-middle" +
                                      (validation.errors.confrim_password
                                        ? " margin-right-15"
                                        : "")
                                    }
                                  ></i>
                                </Button>
                              </div>
                            </div>

                            <div className="mt-4">
                              <Button
                                color="success"
                                className="w-100"
                                type="submit"
                                disabled={
                                  validation.errors.password ||
                                  validation.values.password === "" ||
                                  !validation.values.password ||
                                  !validation.values.confrim_password ||
                                  validation.values.confrim_password === ""
                                }
                              >
                                Reset Password
                              </Button>
                            </div>
                          </Form>
                        </div>

                        <div className="mt-5 text-center">
                          <p className="mb-0">
                            Wait, I remember my password...{" "}
                            <Link
                              to="/login"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              {" "}
                              Click here{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPasswordDetails;
