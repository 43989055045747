import { Dialog } from "primereact/dialog";
import img401 from "../../assets/401.png";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Valid } from "../../store/reducer";
import { Survey } from "../../store/comparereducer";
import { projectdata } from "../../store/dashboardreducer";
import { miningTsx } from "../../store/miningstore";
import { landSurveyTsx } from "../../store/landsurveyreducer";
import { useEffect, useState } from "react";
import { layersTsx } from "../../store/layersReducer";

const UnAuthDialog = (props: any) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  var time = 10;
  const [timer, setTimer] = useState(10);
  useEffect(() => {
    if (props.visible)
      setInterval(() => {
        if (time > 1) {
          time = time - 1;
          setTimer(time);
        } else {
          logout();
        }
      }, 1000);
  }, [time]);

  const unAuth = () => {
    return <div id="time">Logout in {timer}s</div>;
  };
  const logout = () => {
    dispatch(Valid({}));
    dispatch(Survey({}));
    dispatch(projectdata({}));
    dispatch(miningTsx({}));
    dispatch(landSurveyTsx({}));
    dispatch(layersTsx({}));
    navigate("/login");
  };
  return (
    <Dialog
      visible={props.visible}
      onHide={props.onHide}
      closable={false}
      closeOnEscape={false}
      header={unAuth}
      draggable={false}
    >
      <img src={img401} />
      <div className="m-t-10 surveys">
        <Button className="btn-style btn-primary" onClick={logout}>
          Logout
        </Button>
      </div>
    </Dialog>
  );
};

export default UnAuthDialog;
