import * as api from "./api";

const getSurvey = async (projectid: String) => {
  var url = "survey/" + projectid; //signin
  return api
    .getReq(url, {})
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err;
    });
};

const getSourceid = async (surveyid: String) => {
  var url = "sources/" + surveyid;
  return api
    .getReq(url, {})
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err;
    });
};
const getlayers = async (sourceid: String) => {
  var url = "vectorlayer/" + sourceid;
  return api
    .getReq(url, {})
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err;
    });
};

const getAllProjects = async (project: any) => {
  var url = `project/find/${project.id}`;
  return api
    .getReq(url, {})
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err;
    });
};
const postlayers = async (sources: any) => {
  var url = "vectorlayer/id";
  var data = sources;
  return api
    .postReq(url, data, {})
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err;
    });
};

const getAllsources = async (survey: any) => {
  var url = "sources/data";
  var data = survey;
  return api
    .postReq(url, data, {})
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err;
    });
};

const getAllsurvey = async (projects: any) => {
  var url = "survey/latlang";
  var data = projects;
  return api
    .postReq(url, data, {})
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err;
    });
};

const getProjectsByUserId = async (user: any) => {
  let url = `projects/get?module=${user.selectedModule}&roleCode=${user.selectedRole}&userID=${user.id}`;
  return api
    .getReq(url, {})
    .then((data) => {
      if (data.data) return data.data.response;
      if (data === 401) return data;
    })
    .catch((err) => {
      return err;
    });
};
const getCorridorsByProjectId = async (projectid: any) => {
  let url = `getCorridor?smeStatus=Inspection Done,Completed,In Progress&projectId=${projectid}`;

  return api
    .getReq(url, {})
    .then((data) => {
      if (data.data) return data.data.response;
      if (data === 401) return data;
    })
    .catch((err) => {
      return err;
    });
};

const getCorridorsBySourceId = async (id: any) => {
  let url = `getCorridor?sourcesId=${id}`;
  return api
    .getReq(url, {})
    .then((data) => {
      if (data.data) return data.data.response;
      if (data === 401) return data;
    })
    .catch((err) => {
      return err;
    });
};
const getTowerUploads = async (corridorId: any) => {
  let url = `getTowerUploads?towerId=${corridorId}&type=client_screenshot`;
  return api.getReq(url, {}).then((data) => {
    if (data.data) return data.data;
    if (data === 401) return data;
  });
};

const xlReportDownload = async (ele: any) => {
  let url = `corridor/report`;

  return api.postReq(url, ele, {}).then((data) => {
    if (data.data) return data.data.response;
    if (data === 401) return data;
  });
};

const pdfReportGen = async (towerId: any) => {
  let url = `towerReportGeneration/${towerId}`;
  return api
    .getReq(url, {})
    .then((data) => {
      if (data.data) return data.data;
      if (data === 401) return data;
    })
    .catch((err) => {
      return err;
    });
};

const getTowersByCorridorId = async (corridor: any) => {
  let url = `getTower?corridorId=${corridor.id}&page=${corridor.page}&limit=${corridor.limit}&fileType=client_screenshot`;
  return api
    .getReq(url, {})
    .then((data) => {
      if (data.data) return data.data.response;
      if (data === 401) return data;
    })
    .catch((err) => {
      return err;
    });
};
const getSourcesByProjectId = async (id: any) => {
  let url = `sources/find?projectId=${id}`;
  return api
    .getReq(url, {})
    .then((data) => {
      if (data.data) return data.data.response;
      if (data === 401) return data;
    })
    .catch((err) => {
      return err;
    });
};

const getLayers = async (sourceIds: any) => {
  let url = "vectorlayer/get";
  return api
    .postReq(url, sourceIds)
    .then((data) => {
      if (data.data) return data.data;
    })
    .catch((err) => {
      return err;
    });
};

const addScreenshotRecords = async (payload: any) => {
  let url: any = "";
  if (payload?.type === "survey_screenshot") {
    url = "multipleSurveyScreenshotUpload";
  } else {
    url = "multipleScreenshot";
  }
  return api
    .postReq(url, payload)
    .then((data) => {
      if (data.data) return data.data;
    })
    .catch((err) => {
      return err;
    });
};

const updateTowerStatus = async (data: any) => {
  let url = "updateTower";
  return api
    .putReq(url, data)
    .then((data) => {
      if (data.data) return data.data;
    })
    .catch((err) => {
      return err;
    });
};
const getReportsBySurveyID = async (surveyid: any) => {
  let url = "report/get/" + surveyid + "?page=0&limit=100";
  return api
    .getReq(url)
    .then((data) => {
      if (data.data) return data.data;
    })
    .catch((err) => {
      return err;
    });
};

const getDownloadsBySurveyID = async (surveyid: any) => {
  if (surveyid !== undefined) {
    let url = "download/get/" + surveyid + "?page=0&limit=100";
    return api
      .getReq(url)
      .then((data) => {
        if (data.data) return data.data;
      })
      .catch((err) => {
        return err;
      });
  }
};

const getDynamicLayers = async (surveyid: String) => {
  var url = "getDynamicLayers/?surveyId=" + surveyid;
  return api
    .getReq(url, {})
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err;
    });
};
const getTowerNumsByCorridorId = async (corridorId: any) => {
  let url = `getTowerNo/${corridorId}`;
  return await api.getReq(url, {}).then((data) => {
    if (data.data) return data.data;
    if (data === 401) return data;
  });
};

// getting towers based on corridor and tower id's
const getTowerByCorridorId = async (data: any) => {
  let url = `getTower?corridorId=${data.corridorId}&towerNo=${data.towerNo.value}&fileType=client_screenshot`;
  return await api.getReq(url, {}).then((data) => {
    if (data.data) {
      let data1 = data?.data?.response.rows?.map((item: any) => {
        let idx = item?.towerComponents?.filter(
          (ele: any) => !!ele?.actionClosedDate === false
        );
        if (idx?.length > 0) {
          return { ...item, openStatus: "Open" };
        } else {
          return { ...item, openStatus: "Closed" };
        }
      });
      return data1;}
    if (data === 401) return data;
  });
};

const downloadExistingLayer =async(data:any) =>{
  let url = `vectorExportTool`
  return await api.postReq(url, data).then(res =>{
    if(res.data){
      return res?.data
    }
  })
}

const downloadSHPLayer =async(data:any) =>{
  let url = `exportTool`
  return await api.postReq(url, data).then(res =>{
    if(res.data){
      return res?.data
    }
  })
}

const getVideos = async(id:any) =>{
  let url = `getsurveyvideouploads?surveyId=` + id
  return await api.getReq(url).then(res =>{
    if(res.data){
      return res?.data
    }
  })
}

const deleteFeatureLayer =async(layer:any) =>{
  let url = "deleteFeatureDynamicLayers" + "/"+ layer 
  return await api.deleteReq(url).then(res=>{
    if(res.data){
      return res.data
    }
})
}
export {
  getSurvey,
  getSourceid,
  getlayers,
  getAllProjects,
  postlayers,
  getAllsources,
  getAllsurvey,
  getProjectsByUserId,
  getCorridorsByProjectId,
  getTowersByCorridorId,
  getLayers,
  getSourcesByProjectId,
  getReportsBySurveyID,
  getDownloadsBySurveyID,
  getDynamicLayers,
  getCorridorsBySourceId,
  addScreenshotRecords,
  updateTowerStatus,
  getTowerUploads,
  pdfReportGen,
  xlReportDownload,
  getTowerNumsByCorridorId,
  getTowerByCorridorId,
  downloadExistingLayer,
  downloadSHPLayer,
  getVideos,
  deleteFeatureLayer
};
