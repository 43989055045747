import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getDateOnly } from "../Components/Utils";

const ProjectCard = ({ data }) => {
 const navigate = useNavigate()
  let colors = ["danger", "warning", "info", "success", "light"];
  return (
    <React.Fragment>
      <Card onClick={()=> navigate(`/sme-projects/assets/${data.id}`)}>
        <CardBody>
          <div
            className={`p-3  bg-soft-${
              colors[Math.floor(Math.random() * 4)]
            } rounded-top`}
          >
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="mb-0 fs-14">
                    {data?.projectName}
                </h5>
              </div>
            </div>
          </div>
          <div className="p-3">
            <Row className="gy-3">
              <Col xs={6}>
                <p className="text-muted mb-1">Number of Assets</p>
                <h5 className="fs-13">{data?.totalAssets}</h5>
              </Col>
              <Col xs={6}>
                <p className="text-muted mb-1">Location</p>
                <h5 className="fs-13">{data?.location}</h5>
              </Col>
            </Row>
          </div>
        </CardBody>
        <div className="card-footer bg-transparent border-top-dashed py-2">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <div className="d-flex">
                <p className="text-muted mb-1 me-2">Status :</p>
                <div className="fs-12 badge badge-soft-success">
                  {data?.projectStatus}
                </div>
              </div>
            </div>
            <div className="flex-shrink-0">
              <div className="text-muted fs-13">
                <i className="ri-calendar-event-fill me-1 align-bottom"></i>
                {getDateOnly(data?.createdAt)}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default ProjectCard;
