import React, { useEffect, useState } from "react";
import Select from "react-select";
import { createSelectOption, getDateOnly } from "../Components/Utils";
const TopPanal = (props) => {
  const [projectOptions, setProjectOptions] = useState([]);
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    let projectArr = createSelectOption(props?.allProject, "projectName");
    setProjectOptions(projectArr);
    let projectName = props?.selectedProject?.projectName;
    setSelectedProject({ label: projectName, value: projectName });
    let surveyArr = createSelectOption(props?.allSurvey, "surveyName");
    setSurveyOptions(surveyArr);
    let surveyName = props?.selectedSurvey?.surveyName;
    setSelectedSurvey({ label: surveyName, value: surveyName });
    let dateArr = createSelectOption(props?.allSurvey, "dateOfSurvey");
    setDateOptions(dateArr);
    let date = getDateOnly(props?.selectedSurvey?.dateOfSurvey);
    setSelectedDate({ label: date, value: date });
  }, [props]);

  return (
    <div className="top-panal">
      <Select
        className="me-2"
        options={projectOptions}
        value={selectedProject}
        onChange={props?.onProjectChange}
      />
      <Select
        className="me-2"
        options={surveyOptions}
        value={selectedSurvey}
        onChange={props?.onSurveyChange}
      />
      <Select className="me-3" options={dateOptions} value={selectedDate} />
    </div>
  );
};

export default TopPanal;
