import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  survey: {},
};

export const userSlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    surveyTxn: (state, action) => {
      return action.payload;
    },
  },
});

export const { surveyTxn } = userSlice.actions;
export default userSlice.reducer;
