import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../CommonComponent/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../../header/Header";
import { OffcanvasHeader, ToastContainer } from "react-bootstrap";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  Row,
} from "reactstrap";
import {
  Capitalize,
  checkFeatures,
  formatDate,
} from "../../CommonComponent/CommonFunctions";
import * as api from "../../../api/global.api";
import { toast } from "react-toastify";
import { Donut } from "../../admin/Dashboard/Charts";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import { useAppSelector } from "../../../store/hooks";

const ClientAssets = () => {
  const asset = useLocation()?.state?.data;
  const userPriv = useAppSelector((state) => state.user)?.selectedFeatures;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [observation, setObservation] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [row, setRow] = useState([]);
  const [isOffcanvas, setIsOffcanvas] = useState(null);
  const [actionOffcanvas, setActionOffcanvas] = useState(false);
  const [offChat, setChart] = useState({ label: [], series: [] });
  const [form, setForm] = useState({ action: "Open", comments: "" });
  const [lightboxImage, setLightboxImage] = useState({
    image: "",
    comment: "",
  });

  const getAssetsComponents = (val) => {
    setLoading(true);
    let url = "getAssetComponents" + val;
    api.get(url).then((res) => {
      if (res?.status !== "Failed") {
        setData(res?.response?.assetComponentList);
        let result = res?.response?.assetComponentList?.filter(
          (item) =>
            item?.actionStatus === "Open" && item?.operability !== "SAFE"
        );
        if (result?.length === 0) {
          setIsActive(false);
        }
        setLoading(false);
      } else {
        toast.error(res?.errormessage);
        setLoading(false);
      }
    });
  };

  const getObservation = async (val) => {
    setLoading(true);
    let url = "observationWiseView" + val;
    await api.get(url).then((res) => {
      if (res?.status !== "Failed") {
        setObservation(res?.response);
        setLoading(false);
      } else {
        toast.error(res?.errormessage);
        setLoading(false);
      }
    });
  };

  const getobservationWiseData = async (val, rows) => {
    setLoading(true);
    let url = "observationWiseData" + val;
    return await api
      .get(url)
      .then((res) => {
        if (res?.status !== "Failed") {
          setChart({
            label: Object.keys(res?.response),
            series: Object.values(res?.response),
          });
          setRow(rows);
          setLoading(false);
        } else {
          toast.error(res.errormessage);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const onChartClick = async (label, name, obs) => {
    let newObs = obs?.replaceAll(" ", "_")?.toUpperCase();
    await setData((prevData) => {
      let newData = [...prevData];
      let rows = prevData.filter((item) => {
        console.log(item);
        return item.componentName === name && item.observation === newObs;
      });
      console.log(rows);
      if (rows.length > 0)
        getobservationWiseData(
          `?assetId=${rows[0]?.assetId}&observation=${rows[0]?.observation}&componentName=${rows[0]?.componentName}`,
          rows
        );
      return newData;
    });
  };

  const handleStatusSubmit = async () => {
    let obj = {
      ...isOffcanvas,
      actionStatus: form.action,
      comments: form.comments,
      severity: form.action === "Closed" ? "LOW" : isOffcanvas?.severity,
      operability: form.action === "Closed" ? "SAFE" : isOffcanvas?.operability,
      componentDetails: {
        create: isOffcanvas?.componentDetails,
      },
    };
    api.put("updateAssetComponent", obj).then((res) => {
      if (res?.status !== "Failed") {
        getAssetsComponents(`?assetId=${asset?.id}`);
        toast.success("Action status updated successfully");
        setForm({ action: "Open", comments: "" });
        setIsOffcanvas(null);
        setRow([]);
      } else {
        toast.error(res.errormessage);
      }
    });
  };

  const handleActionSubmit = () => {
    setLoading(true);
    let obj = {
      projectId: asset?.projectId,
      id: asset?.id,
      actionStatus: form?.action,
      clientComments: form?.comments,
      severityStatus: form?.action === "Closed" ? "SAFE" : asset.severityStatus,
    };

    api
      .put("updateAsset", obj)
      .then((res) => {
        if (res?.errormessage) {
          toast.error(res.errormessage, { autoClose: 3000 });
          setLoading(false);
        } else {
          toast.success("Action Status Updated Successfully", {
            autoClose: 3000,
          });
          navigate(-1);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error, { autoClose: 3000 });
        setLoading(false);
      });
  };

  useEffect(() => {
    getAssetsComponents(`?assetId=${asset?.id}`);
    getObservation(`/${asset?.id}`);
  }, []);

  return (
    <div className="page-content ps-0">
      <Header />
      <Loader loader={loading} />
      <ToastContainer />
      {lightboxImage.image !== "" && (
        <Lightbox
          mainSrc={lightboxImage.image}
          imageCaption={lightboxImage.comment}
          onCloseRequest={() => setLightboxImage({ image: "", comment: "" })}
        />
      )}
      <div style={{ overflowY: "auto", overflowX: "hidden" }}>
        <Row className="px-4 fs-18">
          <Col className="d-flex align-items-center">
            <i
              className=" ri-arrow-left-s-line fs-20 align-bottom pointer primary"
              onClick={() => navigate("/client-projects")}
            ></i>
            <span
              className="pointer primary"
              onClick={() => navigate("/client-projects")}
            >
              Dashboard /&nbsp;
            </span>
            <span className="pointer primary" onClick={() => navigate(-1)}>
              Oil and Gas Projects /&nbsp;
            </span>
            <span className="breadcrumb-site-name">
              {Capitalize(asset?.assetName)}
            </span>
          </Col>
        </Row>
        <Row className="px-5 mb-4 d-flex justify-content-between">
          <Col className="d-flex justify-content-start align-items-center">
            <div className="me-4 cursor-none">
              {Capitalize(asset?.assetName)}
            </div>
            <Button
              color={
                asset?.severityStatus === "HIGH_RISK"
                  ? "danger"
                  : asset?.severityStatus === "MEDIUM_RISK"
                  ? "warning"
                  : "success"
              }
              className="me-4"
            >
              {Capitalize(asset?.severityStatus?.replace("_", " "))}
            </Button>
            {checkFeatures(
              "Status actioning for assets - Oil and Gas",
              userPriv
            ) &&
              asset?.actionStatus !== "Closed" && (
                <Button
                  color="success"
                  disabled={isActive}
                  onClick={() => {
                    setActionOffcanvas(true);
                    setForm({
                      action: asset?.actionStatus,
                      comments: asset?.clientComments
                        ? asset?.clientComments
                        : "",
                    });
                  }}
                >
                  Action Status
                </Button>
              )}
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <i className="ri-calendar-event-fill me-2 align-bottom ri-lg success"></i>
            <span className="fs-14 success">Inspection Date :</span>
            <span className="fs-14 ps-2">
              {formatDate(asset?.capturedDate)}
            </span>
          </Col>
        </Row>
        <Row className="px-5">
          {checkFeatures("View asset charts - Oil and Gas", userPriv) && (
            <Col lg={6} style={{ overflowY: "auto", height: "80vh" }}>
              <h5>Component wise Observation Count :</h5>
              <Row>
                {observation?.map((item, idx) => {
                  let label = item?.componentData?.map((e) => e.componentName);
                  let series = item?.componentData?.map((e) => e.count);
                  return (
                    <Col lg={4} key={idx}>
                      {idx % 4 === 0 && (
                        <Card className="brd-none">
                          <CardHeader>{item?.observation}</CardHeader>
                          <CardBody>
                            <Donut
                              legends={true}
                              labelName={label || []}
                              series={series || []}
                              height={330}
                              labelCenter={"Total"}
                              size="90%"
                              dataColors='["#8a3ffc", "#33b1ff", "#007d79", "#ff7eb6", "#fa4d56"]'
                              selectedData={(label, name) =>
                                onChartClick(label, name, item?.observation)
                              }
                            />
                          </CardBody>
                        </Card>
                      )}
                      {idx % 4 === 1 && (
                        <Card className="brd-none">
                          <CardHeader>{item?.observation}</CardHeader>
                          <CardBody>
                            <Donut
                              legends={true}
                              height={330}
                              labelName={label || []}
                              series={series || []}
                              labelCenter={"Total"}
                              size="50%"
                              dataColors='["#fa4d56","#ff7eb6", "#8a3ffc", "#33b1ff", "#007d79"]'
                              selectedData={(label, name) =>
                                onChartClick(label, name, item?.observation)
                              }
                            />
                          </CardBody>
                        </Card>
                      )}
                      {idx % 4 === 2 && (
                        <Card className="brd-none">
                          <CardHeader>{item?.observation}</CardHeader>
                          <CardBody>
                            <Donut
                              legends={true}
                              labelName={label || []}
                              series={series || []}
                              height={330}
                              size="0%"
                              dataColors='["#33b1ff", "#007d79", "#8a3ffc", "#33b1ff", "#007d79"]'
                              labelCenter={"Total"}
                              selectedData={(label, name) =>
                                onChartClick(label, name, item?.observation)
                              }
                            />
                          </CardBody>
                        </Card>
                      )}
                      {idx % 4 === 3 && (
                        <Card className="brd-none">
                          <CardHeader>{item?.observation}</CardHeader>
                          <CardBody>
                            <Donut
                              legends={true}
                              height={330}
                              labelName={label || []}
                              series={series || []}
                              labelCenter={"Total"}
                              size="75%"
                              dataColors='["#007d79", "#ff7eb6", "#8a3ffc", "#33b1ff", "#fa4d56"]'
                              selectedData={(label, name) =>
                                onChartClick(label, name, item?.observation)
                              }
                            />
                          </CardBody>
                        </Card>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          )}
          {checkFeatures("View asset summary - Oil and Gas", userPriv) && (
            <Col lg={6} style={{ overflowY: "auto", height: "80vh" }}>
              <h5>Asset Summary :</h5>
              <Row>
                {data?.map((item, idx) => {
                  return (
                    <Col lg={12} key={idx}>
                      {item?.componentDetails?.length > 1 ? (
                        <Swiper
                          slidesPerView={1}
                          spaceBetween={8}
                          mousewheel={true}
                          navigation={true}
                          modules={[Navigation, Pagination, Scrollbar]}
                          pagination={{ clickable: true }}
                          className="swiper product-thumbnail-slider p-2 rounded bg-light"
                        >
                          {item?.componentDetails?.map((obj, key) => (
                            <SwiperSlide key={key}>
                              <Card className="p-2 px-4 brd-none">
                                <Row>
                                  <Col lg={8}>
                                    <Row className="m-0 p-0">
                                      <Col>
                                        <div className="fs-14">Observation</div>
                                        <div className="fs-12">
                                          {Capitalize(
                                            item?.observation?.replace("_", " ")
                                          )}
                                        </div>
                                      </Col>
                                      <Col>
                                        <div className="fs-14">
                                          Component Name
                                        </div>
                                        <div className="fs-12">
                                          {Capitalize(
                                            item?.componentName?.replace(
                                              "_",
                                              " "
                                            )
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="m-0 p-0">
                                      <Col>
                                        <div className="fs-14">Severity</div>
                                        <span
                                          className={`fs-12 ${item.severity}`}
                                        >
                                          {Capitalize(
                                            item?.severity?.replace("_", " ")
                                          )}
                                        </span>
                                      </Col>
                                      <Col>
                                        <div className="fs-14">Operablity</div>

                                        <span
                                          className={`fs-12 ${item.operability}`}
                                        >
                                          {Capitalize(
                                            item?.operability?.replace("_", " ")
                                          )}
                                        </span>
                                      </Col>
                                      {!!item?.location && (
                                        <Col>
                                          <div className="fs-14">Location</div>
                                          <div className="fs-12">
                                            {Capitalize(item?.location)}
                                          </div>
                                        </Col>
                                      )}
                                    </Row>
                                    <Row className="m-0 p-0">
                                      {!!obj?.comments && (
                                        <Col>
                                          <div className="fs-14">Comments</div>
                                          <div className="fs-12">
                                            {obj?.comments}
                                          </div>
                                        </Col>
                                      )}
                                      {!!item?.comments && (
                                        <Col>
                                          <div className="fs-14">
                                            Review Comments
                                          </div>
                                          <div className="fs-12">
                                            {item?.comments}
                                          </div>
                                        </Col>
                                      )}
                                    </Row>
                                  </Col>
                                  <Col lg={4}>
                                    <span className="position-absolute top-0 start-0 bg-success text-white p-1 ms-2 fs-10">
                                      {item?.observation}
                                    </span>
                                    <span className="position-absolute top-0 end-0 text-white p-1 me-2 fs-10">
                                      <i
                                        className=" bx bx-fullscreen bx-sm text-white"
                                        onClick={() =>
                                          setLightboxImage({
                                            image: obj?.defectFile,
                                            comment: obj?.comments,
                                          })
                                        }
                                      ></i>
                                    </span>
                                    <img src={obj?.defectFile} />
                                  </Col>
                                </Row>
                              </Card>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      ) : (
                        <Card className="p-2 px-4 brd-none">
                          <Row>
                            <Col lg={8}>
                              <Row className="m-0 p-0">
                                <Col>
                                  <div className="fs-14">Observation</div>
                                  <div className="fs-12">
                                    {Capitalize(
                                      item?.observation?.replace("_", " ")
                                    )}
                                  </div>
                                </Col>
                                <Col>
                                  <div className="fs-14">Component Name</div>
                                  <div className="fs-12">
                                    {Capitalize(
                                      item?.componentName?.replace("_", " ")
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="m-0 p-0">
                                <Col>
                                  <div className="fs-14">Severity</div>
                                  <span className={`fs-12 ${item.severity}`}>
                                    {Capitalize(
                                      item?.severity?.replace("_", " ")
                                    )}
                                  </span>
                                </Col>
                                <Col>
                                  <div className="fs-14">Operablity</div>
                                  <span className={`fs-12 ${item.operability}`}>
                                    {Capitalize(
                                      item?.operability?.replace("_", " ")
                                    )}
                                  </span>
                                </Col>
                                {!!item?.location && (
                                  <Col>
                                    <div className="fs-14">Location</div>
                                    <div className="fs-12">
                                      {Capitalize(item?.location)}
                                    </div>
                                  </Col>
                                )}
                              </Row>
                              <Row className="m-0 p-0">
                                {!!item?.componentDetails[0]?.comments && (
                                  <Col>
                                    <div className="fs-14">Comments</div>
                                    <div className="fs-12">
                                      {item?.componentDetails[0]?.comments}
                                    </div>
                                  </Col>
                                )}
                                {!!item?.comments && (
                                  <Col>
                                    <div className="fs-14">Review Comments</div>
                                    <div className="fs-12">
                                      {item?.comments}
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                            <Col lg={4}>
                              <span className="position-absolute top-0 start-0 bg-success text-white p-1 ms-2 fs-10">
                                {item?.observation}
                              </span>
                              <span className="position-absolute top-0 end-0 text-white p-1 me-2 fs-10">
                                <i
                                  className=" bx bx-fullscreen bx-sm text-white"
                                  onClick={() =>
                                    setLightboxImage({
                                      image:
                                        item?.componentDetails[0]?.defectFile,
                                      comment:
                                        item?.componentDetails[0]?.comments,
                                    })
                                  }
                                ></i>
                              </span>
                              <img
                                src={item?.componentDetails[0]?.defectFile}
                                className="img-fluid"
                              />
                            </Col>
                          </Row>
                        </Card>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          )}
        </Row>
        {row?.length > 0 && (
          <Offcanvas
            isOpen={row?.length > 0}
            direction="end"
            backdrop={false}
            onClosed={() => setRow([])}
            style={{ width: "600px" }}
          >
            <OffcanvasHeader className="bg-success text-light">
              {Capitalize(row[0]?.observation.replace("_", " "))}
            </OffcanvasHeader>
            <OffcanvasBody>
              <Row>
                <Col lg={12}>
                  <Card className="brd-none">
                    <CardHeader className="d-flex justify-content-between">
                      Severity wise count -{" "}
                      {Capitalize(row[0]?.observation?.replace("_", " "))}
                      <div>
                        <i className="ri-calendar-event-fill me-1 align-bottom ri-lg success"></i>
                        <span className="fs-14 success">Inspection Date :</span>
                        <span className="fs-14 ps-2">
                          {formatDate(asset?.capturedDate)}
                        </span>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Donut
                        legends={true}
                        labelName={offChat?.label || []}
                        series={offChat?.series || []}
                        height={330}
                        labelCenter={"Total"}
                        size="80%"
                        dataColors='["#33b1ff", "#007d79", "#ff7eb6"]'
                        selectedData={(label, name) => console.log("")}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="m-0 p-0">
                {row?.map((item, idx) => {
                  return (
                    <Col lg={12} key={idx}>
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={8}
                        mousewheel={true}
                        navigation={true}
                        modules={[Navigation, Pagination, Scrollbar]}
                        pagination={{ clickable: true }}
                        className="swiper product-thumbnail-slider p-2 rounded bg-light"
                      >
                        {row[0]?.componentDetails?.map((obj, key) => (
                          <SwiperSlide key={key}>
                            <Card className="p-2 px-4 brd-none">
                              <Row className="m-0 p-0">
                                <Col lg={8}>
                                  <Row className="m-0 p-0">
                                    <Col>
                                      <div className="fs-14">Observation</div>
                                      <div className="fs-12">
                                        {Capitalize(
                                          item?.observation?.replace("_", " ")
                                        )}
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="fs-14">
                                        Component Name
                                      </div>
                                      <div className="fs-12">
                                        {Capitalize(
                                          item?.componentName?.replace("_", " ")
                                        )}
                                      </div>
                                    </Col>
                                    {checkFeatures(
                                      "Status Actioning at component level - Oil and Gas",
                                      userPriv
                                    ) &&
                                      item?.actionStatus === "Open" && (
                                        <Col>
                                          <Button
                                            color="success"
                                            className="fs-12"
                                            onClick={() => {
                                              setForm({
                                                action: item?.actionStatus,
                                                comments: item.comments,
                                              });
                                              setIsOffcanvas(item);
                                            }}
                                          >
                                            Action Status
                                          </Button>
                                        </Col>
                                      )}
                                  </Row>
                                  <Row className="m-0 p-0">
                                    <Col>
                                      <div className="fs-14">Severity</div>
                                      <span
                                        className={`fs-12 ${item.severity}`}
                                      >
                                        {Capitalize(
                                          item?.severity?.replace("_", " ")
                                        )}
                                      </span>
                                    </Col>
                                    <Col>
                                      <div className="fs-14">Operablity</div>
                                      <span
                                        className={`fs-12 ${item.operability}`}
                                      >
                                        {Capitalize(
                                          item?.operability?.replace("_", " ")
                                        )}
                                      </span>
                                    </Col>
                                    {!!item?.location && (
                                      <Col>
                                        <div className="fs-14">Location</div>
                                        <div className="fs-12">
                                          {Capitalize(item?.location)}
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                  <Row className="m-0 p-0">
                                    {!!obj?.comments && (
                                      <Col>
                                        <div className="fs-14">Comments</div>
                                        <div className="fs-12">
                                          {obj?.comments}
                                        </div>
                                      </Col>
                                    )}
                                    {!!item?.comments && (
                                      <Col>
                                        <div className="fs-14">
                                          Review Comments
                                        </div>
                                        <div className="fs-12">
                                          {item?.comments}
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                                <Col lg={4}>
                                  <span className="position-absolute top-0 start-0 bg-success text-white p-1 ms-2 fs-10">
                                    {item?.observation}
                                  </span>
                                  <span className="position-absolute top-0 end-0 text-white p-1 me-2 fs-10">
                                    <i
                                      className=" bx bx-fullscreen bx-sm text-white"
                                      onClick={() =>
                                        setLightboxImage({
                                          image: obj?.defectFile,
                                          comment: obj?.comments,
                                        })
                                      }
                                    ></i>
                                  </span>
                                  <img src={obj?.defectFile} />
                                </Col>
                              </Row>
                            </Card>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </Col>
                  );
                })}
              </Row>
            </OffcanvasBody>
            <Row className="position-absolute bottom-0 right-0" style={{zIndex:1}}>
              <Col className="d-flex justify-content-end w-100 me-4">
                <Button
                  color="success"
                  outline
                  onClick={() => {
                    setRow([]);
                    setForm({ action: "Open", commnets: "" });
                  }}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Offcanvas>
        )}
        {isOffcanvas !== null && (
          <Offcanvas
            isOpen={isOffcanvas !== null}
            direction="end"
            backdrop={false}
            onClose={() => setIsOffcanvas(null)}
            style={{ width: "600px" }}
            className="offcanvas-end border-0"
          >
            <OffcanvasHeader className="bg-success text-light">
              Status Change
            </OffcanvasHeader>
            <OffcanvasBody>
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="siteName" className="form-label text-muted">
                      Status
                    </Label>
                    <span className="astriek">*</span>
                    <Input
                      type="select"
                      name="status"
                      id="event-status"
                      onChange={(event) => {
                        setForm({ ...form, action: event.target.value });
                      }}
                      value={form.action}
                    >
                      <option value="Closed">Closed</option>
                      <option value="Open">Open</option>
                    </Input>
                  </div>
                </Col>

                <Col xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="siteName" className="form-label text-muted">
                      Comments
                    </Label>
                    <span className="astriek">*</span>
                    <textarea
                      className="form-control d-block"
                      id="comment"
                      name="comment"
                      placeholder="Enter Comments"
                      rows="3"
                      onChange={(event) => {
                        setForm({ ...form, comments: event.target.value });
                      }}
                      value={form.comments}
                    ></textarea>
                  </div>
                </Col>
              </Row>
              <Row className="position-absolute bottom-0 right-0">
                <Col className="d-flex justify-content-end w-100 me-4">
                  <Button
                    color="success"
                    outline
                    onClick={() => setIsOffcanvas(null)}
                    className="me-4 w-100"
                  >
                    Close
                  </Button>
                  <Button
                    color="success"
                    onClick={() => handleStatusSubmit()}
                    className="w-100"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </OffcanvasBody>
          </Offcanvas>
        )}

        {actionOffcanvas && (
          <Offcanvas
            isOpen={actionOffcanvas}
            direction="end"
            backdrop={false}
            onClose={() => setActionOffcanvas(false)}
            style={{ width: "600px" }}
            className="offcanvas-end border-0"
          >
            <OffcanvasHeader className="bg-success text-light">
              Status Change
            </OffcanvasHeader>
            <OffcanvasBody>
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="siteName" className="form-label text-muted">
                      Status
                    </Label>
                    <span className="astriek">*</span>
                    <Input
                      type="select"
                      name="status"
                      id="event-status"
                      onChange={(event) => {
                        setForm({ ...form, action: event.target.value });
                      }}
                      value={form.action}
                    >
                      <option value="Closed">Closed</option>
                      <option value="Open">Open</option>
                    </Input>
                  </div>
                </Col>

                <Col xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="siteName" className="form-label text-muted">
                      Comments
                    </Label>
                    <span className="astriek">*</span>
                    <textarea
                      className="form-control d-block"
                      id="comment"
                      name="comment"
                      placeholder="Enter Comments"
                      rows="3"
                      onChange={(event) => {
                        setForm({ ...form, comments: event.target.value });
                      }}
                      value={form.comments}
                    ></textarea>
                  </div>
                </Col>
              </Row>
              <Row className="position-absolute bottom-0 right-0">
                <Col className="d-flex justify-content-end w-100 me-4">
                  <Button
                    color="success"
                    outline
                    onClick={() => {
                      setActionOffcanvas(false);
                      setForm({ action: "Open", commnets: "" });
                    }}
                    className="me-4 w-100"
                  >
                    Close
                  </Button>
                  <Button
                    color="success"
                    onClick={() => handleActionSubmit()}
                    className="w-100"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </OffcanvasBody>
          </Offcanvas>
        )}
      </div>
    </div>
  );
};

export default ClientAssets;
