//import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { validation } from "../commons/validation";
import { Input as InputText, Label } from "reactstrap";
import { areaRegex } from "../commons/functions";
import { alphanumerics } from "../commons/functions";

const InputComponent = (props: any) => {
  const [error, setError] = useState<any>({});

  const onHandleChange = (ev: any, field: any, type: any) => {
    if (
      typeof ev.target.value.trim === "function" &&
      ev.target.value.trim(" ") === ""
    ) {
      ev.target.value = ev.target.value.trim(" ");
    }
    let chkValid = validation(
      type,
      ev.target.value,
      field,
      props?.maxLength,
      props.checkMatched
    );
    if (props.validator === "MOBILE" && parseInt(ev.target.value) === 0) {
      chkValid[props.name] = true;
    } else if (props.validator === "PDF_LINK") {
      if (
        !(
          (ev.target.value.startsWith("http://") ||
            ev.target.value.startsWith("https://")) &&
          ev.target.value.endsWith(".pdf")
        )
      )
        chkValid[props.name] = true;
    } else if (props.validator === "AREA" && !areaRegex.test(ev.target.value)) {
      chkValid[props.name] = true;
    } else if (
      props.validator === "Number" &&
      !/^[0-9]+$/.test(ev.target.value)
    ) {
      chkValid[props.name] = true;
    } else if (
      props.validator === "Alphanumeric" &&
      !alphanumerics.test(ev.target.value)
    ) {
      chkValid[props.name] = true;
    }
    setError(chkValid);
    props.onKeyChange(ev.target.value, field, type, chkValid);
  };

  const onKeyDown = (e: any) => {
    if (props?.alphabets) {
      let pattern = new RegExp(/^[a-zA-Z\s]*$/);
      if (!pattern.test(e.key)) {
        e.preventDefault();
      }
    }
    if (props.numbers) {
      let reg = new RegExp("^[0-9]+$");
      let stringRegex = /^[a-zA-Z!@#$%^&*)(+=,_-]$/;
      if (
        stringRegex.test(e.key) ||
        e.which === 32 ||
        (e?.currentTarget?.selectionStart === 0 && e.which === 190)
      ) {
        e.preventDefault();
      }
      if (reg.test(e.key) && e?.currentTarget?.selectionStart === 0) {
        e.preventDefault();
      }
    }
  };

  return (
    <div>
      {props.inputLabel && (
        <label>
          {props?.inputLabelText}
          {props.mode === "view" ? (
            ":"
          ) : (
            <span className="mandatory">{props?.mandatory ? " *" : ""}</span>
          )}
        </label>
      )}
      {props.mode === "view" ? (
        <span>{" " + props?.value}</span>
      ) : (
        <InputText
          className={`w-100 ${error[props?.name] ? "border-err" : ""}`}
          placeholder={props?.placeholder}
          type={props?.type}
          value={props?.value}
          maxLength={props.maxLength ? props.maxLength : ""}
          onKeyDown={(e) => onKeyDown(e)}
          onChange={(e) => onHandleChange(e, props?.name, props?.validator)}
          disabled={props.disabled}
          
        />
      )}

      {error[props?.name] && (
        <p className="form-error mt-1">{`${
          props?.errorMessage
            ? props?.errorMessage
            : `Please enter a valid input`
        }`}</p>
      )}
    </div>
  );
};

export default InputComponent;
