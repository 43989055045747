import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Common/ChartsDynamicColor";
import "../../../styles/dashboard.css";

const DistributedColumn = ({
  data,
  label,
  dataColors,
  custom,
  height,
  textX,
  textY,
}) => {
  var chartColumnDistributedColors = getChartColorsArray(dataColors);
  const series = [
    {
      data: data,
      name: "",
    },
  ];
  var options = {
    chart: {
      height: 300,
      type: "bar",
      events: {
        click: function (chartContext, seriesIndex, config) {
          if (config.dataPointIndex !== null) {
            // console.log(label[config.dataPointIndex]);
          }
        },
      },
      toolbar: {
        show: false,
      },
    },
    colors: chartColumnDistributedColors,
    plotOptions: {
      bar: {
        columnWidth: custom.width + "%",
        distributed: true,
        borderRadius: custom.borderRadius,
        borderRadiusApplication: "end",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: label,
      labels: {
        style: {
          colors: [
            "#038edc",
            "#51d28c",
            "#f7cc53",
            "#f34e4e",
            "#564ab1",
            "#5fd0f3",
          ],
          fontSize: "12px",
          fontWeight: 600,
        },
      },
      title: {
        text: textX,
        offsetY: 75,
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      title: {
        text: textY,
        style: {
          fontSize: "14px",
        },
      },
    },
  };

  return (
    <ReactApexChart
      className="apex-charts"
      series={series}
      options={options}
      type="bar"
      height={height}
    />
  );
};
const DistributedColumn1 = ({
  data,
  label,
  dataColors,
  custom,
  height,
  textX,
  textY,
  selectedData,
}) => {
  var chartColumnDistributedColors = getChartColorsArray(dataColors);
  const series = [
    {
      data: data,
      name: "",
    },
  ];

  var options = {
    chart: {
      height: 300,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          if (e.dataPointIndex !== null) {
            selectedData("Dist", label[e.dataPointIndex]);
          }
        },
      },
      toolbar: {
        show: false,
      },
    },
    colors: chartColumnDistributedColors,
    plotOptions: {
      bar: {
        columnWidth: custom.width + "%",
        distributed: true,
        borderRadius: custom.borderRadius,
        borderRadiusApplication: "end",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: label,
      labels: {
        style: {
          colors: [
            "#038edc",
            "#51d28c",
            "#f7cc53",
            "#f34e4e",
            "#564ab1",
            "#5fd0f3",
          ],
          fontSize: "12px",
          fontWeight: 600,
        },
      },
      title: {
        text: textX,
        offsetY: 75,
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      title: {
        text: textY,
        style: {
          fontSize: "14px",
        },
      },
    },
  };

  return (
    <ReactApexChart
      className="apex-charts"
      series={series}
      options={options}
      type="bar"
      height={height}
    />
  );
};

const Donut = ({
  dataColors,
  labelName,
  series,
  labelCenter,
  height,
  size,
  selectedData,
  legends,
}) => {
  var chartDonutBasicColors = getChartColorsArray(dataColors);
  var options = {
    chart: {
      type: "donut",
      height: "auto",
      events: {
        dataPointSelection: function (event, chartContext, config) {
          if (config.dataPointIndex !== null) {
            selectedData
              ? selectedData(labelCenter, labelName[config.dataPointIndex])
              : null;
          }
        },
      },
    },
    plotOptions: {
      pie: {
        size: 100,
        offsetX: 0,
        offsetY: 0,
        donut: {
          size: size,
          labels: {
            show: true,
            total: {
              show: true,
              label: labelCenter ? labelCenter : "",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, w) {
        return (
          Math.floor(val * w.w.globals.initialSeries.reduce((a, b) => a + b)) /
          100
        );
      },
    },
    legend: {
      show: legends === false ? false : true,
      position: "bottom",
      fontSize: "14px",
      horizontalAlign: "left",
    },
    stroke: {
      lineCap: "round",
      width: 0,
    },
    colors: chartDonutBasicColors,
    labels: labelName,
  };
  return (
    <ReactApexChart
      className="apex-charts"
      series={series}
      options={options}
      type="donut"
      height={height}
    />
  );
};

const SimpleDonut = ({
  series,
  dataColors,
  height,
  labelName,
  labelCenter,
  selectedData,
}) => {
  var chartDonutBasicColors = getChartColorsArray(dataColors);
  var options = {
    chart: {
      height: "auto",
      type: "donut",
      events: {
        dataPointSelection: function (event, chartContext, config) {
          if (config.dataPointIndex !== null) {
            selectedData(labelCenter, labelName[config.dataPointIndex]);
          }
        },
      },
    },
    legend: {
      position: "bottom",
      fontSize: "20px",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, w) {
        return (
          Math.floor(val * w.w.globals.initialSeries.reduce((a, b) => a + b)) /
          100
        );
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: labelCenter,
            },
          },
        },
      },
    },

    labels: labelName,
    colors: chartDonutBasicColors,
  };
  return (
    <ReactApexChart
      className="apex-charts"
      series={series}
      options={options}
      type="donut"
      height={height}
    />
  );
};

export { DistributedColumn, Donut, SimpleDonut, DistributedColumn1 };
