import { PanZoom } from "@wokwi/react-easy-panzoom";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as api from "../../api/rest.api";
import Loader from "../../components/Common/Loader";
import html2canvas from "html2canvas";
import { Card, Col, Offcanvas, OffcanvasBody, Row } from "reactstrap";
import { OffcanvasHeader } from "react-bootstrap";
import { createLogo } from "../Components/Utils";
import TxObservation from "./TxObservation";
import circle from "../../assets/svg-new/circle.svg";
import oval from "../../assets/svg-new/oval.svg";
import square from "../../assets/svg-new/square.svg";
import arrow from "../../assets/svg-new/arrow.svg";
import nextImg from "../../assets/svg-new/next-img.svg";
import prevImg from "../../assets/svg-new/prev-img.svg";
import nextIcon from "../../assets/svg-new/next-arrow.svg";
import prevIcon from "../../assets/svg-new/prev-arrow.svg";
import closebtnimg from "../../assets/svg-new/closebtnimg.svg";

const TxComponentImage = () => {
  const location = useLocation().state;
  const navigate = useNavigate();
  const pan = useRef();
  const canvasRef = useRef();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState({});
  const [records, setRecords] = useState([]);

  const [isOffcanvas, setIsOffcanvas] = useState(false);
  const [offcanvasRecord, setOffcanvasRecord] = useState([]);

  const [isDrawModal, setIsDrawModal] = useState(false);
  const [drawUrl, setDrawUrl] = useState("");
  const [baseUrl, setBaseUrl] = useState(null);
  const [fileName, setFileName] = useState("");

  const [draw, setDraw] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [shape, setShape] = useState("pan");
  const [isDraw, setIsDraw] = useState(false);
  const [start, setStart] = useState({});
  const [mouse, setMouse] = useState({});
  const [convImg, setConvImg] = useState(null);
  const [idx, setIdx] = useState(0);
  const [page, setPage] = useState(null);
  const [imgArr, setImgArr] = useState([]);

  const getBaseImage = async () => {
    setLoading(true);
    let res = await api.addData("convertToBase64", { link: image.defectFile });
    if (res) {
      let url = `data:image/png;base64,${res.data}`;
      createImg(url);
      setBaseUrl(url);
    }
    setLoading(false);
  };

  const createImg = (url) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.webkitImageSmoothingEnable = false;
    const img = new Image();
    img.src = url;
    img.onload = () => {
      // pan.current.zoomOut(5);
      const newWidth = img?.width;
      const newHeight = img?.height;
      setWidth(newWidth);
      setHeight(newHeight);
      setConvImg(img);
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
    };
  };

  const getPrevDraw = async () => {
    let res = await api.addData("gettowercomponentdetail", {
      defectFile: image.defectFile,
    });
    setOffcanvasRecord(res?.data?.rows);
  };

  useEffect(() => {
    if (location?.mode === "edit") {
      setImage(location?.data[0]);
    } else {
      let img = location?.data;
      setImage(img);
      let id = location?.records?.findIndex(
        (e) => e.fileName === location.data.fileName
      );
      setIdx(id);
      let pNo = Math.floor(id / 10);
      setPage(pNo);
      setRecords(location?.records);
    }
  }, []);

  useEffect(() => {
    if (image?.modifiedFile) {
      let name = image?.modifiedFile?.split("modified_").pop();
      setFileName(name);
      setBaseUrl(image?.modifiedFile);
      setDrawUrl(image?.modifiedFile);
      setIsDrawModal(true);
      changeDraw(true);
    } else if (image?.defectFile) {
      getPrevDraw();
      setFileName(image.fileName);
    }
  }, [image]);

  useEffect(() => {
    if (draw) {
      baseUrl ? createImg(baseUrl) : getBaseImage();
    }
  }, [draw]);

  const handleMouseDown = (e) => {
    if (shape !== "pan") {
      setIsDraw(true);
      setStart({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
    }
  };

  const handleMouseUp = async () => {
    setIsDraw(false);
    setShape("pan");
    shape === "square" && drawRectangle();
    shape === "arrow" && drawArrow(start.x, start.y);
    setStart({});
    setMouse({});
    if (start.x !== mouse.x && start.y !== mouse.y) {
      setIsDrawModal(true);
    }
    let ele = document.getElementById("draw-data");
    let imgDraw = await html2canvas(ele);
    setDrawUrl(imgDraw.toDataURL());
  };

  const handleMouseMove = (e) => {
    if (!isDraw || shape === "pan") return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(convImg, 0, 0, canvas.width, canvas.height);
    setMouse({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
    const radius =
      Math.sqrt((mouse.x - start.x) ** 2 + (mouse.y - start.y) ** 2) / 2;
    const arcx = (mouse.x - start.x) / 2 + start.x;
    const arcy = (mouse.y - start.y) / 2 + start.y;
    const radiusX = Math.abs(mouse.x - start.x);
    const radiusY = Math.abs(mouse.y - start.y);
    ctx.beginPath();
    shape === "circle" && ctx.arc(arcx, arcy, radius, 0, 2 * Math.PI);
    shape === "oval" &&
      ctx.ellipse(start.x, start.y, radiusX, radiusY, 0, 0, 2 * Math.PI);
    shape === "square" &&
      ctx.rect(start.x, start.y, mouse?.x - start?.x, mouse?.y - start?.y);
    ctx.strokeStyle = "red";
    ctx.lineWidth = 20;
    ctx.stroke();
  };

  const drawRectangle = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.beginPath();
    ctx.rect(start.x, start.y, mouse.x - start.x, mouse.y - start.y);
    ctx.strokeStyle = "red";
    ctx.lineWidth = 20;
    ctx.stroke();
  };

  const drawArrow = (x, y) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(convImg, 0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x - 120, y + 150);
    ctx.lineTo(x - 120, y + 60);
    ctx.lineTo(x - 300, y + 60);
    ctx.lineTo(x - 300, y - 60);
    ctx.lineTo(x - 120, y - 60);
    ctx.lineTo(x - 120, y - 150);
    ctx.closePath();
    ctx.strokeStyle = "red";
    ctx.lineWidth = 20;
    ctx.stroke();
  };

  const zoomIn = () => {
    pan.current.zoomIn();
  };

  const zoomOut = () => {
    pan.current.zoomOut();
  };

  const changeDraw = (val) => {
    setDraw(val);
  };

  const handleClose = () => {
    sessionStorage.setItem("sme-current-image", JSON.stringify(image));
    navigate("/sme-tower-component", { state: location?.location });
  };
  const updateImage = (val) => {
    if (val === "next") {
      setImage(records[idx + 1]);
      setIdx(idx + 1);
      setBaseUrl(null);
      setPage(Math.floor((idx + 1) / 10));
    } else if (val === "prev") {
      setImage(records[idx - 1]);
      setIdx(idx - 1);
      setPage(Math.floor((idx - 1) / 10));
      setBaseUrl(null);
    } else {
      setImage(records[idx]);
    }
  };

  useEffect(() => {
    let res = records.slice(page * 10, page * 10 + 10);
    setImgArr(res);
  }, [page]);

  return (
    <div className="content">
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <Row className="mb-3 mx-3">
        <Col>
          <div className="client-logo-sme">
            {location?.location?.corridor?.project?.client?.clientLogo ? (
              <img
                src={location?.location?.corridor?.project?.client?.clientLogo}
              />
            ) : (
              <span>
                {createLogo(
                  location?.location?.corridor?.project?.client?.clientName
                )}
              </span>
            )}
          </div>
          <div className="client-logo-sme-name">
            {location?.location?.corridor?.data?.corridorName}
          </div>
        </Col>
        <Col className="text-end me-2 mt-2">
          <span
            className="bread-last"
            onClick={() => navigate("/sme-dashboard")}
          >
            Dashboard
          </span>
          {" > "}
          <span
            className="bread-last "
            onClick={() =>
              navigate("/sme-corridor", {
                state: location?.location?.corridor?.project,
              })
            }
          >
            {location?.location?.corridor?.project?.projectName}
          </span>
          {" > "}
          <span
            className="bread-last"
            onClick={() =>
              navigate("/sme-tower", { state: location?.location?.corridor })
            }
          >
            {location?.location?.corridor?.data?.corridorName}
          </span>
          {" > "}
          <span className="fs-14">
            {location?.location?.data?.towerNo}{" "}
            {location?.location?.data?.towerType}
          </span>
        </Col>
      </Row>

      <div className="img-workspace">
        <div className="img-zoom">
          <i className="bx bx-plus fs-2 mb-3 pointer" onClick={zoomIn} />
          <i className="bx bx-minus fs-2 pointer" onClick={zoomOut} />
        </div>
        <div
          className="close-img"
          onClick={() => (draw ? changeDraw(false) : handleClose())}
        >
          <img src={closebtnimg} />
        </div>
        {!draw && (
          <div className="canvas-draw" onClick={() => changeDraw(true)}>
            Start Marking
          </div>
        )}
        {draw && (
          <div className="canvas-tool">
            <img
              src={circle}
              alt="circle"
              className="mb-3 pointer"
              onClick={() => setShape("circle")}
            />
            <img
              src={oval}
              alt="oval"
              className="mb-3 pointer"
              onClick={() => setShape("oval")}
            />
            <img
              src={square}
              alt="square"
              className="mb-3 pointer"
              onClick={() => setShape("square")}
            />
            <img
              src={arrow}
              alt="arrow"
              className="mb-3 pointer"
              onClick={() => setShape("arrow")}
            />
          </div>
        )}
        {fileName && <div className="draw-file">{fileName}</div>}
        {offcanvasRecord.length > 0 && (
          <div className="draw-arr" onClick={() => setIsOffcanvas(true)}>
            <span>{" > "}</span>
          </div>
        )}
        {!draw && records?.length > 0 && (
          <>
            <div className="canvas-next">
              <img
                src={nextImg}
                style={{
                  pointerEvents: idx > records.length - 2 ? "none" : "auto",
                }}
                className="pointer"
                onClick={() => updateImage("next")}
              />
            </div>
            {
              <div className="canvas-prev">
                <img
                  src={prevImg}
                  className="pointer"
                  style={{ pointerEvents: idx < 1 ? "none" : "auto" }}
                  onClick={() => updateImage("prev")}
                />
              </div>
            }
          </>
        )}
        <PanZoom
          ref={pan}
          boundaryRatioVertical={1}
          boundaryRatioHorizontal={1}
          minZoom={0.1}
          max={4}
          zoomSpeed={1}
          disabled={shape !== "pan"}
        >
          <canvas
            id="draw-data"
            ref={canvasRef}
            height={height}
            width={width}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={{ display: draw ? "block" : "none" }}
          ></canvas>
          <div
            className="show-img"
            style={{ display: draw ? "none" : "block" }}
          >
            <img src={image.defectFile} />
          </div>
        </PanZoom>
      </div>
      {isOffcanvas && (
        <Offcanvas
          isOpen={isOffcanvas}
          toggle={() => setIsOffcanvas(false)}
          id="offcanvasExample"
        >
          <OffcanvasHeader id="offcanvasExampleLabel" className="border-bottom">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="fs-16 fw-500">Previously Marked Observation</div>
              <i
                className="bx bx-x fs-2 pointer"
                onClick={() => setIsOffcanvas(false)}
              ></i>
            </div>
          </OffcanvasHeader>
          <OffcanvasBody>
            {offcanvasRecord?.map((e) => {
              return (
                <Card>
                  <div className="w-100 border-bottom">
                    <img src={e.modifiedFile} className="obj-fit" />
                  </div>
                  <Row className="mx-1">
                    <Col>
                      <div className="fs-13 color-grayl">Comment:</div>{" "}
                      <div className="fs-15">{e.comments}</div>
                    </Col>
                    <Col>
                      <div className="fs-13 color-grayl">Recommendation :</div>{" "}
                      <div className="fs-15">{e.recommendation}</div>
                    </Col>
                  </Row>
                </Card>
              );
            })}
          </OffcanvasBody>
        </Offcanvas>
      )}

      {isDrawModal && (
        <TxObservation
          url={drawUrl}
          isOpen={isDrawModal}
          close={() => {
            if (location?.mode === "edit") {
              navigate("/sme-tower-component", { state: location.location });
            }
            setIsDrawModal(false);
            createImg(baseUrl);
          }}
          image={image}
          setDraw={setDraw}
          setLoading={setLoading}
          data={location?.mode === "edit" ? location : ""}
        />
      )}
      {!draw && records?.length > 0 && (
        <div className="bottom-gallery">
          <div className="prev-img-icon">
            <img
              src={prevIcon}
              onClick={() => setPage(page - 1)}
              style={{
                pointerEvents: page < 1 ? "none" : "auto",
              }}
              className="pointer"
            />
          </div>
          <div className="d-flex">
            {imgArr?.map((img, id) => (
              <div className="btm-img align-items-center justify-content-center">
                <img
                  src={img?.defectFile}
                  style={{
                    border:
                      img?.defectFile === image?.defectFile
                        ? "5px solid #37B200"
                        : "none",
                  }}
                  onClick={() => {
                    setImage(img);
                    setBaseUrl(null);
                    setIdx(page * 10 + id);
                  }}
                />
              </div>
            ))}
          </div>
          <div className="next-img-icon">
            <img
              src={nextIcon}
              onClick={() => setPage(page + 1)}
              style={{
                pointerEvents:
                  page < Math.ceil(records?.length / 10) - 1 ? "auto" : "none",
              }}
              className="pointer"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TxComponentImage;
