import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "reactstrap";

const ImageCard = ({ data, records, status, currentImage }) => {
  const location = useLocation().state;
  const navigate = useNavigate();
  const [isLightbox, setIsLightbox] = useState(false);
  const [id, setId] = useState();
  let fileName = data?.fileName?.split(".")[0];
  let total = records?.length;

  const handleLightbox = (data) => {
    let idx = records?.findIndex((e) => e.fileName === data.fileName);
    setId(idx);
    setIsLightbox(true);
  };
  return (
    <React.Fragment>
      {isLightbox && (
        <Lightbox
          mainSrc={records[id].defectFile}
          imageTitle={records[id].fileName}
          imageCaption={id + 1}
          onCloseRequest={() => setIsLightbox(false)}
          onMovePrevRequest={() => setId((id - 1 + total) % total)}
          onMoveNextRequest={() => setId((id + 1) % total)}
          nextSrc={records[(id + 1) % total].defectFile}
          prevSrc={records[(id - 1 + total) % total].defectFile}
        />
      )}
      <Card
        className="img-card-div"
        style={{
          border:
            currentImage?.fileName === data.fileName ? "5px solid red" : "none",
        }}
      >
        <i
          className="bx bx-fullscreen img-card-full fs-3"
          onClick={() => handleLightbox(data)}
        ></i>
        <img
          className="pointer"
          src={data?.defectFile}
          onClick={() => {
            status !== "Completed" &&
              navigate("/sme-tower-component-details", {
                state: { data, records, location },
              });
          }}
        />
        <span className="p-2 text-center">{fileName}</span>
      </Card>
    </React.Fragment>
  );
};

export default ImageCard;
