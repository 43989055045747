import React, { useState } from "react";
import { Link } from "react-router-dom";
import { menuData } from "./sideMenuData";

const Sidebar = ({ isActive, handleToggle }) => {
  const [id, setId] = useState();
  return (
    <div className={`shadow bg-white ${isActive ? "side-show" : "side-hide"}`}>
      <div>
        <div style={{ borderBottom: "1px solid #eee" }}>
          <button
            onClick={handleToggle}
            type="button"
            className="btn btn-sm px-4 fs-16 header-item vertical-menu-btn topnav-hamburger"
            id="topnav-hamburger-icon"
          >
            <span className="hamburger-icon mb-2">
              <i
                className="ri-align-left"
                style={{ display: isActive ? "" : "none" }}
              ></i>
              <i
                className="ri-arrow-right-line"
                style={{ display: isActive ? "none" : "" }}
              ></i>
            </span>
          </button>
        </div>
        <div>
          {menuData.map((ele, idx) => {
            return (
              <div key={idx} onClick={() => setId(idx)}>
                <Link
                  to={ele.link}
                  className={`${id === idx ? "active" : ""}  d-block`}
                >
                  <div
                    className="d-flex align-items-center justify-content-start ps-4 py-2"
                    style={{ borderBottom: "1px solid #eee" }}
                  >
                    <i className={`${ele.icon}`} style={{ minWidth: "30px" }} />
                    <div
                      className={`sidebar-list ${
                        isActive ? "d-block" : "d-none"
                      }`}
                    >
                      {ele.lable}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
