import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { getProjectsByUserId } from "../../api/dashboard.api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { landSurveyTsx } from "../../store/landsurveyreducer";
import { miningTsx } from "../../store/miningstore";
import { projectTsx } from "../../store/projectreducer";
import { projectsArrTxn } from "../../store/projectsArrayReducer";
import { GetMonths, ProjectCategory } from "../commons/common";
import { errorMessages } from "../commons/constant";
import UnAuthDialog from "../commons/UnAuthDialog";
import { Header } from "../header/Header";
import Loader from "../CommonComponent/Loader";
import { ToastContainer, toast } from "react-toastify";
import { formatDate } from "../commons/functions";

const ClientProjects = (props: any) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const [projects, setProjects] = useState<any>();
  let navigate = useNavigate();
  const months = GetMonths();
  const categories = [...ProjectCategory];
  const [spinner, setSpinner] = useState(false);
  const [showLogout, setShowLogout] = useState(false);

  useEffect(() => {
    projectDetails();
  }, []);
  
  useEffect(() => {
    if (projects && projects.length >= 0) {
      if (projects.length === 0) {
        toast(errorMessages.NO_PROJECTS, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });
      }
    }
  }, [projects]);

  const projectDetails = async () => {
    setSpinner(true);
    let projectlist;
    projectlist = await getProjectsByUserId(user);
    if (projectlist === 401) {
      setShowLogout(true);
      return;
    }
    if (projectlist && projectlist.status) setSpinner(false);
    if (projectlist) {
      projectlist = projectlist?.filter((arr: any) => arr.totalSurvey != 0);
      setProjects(projectlist);
      setSpinner(false);
      dispatch(projectsArrTxn(projectlist));
    }
  };

  const handleClick = (project: any) => {
    project;
    miningfunction(project.moduleCategory);
    dispatch(projectTsx(project));
    navigate("/dashboard");
  };
  const miningfunction = (moduleCategory: any) => {
    if (moduleCategory === "Mining Survey") {
      dispatch(miningTsx({ mining: true }));
      dispatch(landSurveyTsx({ landSurvey: false }));
    } else if (moduleCategory === "Land Survey") {
      dispatch(miningTsx({ mining: false }));
      dispatch(landSurveyTsx({ landSurvey: true }));
    }
  };

  projects;
  return (
    <>
      <Loader loader={spinner} />
      <Header />
      <div className="p-25 content">
        <ToastContainer />
        <Row>
          {projects && projects.length > 0
            ? projects.map((project: any, index: any) => {
                // let date = new Date(project.latestSurvey);
                // let latestSurvey =
                //   date.getDate()-1 +
                //   "-" +
                //   months[date.getMonth()] +
                //   "-" +
                //   date.getFullYear();
                return (
                  <Col
                    lg={3}
                    md={4}
                    sm={6}
                    className="d-flex lgcol p-20"
                    key={index}
                    onClick={(e) => handleClick(project)}
                  >
                    <Card className="card-animate">
                      <div className="mr10 pointer h-100">
                        <div className="contextbox h-100">
                          <div className="p-t-20 p-ellipsis">
                            <span className="p-r-0 p-b-0 opacity-9">
                              Project Name:
                            </span>
                            <Tooltip target=".setting-button" />
                            <span
                              className="setting-button "
                              data-pr-tooltip={project.projectName}
                            >
                              {project.projectName}
                            </span>
                          </div>
                          <div className="row h-90">
                            <div className="col-6 m-t-20 border-right">
                              <div className="w-100 opacity-9">Location:</div>
                              <div className="m-0 w-100 ">
                                {project.location}
                              </div>
                              <div className="w-100 m-t-10 opacity-9">
                                Module:
                              </div>
                              <Tooltip target=".category-button" />
                              <span
                                className="category-button"
                                data-pr-tooltip={project.moduleCategory}
                              >
                                {project.moduleCategory}
                              </span>

                              <div className="w-100 m-t-10 opacity-9">
                                Latest Survey:
                              </div>
                              <div className="m-0 w-100 p-ellipsis">
                                {project.latestSurvey
                                  ? formatDate(project.latestSurvey)
                                  : ""}
                              </div>
                            </div>
                            <div className="col-6 m-t-20 survey">
                              <div className="opacity-9 surveys">
                                Number of Surveys:
                              </div>
                              <div className="border-surveys m-t-10">
                                {project.totalSurvey}
                              </div>
                            </div>
                          </div>
                          <div className="btn btn-primary btn-sm bottom-2 height-30">
                            See Details
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })
            : !spinner && (
                <div className="project-outer h-80vh">
                  <div className="bg-96c6b72e project-outer w-80 h-80">
                    <div className="project-font">
                      No Projects added for you yet
                    </div>
                  </div>
                </div>
              )}
        </Row>
      </div>
      {showLogout && (
        <UnAuthDialog
          visible={showLogout}
          onHide={() => setShowLogout(false)}
        />
      )}
    </>
  );
};

export default ClientProjects;
