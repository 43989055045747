import { useEffect, useState } from "react";
import Loader from "../../CommonComponent/Loader";
import { ToastContainer, toast } from "react-toastify";
import BreadCrumbComponent from "../../CommonComponent/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { pilotAssetsSchema } from "../../Schema/OilTableSchema";
import CustomTable from "../../CommonComponent/CustomTable";
import * as api from "../../../api/rest.api";
import UploadFileModal from "./UploadFileModal";
import { useAppSelector } from "../../../store/hooks";

const AssetList = () => {
  const navigate = useNavigate();
  const location = useLocation().state;
  const userPriv = useAppSelector((state) => state.user)?.privilliges;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ items: [], count: 0 });
  const [page, setpage] = useState<any>({ curr: 1, perPage: 10 });
  const [isModal, setIsModal] = useState<any>(false);
  const [row, setRow] = useState(null);

  console.log(userPriv);
  const items = [
    { label: "Dashboard", command: () => navigate("/pilot-dashboard") },
    { label: "Assets" },
  ];

  const getAssets = () => {
    setLoading(true);
    api
      .addData(
        `getAssets?projectId=${location?.id}&page=${page.curr}&limit=${page.perPage}`,
        {}
      )
      .then((res: any) => {
        if (res?.errorMessage) {
          toast.error(res?.errorMessage);
          setLoading(false);
        } else {
          setData({ items: res?.data?.assetList, count: res?.data?.count });
          setLoading(false);
        }
      });
  };

  const onPageChange = (val: number) => {
    setpage({ ...page, curr: val });
  };

  const onChangeRowsPerPage = (val: number) => {
    setpage({ curr: 1, perPage: val });
  };

  const onActionClick = (val: any) => {
    setRow(val);
    setIsModal(true);
  };

  useEffect(() => {
    getAssets();
  }, []);
  return (
    <div className="page-content">
      <Loader loader={loading} />
      <ToastContainer />
      <BreadCrumbComponent items={items} />
      <CustomTable
        data={data?.items}
        columns={pilotAssetsSchema}
        fixedHeader
        highlightOnHover
        pagination
        paginationServer
        fixedHeaderScrollHeight="800px"
        paginationTotalRows={data?.count}
        onChangePage={onPageChange}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onActionClick={onActionClick}
        uploadPriv={userPriv?.upload}
        reUploadPriv={userPriv?.reUpload}
      />
      {isModal && (
        <UploadFileModal
          isOpen={isModal}
          close={setIsModal}
          data={row}
          setLoader={setLoading}
          getAssets={getAssets}
        />
      )}
    </div>
  );
};

export default AssetList;
