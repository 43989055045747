import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { addDrawData, updateDrawData } from "../../api/map.api";
import { ToastContainer, toast } from "react-toastify";
import { vectorData } from "../Components/Utils";

const DrawModal = (props) => {
  console.log(props)
  const { show, closeModal, modalheader } = props;
  const [formState, setFormState] = useState({ colorPicker: "#364574" });
  const [update, setUpdate] = useState(false);

  const handleChange = (e, field) => {
    setFormState({ ...formState, [field]: e });
  };

  const handleDisable = () => {
    if (!formState.FeatureName) return true;
    return false;
  };
  const handleSave = async () => {
    let obj = {
      FeatureName: formState.FeatureName,
      Color: formState.colorPicker,
      Description: "",
    };
    if (props?.editDraw?.updateId) {
      obj["id"] = formState.updateId;
      obj["surveyId"] = props?.editDraw?.surveyId;
      obj["Coordinates"] = formState?.Coordinates;
      obj["perimeter"] = formState?.perimeter;
      obj['area'] = formState?.area;
      let response = await updateDrawData(obj);
      if (response?.status?.toLowerCase() == "success") {
        toast.success("Feature Updated successfully");
        let data = vectorData([response?.response]);
        data[1] = response?.response;
        props?.handleAddDraw(data);
        setUpdate(false);
      } else {
        toast.error("Error while updating ");
      }
    } else {
      obj["surveyId"] = props?.drawData.surveyid;
      obj["Coordinates"] = JSON.parse(props?.drawData?.geojsonData);
      obj["area"] = props?.drawData?.area;
      obj["perimeter"] = props?.drawData?.perimeter;

      let res = await addDrawData(obj);
      if (res?.status?.toLowerCase() === "success") {
        toast.success("Feature added succcessfully");
        let data = vectorData([res?.response]);
        data[1] = res?.response;
        props?.handleAddDraw(data);
      } else {
        toast.error("Error while adding");
      }
    }
  };

  useEffect(() => {
    if (props?.editDraw?.updateId) {
      setUpdate(true);
      setFormState(props?.editDraw);
    } else {
      setUpdate(false);
      setFormState({ colorPicker: "#364574" });
    }
  }, []);

  return (
    <div className="d-flex align-item-end">
      <ToastContainer />
      <Modal
        isOpen={show}
        toggle={() => closeModal()}
        size="sm"
        className="bg-custom-white toolModal"
        backdrop={false}
      >
        <ModalHeader
          className={`border-bottom p-2 bg-light`}
          toggle={() => closeModal()}
        >
          <span className="text-dark text-bold fs-15">{modalheader}</span>
        </ModalHeader>
        <ModalBody>
          <div className="mb-2">
            <Label className="fs-14">
              Feature Name <span className="required">*</span>
            </Label>
            <Input
              type="text"
              placeholder="Enter"
              size="sm"
              value={formState.FeatureName}
              onChange={(e) => handleChange(e.target.value, "FeatureName")}
            ></Input>
          </div>
          <div className="mb-2">
            <Label className="fs-14">
              Select Color <span className="required">*</span>
            </Label>
            <Input
              type="color"
              className="w-2 p-0"
              size="sm"
              value={formState.colorPicker}
              onChange={(e) => handleChange(e.target.value, "colorPicker")}
            ></Input>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex gap-2 justify-content-end mt-3 mb-2">
            <Button
              outline
              className="w-sm"
              color="primary"
              data-bs-dismiss="modal"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>

            <Button
              type="button"
              className="bg-primary w-sm"
              disabled={handleDisable()}
              onClick={handleSave}
            >
              {update ? "Update" : "Save"}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DrawModal;
