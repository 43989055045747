import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";

const ModuleCard = ({
  data,
  handleModal,
  addFeatures,
  deleteModuleApi,
}: any) => {
  const navigate = useNavigate();
  const handleAddFeature = (val: any) => {
    addFeatures(val);
    handleModal(true);
  };
  const handleEdit = (val: any) => {
    sessionStorage.setItem("editModule", JSON.stringify(val));
    navigate("/module");
  };
  return (
    <div className="module-main">
      {data?.length > 0 ? (
        data?.map((ele: any, idx: any) => (
          <Card className="module-card" key={idx}>
            <div className="module-img">
              <span className="position-absolute end-0 fs-4">
                <i
                  className="bx bxs-edit fs-5 me-2 pointer"
                  style={{ color: "green" }}
                  onClick={() => {
                    handleEdit(ele);
                  }}
                ></i>
                <i
                  className="ri-delete-bin-6-line m-1 pointer fs-5"
                  style={{ color: "red" }}
                  onClick={() => deleteModuleApi(ele?.id)}
                ></i>
              </span>
              <img
                src={ele.coverImage}
                alt=""
                height={"182px"}
                width={"429px"}
              />
            </div>

            <div className="position-relative">
              <span
                className="position-absolute bg-primary p-2 fs-12 start-0 top--0"
                style={{ width: "fit-content" }}
              >
                {ele?.subModuleName
                  ? ele?.subModuleName
                  : ele?.existingSubModule}
              </span>
            </div>
            <div
              className="text-success module-card1"
              onClick={() => {
                handleAddFeature(ele);
              }}
            >
              <i className="ri-menu-add-fill fs-4 me-2 pointer"></i>
              <span className="fs-16 text-center pointer">
                No. of Features ({ele.features.length})
              </span>
            </div>
          </Card>
        ))
      ) : (
        <div className="nodata">No data found</div>
      )}
    </div>
  );
};

export default ModuleCard;
