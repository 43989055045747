import React from "react";
import PrivilegeDataTable from "../../tables/PrivilegeDataTable";

const PrivilegeTable = ({
  data,
  handleDeleteClickId,
  handleOpenModal,
  editRoleData,
}: any) => {
  return (
    <PrivilegeDataTable
      data={data}
      handleDeleteClickId={handleDeleteClickId}
      handleOpenModal={handleOpenModal}
      editRoleData={editRoleData}
    />
  );
};

export default PrivilegeTable;
