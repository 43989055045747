import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Button } from "primereact/button";
import { formatDate } from "../../commons/functions";
import { Card } from "@material-ui/core";

const AllProjects = () => {
  const state = useLocation().state;
  const navigate = useNavigate();
  return (
    <Container fluid className="scroll">
      <Row>
        <Button
          icon="pi pi-arrow-left me-2"
          onClick={() => navigate(-1)}
          style={{
            width: "5rem",
            background: "transparent",
            border: "none",
            color: "#000",
          }}
        >
          Back
        </Button>
        <Col xs={12}>
          {state.completed?.length > 0 && (
            <Card className="mb-3">
              <CardHeader className="mb-3 p-3">Completed Projects</CardHeader>
              <CardBody className="p-3">
                <Row>
                  {state?.completed?.map((e: any, key: any) => (
                    <Col lg={4} key={key} className="mb-3">
                      <div className="d-flex align-items-center mb-2 card-body border">
                        <div className="avatar-sm flex-shrink-0 d-flex">
                          <img
                            src={e?.client?.clientLogo}
                            className="rounded img-fluid mx-1 "
                            style={{ height: "60px" }}
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1">
                          <span className="d-flex justify-content-end">
                            <p className="card-status-completed1 mb-0">
                              {"Completed"}
                            </p>
                          </span>
                          <h6 className="ms-2">{e?.projectName}</h6>
                          <p className="text-muted fs-12 mb-0 d-flex align-items-center ms-2">
                            <i className="ri-map-pin-2-fill me-2 ri-xl"></i>
                            {e?.location}
                          </p>
                          <p className="d-flex justify-content-end me-3">
                            <i className="mdi mdi-circle-medium align-middle mx-1 fs-14"></i>
                            {formatDate(e?.createdAt)}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          )}

          {state.inspectiondone?.length > 0 && (
            <Card className="mb-3">
              <CardHeader className="mb-3 p-3">Inspection Done</CardHeader>
              <CardBody className="p-3">
                <Row>
                  {state?.inspectiondone?.map((e: any, key: any) => (
                    <Col lg={4} key={key} className="mb-3">
                      <div className="d-flex align-items-center mb-2 card-body border">
                        <div className="avatar-sm flex-shrink-0 d-flex">
                          <img
                            src={e?.client?.clientLogo}
                            className="rounded img-fluid mx-1 "
                            style={{ height: "60px" }}
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1">
                          <span className="d-flex justify-content-end">
                            <p className="card-status-completed1 mb-0">
                              {"Completed"}
                            </p>
                          </span>
                          <h6 className="ms-2">{e?.projectName}</h6>
                          <p className="text-muted fs-12 mb-0 d-flex align-items-center ms-2">
                            <i className="ri-map-pin-2-fill me-2 ri-xl"></i>
                            {e?.location}
                          </p>
                          <p className="d-flex justify-content-end me-3">
                            <i className="mdi mdi-circle-medium align-middle mx-1 fs-14"></i>
                            {formatDate(e?.createdAt)}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          )}

          {state.inprogress?.length > 0 && (
            <Card className="mb-3">
              <CardHeader className="mb-3 p-3">In Progress Projects</CardHeader>
              <CardBody className="p-3">
                <Row>
                  {state.inprogress?.map((e: any, key: any) => (
                    <Col lg={4} key={key} className="mb-3">
                      <div className="d-flex align-items-center mb-2 card-body border">
                        <div className="avatar-sm flex-shrink-0 d-flex">
                          <img
                            src={e?.client?.clientLogo}
                            className="rounded img-fluid mx-1 "
                            style={{ height: "60px" }}
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <span className="d-flex justify-content-end">
                            <p
                              className="card-status-completed1 mb-0"
                              style={{
                                backgroundColor:
                                  e?.projectStatus === "In Progress"
                                    ? "orange"
                                    : e?.projectStatus === "Data Captured"
                                    ? "Yellow"
                                    : e?.projectStatus === "Completed"
                                    ? "#74b72e"
                                    : "gray",
                              }}
                            >
                              {e?.projectStatus}
                            </p>
                          </span>
                          <h6 className="ms-2">{e?.projectName}</h6>
                          <p className="text-muted fs-12 mb-0 d-flex align-items-center ms-2">
                            <i className="ri-map-pin-2-fill me-2 ri-xl"></i>
                            {e?.location}
                          </p>
                          <p className="d-flex justify-content-end me-3">
                            <i className="mdi mdi-circle-medium align-middle mx-1 fs-14"></i>
                            {formatDate(e?.createdAt)}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          )}

          {state.yettostart?.length > 0 && (
            <Card className="mb-3">
              <CardHeader className="mb-3 p-3">Yet To Start</CardHeader>
              <CardBody className="p-3">
                <Row>
                  {state?.yettostart?.map((e: any, key: any) => (
                    <Col lg={4} key={key} className="mb-3">
                      <div className="d-flex align-items-center mb-2 card-body border">
                        <div className="avatar-sm flex-shrink-0 d-flex">
                          <img
                            src={e?.client?.clientLogo}
                            className="rounded img-fluid mx-1 "
                            style={{ height: "60px" }}
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1">
                          <span className="d-flex justify-content-end">
                            <p
                              className="card-status-completed1 mb-0"
                              style={{ background: "gray" }}
                            >
                              {"Yet To Start"}
                            </p>
                          </span>
                          <h6 className="ms-2">{e?.projectName}</h6>
                          <p className="text-muted fs-12 mb-0 d-flex align-items-center ms-2">
                            <i className="ri-map-pin-2-fill me-2 ri-xl"></i>
                            {e?.location}
                          </p>
                          <p className="d-flex justify-content-end me-3">
                            <i className="mdi mdi-circle-medium align-middle mx-1 fs-14"></i>
                            {formatDate(e?.createdAt)}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AllProjects;
