import { useEffect, useState } from "react";
import DashboardCard from "../CommonComponent/DashboardCard";
import * as api from "../../api/rest.api";
import { useAppSelector } from "../../store/hooks";
import { Col, Row } from "reactstrap";
import { formatDate } from "../CommonComponent/CommonFunctions";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../CommonComponent/Loader";
import { Valid } from "../../store/reducer";
import { useDispatch } from "react-redux";
import { transmissionLinesTsx } from "../../store/transmissionLinesStore";
import { projectTsx } from "../../store/projectreducer";

const Dashboard = () => {
  const user = useAppSelector((state) => state.user);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getProjects = () => {
    setLoading(true);
    api
      .getData(
        `projects/get?module=${user.selectedModule}&roleCode=PILOT&userID=${user.id}`
      )
      .then((res: any) => {
        if (!!res?.errorMessage) {
          toast.error(res?.errorMessages);
          setLoading(false);
        } else {
          let selectedFeatures: any = [];
          res?.data?.featuresData?.map((item: any) => {
            if (item?.isSelected) {
              selectedFeatures?.push(item?.featureName);
            }
          });
          let userObj = { ...user, selectedFeatures: selectedFeatures };
          dispatch(Valid(userObj));
          setProjects(res?.data);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    getProjects();
  }, []);

  const onProjectClick = (item: any) => {
    console.log(item);
    if (item?.totalCorridors > 0) {
      inspectionfunction(item.subModule);
      dispatch(projectTsx(item));
      navigate(`/pilot/projects/${item.id}`);
    } else if (item?.totalAssets > 0) {
      navigate(`/assets`, { state: item });
    }
  };

  const inspectionfunction = (moduleCategory: any) => {
    if (moduleCategory === "Transmission Lines") {
      dispatch(transmissionLinesTsx({ transmissionLines: true }));
    }
  };

  return (
    <div className="dashboard page-content">
      <ToastContainer />
      <Loader loader={loading} />
      {!!projects?.length ? (
        <Row className="m-4">
          {projects?.map((item: any, idx: number) => (
            <Col
              xxl={3}
              sm={6}
              className="project-card"
              key={idx}
              onClick={() => onProjectClick(item)}
            >
              {item?.totalCorridors > 0 && (
                <DashboardCard
                  card1={true}
                  title={item?.projectName}
                  status={item?.projectStatus}
                  location={item?.location}
                  noOfCorridor={item?.totalCorridors}
                  date={formatDate(item?.createdAt)}
                />
              )}
              {item?.totalAssets > 0 && (
                <DashboardCard
                  card2={true}
                  title={item?.projectName}
                  status={item?.projectStatus}
                  location={item?.location}
                  noOfAsset={item?.totalAssets}
                  date={formatDate(item?.createdAt)}
                />
              )}
            </Col>
          ))}
        </Row>
      ) : (
        <div className="empty-data">No Data Found</div>
      )}
    </div>
  );
};

export default Dashboard;
