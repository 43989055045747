import React from "react";
import { Badge, Button, Card, Col, Row } from "reactstrap";
import { createLogo, getDateOnly } from "../Components/Utils";
import { useNavigate } from "react-router-dom";
import towerImg from "../../assets/svg-new/towerImg.svg";
import CalanderImg from "../../assets/svg-new/Calender-blue.svg";
import CorrImg from "../../assets/svg-new/corrImg.svg";

const ProjectCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Card className="p-2 pointer">
      <Badge
        className={
          data?.projectStatus === "Completed"
            ? "badge_complete"
            : "badge_inprogress"
        }
        color=""
      >
        {data?.projectStatus}
      </Badge>
      <Row>
        <Col xs={4}>
          <div className="dashboard-client-img1">
            <div className="client-logo-sme">
              {data?.client?.clientLogo ? (
                <img src={data?.client?.clientLogo} width="100%" />
              ) : (
                <span>{createLogo(data?.client.clientName)}</span>
              )}
            </div>
            <div className="client-logo-sme-name1">
              <div>{data?.projectName}</div>
              <div className="fs-14">{data.location}</div>
            </div>
          </div>
        </Col>
        <Col xs={8}>
          <Row className="mb-3">
            <Col className="d-flex align-items-center">
              <img src={CorrImg} className="me-3"></img>
              <div className="fs-16">
                No. of Corridors -{" "}
                <span className="color-greenl fw-500">{data.totalCorridors}</span>
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <div className="text-gray fs-16">Inprogress</div>
              <div className="fs-16 fw-500">{data?.inProgressCorridors}</div>
            </Col>
            <Col>
              <div className="text-gray fs-16">Completed</div>
              <div className="fs-16 fw-500">{data?.completedCorridors}</div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="d-flex align-items-center">
              <img src={CalanderImg} className="me-2"></img>
              <div>
                <div className="text-gray fs-16">Created Date</div>
                <div className="fs-16 fw-500">{getDateOnly(data?.createdAt)}</div>
              </div>
            </Col>
            <Col className="d-flex align-items-center">
              <img src={CalanderImg} className="me-2"></img>
              <div>
                <div className="text-gray fs-16">Inspection Date</div>
                <div className="fs-16 fw-500">{getDateOnly(data?.updatedAt)}</div>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="d-flex align-items-center justify-content-center">
              <Button
                color="success"
                outline
                className="success w-8"
                onClick={() => navigate("/sme-corridor", { state: data })}
              >
                View Corridors
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default ProjectCard;
