import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Col, Container, Input, Label, Row, Button } from "reactstrap";
import AuthSlider from "../commons/AuthSlider";
import { validation } from "../commons/validation";
import loginfunction from "../../api/auth.api";
import { Valid } from "../../store/reducer";
import { useDispatch } from "react-redux";
import Loader from "../CommonComponent/Loader";
import { ToastContainer } from "react-toastify";
import { addToast } from "../commons/common";
const videoPath = require("../../assets/drogonew.mp4");

const LoginVelzon = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [type, setType] = useState(true);
  const [login, setLogin] = useState<any>({});
  const [error, setError] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [userData, setUserData] = useState<any>({});

  const handleChange = (e: any, type: string) => {
    if (type === "username") {
      let chkValid = validation("EMAIL", e, "username", 1000, "");
      setError(chkValid);
    }
    setLogin({ ...login, [type]: e });
  };

  const handleData = (user: any) => {
    if (user?.roles[0]?.roleName === "Admin") {
      let userObj = {
        ...user,
        selectedRole: "Admin",
        selectedModule: "",
        privilliges: {},
      };
      dispatch(Valid(userObj));
      navigate("/admin-dashboard");
    } else if (
      user?.roles[0]?.roleName === "Pilot" &&
      user?.roles[0]?.privilliges?.length === 1
    ) {
      let userObj = {
        ...user,
        selectedRole: "Pilot",
        selectedModule: user?.roles[0]?.privilliges[0]?.subModule,
        privilliges: user?.roles[0]?.privilliges[0],
      };
      dispatch(Valid(userObj));
      navigate("/pilot-dashboard");
    } else if (
      user?.roles[0]?.roleName === "SME" &&
      user?.roles[0]?.privilliges?.length === 1
    ) {
      let userObj = {
        ...user,
        selectedRole: "SME",
        selectedModule: user?.roles[0]?.privilliges[0]?.subModule,
        privilliges: user?.roles[0]?.privilliges[0],
      };
      dispatch(Valid(userObj));
      navigate("/sme-dashboard");
    } else if (
      user?.roles[0]?.roleName === "Client" &&
      user?.roles[0]?.privilliges?.length === 1
    ) {
      let userObj = {
        ...user,
        selectedRole: "Client",
        selectedModule: user?.roles[0]?.privilliges[0]?.subModule,
        privilliges: user?.roles[0]?.privilliges[0],
      };
      dispatch(Valid(userObj));
      navigate("/client-dashboard");
    } else {
      let userObj = {
        ...user,
        selectedRole: "",
        selectedModule: "",
        privilliges: {},
      };
      dispatch(Valid(userObj));
      navigate("/select-role");
      window.location.reload();

    }
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();
    setSpinner(true);
    const user = await loginfunction(login.userName, login.password);
    setUserData(user);
    delete user.password;
    if (
      user !== "Invalid username" &&
      user !== "Invalid password" &&
      user !== "no user found"
    ) {
      if (user.resetPassword) {
        setSpinner(false);
      } else {
        if (user.roles?.length == 1) {
          handleData(user);
        } else if (user.roles?.length > 0) {
          let userObj = {
            ...user,
            selectedRole: "",
            selectedModule: "",
            privilliges: {},
          };

          console.log(userObj)
          dispatch(Valid(userObj));
          navigate("/select-role");
          window.location.reload();
        } else {
          navigate("/login");
        }
      }
    } else {
      addToast(user, "error");
      setSpinner(false);
    }
  };

  return (
    <React.Fragment>
      <Loader loader={spinner} />
      <ToastContainer />
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay">
          <video autoPlay loop muted className="backgroundVideoPix">
            <source src={videoPath} type="video/mp4" />
          </video>
        </div>

        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Row>
                  {/* <AuthSlider /> */}
                  <Col lg={6}></Col>
                  <Col lg={6}>
                    <Card className="overflow-hidden">
                      <div className="p-lg-5 p-4">
                        <div>
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p className="text-muted">
                            Sign in to continue to Drogo Drones.
                          </p>
                        </div>

                        <div className="mt-4">
                          <form onSubmit={submitHandler}>
                            <div className="mb-3">
                              <Label htmlFor="username" className="form-label">
                                Username
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="username"
                                placeholder="Enter username"
                                value={login.userName}
                                onChange={(e) =>
                                  handleChange(e.target.value, "userName")
                                }
                                required
                              />
                            </div>

                            <div className="mb-3">
                              <div className="float-end">
                                <p
                                  className="text-muted cursor-pointer"
                                  onClick={() => navigate("/forgot_password")}
                                >
                                  Forgot password?
                                </p>
                              </div>
                              <Label
                                className="form-label"
                                htmlFor="password-input"
                              >
                                Password
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  type={type ? "password" : "text"}
                                  className="form-control pe-5 password-input"
                                  placeholder="Enter password"
                                  id="password-input"
                                  value={login.password}
                                  onChange={(e) =>
                                    handleChange(e.target.value, "password")
                                  }
                                  required
                                />
                                <button
                                  className="btn btn-link position-absolute end-0 top-0 d-flex align-items-center h-100 text-decoration-none text-muted password-addon"
                                  type="button"
                                  id="password-addon"
                                  onClick={() => setType(!type)}
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </button>
                              </div>
                            </div>

                            <div className="form-check">
                              {/* <Input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="auth-remember-check"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="auth-remember-check"
                              >
                                Remember me
                              </Label> */}
                            </div>

                            <div className="mt-4">
                              <Button
                                color="success"
                                className="w-100"
                                type="submit"
                                disabled={
                                  !login.userName ||
                                  login.userName === "" ||
                                  !login.password ||
                                  login.password === ""
                                }
                              >
                                Sign In
                              </Button>
                            </div>
                          </form>
                        </div>

                        <div className="mt-5 text-center">
                          {/* <p className="mb-0">
                            Don't have an account ?{" "}
                            <a
                              href="/auth-signup-cover"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              {" "}
                              Signup
                            </a>{" "}
                          </p> */}
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginVelzon;
