import React from "react";
import { Card, Col, Row } from "reactstrap";
import progBg from "../../assets/svg-new/towerBg.png";
import { createLogo, getDateOnly } from "../Components/Utils";
import CalanderImg from "../../assets/svg-new/Calender-blue.svg";
import locationicon from '../../assets/svg-new/location-icon.svg'
import { useNavigate } from "react-router-dom";

const ProjectCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Card
      className="pointer"
      onClick={() => navigate("/client-project-dashboard", { state: data })}
    >
      <div className="client-proj-img">
        <img src={progBg} />
        <div
          className={`proj-badge ${
            data?.projectStatus === "In Progress"
              ? "light-orange"
              : "light-green2"
          }`}
        >
          {data?.projectStatus}
        </div>
        <div className="d-flex">
          <div className="client-logo-client">
            {data?.client?.clientLogo ? (
              <img src={data?.client?.clientLogo} width="100%" />
            ) : (
              <span>{createLogo(data?.client.clientName)}</span>
            )}
          </div>
          <div className="client-projectName">{data?.projectName}</div>
        </div>
      </div>
      <Row className="mx-2">
        <Col className="d-flex align-items-center color-grayl fs-14">
          <img src={locationicon} className="me-2" />
          {data?.location}
        </Col>
        <Col className="d-flex align-items-center justify-content-end color-grayl fs-14">
          <img src={CalanderImg} className="me-2 w-1"></img>
          {getDateOnly(data?.createdAt)}
        </Col>
      </Row>
      <Row>
        <Col className="ms-3 fs-14">
          No. Of Corridor :{" "}
          <span className="fs-16 corr-num">{data?.totalCorridors}</span>
        </Col>
        <Col className="text-center fs-14">
          Completed :{" "}
          <span className="fs-16 corr-num">{data?.completedCorridors}</span>
        </Col>
        <Col className="text-end me-3 fs-14">
          Inprogress :{" "}
          <span className="fs-16 corr-num">{data?.inProgressCorridors}</span>
        </Col>
      </Row>
    </Card>
  );
};

export default ProjectCard;
