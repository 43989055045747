import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const Pagination = (props:any) => {
  const [pageCount, setPageCount] = useState<any>(0);

  const { itemsPerPage, count } = props;

  useEffect(() => {
    setPageCount(Math.ceil(count / itemsPerPage));
  }, [itemsPerPage, count]);

  const handlePageClick = (event:any) => {
    props.handlePageChange(event.selected + 1);
  };
  return (
    <div className="d-flex justify-content-end mt-2">
      <ReactPaginate
        nextLabel="Next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default Pagination;
