import React from "react";
import { Col, Row } from "reactstrap";

const IdentifierPopup = ({
  OverlayData,
  identifierData,
  setPopover,
}) => {
  const calcPos = (OverlayData) => {
    const regex = /translate\(\s*(-?\d+\.?\d*)px,\s*(-?\d+\.?\d*)px\)/;
    let transformString = OverlayData?.rendered?.transform_;
    const match = transformString.match(regex);
    if (match) {
      const [x, y] = [parseFloat(match[1]), parseFloat(match[2])];
      return [x, y];
    }
  };
  return (
    <div
      className="popover-itentifier"
      style={{
        position: "absolute",
        top: OverlayData ? calcPos(OverlayData)[1] + "px" : "0",
        left: OverlayData ? calcPos(OverlayData)[0] + "px" : "0",
        zIndex: 2,
        display: OverlayData ? "block" : "none",
      }}
    >
      <span className="pop-v"></span>
      <i
        className="bx bx-x pop-x fs-3 pointer"
        style={{ color: "#129fca" }}
        onClick={() => {
          setPopover(false);
        }}
      ></i>
      <Row>
        <Col xs={12} className="fs-14 d-flex justify-content-between">
          <div className="text-muted">Feature Name :</div>
          <div className="text-primary">{identifierData?.coordinates[2]}</div>
        </Col>
        <Col xs={12} className="fs-14 d-flex justify-content-between">
          <div className="text-muted">Latitude :</div>
          <div className="text-primary">{identifierData?.coordinates[0]}</div>
        </Col>
        <Col xs={12} className="fs-14 d-flex justify-content-between">
          <div className="text-muted">Longitude :</div>
          <div className="text-primary">{identifierData?.coordinates[1]}</div>
        </Col>
        {identifierData?.properties?.Length && (
          <Col xs={12} className="fs-14 d-flex justify-content-between">
            <div className="text-muted">Length :</div>
            <div className="text-primary">
              {identifierData?.properties?.Length}
            </div>
          </Col>
        )}
        {identifierData?.properties?.Buffered_Offset && (
          <Col xs={12} className="fs-14 d-flex justify-content-between">
            <div className="text-muted">Buffer :</div>
            <div className="text-primary">
              {identifierData?.properties?.Buffered_Offset}
            </div>
          </Col>
        )}
        {identifierData?.properties?.Area && (
          <Col xs={12} className="fs-14 d-flex justify-content-between">
            <div className="text-muted">Area :</div>
            <div className="text-primary">
              {identifierData?.properties?.Area}
            </div>
          </Col>
        )}
        {identifierData?.properties && <Col xs={12} className="fs-14">
        <div className="fw-500 fs-16">Attributes: </div>
          {Object.entries(identifierData?.properties)?.map((e, idx) => {
            let key = e[0]
            let value = e[1]
            return <div className="d-flex justify-content-between"> <div>{key}</div><div>{value}</div></div>
          })}
        </Col>}
      </Row>
    </div>
  );
};

export default IdentifierPopup;
