import React, { useRef, useState } from "react";
import { Toolbar } from "primereact/toolbar";
import { Image } from "primereact/image";
import titleLogo from "../../assets/newDrogoLogo.png";
import { Avatar } from "primereact/avatar";
import avatar from "../../assets/avatar.png";
import "../../styles/Header.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Tooltip } from "primereact/tooltip";
import { OverlayPanel } from "primereact/overlaypanel";
import { ListGroup } from "react-bootstrap";
import { Valid } from "../../store/reducer";
import { Survey } from "../../store/comparereducer";
import { projectdata } from "../../store/dashboardreducer";
import { miningTsx } from "../../store/miningstore";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { projectsArrTxn } from "../../store/projectsArrayReducer";
import { Button } from "primereact/button";
import { landSurveyTsx } from "../../store/landsurveyreducer";
import { messages } from "../commons/constant";
import InputComponent from "../inputComponent/inputComponent";
import { ToastContainer } from "react-toastify";
import * as globalApi from "../../api/global.api";
import Loader from "../CommonComponent/Loader";
import PasswordComponent from "../commons/PasswordComponent";
import UnAuthDialog from "../commons/UnAuthDialog";
import { layersTsx } from "../../store/layersReducer";
import { surveyTxn } from "../../store/surveyReducer";
import { Capitalize, formatDateTime } from "../commons/functions";
import avatarlogo from "../../assets/avatarlogo.jpg";
import changeRole from "../../assets/role.png";
import { addToast } from "../commons/common";
export const Header = (props) => {
  const [profileMod, setprofileMod] = useState(false);
  const [changePasswordMod, setchangePasswordMod] = useState(false);
  const [formState, setFormState] = useState({});
  const [errors, setErrors] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState(false);
  const logout = () => {
    dispatch(Valid({}));
    dispatch(Survey({}));
    dispatch(projectdata({}));
    dispatch(miningTsx({}));
    dispatch(projectsArrTxn({}));
    dispatch(surveyTxn({}));
    dispatch(landSurveyTsx({}));
    dispatch(layersTsx({}));
    document.title = "Land Survey web visualizer";
    navigate("/login");
  };
  const openUpdatePassword = () => {
    setchangePasswordMod(true);
  };
  const openProfile = () => {
    setprofileMod(true);
  };
  const handleNavigate = () => {
    if (
      window.location.pathname !== "/home" &&
      window.location.pathname !== "/client-projects"
    ) {
      navigate("/home");
    } else if (window.location.pathname === "/client-projects") {
      navigate("/client-projects");
    }
  };
  const op = useRef(null);
  const logoToolTIp = () => (
    <div>
      <ListGroup>
        <ListGroup.Item
          className="pointer p12-20"
          onClick={() => openProfile()}
        >
          <i className="pi pi-user mt5 me-2"> </i>
          Profile
        </ListGroup.Item>
        <ListGroup.Item
          className="pointer p12-20"
          onClick={() => openUpdatePassword()}
        >
          <i className="pi pi-key mt5 me-2"> </i>
          Change Password
        </ListGroup.Item>
        <ListGroup.Item
          className="pointer p12-20"
          onClick={() => navigate("/select-role")}
        >
          <img src={changeRole} width={15} className="me-2" />
          Change Role
        </ListGroup.Item>
        <ListGroup.Item className="pointer p12-20" onClick={() => logout()}>
          <i className="pi pi-sign-out mt5 me-2"> </i>Logout
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
  const leftContents = (
    <div
      onClick={() => handleNavigate()}
      className={
        window.location.pathname !== "/home" &&
        window.location.pathname !== "/client-projects"
          ? "pointer"
          : ""
      }
    >
      <Image
        src={titleLogo}
        alt="Drogodrones"
        height="80px"
        padding-left="10px"
        className="w-200"
      />
    </div>
  );

  const rightContents = (
    <>
      <div className="Client-admin">
        <p>
          <span className="bold">User : </span>
          {" " + user.firstName + " " + user.lastName}
        </p>
        <p>
          <span className="bold">Role : </span>
          {Capitalize(user.selectedRole)}
        </p>
        <p>
          <span className="bold">Last Login : </span>
          {" " + formatDateTime(user.lastLoginDate)}
        </p>
      </div>
      <Avatar
        image={user.imageUrl || avatarlogo}
        className="mr-2 pointer border-black"
        size="medium"
        shape="circle"
        onClick={(e) => op.current.toggle(e)}
      />

      <OverlayPanel ref={op} dismissable>
        {logoToolTIp()}
      </OverlayPanel>
    </>
  );
  const footer = () => {
    return (
      <div className="center">
        <Button
          className="rounded-0 btn-style btn-primary"
          label="Ok"
          onClick={() => setprofileMod(false)}
        />
      </div>
    );
  };
  const handleCancel = () => {
    setchangePasswordMod(false);
    setFormState({});
    setErrors([]);
  };
  const checkDisablePwdUpdate = () => {
    if (
      !formState.oldpassword ||
      formState.oldpassword === "" ||
      !formState.newpassword ||
      formState.newpassword === "" ||
      !formState.confirmnewpassword ||
      formState.confirmnewpassword === "" ||
      errors.newpassword ||
      errors.confirmnewpassword ||
      passwordError
    )
      return true;
    else return false;
  };
  const handleSubmit = () => {
    setSpinner(true);
    setchangePasswordMod(false);
    globalApi
      .login(user?.email, formState.newpassword, formState.oldpassword)
      .then((data) => {
        if (data?.status?.toLowerCase() === "failed") {
          addToast("Unable to update the password", "error");
          setFormState({});
          setSpinner(false);
        } else if (data === 401) {
          setShowLogout(true);
          setSpinner(false);
        } else {
          addToast("Password updated successfully", "success");
          setErrors([]);
          setFormState({});
          setTimeout(() => {
            logout();
          }, 5000);
        }
      })
      .catch((err) => {
        addToast("Unable to update the password", "error");
        setSpinner(false);
      });
  };
  const foot = () => {
    return (
      <div className="DTM">
        <Button
          className="rounded-0 btn-style btn-secondary m-r-10"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          className="rounded-0 btn-style btn-primary"
          onClick={() => handleSubmit()}
          disabled={checkDisablePwdUpdate()}
        >
          Update
        </Button>
      </div>
    );
  };
  const handleFormChange = (e, field, type, error) => {
    errors[field] = error[field];
    if (
      (field === "newpassword" && formState.oldpassword === e) ||
      (field === "oldpassword" && formState.newpassword === e)
    ) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    setErrors(errors);
    setFormState({ ...formState, [field]: e });
  };

  return (
    <div className={props.class ? "menu-bar top-0 pwidth-100 ps-0 ms-0" : ""}>
      <Loader loader={spinner} />

      <ToastContainer />
      <Toolbar
        className="admin w-100"
        left={leftContents}
        right={rightContents}
      />
      {profileMod && (
        <Dialog
          draggable={false}
          header="Profile Details"
          position="top"
          footer={footer}
          visible={profileMod}
          onHide={() => setprofileMod(false)}
          style={{ width: "60vw", maxWidth: 600, minWidth: 400 }}
        >
          <div className="center">
            <img
              className="m-t-10 w-30 h-30 br50"
              src={user?.imageUrl}
              size="xlarge"
            />
          </div>
          <div className="m-t-20 row">
            <div className="col-6">
              <b>Name:</b> {user?.firstName + " " + user?.lastName}
            </div>
            <div className="col-6">
              <b>Role:</b> {user?.role?.roleName}
              <b>Last Login:</b> {formatDateTime(user?.lastLoginDate)}
            </div>
            <div className="text-muted"></div>
          </div>
        </Dialog>
      )}
      {changePasswordMod && (
        <Dialog
          draggable={false}
          closable={false}
          header="Update Password"
          position="top"
          footer={foot}
          visible={changePasswordMod}
          onHide={() => setchangePasswordMod(false)}
          style={{ width: "40vw", maxWidth: 400, minWidth: 300 }}
        >
          <InputComponent
            mandatory={true}
            name="oldpassword"
            value={formState.oldpassword}
            placeholder="Enter Old Password"
            required={true}
            type="password"
            maxLength={30}
            onKeyChange={handleFormChange}
          />
          <div className="m-t-20">
            <PasswordComponent
              value={formState.newpassword}
              handleChange={handleFormChange}
              mandatory={true}
              name="newpassword"
              disabled={!formState.oldpassword || formState.oldpassword === ""}
              matchPassword={formState.oldpassword}
              validator="password"
              errorMessage={messages.NEW_PASSWORD}
              placeholder="Enter New Password"
            />
          </div>
          <div className="m-t-20">
            <InputComponent
              mandatory={true}
              name="confirmnewpassword"
              value={formState.confirmnewpassword}
              placeholder="Confirm New Password"
              required={true}
              checkMatched={formState.newpassword}
              disabled={
                !formState.newpassword ||
                formState.newpassword === "" ||
                passwordError
              }
              validator="newpassword"
              type="password"
              maxLength={30}
              errorMessage={messages.CONFIRM_PASSOWRD}
              onKeyChange={handleFormChange}
            />
          </div>
        </Dialog>
      )}
      {showLogout && (
        <UnAuthDialog
          visible={showLogout}
          onHide={() => setShowLogout(false)}
        />
      )}
    </div>
  );
};
