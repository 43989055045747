import DataTable from "react-data-table-component";
import { Button, Spinner } from "reactstrap";
import { formatDate, formatDateTime } from "./CommonFunctions";
import { Tooltip } from "primereact/tooltip";
import { InputSwitch } from "primereact/inputswitch";
import { Image } from "primereact/image";
import { Tag } from "primereact/tag";
import images from "../../assets/images/images.png";
import pdficon from "../../assets/images/pdficon.png";
import uploadImg from "../../assets/images/uploadImg.svg";
import reportImg from "../../assets/reportUpload.png";
function CustomTable(props: any) {
  let columns: [] = props.columns.map((obj: any) => {
    switch (obj.type) {
      case "fullName":
        return {
          id: obj?.id,
          name: obj?.header,
          selector: (row: any) => (
            <span>{row.firstName + " " + row.lastName}</span>
          ),
          sortable: obj?.sortable,
          grow: obj?.grow,
        };
      case "coordinate":
        return {
          id: obj?.id,
          name: obj?.header,
          selector: (row: any) => {
            let lat = row?.[obj.field]?.split(",")[0];
            let long = row?.[obj.field]?.split(",")[1];
            return (
              <span>
                {lat} <span className="fs-18">, </span> {long}
              </span>
            );
          },
          grow: 1.5,
        };
      case "array":
        return {
          id: obj?.id,
          name: obj?.header,
          selector: (row: any) => {
            let arr = obj?.fieldName
              ? row[obj?.field]?.map((e: any) => e[obj?.fieldName])
              : row[obj?.field];
            return <span>{arr.join(", ")}</span>;
          },
          sortable: obj?.sortable,
          grow: obj?.grow,
        };
      case "date":
        return {
          id: obj?.id,
          name: obj?.header,
          selector: (row: any) =>
            row[obj?.field]
              ? obj.format === "date-time"
                ? formatDateTime(row[obj?.field])
                : formatDate(row[obj?.field])
              : null,
          sortable: obj?.sortable,
          grow: obj?.grow,
        };
      case "link":
        return {
          id: obj?.id,
          name: obj?.header,
          selector: (row: any) => {
            return (
              <span
                className="primary pointer"
                onClick={() => (obj?.onclick ? props[obj?.onclick](row) : "")}
              >
                {row[obj?.field]}
              </span>
            );
          },
          sortable: obj?.sortable,
          grow: obj?.grow,
        };
      case "action":
        return {
          id: obj?.id,
          name: obj?.header,
          cell: (row: any) => {
            return obj?.buttons?.map((btn: any) => {
              if (btn?.show) {
                let value = row[btn?.show?.label];
                let show =
                  btn?.show?.value?.indexOf(value.toLowerCase()) !== -1;
                return (
                  show && (
                    <>
                      {obj?.tooltip && (
                        <Tooltip
                          target={`.${btn?.label}`}
                          style={{ fontSize: "12px" }}
                        />
                      )}
                      <i
                        className={`${btn?.icon} me-2 fs-5 pointer ${btn?.label}`}
                        style={{ color: btn?.color }}
                        data-pr-tooltip={btn?.label}
                        data-pr-position="bottom"
                        onClick={() =>
                          btn?.onclick ? props[btn?.onclick](row) : ""
                        }
                      ></i>
                    </>
                  )
                );
              } else {
                return (
                  <>
                    {obj?.tooltip && (
                      <Tooltip
                        target={`.${btn?.label}`}
                        style={{ fontSize: "12px" }}
                      />
                    )}
                    <i
                      className={`${btn?.icon} me-2 fs-5 pointer ${btn?.label}`}
                      style={{ color: btn?.color }}
                      data-pr-tooltip={btn?.label}
                      data-pr-position="bottom"
                      onClick={() =>
                        btn?.onclick ? props[btn?.onclick](row) : ""
                      }
                    ></i>
                  </>
                );
              }
            });
          },
          sortable: obj?.sortable,
          grow: obj?.grow,
        };
      case "switch":
        return {
          id: obj?.id,
          name: obj?.header,
          selector: (row: any) => {
            return (
              <>
                <InputSwitch
                  checked={row[obj?.field]}
                  onChange={(e) =>
                    obj?.onclick ? props[obj?.onclick](e, row) : ""
                  }
                />
              </>
            );
          },
        };
      case "image":
        return {
          id: obj?.id,
          name: obj?.header,
          selector: (row: any) => {
            const reportCount = row?.uploadDetails?.find(
              (item: any) => item?.fileType === "report"
            );
            return (
              <>
                {obj?.image === "image1" && (
                  <div onClick={() => props[obj?.onclick](row)}>
                    <Image
                      src={images}
                      alt="Image"
                      width="30"
                      className="pointer"
                    />
                    <span className="ms-3">
                      {
                        row?.uploadDetails?.filter(
                          (item: any) => item?.fileType === "client_screenshot"
                        ).length
                      }{" "}
                      Images
                    </span>
                  </div>
                )}
                {obj?.image === "image2" && (
                  <div onClick={() => props[obj?.onclick](row)}>
                    <Image
                      src={pdficon}
                      alt="Image"
                      width="30"
                      className="pointer"
                    />
                  </div>
                )}
                {obj?.image === "image3" && (
                  <div onClick={() => props[obj?.onclick](row)}>
                    <Image
                      src={reportImg}
                      alt="Image"
                      width="30"
                      className="pointer"
                    />
                    <span className="ms-3">
                      {reportCount?.uploadJsonData?.length} Reports
                    </span>
                  </div>
                )}
              </>
            );
          },
        };
      case "status":
        return {
          id: obj?.id,
          name: obj?.header,
          selector: (row: any) => {
            return (
              <>
                {row?.openStatus === "Closed" && (
                  <Tag severity="success" value="Closed" rounded></Tag>
                )}
                {row?.openStatus === "Open" && (
                  <Tag severity="danger" value="Open" rounded></Tag>
                )}
              </>
            );
          },
        };
      case "status1":
        return {
          id: obj?.id,
          name: obj?.header,
          grow: obj?.grow,
          selector: (row: any) => {
            return (
              <>
                {row[obj?.field] === "Completed" && (
                  <Tag severity="success" value="Completed" rounded></Tag>
                )}
                {row[obj?.field] === "Inspection Done" && (
                  <Tag severity="info" value="Inspection Done" rounded></Tag>
                )}
                {row[obj?.field] === "In Progress" && (
                  <Tag severity="warning" value="In Progress" rounded></Tag>
                )}
                {row[obj?.field] === "Save As Draft" && (
                  <Tag severity="warning" value="Save As Draft" rounded></Tag>
                )}
                {row[obj?.field] === "Yet To Start" && (
                  <Tag className="secondary" value="Yet To Start" rounded></Tag>
                )}
                {row[obj?.field] === "Data Captured" && (
                  <Tag
                    className="secondary2"
                    value="Data Captured"
                    rounded
                  ></Tag>
                )}
              </>
            );
          },
        };
      case "projectActon":
        return {
          id: obj?.id,
          name: obj?.header,
          grow: obj?.grow,
          cell: (row: any) => {
            return (
              <>
                {row?.assetZipFileStatus?.toLowerCase() === "inprogress" ? (
                  <span>File Upload is in progress</span>
                ) : (
                  <>
                    {!!row?.capturedDate ? (
                      <>
                        {!row?.assetInspectionStart ? (
                          <>
                            <>
                              {row?.assetZipName && (
                                <span className="border-end pe-2">
                                  {row?.assetZipName}
                                </span>
                              )}
                              <span
                                className={`ms-2 ${
                                  props?.reUploadPriv ? "pointer" : ""
                                }`}
                                onClick={() =>
                                  props?.reUploadPriv
                                    ? props[obj?.onclick](row)
                                    : null
                                }
                              >
                                Reupload
                              </span>
                            </>
                          </>
                        ) : (
                          <>{row?.assetZipName}</>
                        )}
                      </>
                    ) : (
                      <>
                        <span
                          className={props?.uploadPriv ? "pointer" : ""}
                          onClick={() =>
                            props?.uploadPriv ? props[obj?.onclick](row) : null
                          }
                        >
                          <img
                            src={uploadImg}
                            alt=""
                            className="avatar-xxs me-2"
                          />
                          Upload
                        </span>
                      </>
                    )}
                  </>
                )}
              </>
            );
          },
        };
      case "button":
        return {
          id: obj?.id,
          name: obj?.header,
          selector: (row: any) => {
            return (
              <>
                {row?.towerStatus === "Completed" && (
                  <Button
                    color="info"
                    outline
                    onClick={() => props?.onActionClick(row)}
                  >
                    View Component
                  </Button>
                )}
                {row?.towerStatus !== "Completed" && (
                  <Button
                    color="info"
                    onClick={() => props?.onActionClick(row)}
                    outline
                  >
                    Take Action
                  </Button>
                )}
              </>
            );
          },
        };
      default:
        return {
          id: obj?.id,
          name: obj?.header,
          selector: (row: any) => row[obj?.field],
          sortable: obj?.sortable,
          grow: obj?.grow,
        };
    }
  });
  return (
    <DataTable
      //General
      columns={columns}
      data={props.data}
      keyField={props.key}
      title={props.title}
      noDataComponent={props.noDataComponent}
      responsive={props.responsive || true}
      striped={props.striped || true}
      highlightOnHover={props.highlightOnHover || true}
      pointerOnHover={props.pointerOnHover || false}
      dense={props.dense || false}
      noTableHead={props.noTableHead || false}
      persistTableHead={props.persistTableHead || false}
      disabled={props.disabled || false}
      onRowClicked={props.onRowClicked}
      onRowDoubleClicked={props.onRowDoubleClicked}
      onRowMouseEnter={props.onRowMouseEnter}
      onRowMouseLeave={props.onRowMouseLeave}
      //Row Selection
      selectableRows={props.selectableRows || false}
      selectableRowsHighlight={props.selectableRowsHighlight || true}
      onSelectedRowsChange={props.onSelectedRowsChange}
      selectableRowDisabled={props.selectableRowDisabled}
      selectableRowSelected={props.selectableRowSelected}
      //Row Expander
      expandableRows={props.expandableRows || false}
      expandableRowsComponent={props.expandableRowsComponent}
      expandableRowExpanded={props.expandableRowExpanded}
      expandableRowDisabled={props.expandableRowDisabled}
      //Pagination
      pagination={props.pagination || false}
      paginationServer={props.paginationServer || false}
      paginationResetDefaultPage={props.paginationResetDefaultPage || false}
      paginationDefaultPage={props.paginationDefaultPage || 1}
      paginationTotalRows={props.paginationTotalRows}
      paginationPerPage={props.paginationPerPage}
      paginationServerOptions={props.paginationServerOptions}
      paginationRowsPerPageOptions={
        props.paginationRowsPerPageOptions || [10, 15, 20, 25, 30]
      }
      paginationComponent={props.paginationComponent}
      paginationComponentOptions={props.paginationComponentOptions}
      paginationIconFirstPage={props.paginationIconFirstPage}
      paginationIconLastPage={props.paginationIconLastPage}
      paginationIconNext={props.paginationIconNext}
      paginationIconPrevious={props.paginationIconPrevious}
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangeRowsPerPage}
      //Progress
      progressPending={props.progressPending}
      progressComponent={
        props.progressComponent ? (
          props.progressComponent
        ) : (
          <Spinner color="primary" />
        )
      }
      //Theme
      theme={props.theme || "default"}
      customStyles={props.customStyles}
      //Headers
      fixedHeader={props.fixedHeader || false}
      fixedHeaderScrollHeight={props.fixedHeaderScrollHeight}
      //Sorting
      defaultSortFieldId={props.defaultSortFieldId}
      onSort={props.onSort}
      sortServer={props.sortServer}
    />
  );
}

export default CustomTable;
