import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Capitalize } from "../commons/functions";
import { Tooltip } from "primereact/tooltip";


const SourceTable = ({ data, handleEdit, setOpen, handleDelete, setMode }) => {
  const navigate = useNavigate();
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Source ID</span>,
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Data Type</span>,
      sortable: true,
      selector: (row) => row.dataType,
    },
    {
      name: <span className="font-weight-bold fs-13">Source Name</span>,
      selector: (row) => Capitalize(row.sourceName),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Source Display Name</span>,
      selector: (row) => Capitalize(row.sourceDisplayName),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Source Link</span>,
      selector: (row) => row.sourceLink,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Actions</span>,
      sortable: true,

      cell: (row) => {
        return (
          <span>
            <Tooltip target=".custom-target-icon-delete" />
            <Tooltip target=".custom-target-icon-edit" />
            <Tooltip target=".custom-target-icon-source" />
            {row.dataType === "Vector" && (
              <i
                class="ri-database-2-fill fs-4 me-2 pointer custom-target-icon-source"
                style={{ color: "green" }}
                data-pr-tooltip="Corridor"
                data-pr-position="top"
                onClick={() => {
                  navigate(`/delivers/source/corridor/${row.id}`);
                  sessionStorage.setItem(
                    "sourceDisplayName",
                    row.sourceDisplayName
                  );
                }}
              ></i>
            )}
            <i
              className="bx bx-edit fs-4 me-2 pointer custom-target-icon-edit"
              style={{ color: "green" }}
              data-pr-tooltip="Edit"
              data-pr-position="top"
              onClick={() => {
                setMode("Edit Source");
                handleEdit(row);
                setOpen(true);
              }}
            ></i>
            <i
              className="ri-delete-bin-6-line fs-4 me-2 pointer custom-target-icon-delete"
              style={{ color: "red" }}
              data-pr-tooltip="Delete"
              data-pr-position="top"
              onClick={() => {
                handleDelete(row);
              }}
            ></i>
          </span>
        );
      },
    },
  ];
  return <DataTable columns={columns} data={data}  />;
};

export default SourceTable;
