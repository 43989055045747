import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Row } from "reactstrap";
import SearchOption from "../../components/Common/SearchOption";
import * as api from "../../api/rest.api";
import { createLogo, getDateOnly } from "../Components/Utils";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { pageData } from "../../store/PageData";
import Loader from "../../components/Common/Loader";
import DeleteModal from "../../components/Common/DeleteModal";
import PaginationComponent from "../Components/PaginationComponent";
import { toast } from "react-toastify";
import { errorMessages } from "../../components/commons/constant";

const Tower = () => {
  const location = useLocation().state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [towers, setTowers] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [rows, setRows] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);

  const getTowers = async () => {
    setLoading(true);
    let url = `getTower?corridorId=${
      location?.data?.id
    }&smeStatus=Inspection Done,Completed,In Progress,Save As Draft&page=${
      page + 1
    }&limit=10`;
    let res = await api.getData(url);
    if (res?.data) {
      console.log(res?.data);
      setTowers(res?.data?.rows);
      setTotal(res?.data?.totalCount);
      setTableData(res?.data?.rows);
    }
    setLoading(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let url;
      if (searchTerm) {
        url = `getTower?corridorId=${location?.data?.id}&search=${searchTerm}&smeStatus=Inspection Done,Completed,In Progress,Save As Draft`;
      } else {
        url = `getTower?corridorId=${
          location?.data?.id
        }&smeStatus=Inspection Done,Completed,In Progress,Save As Draft&page=${
          page + 1
        }&limit=10`;
      }
      let res = await api.getData(url);
      if (res?.data) {
        setTowers(res?.data?.rows);
        setTableData(res?.data?.rows);
        // let filter = res?.data?.rows.slice(page * 10, page * 10 + 10);
        // searchTerm ? setTableData(res?.data?.rows) : setTableData(filter);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    getTowers();
  }, []);

  const handleAction = (row) => {
    let obj = {
      project: location.project,
      corridor: location.data,
      tower: row,
    };
    sessionStorage.setItem("sme-tab", JSON.stringify("image"));
    sessionStorage.removeItem("sme-current-image");
    dispatch(pageData(obj));
    navigate("/sme-tower-component", {
      state: { data: row, corridor: location },
    });
  };

  const handleDelete = async () => {
    setLoading(true);
    let obj = {
      towerId: rows?.id,
    };
    let res = await api.addData("deletetowercomponent", obj);
    if (res?.data) {
      toast.success("Tower records deleted successfully");
      getTowers();
      setIsDelete(false);
      setRows(null);
    } else {
      toast.error(res?.errorMessage);
      setLoading(false);
    }
  };

  const towerColumns = [
    {
      name: "Tower Number",
      cell: (row) => (
        <span>
          {row.towerNo} {row.towerType}{" "}
        </span>
      ),
    },
    { name: "Line Name", selector: "lineName" },
    { name: "Tower Category", selector: "towerCategory" },
    { name: "Tower Type", selector: "towerType" },
    { name: "Lat & Long", selector: "location" },
    { name: "Inspection Date", cell: (row) => getDateOnly(row.inspectionDate) },
    {
      name: "Status",
      cell: (row) => (
        <span
          className={`${
            row.towerStatus === "Completed"
              ? "t-status-complete"
              : row.towerStatus === "Save As Draft"
              ? "t-status-saveasdraft"
              : row.towerStatus === "In Progress"
              ? "t-status-inprogress"
              : "t-status-inspectiondone"
          }`}
        >
          {row.towerStatus}
        </span>
      ),
    },
    {
      name: "Action",
      cell: (row) => {
        if (
          row?.towerStatus === "Completed" ||
          row?.towerStatus === "Save As Draft"
        ) {
          return (
            <span className="view-comp" onClick={() => handleAction(row)}>
              View Component
            </span>
          );
        } else {
          return (
            <>
              <Button color="success" outline onClick={() => handleAction(row)}>
                Take Action
              </Button>
              <span>
                <i
                  className="bx bx-trash fs-2 text-danger ms-2 pointer"
                  onClick={() => {
                    setIsDelete(true);
                    setRows(row);
                  }}
                ></i>
              </span>
            </>
          );
        }
      },
    },
  ];
  const onPageChange = (val) => {
    if (val === -1) {
      setPage(0);
    } else {
      setPage(val);

      let debounce = setTimeout(async () => {
        let url;
        setLoading(true);
        if (val > -1) {
          if (searchTerm) {
            url = `getTower?corridorId=${location?.data?.id}&search=${searchTerm}&smeStatus=Inspection Done,Completed,In Progress,Save As Draft`;
          } else {
            url = `getTower?corridorId=${
              location?.data?.id
            }&smeStatus=Inspection Done,Completed,In Progress,Save As Draft&page=${
              val + 1
            }&limit=10`;
          }
          let res = await api.getData(url);
          if (res?.data) {
            setTowers(res?.data?.rows);
            setTableData(res?.data?.rows);
          }
          setLoading(false);
        }
      }, [1000]);
      return () => clearTimeout(debounce);
    }
  };

  return (
    <div className="content">
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      {isDelete && (
        <DeleteModal
          show={isDelete}
          onCloseClick={() => setIsDelete(false)}
          onDeleteClick={handleDelete}
        />
      )}
      <Row className="mb-3 mx-3">
        <Col>
          <div className="client-logo-sme">
            {location?.project?.client?.clientLogo ? (
              <img src={location?.project?.client?.clientLogo} />
            ) : (
              <span>{createLogo(location?.project?.client?.clientName)}</span>
            )}
          </div>
          <div className="client-logo-sme-name">
            {location?.data?.corridorName}
          </div>
        </Col>
        <Col className="text-end me-2 mt-2">
          <span
            className="bread-last"
            onClick={() => navigate("/sme-dashboard")}
          >
            Dashboard
          </span>
          {" > "}

          <span
            className="bread-last"
            onClick={() =>
              navigate("/sme-corridor", { state: location.project })
            }
          >
            {location?.project?.projectName}
          </span>
          {" > "}
          <span className="fs-14">{location?.data?.corridorName}</span>
        </Col>
      </Row>
      <Row className="px-3 mx-3 mb-0">
        <Card>
          <SearchOption handleSearch={(val) => setSearchTerm(val)} />
        </Card>
      </Row>
      <Row className="mx-3 mb-2 mt-0">
        <DataTable
          className="sme-tower"
          columns={towerColumns}
          data={tableData}
          highlightOnHover
        />
      </Row>
      {searchTerm === "" && (
        <Row className="mx-2 mb-4">
          <PaginationComponent
            page={page}
            limit={10}
            total={total}
            onPageChange={onPageChange}
          />
        </Row>
      )}
    </div>
  );
};

export default Tower;
