import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectdata: {},
};

export const userSlice = createSlice({
  name: "projectdata",
  initialState,
  reducers: {
    projectdata: (state, action) => {
      return action.payload;
    },
  },
});

export const { projectdata } = userSlice.actions;
export default userSlice.reducer;
