import React from "react";
import { Card, Col, Row } from "reactstrap";
import progBg from "../../assets/svg-new/oil.jpg";
import { createLogo, getDateOnly } from "../Components/Utils";
import CalanderImg from "../../assets/svg-new/Calender-blue.svg";
import { useNavigate } from "react-router-dom";

const ProjectCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Card
      className="pointer"
      onClick={() => {
        navigate(`/client-projects/assets/${data?.id}`);
        sessionStorage.setItem("site", JSON.stringify(data));
      }}
    >
      <div className="client-proj-img">
        <img src={progBg} />
        <div
          className={`proj-badge ${
            data?.projectStatus === "In Progress"
              ? "light-orange"
              : "light-green2"
          }`}
        >
          {data?.projectStatus}
        </div>
        <div className="d-flex">
          <div className="client-logo-client">
            {data?.client?.clientLogo ? (
              <img src={data?.client?.clientLogo} width="100%" />
            ) : (
              <span>{createLogo(data?.client.clientName)}</span>
            )}
          </div>
          <div className="client-projectName text-light">
            {data?.projectName}
          </div>
        </div>
      </div>
      <Row className="mx-2">
        <Col className="d-flex align-items-center color-grayl">idx
          <i class="bx bxs-location-plus fs-4 text-danger me-2"></i>
          {data?.location}
        </Col>
        <Col className="d-flex align-items-center justify-content-end color-grayl">
          <img src={CalanderImg} className="me-2 w-1"></img>
          {getDateOnly(data?.createdAt)}
        </Col>
      </Row>
      <Row>
        <Col className="ms-3 fs-15">
          No. Of Assets :{" "}
          <span className="fs-19 corr-num">{data?.totalAssets}</span>
        </Col>
        <Col className="text-center fs-15">
          Completed :{" "}
          <span className="fs-19 corr-num">{data?.completedAssets}</span>
        </Col>
        <Col className="text-end me-3 fs-15">
          Inprogress :{" "}
          <span className="fs-19 corr-num">{data?.inProgressAssets}</span>
        </Col>
      </Row>
    </Card>
  );
};

export default ProjectCard;
