import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const projSlice = createSlice({
  name: "proj",
  initialState,
  reducers: {
    proj: (state, action) => {
      return action.payload;
    },
  },
});

export const { proj } = projSlice.actions;
export default projSlice.reducer;
