import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import AddRole from "./AddRole";
import { useFormik } from "formik";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { Link, useNavigate } from "react-router-dom";
import PrivilegeTable from "./PrivilegeTable";
import * as Yup from "yup";
import { Capitalize, alphabets, phoneRegex } from "../../commons/functions";
import * as api from "../../../api/global.api";
import Loader from "../../CommonComponent/Loader";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Common/DeleteModal";
import "../../../assets/scss/user.css";

const AddUser = () => {
  const navigate = useNavigate();
  const storageEditUserData: any = sessionStorage.getItem("editUserData");
  const fileType: any = "image/*";
  const editFormData: any = JSON.parse(storageEditUserData)
    ? JSON.parse(storageEditUserData)
    : null;

  const [isDelete, setIsDelete] = useState<any>(false);
  const [isModal, setIsModal] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);

  //userData
  const [selectedMulti, setSelectedMulti] = useState<any>([]);
  const [options, setOptions] = useState<any>();
  const [selectedFiles, setselectedFiles] = useState<any>([]);

  //tableData
  const storageTableData: any = sessionStorage.getItem("tableData");
  const [tableData, setTableData] = useState<any>(
    storageTableData ? JSON.parse(storageTableData) : []
  );
  const [deleteRoleIdNumber, setDeleteRoleIdNumber] = useState<any>();
  const [editData, setEditData] = useState<any>(null);

  //BreadCrumb
  const [selectedView, setSelectedView] = useState<any>("Add User");

  //RolesData

  const formik = useFormik<any>({
    initialValues: {
      firstName: "",
      lastName: "",
      contactNumber: "",
      email: "",
      subModuleName: "",
      imageUrl: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(alphabets, "Please enter valid first name")
        .trim("Please enter valid first name")
        .strict(true)
        .required("required"),
      lastName: Yup.string()
        .matches(alphabets, "Please enter valid last name")
        .trim("Please enter valid last name")
        .strict(true)
        .required("required"),
      contactNumber: Yup.string()
        .matches(phoneRegex, "Please enter valid contact number")
        .required("required"),
      email: Yup.string()
        .email("Please enter valid email address")
        .required("required"),
      subModuleName: Yup.string().required("required"),
    }),
    onSubmit: (values: any) => {
      let newTable = [...tableData];
      let newUser = {
        ...values,
        roles: [...newTable],
      };
      editFormData === null ? addUser(newUser) : updateUser(newUser);
    },
  });

  const getEnums = () => {
    api.get("getSubModules").then((res: any) => {
      let data = res?.response?.map((ele: any) => {
        return { label: ele, value: ele };
      });
      setOptions(data);
    });
  };

  const addUser = (val: any) => {
    setLoading(true);
    api
      .post("user/add", val)
      .then((res) => {
        if (res.status === "Success") {
          sessionStorage.removeItem("tableData");
          toast.success("User addeed successfully");
          navigate("/users");
        } else {
          setLoading(false);
          toast.error(res.errormessage);
        }
      })
      .catch((res) => {
        setLoading(false);
      });
  };

  const updateUser = (val: any) => {
    setLoading(true);
    api
      .post("user/update", { ...val, id: editFormData?.id })
      .then((res) => {
        if (res.status === "Success") {
          toast.success("User updated successfully");
          navigate("/users");
        } else {
          toast.error(res.errormessage);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const handletableData = (val: any) => {
    if (val.id) {
      let newData = [...tableData];
      let arr = newData?.map((ele) => ele.id);
      let idx = arr.indexOf(val.id);
      newData[idx] = val;
      setTableData(newData);
      sessionStorage.setItem("tableData", JSON.stringify(newData));
      toast.success("Role updated successfully");
    } else {
      let data = tableData.filter(
        (e: any) => e.roleName === val.roleName && e.roleType === val.roleType
      );
      if (data.length === 0) {
        let id = tableData.length + 1;
        setTableData([...tableData, { ...val, id: id }]);
        sessionStorage.setItem(
          "tableData",
          JSON.stringify([...tableData, { ...val, id: id }])
        );
        toast.success("Role added successfully");
      } else {
        toast.error("Role already exist");
      }
    }
  };

  function handleMulti(val: any) {
    if (selectedMulti.length > val.length) {
      let present = false;
      const arr = val?.map((e: any) => e.label);
      const deleted = selectedMulti?.find(
        (item: any) => arr.indexOf(item.label) === -1
      );
      for (let i = 0; i < tableData.length; i++) {
        let data = tableData[i].privilliges.create?.find(
          (item: any) => item.subModule === deleted?.label
        );
        if (data?.subModule === deleted?.label) {
          present = true;
          break;
        }
      }
      if (present) {
        toast.error("Sub Module is in use. Please remove from roles");
      } else {
        setSelectedMulti(val);
      }
    } else {
      setSelectedMulti(val);
    }
    let newArr = val?.map((ele: any) => ele.label);
    formik.setFieldValue("subModuleName", newArr.join(", "));
  }

  function handleAcceptedFiles(files: any) {
    let fs = files[0]?.path.split(".")[1]?.toLowerCase();
    if (fs === "jpeg" || fs === "png" || fs === "jpg") {
      files?.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: file.size,
        })
      );

      let formData: any = new FormData();
      formData.append("file", files[0]);
      setLoading(true);
      api.post("fileUpload", formData).then((res) => {
        formik.setFieldValue("imageUrl", res?.url);
        setLoading(false);
      });
      setselectedFiles(files);
    } else {
      toast.error("Please upload .jpeg or .png or .jpg format file only");
    }
  }

  const handleModal = (val: any) => {
    setIsModal(val);
  };

  const deleteModalClose = () => {
    setDeleteRoleIdNumber(null);
    setIsDelete(false);
  };

  const deleteRoleId = (val: any) => {
    setDeleteRoleIdNumber(val?.id);
    setIsDelete(true);
  };

  const handleDeleteClick = () => {
    setIsDelete(false);
    let newData = tableData.filter(
      (ele: any) => ele?.id !== deleteRoleIdNumber
    );
    setTableData(newData);
    sessionStorage.setItem("tableData", JSON.stringify(newData));
    toast.success("Role deleted successfully");
  };

  const editRoleData = (val: any) => {
    setEditData(val);
  };

  useEffect(() => {
    if (editFormData) {
      formik.setFieldValue("firstName", editFormData.firstName);
      formik.setFieldValue("lastName", editFormData.lastName);
      formik.setFieldValue("email", editFormData.email);
      formik.setFieldValue("contactNumber", editFormData.contactNumber);
      formik.setFieldValue("subModuleName", editFormData.subModuleName);
      formik.setFieldValue("imageUrl", editFormData.imageUrl);
      let newData = editFormData?.subModuleName.split(", ");
      setSelectedMulti(
        newData?.map((e: any) => {
          return { label: e, value: e };
        })
      );
      setTableData(editFormData?.roles);
      getEnums();
      setSelectedView("Edit User");
    } else {
      setSelectedView("Add User");
      setTableData([]);
      getEnums();
    }
  }, []);

  const clearForm = () => {
    navigate("/users");
  };

  return (
    <div className="mt-3">
      <Loader loader={loading} />
      <ToastContainer />
      <BreadCrumb
        pageTitle={"User Management"}
        title={selectedView}
        pageLink={"/users"}
      />
      <Card className="create-user-card">
        <CardBody style={{ height: "76vh", overflowY: "auto" }}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            <Row className="mb-3">
              <Col lg={4}>
                <div className="mb-2">
                  <Label htmlFor="firstName" className="form-label fs-16">
                    First Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName || ""}
                    invalid={
                      formik.touched.firstName && formik.errors.firstName
                        ? true
                        : false
                    }
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <FormFeedback type="invalid">
                      {formik.errors.firstName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={4}>
                <div className="mb-2">
                  <Label htmlFor="lastName" className="form-label fs-16">
                    Last Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={Capitalize(formik.values.lastName) || ""}
                    invalid={
                      formik.touched.lastName && formik.errors.lastName
                        ? true
                        : false
                    }
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <FormFeedback type="invalid">
                      {formik.errors.lastName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={4}>
                <div className="mb-2">
                  <Label htmlFor="contactNumber" className="form-label fs-16">
                    Contact Number
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="contactNumber"
                    name="contactNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contactNumber || ""}
                    invalid={
                      formik.touched.contactNumber &&
                      formik.errors.contactNumber
                        ? true
                        : false
                    }
                  />
                  {formik.touched.contactNumber &&
                  formik.errors.contactNumber ? (
                    <FormFeedback type="invalid">
                      {formik.errors.contactNumber}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col lg={4}>
                <div className="mb-2">
                  <Label htmlFor="email" className="form-label fs-16">
                    Email ID
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email || ""}
                    invalid={
                      formik.touched.email && formik.errors.email ? true : false
                    }
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <FormFeedback type="invalid">
                      {formik.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={4}>
                <div className="mb-2">
                  <Label htmlFor="subModuleName" className="form-label fs-16">
                    Sub Module
                  </Label>
                  <Select
                    value={selectedMulti}
                    isMulti={true}
                    onChange={(val) => {
                      handleMulti(val);
                    }}
                    options={options}
                    closeMenuOnSelect={false}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="mb-2">
                  <Label htmlFor="profilePic" className="form-label fs-16">
                    Upload Profile Photo
                  </Label>
                  {formik.values.imageUrl === "" && (
                    <Dropzone
                      onDrop={(acceptedFiles: any) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                      // accept={fileType}
                    >
                      {({ getRootProps, getInputProps }: any) => (
                        <div className="dropzone dz-clickable form-control p-0">
                          <div className="needsclick" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="mx-4 d-flex align-items-center">
                              <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />
                              <span className="text-muted">
                                Upload Profile Photo
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  {formik.values.imageUrl !== "" && (
                    <Card>
                      <div className="d-flex">
                        <img
                          data-dz-thumbnail=""
                          height="70"
                          className="avatar-sm rounded bg-light"
                          alt={"image"}
                          src={formik.values.imageUrl}
                        />
                        <span className="p-3">Uploaded Image</span>
                        <i
                          className="ri-delete-bin-6-line fs-3  pointer red p-2"
                          onClick={() => formik.setFieldValue("imageUrl", "")}
                        ></i>
                      </div>
                    </Card>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="d-flex justify-content-end">
                <Button
                  color="primary"
                  onClick={() => setIsModal(true)}
                  disabled={
                    editFormData === null
                      ? !(formik.isValid && formik.dirty)
                      : !formik.dirty
                  }
                >
                  <span className="me-2">+</span>Add Role
                </Button>
              </Col>
            </Row>
          </Form>

          {tableData?.length > 0 && (
            <>
              <Card className="priv-table">
                <PrivilegeTable
                  data={tableData}
                  handleDeleteClickId={deleteRoleId}
                  handleOpenModal={handleModal}
                  editRoleData={editRoleData}
                />
              </Card>
            </>
          )}
        </CardBody>

        <CardFooter
          className="d-flex justify-content-end border-0 align-items-center"
          style={{
            backgroundColor: "transparent",
            height: "6vh",
            marginBottom: "1vh",
          }}
        >
          <Button
            color="success"
            outline
            className="me-3 wd-10"
            onClick={() => clearForm()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="wd-10"
            onClick={() => formik.handleSubmit()}
          >
            {editFormData === null ? "Save" : "Update"}
          </Button>
        </CardFooter>
      </Card>

      {isModal && (
        <AddRole
          isModal={isModal}
          handleModalClose={handleModal}
          selectedSubModule={selectedMulti}
          // dataRole={data}
          editData={editData}
          editRoleData={editRoleData}
          handletableData={handletableData}
        />
      )}

      <DeleteModal
        show={isDelete}
        onDeleteClick={handleDeleteClick}
        onCloseClick={deleteModalClose}
      />
    </div>
  );
};

export default AddUser;
