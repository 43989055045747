import * as api from "./api";

const get = (url: string) => {
  return api
    .getReq(url, {})
    .then((data) => {
      if (data && data.data) return data.data;
      else if (data === 401) return data;
      else return data;
    })
    .catch((err) => {
      return err;
    });
};

const put = (url: string, params: any) => {
  return api
    .putReq(url, params)
    .then((data) => {
      if (data && data.data) return data.data;
      else if (data === 401) return data;
      else return data;
    })
    .catch((err) => {
      return err;
    });
};

const deleteApi = (url: string) => {
  return api
    .deleteReq(url)
    .then((data) => {
      if (data && data.data) return data.data;
      else if (data === 401) return data;
      else return data;
    })
    .catch((err) => {
      return err;
    });
};

const deleteData = (url: string, params: any) => {
  return api
    .deleteReq(url, params)
    .then((data) => {
      if (data && data.data) return data.data;
      else if (data === 401) return data;
      else return data;
    })
    .catch((err) => {
      return err;
    });
};

const imgUpload = (url: string, params: any, header: any) => {
  return api
    .putReq(url, params, header)
    .then((data) => {
      if (data && data.data) return data.data;
      else if (data === 401) return data;
    })
    .catch((err) => {
      return err;
    });
};

const post = (url: string, params: any) => {
  return api
    .postReq(url, params)
    .then((data) => {
      if (data && data.data) return data.data;
      else if (data === 401) return data;
      else return data;
    })
    .catch((err) => {
      return err;
    });
};

const getBlob = (url: string, params: any) => {
  return api
    .download(url, params)
    .then((data) => {
      if (data) return data;
    })
    .catch((err) => {
      return err;
    });
};

const getBlobGet = (url: string) => {
  return api
    .downloadGet(url)
    .then((data) => {
      if (data) return data;
    })
    .catch((err) => {
      return err;
    });
};

const login = (userName: string, password: string, oldPassword: string) => {
  var url = "login/update"; //signin
  var headers = makeHeaders(userName, password, oldPassword);
  return api
    .loginReq(url, {}, headers)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err;
    });
};

const makeHeaders = (
  userName: string,
  password: string,
  oldPassword: string
) => {
  if (oldPassword && oldPassword !== "") {
    return {
      Authorization:
        "Basic " + window.btoa(userName + ":" + oldPassword + ":" + password),
    };
  } else {
    return {
      Authorization: "Basic " + window.btoa(userName + ":" + password),
    };
  }
};

export {
  get,
  put,
  post,
  getBlob,
  getBlobGet,
  imgUpload,
  login,
  deleteApi,
  deleteData,
};
