import React, { useState } from "react";
import ClientHeader from "../Dashboard/ClientHeader";
import { Button, Col, Row } from "reactstrap";
import homeicon from "../../assets/svg-new/home-icon.svg";
import VideoPlayerNew from "../../components/Common/VideoPlayer/ReactPlayer2";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import { addScreenshotRecords } from "../../api/dashboard.api";
import { toast } from "react-toastify";

const TxVideo = () => {
  const location = useLocation().state;
  let records = location?.records;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);

  const removeScreenshot = (e) => {
    let remove = [...bookmarks];
    let newData = remove.filter((ele) => ele.time !== e.time);
    setBookmarks(newData);
  };

  const handleBack = () => {
    let obj = {
      ...records,
      tower: location.tower,
    };
    navigate("/client-tx-component", { state: obj });
  };

  const handleDisable = () => {
    if (bookmarks.length === 0) {
      return true;
    } else {
      let chk = bookmarks.filter((e) => e.time === null);
      if (chk.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleSave = async () => {
    setLoading(true);
    let id = location.tower?.id;
    let obj = { screenshotData: bookmarks, id: id, type: "client_screenshot" };
    let res = await addScreenshotRecords(obj);
    console.log(res);
    if (res?.status === "Success") {
      setLoading(false);
      toast.success("Screenshot uploaded successfully");
      handleBack();
    } else {
      toast.error(res?.errormessage[0]?.message);
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <ClientHeader />
      <div className="content">
        {loading && (
          <div className="loading">
            <Loader />
          </div>
        )}
        <Row className="mb-3 mx-3">
          <Col className="fw-500">Tower Videos</Col>
          <Col className="text-end">
            <div className="d-flex align-items-center justify-content-end">
              <img
                src={homeicon}
                onClick={() => navigate("/client-dashboard")}
                className="me-1 pb-1 pointer"
              />
              <i class="bx bx-chevron-right fs-4"></i>
              <span
                className="text-low pointer"
                onClick={() =>
                  navigate("/client-project-dashboard", {
                    state: records.data.project,
                  })
                }
              >
                {records?.data?.project?.projectName}
              </span>
              <i class="bx bx-chevron-right fs-4"></i>
              <span
                className="text-low pointer"
                onClick={() =>
                  navigate("/client-tx-towers", { state: records.data })
                }
              >
                {records?.tower?.corridor?.corridorName}
              </span>
              <i class="bx bx-chevron-right fs-4"></i>
              <span className="text-low pointer" onClick={() => handleBack()}>
                {location?.tower?.towerNo} {location?.tower?.towerType}
              </span>
              <i class="bx bx-chevron-right fs-4"></i>
              <span>{location?.data?.fileName}</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <VideoPlayerNew
              data={location?.data}
              setBookmarks={setBookmarks}
              bookmarks={bookmarks}
            />
          </Col>
          <Col>
            <div className="text-scrrenshot">
              Screenshot ({bookmarks.length})
            </div>
            <Row className="row-height-book p-2">
              {bookmarks?.map((e) => (
                <Col key={e?.time} md={4}>
                  <div className="book-img">
                    <div className="book-x" onClick={() => removeScreenshot(e)}>
                      <i className="bx bx-trash fs-5"></i>
                    </div>

                    <img src={e.base64} />
                  </div>
                </Col>
              ))}
            </Row>
            <Row className="mb-3">
              <Col className="text-end">
                <Button
                  className="w-sm me-3"
                  outline
                  color="primary"
                  onClick={() => handleBack()}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-primary w-sm me-3"
                  disabled={handleDisable()}
                  onClick={() => handleSave()}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default TxVideo;
