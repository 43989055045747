import { Reducer, combineReducers, AnyAction } from "@reduxjs/toolkit";
import { applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import compareReducer from "./comparereducer";
import dashboardReducer from "./dashboardreducer";
import App from "./dataStore";
import landsurveyreducer from "./landsurveyreducer";
import miningReducer from "./miningstore";
import transmissionLinesReducer from "./transmissionLinesStore";
import projectreducer from "./projectreducer";
import surveyReducer from "./surveyReducer";
import projectsArrayReducer from "./projectsArrayReducer";
import demoReducer from "./demoStoreReducer";
import userReducer from "./reducer";
import layerreducer, { layersList } from "./layerreducer";
import projreducer from "./projectsReducer";
import pageDateReducer from "./PageData";
import adminsidebarReducer from "./adminsidebar";
const { createLogger } = require(`redux-logger`);

const reducers = combineReducers({
  user: userReducer,
  compare: compareReducer,
  projectdata: dashboardReducer,
  mining: miningReducer,
  landSurvey: landsurveyreducer,
  transmissionLines: transmissionLinesReducer,
  project: projectreducer,
  projectsArr: projectsArrayReducer,
  survey: surveyReducer,
  layers: layerreducer,
  proj: projreducer,
  pageData: pageDateReducer,
  demo: demoReducer,
  App: App,
  adminsidebar: adminsidebarReducer,
});
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "counter/logout") {
    state = {} as RootState;
  }
  return reducers(state, action);
};

const persistConfig = { key: "root", storage };
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware: any = [];
if (process.env.NODE_ENV !== "production") {
  //   middleware.push(createLogger());
}

let store = createStore(persistedReducer, applyMiddleware(...middleware));

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
