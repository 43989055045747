import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getDateOnly } from "../Components/Utils";
import MapTile from "./MapTile";
import { useNavigate } from "react-router-dom";

const ProjectCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Card
      className="br-0 pointer"
      onClick={() => {
        navigate("/surveys", { state: data });
        sessionStorage?.setItem("surveyProject", JSON.stringify(data));
        sessionStorage.removeItem("surveyToShow");
      }}
    >
      <CardHeader className="p-0 mb-0 bb-0">
        <MapTile coordinate={[78.621857, 17.209671]} />
        <div className="client-address">{data?.projectName}</div>
      </CardHeader>
      <CardBody className="py-3 px-5">
        <Row>
          <Col>
            <div className="color-grayl fs-16">No. Of Surveys</div>
            <div className="fs-20">{data?.totalSurvey}</div>
          </Col>
          <Col>
            <div className="color-grayl fs-16">Locations</div>
            <div className="fs-20">{data.location}</div>
          </Col>
          <Col>
            <div className="color-grayl fs-16">Date</div>
            <div className="fs-20">{getDateOnly(data?.createdAt)}</div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ProjectCard;
