import React, { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import excel from "../../assets/excel.png";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import zipImage from "../../assets/zip.png";
import pdf from "../../assets/pdf.png";

export const FileUploadDemo = (props: any) => {
  const [totalSize, setTotalSize] = useState(0);
  const toast = useRef(null);
  const fileUploadRef = useRef(null);
  let itemImage =
    props.icon === "excel"
      ? excel
      : props.icon === "zip"
      ? zipImage
      : props.icon === "pdf"
      ? pdf
      : "";

  const onTemplateSelect = (e: any) => {
    props.onChaneImage(e.files[0]);

    // let _totalSize = totalSize;
    //_totalSize += e.files[0].size;

    // e.files.forEach((file: any) => {
    //   _totalSize += file.size;
    // });

    // setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e: any) => {
    let _totalSize = 0;
    // _totalSize += e.files[0].size || 0;
    // e.files.forEach((file: any) => {
    //   _totalSize += file.size || 0;
    // });

    setTotalSize(_totalSize);
    //toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
  };

  const onTemplateRemove = (file: any, callback: any) => {
    setTotalSize(totalSize - file.size);
    if (props.handleDelete) {
      props.handleFileDelete();
    }
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <span className={props.disabled ? "disabled" : ""}>{chooseButton}</span>
        {props.showSample && (
          <span
            className="p-button p-fileupload-choose p-component p-button-icon-only custom-choose-btn p-button-rounded p-button-outlined"
            onClick={props.handleDownload}
          >
            <i className="pi pi-download br50 border pointer" />
          </span>
        )}
        {/* {uploadButton} */}
        {/* {cancelButton} */}
      </div>
    );
  };

  const itemTemplate = (file: any, props: any) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img
            alt={file.name}
            role="presentation"
            src={itemImage === "" ? file.objectURL : itemImage}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        {props.accept === "img/*" && (
          <Tag
            value={props.formatSize}
            severity="warning"
            className="px-3 py-2"
          />
        )}
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  return (
    <div>
      <Toast ref={toast}></Toast>

      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      {/* <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" /> */}
      <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

      <FileUpload
        ref={fileUploadRef}
        name="demo[]"
        url=""
        accept={props.accept}
        maxFileSize={props.maxFileSize ? props.maxFileSize : 1000000}
        onUpload={onTemplateUpload}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        chooseOptions={chooseOptions}
        // uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
      />
    </div>
  );
};
