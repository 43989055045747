import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const justifyOptions = () => {
  return [
    {
      name: "Dashboard",
      icon: "custom-target-icon pi pi-map",
      value: "dashboard",
    },
    {
      name: "3D View",
      icon: "custom-target-icon pi pi-box",
      value: "3dView",
    },
    {
      name: "Temporal view",
      icon: "custom-target-icon pi pi-sort",
      value: "compare",
    },
  ];
};

const getMode = (url: any) => {
  let urlArr = url.split("/").splice(1);
  let mode = "";
  if (urlArr.includes("view")) mode = "view";
  if (urlArr.includes("edit")) mode = "edit";
  if (urlArr.includes("create")) mode = "create";
  if (
    urlArr.includes("surveys") ||
    urlArr.includes("deliverables") ||
    urlArr.includes("surveydetails")
  )
    mode = "edit";
  let id = "";

  if (mode === "view" || mode === "edit") id = urlArr[urlArr.length - 1];
  return { mode, id };
};

const getMode1 = (url: any) => {
  let urlArr = url.split("/").splice(1);
  let mode = "";
  if (urlArr.includes("view")) mode = "view";
  if (urlArr.includes("edit")) mode = "edit";
  if (urlArr.includes("create")) mode = "create";

  let id = urlArr[urlArr.length - 1];
  return { mode, id };
};

const GetMonths = () => {
  return [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
};
const sortBy = (arr: any, key: string) => {
  return arr.sort((a: any, b: any) =>
    a[key] < b[key] ? -1 : b[key] < a[key] ? 1 : 0
  );
};

const ProjectCategory = [
  { ind: 1, name: "Mining" },
  { ind: 2, name: "Land Survey" },
];

const Measurements = (title: string) => {
  let obj = {} as any;
  obj.title = title;
  obj.key = "measurements";
  obj.measurements = [
    { name: "Length", key: "A" },
    { name: "Area", key: "M" },
    { name: "Slope", key: "P" },
    { name: "Volume", key: "R" },
    { name: "Path Profiles", key: "S" },
  ];
  return obj;
};

const VolumesArr = [
  {
    name: "",
    value:
      new Date().getDate() +
      "-" +
      new Date().getMonth() +
      "-" +
      new Date().getFullYear(),
  },
  { name: "Area", value: { num: 0, power: 2 } },
  { name: "Cut", value: { num: 0, power: 3 } },
  { name: "Fill", value: { num: 0, power: 3 } },
  { name: "Net Volume", value: { num: 0, power: 3 } },
];

const Downloads = () => {
  let obj = {} as any;
  obj.title = "Downloads";
  obj.downloads = [
    { name: "Ortho Map" },
    { name: "Boundary" },
    { name: "DTM (TIFF file)" },
    { name: "DSM (TIFF file)" },
    { name: "Contours (DWG file)" },
    { name: "Topography on Ortho(PDF)" },
  ];
  return obj;
};

const SideMenuSetData = (data: any) => {
  let arr = [];
  for (const [key, value] of Object.entries(data)) {
    switch (key) {
      case "layers":
        arr.push(arrangeSideMenuData("Layers", "eye", key, value));
        break;
      case "reports":
        arr.push(arrangeSideMenuData("Reports", "checkbox", key, value));
        break;
    }
  }
  return arr;
};

const arrangeSideMenuData = (
  title: string,
  view: string,
  type: string,
  data: any
) => {
  return {
    title: title,
    view: view,
    [type]: data,
  };
};

const uniqBy = (arr: any, predicate: any) => {
  const cb =
    typeof predicate === "function" ? predicate : (o: any) => o[predicate];
  return [
    ...arr
      .reduce((map: any, item: any) => {
        const key = item === null || item === undefined ? item : cb(item);
        map.has(key) || map.set(key, item);
        return map;
      }, new Map())
      .values(),
  ];
};

const classNameChangeFunction = (id: any, oldClass: any, newClass: any) => {
  var strengthValidation = document.getElementById(id);
  strengthValidation?.classList.replace(oldClass, newClass);
};

const addToast = (summary: any, severity: string) => {
  const className =
    severity && severity === "success"
      ? "bg-success text-white"
      : "bg-danger text-white";
  toast(summary, {
    position: "top-right",
    hideProgressBar: true,
    closeOnClick: false,
    className: className,
    delay: 3000,
  });
};

export {
  justifyOptions,
  getMode,
  getMode1,
  GetMonths,
  sortBy,
  classNameChangeFunction,
  ProjectCategory,
  Measurements,
  VolumesArr,
  Downloads,
  SideMenuSetData,
  uniqBy,
  addToast,
};
