export const messages = {
  EMAIL: "Please enter valid email address",
  MOBILE: "Please enter valid mobile number",
  ROLE_NAME: "Please enter valid role name",
  ROLE_CREATE: "New Role added successfully",
  ROLE_UPDATE: "Role updated successfully",
  CLIENT_CREATE: "Client created successfully",
  CLIENT_UPDATE: "Client updated successfully",
  CREATE_DRONE: "Drone added successfully",
  UPDATE_DRONE: "Drone updated successfully",
  CREATE_USER: "User created successfully",
  UPDATE_USER: "User updated successfully",
  PROJECT_CREATE: "Project created successfully",
  PROJECT_UPDATE: "Project updated successfully",
  NEW_PASSWORD:
    "Password should contain a small letter, capital letter, number and a symbol with minimum of 8 characters",
  CONFIRM_PASSOWRD: "Passwords did not match",
  INCORRECT_FORMAT: "Incorrect format",
  PDF_LINK: "Invalid URL",
};
export const adminsArr = [
  { id: "chetan", name: "Chetan", role: "Admin", name_role: "Chetan-Admin" },
  {
    id: "yaswanth",
    name: "yaswanth",
    role: "admin",
    name_role: "yaswanth-admin",
  },
];
export const frequenciesArr = [
  { value: 1, frequency: "One time" },
  { value: 2, frequency: "Monthly" },
  { value: 3, frequency: "Weekly" },
  { value: 4, frequency: "Bi-weekly" },
  { value: 5, frequency: "Custom" },
];
export const droneTypesArr = [
  { id: "3D", type: "3D" },
  { id: "2D", type: "2D" },
];
export const headersArr = {
  BaseMap: "Edit Base Style",
  Source: "Edit Source",
  Layer: "Edit Layer",
  Properties: "Edit Source Attribute",
  Point: "Edit Point",
  Line: "Edit Line",
  Polygon: "Edit Polygon",
  Report: "Edit Report",
  Download: "Edit Download",
  Marker: "Edit Marker",
  Symbol: "Edit Symbol",
};
export const fileTypeArr = [
  { label: "ECW", value: ".ecw" },
  { label: "TIFF", value: ".tiff" },
  { label: "PDF", value: ".pdf" },
  { label: "KML", value: ".kml" },
  { label: "JSON", value: ".json" },
  { label: "SHP", value: ".shp" },
  { label: "ZIP", value: ".zip" },
];
export const mapSources = [
  { mapSource: "Street View", value: "STREET_VIEW" },
  { mapSource: "Satellite View", value: "SATELLITE_VIEW" },
];

export const modules = [
  { name: "Drone Master", type: "DRONE_MASTER" },
  { name: "Roles", type: "ROLES" },
  { name: "User", type: "USER" },
  { name: "Client", type: "CLIENT" },
  { name: "Project", type: "PROJECT" },
];

export const statusOfDrones = [
  { name: "In Use" },
  { name: "Available" },
  { name: "Under Maintenance" },
];

export const dataTypesArr = [
  { datatype: "Raster", value: "raster" },
  { datatype: "Vector", value: "vector" },
];

export const errorMessages = {
  NO_PROJECTS: "No Projects created for this user.",
  NO_CORRIDORS: "No corridors created for this Project.",
};
export const successMsg = {
  BASEMAP_ADD: "Base Map added successfully",
  BASEMAP_EDIT: "Base Map updated successfully",
  BASEMAP_DELETE: "Base Map deleted successfully",
  SOURCE_ADD: "Source added successfully",
  SOURCE_EDIT: "Source updated successfully",
  SOURCE_DELETE: "Source deleted successfully",
  LAYER_ADD: "Layer added successfully",
  LAYER_EDIT: "Layer updated successfully",
  LAYER_DELETE: "Layer deleted successfully",
  PROPERTIES_ADD: "Source Attribute added successfully",
  PROPERTIES_EDIT: "Source Attribute updated successfully",
  PROPERTIES_DELETE: "Source Attribute deleted successfully",
  POINT_ADD: "Point added successfully",
  POINT_EDIT: "Point updated successfully",
  POINT_DELETE: "Point deleted successfully",
  LINE_ADD: "Line added successfully",
  LINE_EDIT: "Line updated successfully",
  LINE_DELETE: "Line String deleted successfully",
  SYMBOL_ADD: "Symbol added successfully",
  SYMBOL_EDIT: "Symbol updated successfully",
  SYMBOL_DELETE: "Symbol deleted successfully",
  POLYGON_ADD: "Polygon added successfully",
  POLYGON_EDIT: "Polygon updated successfully",
  POLYGON_DELETE: "Polygon deleted successfully",
  REPORT_ADD: "Report added successfully",
  REPORT_EDIT: "Report updated succssfully",
  REPORT_DELETE: "Report deleted successfully",
  DOWNLOAD_ADD: "Download added successfully",
  DOWNLOAD_EDIT: "Download updated successfully",
  DOWNLOAD_DELETE: "Download deleted successfully",
  FILE_DELETE: "File deleted successfully",
  FILE_UPLOAD: "File uploaded successfully",
  MARKER_ADD: "Marker added successfully",
  MARKER_EDIT: "Marker updated successfully",
  MARKER_DELETE: "Marker deleted successfully",
  USER_DELETE: "User deleted successfully",
  DRONE_DELETE: "Drone deleted successfully",
  DRONE_EDIT: "Drone updated successfully",
  CLIENT_DELETE: "Client deleted successfully",
  SURVEY_ADD: "Survey added successfully",
  SURVEY_EDIT: "Survey updated successfully",
  SURVEY_DELETE: "Survey deleted successfully",
  PILOT_UPDATE: "Status updated successfully",
  ACCEPT: "Files accepted",
  DRONE_ACTIVE: "Drone made active",
  DRONE_INACTIVE: "Drone made inactive",
  REJECT: "Files rejected",
  DELETE_CSV: "Measurements CSV deleted successfully",
  UPLOAD_CSV: "Measurements CSV uploaded successfully",
  UPLOAD_ZIP: "Property Images ZIP uploaded successfully",
  DELETE_ZIP: "Property Images ZIP deleted successfully",
};

export const errorMsg = {
  PROJECT_GET: "Unable to get Projects",
  PROJECT_FIND: "Unable to find Project",
  PROJECT_ADD: "Unable to add Project",
  PROJECT_UPDATE: "Unable to update Project",
  PROJECT_DELETE: "Unable to delete Project",
  CLIENT_GET: "Unable to get Clients",
  CLIENT_FIND: "Unable to find Client",
  CLIENT_ADD: "Unable to add Client",
  CLIENT_UPDATE: "Unable to update Client",
  CLIENT_DELETE: "Unable to delete Client",
  DRONE_GET: "Unable to get Drones",
  DRONE_FIND: "Unable to find Drone",
  DRONE_ADD: "Unable to add Drone",
  DRONE_UPDATE: "Unable to update Drone",
  DRONE_DELETE: "Unable to delete Drone",
  SURVEY_GET: "Unable to get Surveys",
  SURVEY_ADD: "Unable to add Survey",
  SURVEY_UPDATE: "Unable to update Survey",
  SURVEY_DELETE: "Unable to delete Survey",
  ROLE_GET: "Unable to get Roles",
  ROLE_FIND: "Unable to find Role",
  ROLE_ADD: "Unable to add Role",
  ROLE_UPDATE: "Unable to update Role",
  ROLE_DELETE: "Unable to delete Role",
  USER_GET: "Unable to get Users",
  USER_FIND: "Unable to find User",
  USER_ADD: "Unable to add User",
  USER_UPDATE: "Unable to update User",
  USER_DELETE: "Unable to delete User",
  BASEMAP_GET: "Unable to get BaseMaps",
  BASEMAP_ADD: "Unable to add Base Map",
  BASEMAP_EDIT: "Unable to edit Base Map",
  BASEMAP_DELETE: "Unable to delete Base Map ",
  SOURCES_GET: "Unable to get Sources",
  SOURCE_ADD: "Unable to add Source",
  SOURCE_EDIT: "Unable to edit Source",
  SOURCE_DELETE: "Unable to delete source ",
  LAYER_GET: "Unable to get Layers",
  LAYER_ADD: "Unable to add Layer",
  LAYER_EDIT: "Unable to edit Layer",
  LAYER_DELETE: "Unable to delete Layer ",
  PROPERTIES_GET: "Unable to get Source Attributes",
  PROPERTIES_ADD: "Unable to add Source Attribute",
  PROPERTIES_EDIT: "Unable to edit Source Attribute",
  PROPERTIES_DELETE: "Unable to delete Source Attribute",
  POINT_GET: "Unable to get Points",
  POINT_ADD: "Unable to add Point",
  POINT_EDIT: "Unable to edit Point",
  POINT_DELETE: "Unable to delete Point ",
  LINE_GET: "Unable to get LineStrings",
  LINE_ADD: "Unable to add Line",
  LINE_EDIT: "Unable to edit Line",
  LINE_DELETE: "Unable to delete Line String ",
  SYMBOL_GET: "Unable to get Symbols",
  SYMBOL_ADD: "Unable to add Symbol",
  SYMBOL_EDIT: "Unable to edit Symbol",
  SYMBOL_DELETE: "Unable to delete Symbol",
  POLYGON_GET: "Unable to get Polygons",
  POLYGON_ADD: "Unable to add Polygon",
  POLYGON_EDIT: "Unable to edit Polygon",
  POLYGON_DELETE: "Unable to delete Polygon ",
  DOWNLOAD_GET: "Unable to get Download",
  DOWNLOAD_ADD: "Unable to add Download",
  DOWNLOAD_EDIT: "Unable to edit Download",
  DOWNLOAD_DELETE: "Unable to delete Download ",
  REPORT_GET: "Unable to get Reports",
  REPORT_ADD: "Unable to add Report",
  REPORT_EDIT: "Unable to edit Report",
  REPORT_DELETE: "Unable to delete Report ",
  MARKER_GET: "Unable to get Markers",
  MARKER_ADD: "Unable to add Marker",
  MARKER_EDIT: "Unable to edit Marker",
  MARKER_DELETE: "Unable to delete Marker ",
  CATEGORY_GET: "Unable to get Categorys",
  PILOT_COMMENT: "Unable to upload stauts",
  PILOT_UPLOAD: "Unable to add file",
  PILOT_UPDATE: "Unable to update data",
  REJECT_FAIL: "Unable to reject",
  ACCEPT: "Unable to accept",
  FILE_DELETE: "Unable to delete file",
  DRONE_ACTIVE_FAILED: "Unable to make drone as active",
  DRONE_INACTIVE_FAILED: "Unable to make drone as inactive",
  DOWNLOAD_FILE: "Unable to download file",
  SAME_MAP_TYPE: "Same map type cannot be selected",
  UPLOAD_CSV: "Unable to upload measurement CSV",
  UPLOAD_ZIP: "Unable to upload Property Images ZIP",
};
export const statusArr = [
  { ind: 1, status: "Partially Captured" },
  { ind: 2, status: "Captured" },
  { ind: 3, status: "Data Submitted" },
];
