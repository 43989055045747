import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import { InputSwitch } from "primereact/inputswitch";

const SourceTable = ({
  data,
  handleEdit,
  setOpen,
  handleDelete,
  setMode,
  onActivate,
}) => {
  const navigate = useNavigate();
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Line Name</span>,
      selector: (row) => row.lineName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Tower Number</span>,
      sortable: true,
      selector: (row) => row.towerNo,
    },
    {
      name: <span className="font-weight-bold fs-13">Tower Category</span>,
      selector: (row) => row.towerCategory,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Tower Type</span>,
      selector: (row) => row.towerType,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Voltage</span>,
      selector: (row) => row.voltage,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Structure</span>,
      selector: (row) => row.structure,
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-13">Location (lat, long)</span>
      ),
      selector: (row) => {
        let lat = row?.location?.split(",")[0];
        let long = row?.location?.split(",")[1];
        return (
          <span>
            {lat} <span className="fs-18">, </span> {long}
          </span>
        );
      },
      sortable: true,
      grow: 2,
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Phase</span>,
    //   selector: (row) => row.phase,
    //   sortable: true,
    // },
    {
      name: <span className="font-weight-bold fs-13">Circuit</span>,
      selector: (row) => row.circuit,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Span</span>,
      selector: (row) => row.span,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Active</span>,
      cell: (row) => {
        return (
          <InputSwitch
            onChange={() => onActivate(row)}
            checked={row.isActive}
            disabled={
              row?.towerStatus !== "Completed" &&
              row?.towerStatus !== "Save As Draft"
            }
          />
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Actions</span>,
      sortable: true,

      cell: (row) => {
        return (
          <span>
            <Tooltip target=".custom-target-icon-delete" />
            <Tooltip target=".custom-target-icon-edit" />
            <i
              className="bx bx-edit fs-4 me-2 pointer custom-target-icon-edit"
              style={{ color: "green" }}
              data-pr-tooltip="Edit"
              data-pr-position="top"
              onClick={() => {
                setOpen(true);
                setMode("Edit Tower");
                handleEdit(row);
              }}
            ></i>
            <i
              className="ri-delete-bin-6-line fs-4 me-2 pointer custom-target-icon-delete"
              style={{ color: "red" }}
              data-pr-tooltip="Delete"
              data-pr-position="top"
              onClick={() => {
                handleDelete(row);
              }}
            ></i>
          </span>
        );
      },
    },
  ];
  return <DataTable columns={columns} data={data}/>;
};

export default SourceTable;
