import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";

const PaginationComponent = ({ page, limit, total, onPageChange }) => {
  const [max, setMax] = useState(0);
  const [pageNo, setPageNo] = useState(page + 1);

  useEffect(() => {
    setMax(Math.ceil(total / limit));
    setPageNo(page + 1);
  }, [total, limit]);

  const handlePageChange = (val) => {
    if (val === "prev") {
      setPageNo(pageNo - 1);
      onPageChange(pageNo - 2);
    } else if (val === "next") {
      setPageNo(pageNo + 1);
      onPageChange(pageNo);
    } else {
      setPageNo(val);
      onPageChange(val - 1);
    }
  };
  return (
    <div className="new-pagination">
      <Button
        color="success"
        onClick={() => handlePageChange("prev")}
        disabled={pageNo === 1}
        className={`${pageNo === 1 ? "dis-page" : "act-page"} prev-pages`}
      >
        Previous
      </Button>
      <Input
        type="number"
        value={pageNo || ""}
        defaultValue={1}
        min={1}
        max={max}
        onChange={(e) => {
          if (e.target.value < max + 1 && e.target.value > -1)
            handlePageChange(+e.target.value);
        }}
      />
      <span className="page-total"> of {max}</span>
      <Button
        color="success"
        className={`${pageNo === max ? "dis-page" : "act-page"} next-pages`}
        onClick={() => handlePageChange("next")}
        disabled={pageNo === max}
      >
        Next
      </Button>
    </div>
  );
};

export default PaginationComponent;
