const pilotAssetsSchema = [
  {
    id: 1,
    header: "Asset ID",
    field: "assetRef",
    sortable: true,
  },
  {
    id: 2,
    header: "Asset Type",
    field: "assetType",
    sortable: true,
  },
  {
    id: 3,
    header: "Asset Name",
    field: "assetName",
    sortable: true,
  },
  {
    id: 4,
    header: "Location",
    field: "address",
  },
  {
    id: 5,
    header: "Status",
    type: "status1",
    field: "assetStatus",
    grow: 1.5,
  },
  {
    id: 6,
    header: "Uploaded By",
    field: "uploadedBy",
  },
  // {
  //   id: 7,
  //   header: "Upload Date",
  //   field: "uploadedDate",
  //   type: "date",
  // },
  {
    id: 8,
    header: "Capture Date",
    field: "capturedDate",
    type: "date",
  },
  {
    id: 9,
    type: "projectActon",
    header: "Action",
    onclick: "onActionClick",
    grow: 1.5,
  },
];

const smeAssetsSchema = [
  {
    id: 1,
    header: "Asset ID",
    field: "assetRef",
    sortable: true,
  },
  {
    id: 2,
    header: "Asset Type",
    field: "assetType",
    sortable: true,
  },
  {
    id: 3,
    header: "Asset Name",
    field: "assetName",
    sortable: true,
  },
  {
    id: 4,
    header: "Location",
    field: "address",
  },
  {
    id: 5,
    header: "Status",
    type: "status1",
    field: "assetStatus",
    grow: 1.5,
  },
  {
    id: 6,
    header: "Uploaded By",
    field: "uploadedBy",
  },
  // {
  //   id: 7,
  //   header: "Upload Date",
  //   field: "uploadedDate",
  //   type: "date",
  // },
  {
    id: 8,
    header: "Capture Date",
    field: "capturedDate",
    type: "date",
  },
  {
    id: 9,
    type: "projectActon2",
    header: "Action",
    onclick: "onActionClick",
    grow: 1.5,
  },
];

export { pilotAssetsSchema, smeAssetsSchema };
