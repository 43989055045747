import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import VideoPlayer from "../../components/Common/VideoPlayer/ReactPlayer2";
import { createLogo } from "../Components/Utils";
import { addScreenshotRecords } from "../../api/dashboard.api";
import Loader from "../../components/Common/Loader";
import { toast } from "react-toastify";

const TxComponentVideo = () => {
  const location = useLocation().state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);

  const removeScreenshot = (e) => {
    let remove = [...bookmarks];
    let newData = remove.filter((ele) => ele.time !== e.time);
    setBookmarks(newData);
  };

  const handleSave = async () => {
    setLoading(true);
    let id = location?.location?.data?.id;
    let obj = { screenshotData: bookmarks, id: id, type: "screenshot" };
    let res = await addScreenshotRecords(obj);
    if (res?.status === "Success") {
      setLoading(false);
      toast.success("Screenshot uploaded successfully")
      navigate("/sme-tower-component", { state: location?.location });
    }
  };

  const handleDisable = () => {
    if (bookmarks.length === 0) {
      return true;
    } else {
      let chk = bookmarks.filter((e) => e.time === null);
      if (chk.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };


  return (
    <div className="content">
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <Row className="mb-3 mx-3">
        <Col>
          <div className="client-logo-sme">
            {location?.location?.corridor?.project?.client?.clientLogo ? (
              <img
                src={location?.location?.corridor?.project?.client?.clientLogo}
              />
            ) : (
              <span>
                {createLogo(
                  location?.location?.corridor?.project?.client?.clientName
                )}
              </span>
            )}
          </div>
          <div className="client-logo-sme-name">
            {location?.location?.corridor?.data?.corridorName}
          </div>
        </Col>
        <Col className="text-end me-2 mt-2">
          <span
            className="bread-last"
            onClick={() => navigate("/sme-dashboard")}
          >
            Dashboard
          </span>
          {" > "}
          <span
            className="bread-last "
            onClick={() =>
              navigate("/sme-corridor", {
                state: location?.location?.corridor?.project,
              })
            }
          >
            {location?.location?.corridor?.project?.projectName}
          </span>
          {" > "}
          <span
            className="bread-last"
            onClick={() =>
              navigate("/sme-tower", { state: location?.location?.corridor })
            }
          >
            {location?.location?.corridor?.data?.corridorName}
          </span>
          {" > "}
          <span className="fs-14">{location?.location?.data?.towerNo} {location?.location?.data?.towerType}</span>
        </Col>
      </Row>

      <Row>
        <Col>
          <VideoPlayer
            data={location?.data}
            setBookmarks={setBookmarks}
            bookmarks={bookmarks}
          />
        </Col>
        {location?.status !== "Completed" && (
          <Col>
            <div className="text-scrrenshot">
              Screenshot ({bookmarks.length})
            </div>
            <Row className="row-height-book p-2">
              {bookmarks?.map((e) => (
                <Col key={e?.time} md={4}>
                  <div className="book-img">
                    <div className="book-x" onClick={() => removeScreenshot(e)}>
                      <i className="bx bx-trash fs-5"></i>
                    </div>

                    <img src={e.base64} />
                  </div>
                </Col>
              ))}
            </Row>
            <Row className="mb-3">
              <Col className="text-end">
                <Button
                  className="w-sm me-3"
                  outline
                  color="primary"
                  onClick={() =>
                    navigate("/sme-tower-component", {
                      state: location?.location,
                    })
                  }
                >
                  Cancel
                </Button>
                <Button
                  className="bg-primary w-sm me-3"
                  disabled={handleDisable()}
                  onClick={() => handleSave()}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default TxComponentVideo;
