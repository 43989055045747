export const FilterConfig = {
  ACTIVE_INACTIVE: [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ],
  ROLE_TYPE: [
    { label: "Client Level", value: "Client Level" },
    { label: "Project Level", value: "Project Level" },
  ],
  DRONE_STATUS: [
    { label: "Available", value: "Available" },
    { label: "In use", value: "In use" },
    { label: "Under Maintainance", value: "Under Maintainance" },
    { label: "Unassigned", value: "Unassigned" },
    { label: "Assigned", value: "Assigned" },
  ],
  PROCESS_STATUS: [
    {
      label: "Data Yet To Be Captured",
      value: "Data Yet To Be Captured",
    },
    {
      label: "Data Captured",
      value: "Data Captured",
    },
    {
      label: "Captured",
      value: "Captured",
    },
    {
      label: "Partially Captured",
      value: "Partially Captured",
    },
  ],
} as any;
