import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Prev } from "react-bootstrap/esm/PageItem";

const AddButton = (props: any) => {
  let navigate = useNavigate();

  const handleOnClick = () => {
    navigate(props.routerLink);
    sessionStorage.setItem(
      "prevSurveyRoute",
      JSON.stringify({ name: props.prevClient, link: props.prevLink })
    );
  };

  return (
    <div className="text-right">
      <Button
        style={{ backgroundColor: "#276e49", border: "#339966" }}
        className="btn-label"
        onClick={() => handleOnClick()}
      >
        {props.icon && <i className={props.icon} />}
        {props.label}
      </Button>
    </div>
  );
};

export default AddButton;
