import {
  BrowserRouter,
  HashRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import App from "../../App";
import { useAppSelector } from "../../store/hooks";
// import Login from "../login/login";
import LoginVelzon from "../login/LoginVelzon";
import ForgotPassword from "../login/ForgotPassword";
import ClientProjects from "../ClientProjects/ClientProjects";
import ForgotPasswordDetails from "../login/ForgotPasswordDetails";
import { useEffect } from "react";

const Layout = () => {
  const user = useAppSelector((state) => state.user);
  var condition = user.loginName && user.roles;
  let resetPassword = window.location?.search.includes("?resetpassword");
  useEffect(() => {
    localStorage.setItem("resetToken", window.location.search?.split("=")?.[1]);
    if (resetPassword) window.location.replace("/resetpassword");
  }, [window.location.search]);

  return condition ? (
    <App user={user} />
  ) : (
    <HashRouter>
      <Routes>
        <Route path="/login" element={<LoginVelzon />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/client-projects" element={<ClientProjects />} />
        <Route path="/resetpassword" element={<ForgotPasswordDetails />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </HashRouter>
  );
};

export default Layout;
