import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
} from "reactstrap";
import { calculateVolume } from "../../api/api";
import { addDrawData, generateXLS } from "../../api/map.api";
import { vectorData } from "../Components/Utils";
import Select from "react-select";

const VolumetricModal = (props) => {
  const { show, closeModal, modalheader } = props;
  const [formState, setFormState] = useState({ colorPicker: "#000" });
  const [volume, setVolume] = useState(null);
  const [isShowVol, setIsShowVol] = useState(false);
  const [calcOn, setCalcOn] = useState([]);
  const [selectedCalc, setSelectedCalc] = useState(null);
  const handleChange = (value, name) => {
    setFormState({ ...formState, [name]: value });
  };

  const handleDisable = () => {
    if (!formState.FeatureName || !formState.colorPicker) {
      return true;
    } else {
      return false;
    }
  };

  const handleSave = async () => {
    props?.setLoading(true);
    let workingOn = props?.raw?.find(
      (e) => e?.layerType === selectedCalc?.value
    );
    let obj = {
      polycord: props?.data?.coordinates[0],
      source_name: workingOn?.sourceName.split(":").pop(),
      workspace_name: workingOn?.sourceName.split(":").shift(),
      epsg: workingOn?.epsg,
    };
    let res = await calculateVolume("volume/coordinates", obj);
    if (res?.data?.status === "success") {
      let result = { res: res?.data?.resp, form: formState, data: props?.data };
      setVolume(res?.data?.resp);
      setIsShowVol(true);
      props?.handleVolumetric(result);
      props?.setLoading(false);
    } else {
      toast.error(res?.data?.Error);
      props?.setLoading(false);
    }
  };

  const saveLayer = async () => {
    props?.setLoading(true);
    let obj = {
      FeatureName: formState.FeatureName,
      Color: formState.colorPicker,
      Description: "",
      baseHeight: formState?.baseHeight,
      perimeter: props?.data?.perimeter,
      area: props?.data?.area,
      Coordinates: props?.data?.geojsonData,
      surveyId: props?.data?.surveyid,
    };
    let res = await addDrawData(obj);
    if (res?.status?.toLowerCase() === "success") {
      toast.success("Feature added succcessfully");
      let data = vectorData([res?.response]);
      data[1] = res?.response;
      props?.handleAddDraw(data);
      closeModal();
      props.setTool("none");
      props?.setAccessory("none");
      props?.closeDynamics();
      props?.setLoading(false);
    } else {
      toast.error("Error while adding");
      props?.setLoading(false);
    }
  };

  const exportXLS = async () => {
    let obj = {
      perimeter: props?.data?.perimeter,
      area: props?.data?.area,
      volume: volume + "m³",
    };
    let res = await generateXLS(obj);
    if (res?.url) {
      var a = document.createElement("a");
      a.href = res?.url;
      a.download = res?.url?.split("/")?.pop();
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      toast.error(res.errormessage);
    }
  };

  useEffect(() => {
    let opt = props?.raw?.filter(
      (e) => e?.layerType === "DSM" || e?.layerType === "DTM"
    );
    let calcOnOpt = opt?.map((e) => ({
      label: e?.layerType,
      value: e?.layerType,
    }));
    setCalcOn(calcOnOpt);
  }, [props?.raw]);

  return (
    <div className="d-flex align-item-end">
      <ToastContainer />
      <Modal
        isOpen={show}
        toggle={() => closeModal()}
        className={`bg-custom-white toolModal1 ${isShowVol ? "invisible" : ""}`}
        backdrop={false}
      >
        <ModalHeader
          className={`border-bottom p-2 bg-light`}
          toggle={() => closeModal()}
        >
          <span className="text-dark text-bold fs-15">{modalheader}</span>
        </ModalHeader>
        <ModalBody>
          <div className="mb-2">
            <Label className="fs-14">
              Feature Name <span className="required">*</span>
            </Label>
            <Input
              type="text"
              placeholder="Enter"
              size="sm"
              value={formState?.FeatureName}
              onChange={(e) => handleChange(e.target.value, "FeatureName")}
            ></Input>
          </div>
          {/* <div className="mb-2">
            <Label className="fs-14">Base Height</Label>
            <Input
              type="text"
              placeholder="Enter (in Meters)"
              size="sm"
              value={formState?.baseHeight}
              onChange={(e) => handleChange(e.target.value, "baseHeight")}
            ></Input>
          </div> */}
          <div className="mb-2">
            <Label className="fs-14">
              Select Color <span className="required">*</span>
            </Label>
            <Input
              type="color"
              className="w-2 p-0"
              size="sm"
              value={formState?.colorPicker}
              onChange={(e) => handleChange(e.target.value, "colorPicker")}
            ></Input>
          </div>
          <div className="mb-3">
            <Label className="text-muted">
              Calculate On <span className="required">*</span>
            </Label>
            <Select
              options={calcOn}
              value={selectedCalc}
              onChange={(val) => setSelectedCalc(val)}
              menuPlacement="top"
            ></Select>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex gap-2 justify-content-end mt-3 mb-2">
            <Button
              outline
              className="w-sm"
              color="primary"
              data-bs-dismiss="modal"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>

            <Button
              type="button"
              className="bg-primary w-sm"
              disabled={handleDisable()}
              onClick={() => handleSave()}
            >
              Calculate
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      {isShowVol && (
        <Modal
          isOpen={isShowVol}
          toggle={() => {
            setIsShowVol(false);
            saveLayer();
          }}
          className="bg-custom-white toolModal1"
          backdrop={false}
        >
          <ModalHeader
            className={`border-bottom p-3 bg-light`}
            toggle={() => {
              setIsShowVol(false);
              saveLayer();
            }}
          >
            <span className="text-dark text-bold fs-15">{modalheader}</span>
          </ModalHeader>
          <ModalBody>
            <Row className="mt-3">
              <Col>
                <div className="fs-14 text-muted">Total Volume</div>
                <div className="text-dark fw-500 fs-15">{volume} m³</div>
              </Col>
              <Col>
                <div className="fs-14 text-muted">Total Area</div>
                <div className="text-dark fw-500 fs-15">
                  {props?.data?.area}
                </div>
              </Col>
              <Col>
                <div className="fs-14 text-muted">Perimeter</div>
                <div className="text-dark fw-500 fs-15">
                  {props?.data?.perimeter}
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="text-center">
                <Button
                  className="bg-primary w-sm"
                  onClick={() => {
                    saveLayer();
                    exportXLS();
                  }}
                >
                  Export
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default VolumetricModal;
