import React, { useEffect, useState } from "react";
import SMEHeader from "./SMEHeader";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api/rest.api";
import { Card, Col, Row } from "reactstrap";
import Select from "react-select";
import ProjectCard from "../SMETx/ProjectCard";
import ProjectCardOil from "../SMEOil/ProjectCard";
import { createSelectOption } from "../Components/Utils";
import { proj } from "../../store/projectsReducer";
import Loader from "../../components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";

const SMEDashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [projectStatus, setProjectStatus] = useState({});
  const [options, setOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  const getProjects = async () => {
    setLoading(true);
    let response = await api.getData(
      `projects/get?roleCode=${user.selectedRole}&userID=${user.id}&module=${user.selectedModule}`
    );
    if (response?.data) {
      let completed = response.data.filter(
        (project) => project?.projectStatus === "Completed"
      );
      let inprogress = response.data.filter(
        (project) => project?.projectStatus === "In Progress"
      );
      let opt = createSelectOption(response.data, "projectName");
      setOptions(opt);
      setProjectStatus({
        total: response.data.length,
        completed: completed.length,
        inprogress: inprogress.length,
      });
      setProjects(response.data);
      setFiltered(response.data);
      dispatch(proj(response.data));
      setLoading(false);
    }else{
      toast.error(response.errorMessage)
      setLoading(false)
      setProjects([])
      setFiltered([])
    }
  };

  useEffect(() => {
    getProjects();
  }, [user]);

  useEffect(() => {
    if (selectedProject !== null) {
      let data = projects.filter(
        (e) => e.projectName === selectedProject?.value
      );
      setFiltered(data);
    } else {
      setFiltered(projects);
    }
  }, [selectedProject]);

  return (
    <div className="content">
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <ToastContainer />
      <Row className="mt-2 mx-3">
        <Col>
          <Select
            className="w-5"
            options={options}
            placeholder="Select Project"
            value={selectedProject}
            onChange={(val) => setSelectedProject(val)}
            isClearable={true}
          ></Select>
        </Col>
        <Col className="text-end text-muted fs-14">Dashboard</Col>
      </Row>
      <Row className="mx-4">
        <Card className="p-3">
          <div className="d-flex">
            <div className="text-muted me-5">
              No.of Projects :{" "}
              <span className="bold">{projectStatus?.total}</span>
            </div>
            <div className="text-muted me-5">
              Completed :{" "}
              <span className="bold">{projectStatus?.completed}</span>
            </div>
            <div className="text-muted me-5">
              Inprogress :{" "}
              <span className="bold">{projectStatus?.inprogress}</span>
            </div>
          </div>
        </Card>
      </Row>
      {filtered?.length > 0 ? (
        <Row className="mx-3">
          {filtered.map((project, id) => {
            return (
              <Col key={id} md={6}>
                {project?.totalCorridors > 0 && <ProjectCard data={project} />}
                {project?.totalAssets > 0 && <ProjectCardOil data={project} />}
              </Col>
            );
          })}
        </Row>
      ) : (
        <div className="text-center">No Projects avilable for this module</div>
      )}
    </div>
  );
};

export default SMEDashboard;
