import { Dialog } from "primereact/dialog";
import ActionButtons from "../../actionsComponent/ActionButtons";

const DeleteDeliverable = (props: any) => {
  return (
    <Dialog
      visible={props.visible}
      onHide={props.onHide}
      header={props.header}
      draggable={false}
    >
      <div className="m-t-10">
        Are you sure you want to delete{" "}
        {props.type === "csv"
          ? "Measurements CSV File"
          : props.type === "zip"
          ? "Property ZIP File"
          : props.data.deliverableName}{" "}
      </div>
      <ActionButtons
        buttons={["ok", "cancel"]}
        onSave={props.handleSave}
        onCancel={props.onHide}
      />
    </Dialog>
  );
};

export default DeleteDeliverable;
