import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import InputComponent from "../../inputComponent/inputComponent";
import Loader from "../../CommonComponent/Loader";
import { toast, ToastContainer } from "react-toastify";
import Breadcrum from "../../commons/Breadcrum";
import ActionButtons from "../../actionsComponent/ActionButtons";
import { addToast, getMode1, GetMonths } from "../../commons/common";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import * as globalApi from "../../../api/global.api";
import { errorMsg, successMsg } from "../../commons/constant";
import UnAuthDialog from "../../commons/UnAuthDialog";
import { useAppSelector } from "../../../store/hooks";
import { Capitalize, formatDateTime } from "../../commons/functions";
import BreadCrumb from "../../Common/BreadCrumb";

const SurveyDetails = () => {
  let prevProjectRoute: any = sessionStorage.getItem("prevProjectRoute");
  let prevSurveyRoute: any = sessionStorage.getItem("prevSurveyRoute");
  let prevClientRoute: any = sessionStorage.getItem("prevRoute");
  const navigate = useNavigate();
  let surveyId = useParams().id;
  const [spinner, setSpinner] = useState<any>(false);
  const [growl, setGrowl] = useState<any>();
  const [showLogout, setShowLogout] = useState(false);
  const projectObj = useAppSelector((state) => state.project);

  const months = GetMonths();
  let formType = getMode1(window.location.hash);
  let mode = formType.mode === "create" ? "create" : formType.mode;
  let id = formType.id;
  const [formState, setFormState] = useState<any>({});
  const dataTypeOptions = ["Cesium", "Potree"];
  const [allCheck, setAllCheck] = useState<any>({
    name: "ALL",
    checked: false,
  });
  const [deliverables, setDeliverables] = useState<any>([]);
  const [selectedDeliverables, setSelectedDeliverables] = useState<any>([]);
  const [stopDisable, setStopDisable] = useState(false);
  const [volumeDelete, setVolumeDelete] = useState(false);
  const [propertyImageDelete, setpropertyImageDelete] = useState(false);
  let projectid = useAppSelector((state) => state.project.projectid);

  const handleChange = (e: any, type: any) => {
    if (type === "reqd_3d") {
      formState.data_type_3d = "";
      formState.potree_link = "";
      formState.survey_3d_id = "";
    }
    if (type === "data_type_3d") {
      formState.potree_link = "";
      formState.survey_3d_id = "";
    }
    if (type === "completionPercent") {
      let val = e.length > 3 ? e.slice(0, 3) : e;
      setFormState({ ...formState, completionPercent: val });
    } else {
      setFormState({ ...formState, [type]: e });
    }
  };

  const handleDeliverablesChange = (e: any, type: string) => {
    let selDeliverables = [...selectedDeliverables];
    if (type === "ALL") {
      setAllCheck({ name: type, checked: e });
      let copyDeliverables = [...deliverables];
      copyDeliverables.forEach((del: any) => {
        if (stopDisable) {
          if (del.name === "Property Image") setpropertyImageDelete(!e);
          if (del.name === "Volume Assessment") setVolumeDelete(!e);
          del.checked = e;
          return del;
        } else {
          del.checked = e;
          return del;
        }
      });
      setSelectedDeliverables(copyDeliverables);
    } else {
      let findDel = deliverables.find(
        (del: any) => del.name.toLowerCase() === type.toLowerCase()
      );
      if (!e) {
        if (stopDisable) {
          if (type === "Property Image") setpropertyImageDelete(!e);
          if (type === "Volume Assessment") setVolumeDelete(!e);
          let ind = selDeliverables.findIndex((sel: any) => sel.name === type);
          selDeliverables.splice(ind, 1);
        } else {
          let ind = selDeliverables.findIndex((sel: any) => sel.name === type);
          selDeliverables.splice(ind, 1);
        }
        if (selDeliverables.length !== deliverables.length)
          setAllCheck({ ...allCheck, checked: false });
        else setAllCheck({ ...allCheck, checked: true });
        setSelectedDeliverables(selDeliverables);
      } else {
        findDel.checked = e;
        selDeliverables.push(findDel);
        let filterArr = selDeliverables.filter((sel) => sel.checked);
        if (filterArr.length !== deliverables.length)
          setAllCheck({ ...allCheck, checked: false });
        else setAllCheck({ ...allCheck, checked: true });
        setSelectedDeliverables(filterArr);
      }
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSaveDisable = () => {
    let disabled = false;
    if (
      !formState.surveyname ||
      formState.surveyname?.trim() === "" ||
      !formState.lat ||
      formState.lat === "" ||
      !formState.long ||
      formState.long === "" ||
      !formState.date ||
      formState.date === "" ||
      !formState.completionPercent ||
      formState.completionPercent === "" ||
      !formState.zoom ||
      formState.zoom === "" ||
      !formState.zoom_min ||
      formState.zoom_min === "" ||
      !formState.zoom_max ||
      formState.zoom_max === "" ||
      validate3D()
    ) {
      disabled = true;
    }
    return disabled;
  };

  const validate3D = () => {
    if (formState?.reqd_3d) {
      if (formState?.data_type_3d === "Cesium") {
        if (
          !formState?.survey_3d_id ||
          formState?.survey_3d_id === "" ||
          formState?.survey_3d_id === null ||
          formState?.survey_3d_id === "null" ||
          formState?.survey_3d_id === undefined ||
          formState?.survey_3d_id === "undefined" ||
          formState?.survey_3d_id === 0
        )
          return true;
        else return false;
      } else {
        if (
          !formState?.potree_link ||
          formState?.potree_link?.trim() === "" ||
          formState?.potree_link === null ||
          formState?.potree_link === "null" ||
          formState?.potree_link === undefined ||
          formState?.potree_link === "undefined"
        )
          return true;
        else return false;
      }
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      getSurveys();
    }
  }, []);

  const getSurveys = () => {
    let url = `survey/get?id=${surveyId}`;
    globalApi.get(url).then((resp) => {
      if (resp.response) {
        setFormState({
          ...formState,
          projectId: resp.response.rows[0]?.projectId,
          lat: resp.response.rows[0]?.lat,
          long: resp.response.rows[0]?.long,
          zoom: resp.response.rows[0]?.zoom,
          zoom_min: resp.response.rows[0]?.zoomMin,
          zoom_max: resp.response.rows[0]?.zoomMax,
          completionPercent: resp.response.rows[0]?.completionPercent,
          surveyname: resp.response.rows[0]?.surveyName,
          reqd_3d: resp.response.rows[0]?.reqd3d,
          data_type_3d: resp.response.rows[0]?.dataType3d,
          survey_3d_id: resp.response.rows[0]?.survey3dId
            ? resp.response.rows[0]?.survey3dId
            : null,
          potree_link: resp.response.rows[0]?.potreeLink
            ? resp.response.rows[0]?.potreeLink
            : null,
          date: new Date(resp.response.rows[0]?.dateOfSurvey),
        });
      }
    });
  };

  const handleSave1 = () => {
    setSpinner(true);
    if (
      Number(formState.zoom_min) > Number(formState.zoom) ||
      Number(formState.zoom) > Number(formState.zoom_max)
    ) {
      addToast(
        "unable to create survey as zoom range is not inbetween max and min zoom",
        "fail"
      );
      setSpinner(false);
    } else {
      let url = mode === "create" ? "survey/add" : "survey/update";
      let date = new Date(formState.date);
      let NewObj;
      let Obj = {
        projectId: Number(id),
        lat: Number(formState.lat),
        long: Number(formState.long),
        surveyName: formState.surveyname,
        dateOfSurvey: date,
        zoom: Number(formState.zoom),
        zoomMin: Number(formState.zoom_min),
        zoomMax: Number(formState.zoom_max),
        completionPercent: Number(formState.completionPercent),
        reqd3d: formState.reqd_3d ? formState.reqd_3d : false,
        dataType3d: formState.data_type_3d,
        survey3dId: formState.survey_3d_id
          ? Number(formState.survey_3d_id)
          : null,
        potreeLink: formState.potree_link ? formState.potree_link : null,
      };
      if (mode === "create") {
        NewObj = Obj;
      } else {
        NewObj = {
          ...Obj,
          id: Number(surveyId),
          projectId: formState.projectId,
        };
      }
      globalApi.post(url, NewObj).then((res) => {
        if (res?.status === "Success") {
          setSpinner(false);
          addToast(
            mode === "create"
              ? "Survey created successfully"
              : "Survey updated successfully",
            "success"
          );
          navigate(-1);
        } else {
          setSpinner(false);
          addToast(res?.errormessage, "error");
        }
      });
    }
  };


  return (
    <>
      <Loader loader={spinner} />
      <div className="mt-3 px-3">
        <ToastContainer />
        <div className="m-b-20">
          {/* <Breadcrum /> */}
          <BreadCrumb
            title={mode === "create" ? "Add Survey" : "Edit Survey"}
            pageTitle={JSON.parse(prevSurveyRoute).name}
            pageLink={JSON.parse(prevSurveyRoute).link}
            pageTitle1={JSON.parse(prevProjectRoute).name}
            pageLink1={JSON.parse(prevProjectRoute).link}
            pageTitle2={JSON.parse(prevClientRoute).name}
            pageLink2={JSON.parse(prevClientRoute).link}
          />
        </div>
        <div className="row">
          <div className="col-6">
            <InputComponent
              inputLabel={true}
              inputLabelText="Survey Name"
              mandatory={true}
              name="surveyname"
              placeholder="Enter Survey Name"
              value={formState.surveyname}
              required={true}
              type="text"
              onKeyChange={(e: any) => handleChange(e, "surveyname")}
            />
          </div>
          <div className="col-6">
            <InputComponent
              inputLabel={true}
              inputLabelText="Latitude"
              mandatory={true}
              name="long"
              placeholder="Enter Latitude"
              value={formState.long}
              required={true}
              type="number"
              onKeyChange={(e: any) => handleChange(e, "long")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <InputComponent
              inputLabel={true}
              inputLabelText="Longitude"
              mandatory={true}
              name="lat"
              placeholder="Enter Longitude"
              value={formState.lat}
              required={true}
              type="number"
              onKeyChange={(e: any) => handleChange(e, "lat")}
            />
          </div>
          <div className="col-6">
            <label>
              Date of Survey
              <span className="mandatory">*</span>
            </label>
            <Calendar
              className="w-100"
              placeholder="Select the Date of Survey"
              onChange={(e) => handleChange(e.target.value, "date")}
              value={formState.date}
              maxDate={new Date()}
              readOnlyInput
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <InputComponent
              inputLabel={true}
              inputLabelText="% Completion"
              mandatory={true}
              name="completionPercent"
              placeholder="Enter % Completion"
              value={formState.completionPercent}
              required={true}
              type="number"
              onKeyChange={(e: any) => handleChange(e, "completionPercent")}
            />
          </div>
          <div className="col-6">
            <InputComponent
              inputLabel={true}
              inputLabelText="Zoom"
              mandatory={true}
              name="zoom"
              placeholder="Enter Zoom"
              value={formState.zoom}
              required={true}
              type="number"
              onKeyChange={(e: any) => handleChange(e, "zoom")}
            />
          </div>
          <div className="col-6">
            <InputComponent
              inputLabel={true}
              inputLabelText="Minimum Zoom"
              mandatory={true}
              name="zoom_min"
              placeholder="Enter Minimum Zoom"
              value={formState.zoom_min}
              required={true}
              type="number"
              onKeyChange={(e: any) => handleChange(e, "zoom_min")}
            />
          </div>
          <div className="col-6">
            <InputComponent
              inputLabel={true}
              inputLabelText="Maximum Zoom"
              mandatory={true}
              name="zoom_max"
              placeholder="Enter Maximum Zoom"
              value={formState.zoom_max}
              required={true}
              type="number"
              onKeyChange={(e: any) => handleChange(e, "zoom_max")}
            />
          </div>
          <div className="col-6">
            <div className="field-checkbox checkbox-clr-green">
              <Checkbox
                inputId="binary"
                checked={formState?.reqd_3d}
                onChange={(e) => handleChange(e.target.checked, "reqd_3d")}
              />
              <label htmlFor="binary">3D is required ?</label>
            </div>
          </div>
          {formState.reqd_3d && (
            <div className="col-6">
              <label>
                Data Type <span className="mandatory">*</span>
              </label>
              <Dropdown
                value={formState.data_type_3d}
                options={dataTypeOptions}
                className="w-100 border ht-50"
                onChange={(e) => handleChange(e.target.value, "data_type_3d")}
                placeholder="Select Type"
                filter
                filterBy="dataType"
              />
            </div>
          )}
          {formState.data_type_3d === "Cesium" && (
            <div className="col-6">
              <InputComponent
                inputLabel={true}
                inputLabelText="3D ID"
                mandatory={true}
                name="survey_3d_id"
                placeholder="Enter 3D ID"
                value={formState.survey_3d_id ? formState.survey_3d_id : ""}
                required={true}
                type="number"
                onKeyChange={(e: any) => handleChange(e, "survey_3d_id")}
              />
            </div>
          )}
          {formState.data_type_3d === "Potree" && (
            <div className="col-6">
              <InputComponent
                inputLabel={true}
                inputLabelText="Potree URL"
                mandatory={true}
                name="potree_link"
                placeholder="Enter Potree URL"
                value={formState.potree_link ? formState.potree_link : ""}
                required={true}
                type="text"
                onKeyChange={(e: any) => handleChange(e, "potree_link")}
              />
            </div>
          )}

          <>
            {mode !== "view" && (
              <ActionButtons
                buttons={["Save", "Cancel"]}
                onSave={handleSave1}
                onCancel={handleCancel}
                disabled={handleSaveDisable()}
                mode={mode}
              />
            )}
          </>
        </div>
      </div>
      {showLogout && (
        <UnAuthDialog
          visible={showLogout}
          onHide={() => setShowLogout(false)}
        />
      )}
    </>
  );
};

export default SurveyDetails;
