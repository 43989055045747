import DataTable from "react-data-table-component";
import Pagination from "../commons/pagination";
import { formatDateTime } from "../commons/functions";
import { useEffect, useState } from "react";

const FeatureDataTable = ({
  data,
  handleDeleteClick,
  handleModal,
  handleEditClick,
  newFeature,
  features,
}) => {
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(data?.length);
  const [newData, setNewData] = useState(data.slice(0, 5));

  useEffect(() => {
    setTotalCount(data.length);
    let id = (currentPage - 1) * 5;
    setNewData(data.slice(id, id + 5));
  }, [data]);

  const handlePageChange = (val) => {
    setCurrentPage(val);
    let id = (val - 1) * 5;
    setNewData(data.slice(id, id + 5));
  };
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Feature Name</span>,
      selector: (row) => row.featureName,
      width: "200px",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Created Date</span>,
      selector: (row) => {
        return formatDateTime(row.createdAt);
      },
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,
      width: "200px",
      cell: (row) => {
        let idx = features?.filter((e) => e.label === row.featureName);
        return (
          <span>
            {idx?.length === 0 && (
              <i
                className="bx bx-edit fs-4 me-2 pointer"
                style={{ color: "green" }}
                onClick={() => {
                  handleModal(true);
                  handleEditClick(row);
                }}
              ></i>
            )}
            <i
              className="ri-delete-bin-6-line fs-4 me-2 pointer"
              style={{ color: "red" }}
              onClick={() => handleDeleteClick(row)}
            ></i>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div className="feature-table">
        <DataTable columns={columns} data={newData} />
      </div>
      {data.length > 5 && (
        <Pagination
          itemsPerPage={5}
          count={totalCount}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default FeatureDataTable;
