import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { DefectCharts, TowerObservationCountCharts } from "./WidgetsCharts";
import * as api from "../../api/rest.api";
import { convertDateToISO } from "../Components/Utils";

const TxComponentCharts = ({
  defectData,
  obsValue,
  obsData,
  thermalReport,
}) => {
  const selectedData = (idx) => {
    if (obsData[idx] === "Thermal") {
      thermalReport();
    }
  };
  return (
    <Row>
      <Col md={4}>
        <Card className="br-0">
          <CardHeader className="bg-light">
            <span className="fs-16">Component Status</span>
          </CardHeader>
          <CardBody className="p-3">
            <DefectCharts
              data={defectData}
              dataColors='["#CED4DA", "#29EDAB"]'
            />
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                <i class="bx bxs-circle color-open me-2"></i>
                <div className="text-close me-4">
                  Closed{" "}
                  <span className="text-dark ms-1">({defectData[1]})</span>
                </div>
                <i class="bx bxs-circle color-close me-2"></i>
                <div className="text-close me-4">
                  Open <span className="text-dark ms-1">({defectData[0]})</span>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      {obsData?.length > 0 && obsValue?.length > 0 && (
        <Col md={8}>
          <Card className="br-0">
            <CardHeader>Count of Observation at Project Level</CardHeader>
            <CardBody className="p-3">
              <TowerObservationCountCharts
                dataColors='["#4C9DE3", "#EF7D34", "#3FE3AC", "#D9E352", "#60A0C1", "#9191F5", "#F151FC", "#FCBA51"]'
                data={obsData}
                values={obsValue}
                selectedData={selectedData}
              />
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default TxComponentCharts;
