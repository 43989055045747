import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  layersList: {},
};

export const userSlice = createSlice({
  name: "layersList",
  initialState,
  reducers: {
    layersList: (state, action) => {
      return action.payload;
    },
  },
});

export const { layersList } = userSlice.actions;
export default userSlice.reducer;
