import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Doughnut } from "react-chartjs-2";
import getChartColorsArray from "../../Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";
import * as api from "../../../api/global.api";
import { useParams } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { convertDateToISO } from "../../commons/functions";
import { DistributedColumn, SimpleDonut } from "../../admin/Dashboard/Charts";
import Loader from "../../CommonComponent/Loader";
import { useAppSelector } from "../../../store/hooks";

const DashboardChart = ({setLoading, loading}) => {
  const user = useAppSelector(state=>state.user)
  // const [loading, setLoading] = useState(false);
  const projectId = useParams().id;
  const [state, setState] = useState({
    overallAssetStatus: {
      labels: [],
      data: [],
    },
    overallObservationWiseAssetCount: {
      labels: [],
      data: [],
    },
    overallAssetCount: {
      labels: [],
      data: [],
    },
  });

  useEffect(() => {
    getAssetDetail();
  }, []);

  const getAssetDetail = (date) => {
    setLoading(true);
    api.get(`siteWiseAssetDetail?id=${projectId}`, {}).then((res) => {
      setState({
        ...state,
        overallAssetStatus: {
          labels: Object.keys(res.severityWiseAssetData),
          data: Object.values(res.severityWiseAssetData),
        },
        overallObservationWiseAssetCount: {
          labels: res?.observationData?.map((obj) => obj?.observation),
          data: res?.observationData?.map((obj) => obj?.count),
        },
        overallAssetCount: {
          labels: res?.assetData?.map((obj) => obj?.assetType),
          data: res?.assetData?.map((obj) => obj?.count),
        },
      });
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      {!loading && (
        <Row>
          <Col lg={3}>
            <Card>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <h4 className="card-title mb-0">Overall Assets Status</h4>
              </CardHeader>
              <CardBody>
                <SimpleDonut
                  series={state?.overallAssetStatus?.data || []}
                  labelName={state?.overallAssetStatus?.labels || []}
                  labelCenter={"Total"}
                  dataColors='[
                  "#00873e",
                  "#FFA500",
                  "#BB0038",
                  "#E2EA7C",
                  "#5F5E5F",
                  "#CECECE",
                  "#EF9B65",
                  "#E28288",
                  "#899CC6",
                  "#87D1E8",
                  "#39C71736",
                  "#8E9798A3",
                  "#EAA172",
                  "#7777C7",
                  "#71B4D1",
                  "#E18FE6",
                  "#BEB5B5",
                  "#82EAC7",
                  "#E1E97B",
                  "#E1E97B"
                ]'
                  height={380}
                  selectedData={() => {}}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <h4 className="card-title mb-0">
                  Observation count for all Assets
                </h4>
              </CardHeader>
              <CardBody>
                <SimpleDonut
                  series={state?.overallObservationWiseAssetCount?.data || []}
                  labelName={
                    state?.overallObservationWiseAssetCount?.labels || []
                  }
                  labelCenter={"Total"}
                  dataColors='["#8a3ffc", "#33b1ff", "#007d79", "#ff7eb6", "#fa4d56", "#6fdc8c", "#4589ff", "#d12771", "# 08bdba", "#bae6ff" ]'
                  height={380}
                  selectedData={() => {}}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg={5}>
            <Card>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <h4 className="card-title mb-0">Overall Assets Count</h4>
              </CardHeader>
              <CardBody>
                <DistributedColumn
                  data={state?.overallAssetCount?.data || []}
                  label={state?.overallAssetCount?.labels || []}
                  textY={"No. of Projects"}
                  textX={"States"}
                  custom={{ width: 30, borderRadius: 0 }}
                  height={350}
                  dataColors='["#8a3ffc", "#33b1ff", "#007d79", "#ff7eb6", "#fa4d56", "#6fdc8c", "#4589ff", "#d12771", "# 08bdba", "#bae6ff" ]'
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default DashboardChart;
