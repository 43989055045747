export const BreadcrumItems = {
  clients: { name: "Client Management", link: "clients" },
  create: { name: "Create", link: "create" },
  view: { name: "View", link: "view" },
  edit: { name: "Edit", link: "edit" },
  roles: { name: "Roles", link: "roles" },
  drones: { name: "Drones Management", link: "drones" },
  delivers: { name: "Delivery Management", link: "delivers" },
  surveys: { name: "Surveys", link: "surveys" },
  survey: { name: "Survey", link: "surveys" },
  users: { name: "Users", link: "users" },
  surveydetails: { name: "Add Survey Details", link: "delivers" },
  pilot: { name: "Pilot", link: "pilot" },
  process: { name: "Processing Team", link: "process" },
  deliverables: { name: "Deliverables", link: "deliverables" },
};
