import React from "react";
import { Badge, Card, Col, Row } from "reactstrap";
import { createLogo, getDateOnly } from "../Components/Utils";
import CalanderImg from "../../assets/svg-new/CalanderImg.svg";
import towerImg from "../../assets/svg-new/towerImg.svg";
import { useNavigate } from "react-router-dom";

const CorridorCard = ({ data, project }) => {
  const navigate = useNavigate();
  return (
    <Card
      className="p-2 pointer"
      onClick={() => {
        navigate("/sme-tower", { state: { data, project } });
      }}
    >
      <Badge
        className={
          data?.corridorStatus === "Completed"
            ? "badge_complete"
            : "badge_inprogress"
        }
        color=""
      >
        {data?.corridorStatus}
      </Badge>
      <Row>
        <Col xs={4}>
          <div className="dashboard-client-img">
            <div className="client-logo-sme">
              {project?.client?.clientLogo ? (
                <img src={project?.client?.clientLogo} width="100%" />
              ) : (
                <span>{createLogo(project?.client?.clientName)}</span>
              )}
            </div>
            <div className="client-logo-sme-name">{project?.projectName}</div>
          </div>
        </Col>
        <Col xs={8}>
          <Row className="mb-3">
            <div className="fs-16 project-title fw-500">
              Corridor Name: {data?.corridorName}
            </div>
            <div className="d-flex align-items-center">
              <i className="bx bxs-edit-location text-danger me-1 fs-5"></i>
              <span className="fs-14">{data?.address}</span>
            </div>
          </Row>
          <Row className="mb-2">
            <Col>
              <div className="text-gray fs-16">Stretch Starting</div>
              <div className="fs-16 fw-500">{data?.startingPosition}</div>
            </Col>
            <Col>
              <div className="text-gray fs-16">Stretch Ending</div>
              <div className="fs-16 fw-500">{data?.endingPosition}</div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="d-flex align-items-center">
              <img src={towerImg} className="me-2"></img>
              <div>
                <div className="text-gray fs-16">No. Of Towers</div>
                <div className="fs-18 color-blue-l fw-500">{data?.totalTowers}</div>
              </div>
            </Col>
            <Col className="d-flex align-items-center">
              <img src={CalanderImg} className="me-2"></img>
              <div>
                <div className="text-gray fs-16">Created Date</div>
                <div className="fs-18 color-blue-l fw-500">
                  {getDateOnly(data?.createdAt)}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-gray fs-16">Corridor Length</div>
              <div className="fs-18 color-blue-l fw-500">{data?.corridorLength}</div>
            </Col>
            <Col>
              <div className="text-gray fs-16">Line Name</div>
              <div className="fs-18 color-blue-l fw-500">{data?.lineName}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default CorridorCard;
