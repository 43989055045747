import React, { useState } from "react";
import Select from "react-select";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const BufferModal = (props) => {
  const [selectedDirection, setSelectedDirection] = useState(null);
  const [formState, setFormState] = useState({
    colorPicker: "rgba(255, 0, 0, 0.3)",
    bufferColour: "red",
  });

  const handleChange = (e, field) => {
    if (field === "offsetValue" && e.includes(".")) {
      if (e.split(".")[1].length < 3) {
        setFormState({ ...formState, [field]: e });
      }
    }
    if (
      field === "offsetValue" &&
      (props?.data === "Point" || props?.tool === "Point") &&
      Number(e) < 0
    ) {
    } else {
      setFormState({ ...formState, [field]: e });
    }
  };

  const handleDisable = () => {
    if (props?.data === "LineString" || props?.tool === "LineString") {
      if (
        !formState.FeatureName ||
        !formState.Description ||
        !formState.offsetValue ||
        !formState.direction
      )
        return true;
      return false;
    } else {
      if (
        !formState.FeatureName ||
        !formState.Description ||
        !formState.offsetValue
      )
        return true;
      return false;
    }
  };

  const onSubmit = async () => {
    props?.handleBuffer(formState);
    setFormState({ colorPicker: "red" });
    props?.toggle();
  };

  const handleDirectionChange = (val) => {
    setSelectedDirection(val);
    handleChange(val.value, "direction");
  };

  return (
    <Modal isOpen={props?.show} className="bg-custom-white toolModal">
      <ModalHeader
        className={`border-bottom p-2 bg-light px-4`}
        toggle={() => props?.toggle()}
      >
        <span className="text-dark text-bold fs-15">{props?.modalheader}</span>
      </ModalHeader>
      <ModalBody>
        <div className="mb-2">
          <Label className="fs-14">
            Feature Name <span className="required">*</span>
          </Label>
          <Input
            type="text"
            placeholder="Enter"
            size="sm"
            value={formState.FeatureName}
            onChange={(e) => {
              handleChange(e.target.value, "FeatureName");
            }}
          ></Input>
        </div>
        <div className="mb-2">
          <Label className="fs-14">
            Offset <span className="required">*</span>
          </Label>
          <Input
            type="number"
            size="sm"
            value={formState.offsetValue}
            onChange={(e) => {
              handleChange(e.target.value, "offsetValue");
            }}
            placeholder="Enter                                      in Mtrs"
          ></Input>
        </div>
        {(props?.data === "LineString" || props.tool === "LineString") && (
          <div className="mb-2">
            <Label className="fs-14">
              Direction <span className="required">*</span>
            </Label>
            <Select
              size="sm"
              value={selectedDirection}
              options={[
                { label: "Left", value: "Left" },
                { label: "Right", value: "Right" },
              ]}
              onChange={handleDirectionChange}
            />
          </div>
        )}
        <div className="mb-2">
          <Label className="fs-14">
            Description <span className="required">*</span>
          </Label>
          <textarea
            className="form-control"
            size="sm"
            value={formState.Description}
            options={[]}
            onChange={(e) => {
              handleChange(e.target.value, "Description");
            }}
          ></textarea>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex gap-2 justify-content-end mt-3 mb-2">
          <Button
            outline
            className="w-sm"
            color="primary"
            data-bs-dismiss="modal"
            onClick={() => props?.toggle()}
          >
            Cancel
          </Button>

          <Button
            type="button"
            className="bg-primary w-sm"
            disabled={handleDisable()}
            onClick={onSubmit}
          >
            Display Buffer
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default BufferModal;
