import { UncontrolledTooltip } from "reactstrap";
import { getArea, getLength } from "ol/sphere.js";
import { Vector as VectorSource, ImageWMS } from "ol/source";
import { Vector as VectorLayer, Image } from "ol/layer";
import GeoJSON from "ol/format/GeoJSON";
import { Style, Circle, Fill, Stroke } from "ol/style";

export const GEOSERVER_URL = process.env.REACT_APP_GEOSERVER_URL;

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const createLogo = (str) => {
  const result = str?.split(/[, ]+/);
  if (result.length === 1) {
    let str = result[0][0] + result[0][0];
    return str;
  } else {
    let str = result[0][0] + result[result.length - 1][0];
    return str;
  }
};

const Tooltip = (id, label, placement) => {
  return (
    <UncontrolledTooltip
      placement={placement ? placement : "bottom"}
      target={id}
    >
      {label}
    </UncontrolledTooltip>
  );
};
const convertDateToISO = (date) => {
  let doo = new Date(date);
  let convertDate = new Date(
    doo.getTime() + Math.abs(doo.getTimezoneOffset() * 60000)
  ).toISOString();
  return convertDate;
};

const createSelectOption = (arr, data) => {
  let options = arr?.map((item) => {
    if (data === "startDate") {
      let date = getDateOnly(new Date(item[data]));
      return { label: date, value: date };
    } else if (data === "projectName" || data === "surveyName") {
      return { label: item[data], value: item[data], id: item["id"] };
    } else if (data === "dateOfSurvey") {
      let val = getDateOnly(item[data]);
      return { label: val, value: val };
    } else {
      return { label: item[data], value: item[data] };
    }
  });
  return options;
};

const getDate = (date, dateWithoutTime) => {
  const sDate = new Date();
  if (!date) {
    date = new Date(sDate);
  }
  date = new Date(date);
  let DATE_OPTIONS = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  if (dateWithoutTime) {
    DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
  }
  return date.toLocaleDateString("en-US", DATE_OPTIONS);
};

const getDateOnly = (date) => {
  return getDate(date, true);
};

const sortBy = (arr, key) => {
  return arr.sort((a, b) => (a[key] < b[key] ? -1 : b[key] < a[key] ? 1 : 0));
};

const makeRasterdata = (sources) => {
  let sourcesArray = [];
  if (sources?.length > 0) {
    sourcesArray = sources.map((source) =>
      makeSource(source.dataType, source.sourceLink, source.sourceName)
    );
  }
  let layersarray = [];
  if (sources?.length > 0) {
    layersarray = sources.map((source) => {
      if (source.dataType === "vector") {
        return {
          displayname: source.sourceDisplayName || source.sourceName,
          visibility: source.visibility,
          priority: source.priority,
          geoservervector: true,
        };
      } else {
        return {
          displayname: source.sourceDisplayName || source.sourceName,
          visibility: source.visibility,
          priority: source.priority,
          vector: false,
        };
      }
    });
  }
  return [sourcesArray, layersarray];
};

const prepareDynamicLayer = (layers) => {
  return layers.map((layer) => {
    return {
      displayname: layer.FeatureName,
      description: layer.Description,
      visibility: 1,
      priority: 100,
      vector: true,
    };
  });
};

const makeSource = (datatype, url, sourcename) => {
  if (
    datatype === "raster" ||
    datatype === "vector" ||
    datatype === "Raster" ||
    datatype === "Vector"
  ) {
    let res = {
      source: {
        type: datatype,
        url: url,
        sourcename: sourcename,
      },
      layers: [
        {
          id: sourcename,
          type: datatype,
          source: sourcename,
          "source-layer": sourcename,
          minzoom: 0,
          maxzoom: 22,
          layout: {
            visibility: "none",
          },
        },
      ],
    };
    return res;
  }
};

const vectorData = (data) => {
  let PreparedData = data.map((layer) => {
    let obj = {
      type: "Feature",
      geometry: layer.Coordinates,
      properties: {
        name: layer.FeatureName,
        additionalProperty: "Value B",
        isBufferedLayer: layer.bufferDistance != null ? true : false,
        bufferDistance:
          layer.bufferDistance != null ? layer.bufferDistance : "",
      },
    };

    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(obj),
    });
    let style;
    if (layer.Coordinates.type === "Point") {
      style = new Style({
        image: new Circle({
          radius: 3,
          fill: new Fill({
            color:
              layer.bufferDistance != null
                ? "rgba(255, 0, 0, 0.3)"
                : layer.Color,
          }),
          stroke: new Stroke({
            color: layer.bufferDistance != null ? "red" : layer.Color,
            width: 2,
          }),
        }),
      });
    } else if (layer.Coordinates.type === "Polygon") {
      style = new Style({
        fill: new Fill({
          color:
            layer.bufferDistance != null ? "rgba(255, 0, 0, 0.3)" : layer.Color,
        }),
        stroke: new Stroke({
          color: layer.bufferDistance != null ? "red" : layer.Color,
          width: 2,
        }),
      });
    } else if (layer.Coordinates.type === "LineString") {
      style = new Style({
        fill: new Fill({
          color:
            layer.bufferDistance != null ? "rgba(255, 0, 0, 0.3)" : layer.Color,
        }),
        stroke: new Stroke({
          color:
            layer.bufferDistance != null ? "rgba(255, 0, 0, 0.3)" : layer.Color, // Set your desired stroke color here
          width: layer.bufferDistance != null ? 20 : 2, // Set the stroke width
        }),
      });
    }
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: style,
      properties: {
        isBufferedLayer: layer.bufferDistance != null ? true : false,
      },
    });
    return vectorLayer;
  });

  return PreparedData;
};

const createLayers = (data) => {
  let madeLayers = [];
  let madeSources = [];
  data?.map((layer) => {
    let params = {};
    if (layer?.source.type === "raster") {
      params = {
        FORMAT: "image/png8",
        LAYERS: layer?.source?.sourcename,
      };
    } else {
      params = {
        LAYERS: layer?.source?.sourcename,
      };
    }
    let sourceLayer = new ImageWMS({
      url: GEOSERVER_URL + layer?.source.url,
      params: params,
      ratio: 1,
      attributions: "geoserver",
    });
    let createdLayer = new Image({
      source: sourceLayer,
    });

    createdLayer.getPixelValue = (coordinate) => {
      return new Promise((resolve, reject) => {
        sourceLayer
          .getImage()
          .then((image) => {
            let pixel = image.getImageData(
              coordinate[0],
              coordinate[1],
              1,
              1
            ).data;
            resolve(pixel);
          })
          .catch(reject);
      });
    };
    madeLayers.push(createdLayer);
    madeSources.push(sourceLayer);
  });
  return [madeLayers, madeSources];
};

const formatArea = function (polygon) {
  const area = getArea(polygon);
  let output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";
  } else {
    output = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
  }
  return output;
};

const formatLength = function (line) {
  const length = getLength(line);
  let output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " " + "km";
  } else {
    output = Math.round(length * 100) / 100 + " " + "m";
  }
  return output;
};

const formatLengthInMeters = function (line) {
  const length = getLength(line);
  let output;
  output = Math.round(length * 100) / 100 + " " + "m";
  return output;
};

const flattenArray = (arr) => {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(
      Array.isArray(toFlatten) ? flattenArray(toFlatten) : toFlatten
    );
  }, []);
};

let curr = 2020;
let yearArr = [];
let start = new Date().getFullYear();
while (start >= curr) {
  yearArr.push(start);
  start--;
}
const dateData = yearArr?.map((e) => {
  return { label: "FY " + e + " - " + "FY " + (+e + 1), value: e };
});

const checkStringInArray = (string, array) => {
  let newArray = array?.map(e=>{
    let item = e?.split("-")[0]?.trim()
    return item
  })
  let newStr = string?.split("-")[0]?.trim()
  if (newArray?.includes(newStr)) {
    return true;
  } else {
    return false;
  }
};

export {
  createLogo,
  Tooltip,
  createSelectOption,
  getDate,
  getDateOnly,
  sortBy,
  makeRasterdata,
  prepareDynamicLayer,
  makeSource,
  createLayers,
  formatLength,
  formatArea,
  vectorData,
  formatLengthInMeters,
  convertDateToISO,
  flattenArray,
  dateData,
  checkStringInArray
};
