import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: true,
};

const adminsidebarSlice = createSlice({
  name: "adminsidebar",
  initialState,
  reducers: {
    adminsidebar: (state, action) => {
      return action.payload;
    },
  },
});

export const { adminsidebar } = adminsidebarSlice.actions;
export default adminsidebarSlice.reducer;
