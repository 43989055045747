import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import ActionButtons from "../../actionsComponent/ActionButtons";
import InputComponent from "../../inputComponent/inputComponent";

const AddNew = (props: any) => {
  const checkDisabled = () => {
    if (
      !props.formState.location ||
      props.formState.location === "" ||
      !props.formState.division ||
      props.formState.division === "" ||
      !props.formState.scheduleStartDate ||
      props.formState.scheduleStartDate === "" ||
      !props.formState.scheduleEndDate ||
      props.formState.scheduleEndDate === ""
    )
      return true;
    return false;
  };

  return (
    <Dialog
      visible={props.visible}
      draggable={true}
      onHide={props.onHide}
      header="Add New Division"
      className="w-50vw"
    >
      <div className="row m-t-10">
        <div className="col-4">
          <InputComponent
            inputLabel={true}
            inputLabelText="Location"
            mandatory={true}
            name="location"
            value={props.formState.location}
            placeholder="Location"
            required={true}
            type="text"
            onKeyChange={props.handleChange}
          />
        </div>
        <div className="col-4">
          <InputComponent
            inputLabel={true}
            inputLabelText="Division"
            mandatory={true}
            name="division"
            value={props.formState.division}
            placeholder="Division"
            required={true}
            type="text"
            onKeyChange={props.handleChange}
          />
        </div>
        <div className="col-4">
          <label>
            schedule Start Date <span className="mandatory"> *</span>
          </label>
          <Calendar
            value={props.formState.scheduleStartDate}
            readOnlyInput
            placeholder="Schedule Start Date"
            className="w-100"
            dateFormat="dd-mm-yy"
            onChange={(e: any) =>
              props.handleChange(e.target.value, "scheduleStartDate")
            }
          />
        </div>
      </div>
      <div className="row m-t-10">
        <div className="col-4">
          <label>
            schedule End Date <span className="mandatory"> *</span>
          </label>
          <Calendar
            value={props.formState.scheduleEndDate}
            readOnlyInput
            placeholder="Schedule End Date"
            className="w-100"
            dateFormat="dd-mm-yy"
            onChange={(e: any) =>
              props.handleChange(e.target.value, "scheduleEndDate")
            }
          />
        </div>
      </div>
      <ActionButtons
        buttons={["save", "cancel"]}
        onSave={props.handleSave}
        onCancel={props.onCancel}
        disabled={checkDisabled()}
      />
    </Dialog>
  );
};

export default AddNew;
