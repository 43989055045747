import React, { useEffect, useState } from "react";
import RightPanal from "./RightPanal";
import LeftPanal from "./LeftPanal";
import TopPanal from "./TopPanal";
import BottomPanal from "./BottomPanal";
import Loader from "../../components/CommonComponent/Loader";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useLocation } from "react-router-dom";
import DeleteModal from "../../components/Common/DeleteModal";
import * as api from "../../api/global.api";
import {
  deleteFeatureLayer,
  getDownloadsBySurveyID,
  getDynamicLayers,
  getReportsBySurveyID,
  getSourceid,
  getSurvey,
} from "../../api/dashboard.api";
import {
  makeRasterdata,
  months,
  prepareDynamicLayer,
  sortBy,
} from "../Components/Utils";
import { layersList } from "../../store/layerreducer";
import OpenLayerMap from "./OpenLayerMap";
import { ToastContainer, toast } from "react-toastify";
import { projectTsx } from "../../store/projectreducer";

const SurveyMap = () => {
  const dispatch = useAppDispatch();
  const location = useLocation()?.state;
  const data = useAppSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [row, setRow] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [updateMap, setUpdateMap] = useState(false);
  const [drawModal, setDrawModal] = useState(false);

  const [allProject, setAllProject] = useState([]);
  const [allSurvey, setAllSurvey] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  const [raw, setRaw] = useState([]);
  const [layers, setLayers] = useState([]);
  const [dynamic, setDynamic] = useState([]);
  const [dynamicData, setDynamicData] = useState([]);
  const [rData, setrData] = useState(null);
  const [reports, setReports] = useState([]);
  const [downloads, setDownloads] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  const [tool, setTool] = useState("none");
  const [accessory, setAccessory] = useState("none");
  const [isSelect, setIsSelect] = useState(false);
  const [measurement, setMeasurement] = useState("");

  const [closeDynamic, setCloseDynamic] = useState("false");
  const [modal3D, setModal3D] = useState(false);

  const onProjectChange = (val) => {
    let project = allProject?.find((ele) => ele?.projectName === val.value);
    setSelectedProject(project);
    dispatch(projectTsx(project));
    sessionStorage.removeItem("surveyToShow")
    sessionStorage.setItem("surveyProject", JSON.stringify(project));
  };

  const onSurveyChange = (val) => {
    let survey = allSurvey?.find((ele) => ele?.surveyName === val.value);
    setSelectedSurvey(survey);
    sessionStorage.setItem("surveyToShow", JSON.stringify(survey));
  };

  useEffect(() => {
    setAllProject(data?.projectsArr);
    let storedProject = JSON.parse(sessionStorage.getItem("surveyProject"));
    setSelectedProject(storedProject ? storedProject : location);
    dispatch(projectTsx(location));
  }, []);

  useEffect(async () => {
    if (selectedProject !== null) {
      setLoading(true);
      let allSurvey = await getSurvey(selectedProject?.id);
      let resAllSurvey = allSurvey?.response?.map((item) => {
        let date = new Date(item.dateOfSurvey);
        date.setHours(date.getHours() + 5);
        date.setMinutes(date.getMinutes() + 30);
        const formatedate = date.toISOString();
        return {
          ...item,
          date: months[date.getMonth()] + " (" + date.getFullYear() + ")",
          location: [item.lat, item.long],
          month: months[date.getMonth()],
          year: date.getFullYear(),
          zoom_max: item.zoomMax,
          zoom_min: item.zoomMin,
          surveyname: item.surveyName,
          surveyId: item.id,
          survey_3d_id: item.survey3dId || null,
          potree_link: item.potreeLink || null,
          sortDate: formatedate.split("T")[0],
          startDate: date,
        };
      });
      setAllSurvey(resAllSurvey);
      let existingSurvey = JSON.parse(sessionStorage.getItem("surveyToShow"));
      if (existingSurvey) {
        setSelectedSurvey(existingSurvey);
      } else {
        setSelectedSurvey(resAllSurvey[0]);
        sessionStorage.setItem("surveyToShow", JSON.stringify(resAllSurvey[0]));
      }
      setLoading(false);
    }
  }, [selectedProject]);

  useEffect(async () => {
    if (selectedSurvey !== null) {
      setLoading(true);
      let sourceData = await getSourceid(selectedSurvey.id);
      setRaw(sourceData?.response);
      sourceData = await sortBy(sourceData?.response, "priority");
      let rData = makeRasterdata(sourceData);
      setrData(rData[0]);
      let layerDetails = await sortBy(rData[1], "priority");
      setLayers(layerDetails);

      let dynamicData = await getDynamicLayers(selectedSurvey?.id);
      setDynamicData(dynamicData?.response?.rows || []);
      let dynamicLayers = prepareDynamicLayer(dynamicData?.response?.rows);
      let chk = dynamicLayers.filter((e) => e?.visibility === 0).length;
      chk ? setSelectAll(false) : setSelectAll(true);
      setDynamic(dynamicLayers);

      layerDetails = [...layerDetails, ...dynamicLayers];
      dispatch(layersList(layerDetails));

      let reportsData = await getReportsBySurveyID(selectedSurvey?.id);
      setReports(reportsData?.response?.rows || []);

      let downloadData = await getDownloadsBySurveyID(selectedSurvey?.id);
      setDownloads(downloadData?.response?.rows || []);
      get3DSources();

      setUpdateMap(true);
      setLoading(false);
    }
  }, [selectedSurvey]);

  const get3DSources = async () => {
    let response = await api.get(
      `source3d/get?surveyId=${selectedSurvey?.id}&projectId=${selectedProject?.id}`
    );
    if (response?.status === "Success") {
      if (response?.response?.findSource3d?.length > 0) {
        setModal3D(true);
      } else {
        setModal3D(false);
      }
    } else {
      toast.error(response?.errormessage);
      setModal3D(false);
    }
  };

  useEffect(() => {
    if (updateMap) {
      let obj = {
        smap: dynamicData,
        rData: rData,
        surveyData: selectedSurvey,
        projectData: selectedProject,
        layerDetails: data?.layers,
        raw: raw,
      };
      setMapData(obj);
      setUpdateMap(false);
    }
  }, [updateMap]);

  const changeVisibilty = (val) => {
    let res = layers?.map((e) => {
      if (e?.displayname === val?.displayname) {
        return { ...e, visibility: Number(!e?.visibility) };
      } else {
        return e;
      }
    });
    setLayers(res);
    let updatedlayers = data?.layers?.map((e) => {
      if (e?.displayname === val?.displayname) {
        return { ...e, visibility: Number(!e?.visibility) };
      } else {
        return e;
      }
    });
    dispatch(layersList(updatedlayers));
  };

  const addDynamicLayer = async () => {
    let dynamicData = await getDynamicLayers(selectedSurvey?.id);
    setDynamicData(dynamicData?.response?.rows);
    let dynamicLayers = prepareDynamicLayer(dynamicData?.response?.rows);
    let chk = dynamicLayers.filter((e) => e?.visibility === 0).length;
    chk ? setSelectAll(false) : setSelectAll(true);
    setDynamic(dynamicLayers);
    let layerDetails = [...layers, ...dynamicLayers];
    dispatch(layersList(layerDetails));
    setUpdateMap(true);
  };

  const deletelayer = (layer) => {
    setRow(layer);
    setIsDeleteModal(true);
  };

  const onDeleteClick = () => {
    deleteFeatureLayer(row?.displayname).then(async (res) => {
      if (res.status === "Success") {
        toast.success("Feature deleted successfully");
        setRow(null);
        setIsDeleteModal(false);
        let dynamicData = await getDynamicLayers(selectedSurvey?.id);
        setDynamicData(dynamicData?.response?.rows);
        let dynamicLayers = prepareDynamicLayer(dynamicData?.response?.rows);
        let chk = dynamicLayers.filter((e) => e?.visibility === 0).length;
        chk ? setSelectAll(false) : setSelectAll(true);
        setDynamic(dynamicLayers);
        let layerDetails = [...layers, ...dynamicLayers];
        dispatch(layersList(layerDetails));
        setUpdateMap(true);
      }
    });
  };

  const closeDynamics = () => {
    setCloseDynamic(true);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {loading && <Loader loader={loading} />}
      {isDeleteModal && (
        <DeleteModal
          show={isDeleteModal}
          onCloseClick={() => setIsDeleteModal(false)}
          onDeleteClick={() => onDeleteClick()}
        />
      )}

      {selectedProject !== null && selectedSurvey !== null && (
        <div className="survey-map">
          {mapData !== null && (
            <OpenLayerMap
              mapData={mapData}
              setAccessory={setAccessory}
              accessory={accessory}
              tool={tool}
              setTool={setTool}
              isSelect={isSelect}
              setMeasurement={setMeasurement}
              addDynamicLayer={addDynamicLayer}
              drawModal={drawModal}
              setDrawModal={setDrawModal}
              setLoading={setLoading}
              closeDynamics={closeDynamics}
            />
          )}

          <RightPanal
            setAccessory={setAccessory}
            accessory={accessory}
            tool={tool}
            setTool={setTool}
            setIsSelect={setIsSelect}
            survey={selectedSurvey}
            setLoading={setLoading}
            allSurvey={allSurvey}
            dynamic={dynamic}
            dynamicData={dynamicData}
            selectedProject={selectedProject}
            selectedSurvey={selectedSurvey}
            rData={rData}
            addDynamicLayer={addDynamicLayer}
            closeDynamic={closeDynamic}
            setCloseDynamic={setCloseDynamic}
            raw={raw}
            modal3D={modal3D}
          />

          <LeftPanal
            layers={layers}
            setLayers={setLayers}
            reports={reports}
            downloads={downloads}
            setAccessory={setAccessory}
            accessory={accessory}
            tool={tool}
            setTool={setTool}
            project={selectedProject}
            setLoading={setLoading}
            changeVisibilty={changeVisibilty}
            measurement={measurement}
          />

          <TopPanal
            allProject={allProject}
            selectedProject={selectedProject}
            onProjectChange={onProjectChange}
            allSurvey={allSurvey}
            selectedSurvey={selectedSurvey}
            onSurveyChange={onSurveyChange}
          />

          <BottomPanal
            dynamic={dynamic}
            setDynamic={setDynamic}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            setIsDeleteModal={setIsDeleteModal}
            setRow={setRow}
            layers={layers}
            deletelayer={deletelayer}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default SurveyMap;
