import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Loader from "../../components/CommonComponent/Loader";
import {
  createLogo,
  createSelectOption,
  makeRasterdata,
  prepareDynamicLayer,
  sortBy,
  vectorData,
} from "../Components/Utils";
import Map from "ol/Map.js";
import { ImageWMS, OSM } from "ol/source";
import { View } from "ol";
import { Image, Vector as VectorLayer } from "ol/layer";
import Swipe from "ol-ext/control/Swipe";
import "ol/ol.css";
import "ol-ext/control/Permalink.css";
import "ol-ext/control/Search.css";
import "ol-ext/control/Swipe.css";
import "../../styles/Compare.css";
import {
  getDynamicLayers,
  getReportsBySurveyID,
  getSourceid,
} from "../../api/dashboard.api";
import { layersList } from "../../store/layerreducer";
import { fromLonLat } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from "reactstrap";
import layerimg from "../../assets/svg-new/Path 24462.svg";
import homeimg from "../../assets/svg-new/home.svg";
import { useNavigate } from "react-router-dom";

const Comparison = () => {
  const mapElement = useRef();
  const mapRef = useRef();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const GEOSERVER_URL = process.env.REACT_APP_GEOSERVER_URL;
  const compareSurvey = useAppSelector((state) => state.compare);
  const storedLayers = useAppSelector((state) => state?.layers);
  const surveyOption = createSelectOption(compareSurvey, "surveyname");
  const client = useAppSelector((state) => state?.project?.client);
  const [layerState1, setLayerState1] = useState();
  const [layerState2, setLayerState2] = useState();
  const [layersState, setLayersState] = useState();
  const [loading, setLoading] = useState(false);
  const [map, setMap] = useState();
  const [control, setControl] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [selectAll2, setSelectAll2] = useState(false);
  const [isLayersDropdown, setIsLayersDropDown] = useState(false);
  const [isLayersDropdown2, setIsLayersDropDown2] = useState(false);
  const [lsurvey, setLsurvey] = useState(compareSurvey[0]);
  const [rsurvey, setRsurvey] = useState(compareSurvey[1]);
  mapRef.current = map;
  const [selectedLeftSurvey, setSelectedLeftSurvey] = useState({
    label: compareSurvey[0].surveyname,
    value: compareSurvey[0].surveyname,
  });

  const [selectedRightSurvey, setSelectedRightSurvey] = useState({
    label: compareSurvey[1].surveyname,
    value: compareSurvey[1].surveyname,
  });

  useEffect(() => {
    const comapremaps = async () => {
      setLoading(true);
      if (map) {
        deleteElevation(".ol-viewport");
      }
      let surveylData = await prepareMapData(lsurvey, true);
      let surveyrData = await prepareMapData(rsurvey, false);
      let vectorLayerData1 = makeVectorLayers(surveylData[2]?.vectorLayers);
      let vectorLayerData2 = makeVectorLayers(surveyrData[2]?.vectorLayers);
      const washingtonLonLat = lsurvey?.location;
      const washingtonWebMercator = fromLonLat(washingtonLonLat);
      const osm = new TileLayer({
        source: new OSM(),
      });
      const osm2 = new TileLayer({
        source: new OSM(),
      });
      const view = new View({
        center: washingtonWebMercator,
        zoom: 15,
      });
      let rawLayerData1 = surveylData[0];
      let rawLayerData2 = surveyrData[0];
      let layers1 = createLayers(rawLayerData1, surveylData[1]);
      let layers2 = createLayers(rawLayerData2, surveyrData[1]);
      layers1 = [...layers1, ...vectorLayerData1];
      layers2 = [...layers2, ...vectorLayerData2];
      const map1 = new Map({
        target: mapElement.current,
        layers: [osm, ...layers1, ...layers2],
        view: view,
      });
      setLayerState1(layers1);
      setLayerState2(layers2);
      let ctrl = new Swipe();
      layers1.map((layer) => {
        ctrl.addLayer(layer, false);
      });
      layers2.map((layer) => {
        ctrl.addLayer(layer, true);
      });
      map1.addControl(ctrl);
      setMap(map1);
      setLoading(false);
      setControl(ctrl);
    };
    comapremaps();
  }, [lsurvey, rsurvey]);

  const deleteElevation = (data) => {
    const boxes = document.querySelectorAll(data);
    if (boxes.length > 0) {
      boxes.forEach((box) => {
        box.remove();
      });
    }
  };

  const prepareMapData = async (survey, bool) => {
    let getSourceData = await getSourceid(survey?.id);
    getSourceData = await sortBy(getSourceData?.response, "priority");
    let rData = makeRasterdata(getSourceData);
    let getLayerDetails = rData[1];
    getLayerDetails = await sortBy(getLayerDetails, "priority");
    let getDynamicLayerData = await getDynamicLayers(survey?.id);
    let dynamicLayers = prepareDynamicLayer(
      getDynamicLayerData?.response?.rows
    );
    getLayerDetails = [...getLayerDetails, ...dynamicLayers];
    let getReports = await getReportsBySurveyID(survey?.id);
    let reports = [];
    if (getReports && getReports?.response?.rows) {
      reports = getReports?.response?.rows.filter((report) => report.key);
    }
    let params = { layers: getLayerDetails, reports: reports };
    if (bool) {
      dispatch(layersList(params.layers));
      // setMenuData(params)
    } else {
      let chk = params?.layers?.filter((e) => e.visibility === 0)?.length;
      chk > 0 ? setSelectAll2(false) : setSelectAll2(true);
      setLayersState(params);
    }
    return [...rData, { vectorLayers: getDynamicLayerData.response?.rows }];
  };

  const createLayers = (LayerData, Layers) => {
    let madeLayers = [];
    LayerData.map((Layer, id) => {
      let visibility = Layers[id]?.visibility == 1 ? true : false;
      let params = {};
      if (Layer?.source.type == "raster") {
        params = {
          FORMAT: "image/png8",
          LAYERS: Layer?.source?.sourcename, //"sf:sfdem",
        };
      } else {
        params = {
          LAYERS: Layer?.source?.sourcename, //"sf:sfdem",
        };
      }
      let createdLayer = new Image({
        //title: "Latest2",
        source: new ImageWMS({
          url: GEOSERVER_URL + Layer?.source.url,
          params: params,
          ratio: 1,

          // transparent : "true",
          attributions: "geoserver",
        }),
        visible: visibility,
      });
      madeLayers.push(createdLayer);
    });
    return madeLayers;
  };

  const onlsurveyChange = (val) => {
    let lselected = compareSurvey?.find((e) => e.surveyname === val.value);
    setLsurvey(lselected);
    setSelectedLeftSurvey(val);
  };

  const onrsurveyChange = (val) => {
    let rselected = compareSurvey?.find((e) => e.surveyname === val.value);
    setRsurvey(rselected);
    setSelectedRightSurvey(val);
  };

  const makeVectorLayers = (layer) => {
    let data = vectorData(layer);
    return data;
  };

  const toggleLayersDropdown = () => {
    setIsLayersDropDown(!isLayersDropdown);
  };

  const toggleLayersDropdown2 = () => {
    setIsLayersDropDown2(!isLayersDropdown2);
  };

  const makeLayerDropdown = (layer) => {
    return (
      <div className="mb-2 layer-dropdown pe-3">
        <div className="d-flex">
          <span className="me-3">
            <Input
              type="checkbox"
              checked={layer?.visibility}
              onChange={() => changeVisibilty(layer)}
            />
          </span>
          <div>{layer?.displayname}</div>
        </div>
        <div></div>
      </div>
    );
  };
  const makeLayerDropdown2 = (layer) => {
    return (
      <div className="mb-2 layer-dropdown pe-3">
        <div className="d-flex">
          <span className="me-3">
            <Input
              type="checkbox"
              checked={layer?.visibility}
              onChange={() => changeVisibilty2(layer)}
            />
          </span>
          <div>{layer?.displayname}</div>
        </div>
        <div></div>
      </div>
    );
  };

  const changeVisibilty2 = (layer) => {
    let ele = layersState?.layers;
    let data = ele.map((e) => {
      if (e.displayname === layer.displayname) {
        return { ...e, visibility: Number(!e?.visibility) };
      } else {
        return e;
      }
    });
    setLayersState({ ...layersState, layers: data });
  };

  const changeAllVisibilty2 = () => {
    let data;
    if (!selectAll2) {
      data = layersState?.layers?.map((e) => ({ ...e, visibility: 1 }));
    } else {
      data = layersState?.layers?.map((e) => ({ ...e, visibility: 0 }));
    }
    setLayersState({ ...layersState, layers: data });
    setSelectAll2(!selectAll2);
  };

  const changeVisibilty = (layer) => {
    let data = storedLayers?.map((e) => {
      if (e.displayname === layer.displayname) {
        return { ...e, visibility: Number(!e.visibility) };
      } else {
        return e;
      }
    });
    dispatch(layersList(data));
  };

  const changeAllVisibilty = () => {
    let data;
    if (!selectAll) {
      data = storedLayers?.map((e) => ({ ...e, visibility: 1 }));
    } else {
      data = storedLayers?.map((e) => ({ ...e, visibility: 0 }));
    }
    dispatch(layersList(data));
  };
  useEffect(() => {
    if (mapRef?.current != null && layerState2?.length > 0) {
      let count = 0;
      layerState2.map((layer, index) => {
        if (
          layersState?.layers[index]?.visibility === 1 ||
          layersState?.layers[index]?.visibility === true
        ) {
          count++;
          layer.setVisible(true);
        } else {
          layer.setVisible(false);
        }
      });
    }
  }, [layersState]);

  useEffect(() => {
    if (mapRef.current != null && storedLayers.length > 0) {
      let count = 0;
      layerState1.map((layer, index) => {
        if (
          storedLayers[index]?.visibility === 1 ||
          storedLayers[index]?.visibility === true
        ) {
          count++;
          layer.setVisible(true);
        } else {
          layer.setVisible(false);
        }
      });
    }
    let chk = storedLayers?.filter((e) => e.visibility === 0).length;
    chk > 0 ? setSelectAll(false) : setSelectAll(true);
  }, [storedLayers]);

  return (
    <div>
      {loading && <Loader loader={loading} />}
      <div
        ref={mapElement}
        className="map-container"
        style={{
          width: "100%",
          height: "100vh",
        }}
      ></div>
      <div
        className="compare-logo"
        onClick={() => navigate("/client-dashboard")}
      >
        {client?.clientLogo ? (
          <img
            src={client?.clientLogo}
            width={"70px"}
            height={"70px"}
            className="me-4 br-2"
          />
        ) : (
          <div className="avatar">{createLogo(client?.clientName)}</div>
        )}
      </div>
      <div className="left-select">
        <Input className="me-2 w-6" value={lsurvey?.sortDate} type="date" />
        <Select
          className="w-5"
          options={surveyOption}
          value={selectedLeftSurvey}
          onChange={onlsurveyChange}
        />
      </div>
      <div className="right-select">
        <Input className="me-2 w-7" value={rsurvey?.sortDate} type="date" />
        <Select
          className="w-5"
          options={surveyOption}
          value={selectedRightSurvey}
          onChange={onrsurveyChange}
        />
      </div>
      <div
        className="middle-icon pointer"
        onClick={() => {
          navigate(-1);
        }}
      >
        <img src={homeimg} alt="dashboard" />
      </div>
      <div className="bottom-panal">
        <Dropdown
          className="card-header-dropdown p-0 m-0"
          isOpen={isLayersDropdown}
          toggle={toggleLayersDropdown}
          direction="end"
        >
          <DropdownToggle
            tag="a"
            className="text-reset dropdown-btn"
            role="button"
          >
            <div className="bottom-layers">
              <img src={layerimg} alt="layerimg" />
              <div className="text-center">Drawn Layers</div>
            </div>
          </DropdownToggle>
          <DropdownMenu className="tools-menu-top1 p-0">
            <Card className="p-0 m-0 br-0 brd-none">
              <CardHeader className="d-flex align-items-center justify-content-between bg-primary br-0 m-0 ps-2 ms-0">
                <div className="text-light fs-6">
                  <span className="me-3">
                    <Input
                      type="checkbox"
                      checked={selectAll}
                      onChange={() => changeAllVisibilty()}
                    />
                  </span>
                  Layers
                </div>
                <i
                  className="bx bx-x fs-4 pointer text-light"
                  onClick={toggleLayersDropdown}
                ></i>
              </CardHeader>
              <CardBody className="p-2 max-height-100">
                <Row>
                  {storedLayers?.map((layer) => (
                    <Col sm={12}>{makeLayerDropdown(layer)}</Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="bottom-panal_1">
        <Dropdown
          className="card-header-dropdown p-0 m-0"
          isOpen={isLayersDropdown2}
          toggle={toggleLayersDropdown2}
          direction="end"
        >
          <DropdownToggle
            tag="a"
            className="text-reset dropdown-btn"
            role="button"
          >
            <div className="bottom-layers">
              <img src={layerimg} alt="layerimg" />
              <div className="text-center">Drawn Layers</div>
            </div>
          </DropdownToggle>
          <DropdownMenu className="tools-menu-top p-0">
            <Card className="p-0 m-0 br-0 brd-none">
              <CardHeader className="d-flex align-items-center justify-content-between bg-primary br-0 m-0 ps-2 ms-0">
                <div className="text-light fs-6">
                  <span className="me-3">
                    <Input
                      type="checkbox"
                      checked={selectAll2}
                      onChange={() => changeAllVisibilty2()}
                    />
                  </span>
                  Layers
                </div>
                <i
                  className="bx bx-x fs-4 pointer text-light"
                  onClick={toggleLayersDropdown2}
                ></i>
              </CardHeader>
              <CardBody className="p-2 max-height-100">
                <Row>
                  {layersState?.layers?.map((layer) => (
                    <Col sm={12}>{makeLayerDropdown2(layer)}</Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Comparison;
