import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import Breadcrum from "../../commons/Breadcrum";
import { addToast, getMode1, uniqBy } from "../../commons/common";
import Loader from "../../CommonComponent/Loader";
import * as deliverablesApi from "../../../api/global.api";
import { Dropdown } from "primereact/dropdown";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import PrimaryTable from "../../primaryTable/PrimaryTable";
import { Projects } from "../ProjectManagementSchema";
import { errorMsg, fileTypeArr, successMsg } from "../../commons/constant";
import { Tooltip } from "primereact/tooltip";
import AddDeliverables from "./AddDeliverable";
import UploadsModal from "./UploadsModal";
import DeleteDeliverable from "./DeleteDeliverable";
import { FileUploadDemo } from "../../commons/FileUpload";
import UnAuthDialog from "../../commons/UnAuthDialog";
import BreadCrumb from "../../Common/BreadCrumb";

const Deliverables = (props: any) => {
  let prevProjectRoute: any = sessionStorage.getItem("prevProjectRoute");
  let prevClientRoute: any = sessionStorage.getItem("prevRoute");
  let formType = getMode1(window.location.hash);
  let mode = formType.mode;
  let id = formType.id;

  const [activeIndex, setActiveIndex] = useState<any>("");
  const [inprogress, setInprogress] = useState(false);
  const [survey, setSurvey] = useState<any>();
  const [project, setProject] = useState<any>({});
  const [volumeAccordion, setVolumeAccordion] = useState(false);
  const [showVolumeUpload, setShowVolumeUpload] = useState(true);

  const fileTypes = [...fileTypeArr];
  const [spinner, setSpinner] = useState(false);
  const [surveys, setSurveys] = useState<any>([]);
  const [showAddReport, setShowAddReport] = useState(false);
  const [deliverables, setDeliverables] = useState<any>([]);
  const [data, setData] = useState<any>({});
  const [showAddDownload, setShowAddDownload] = useState(false);
  const reportColumns: any = Projects.REPORTS;
  const downloadColumns: any = Projects.DOWNLOADS;
  const [formState, setFormState] = useState<any>({});
  const [disableUpload, setDisableUpload] = useState(false);
  const [reportRows, setReportRows] = useState<any>([]);
  const [downloadRows, setDownloadRows] = useState<any>([]);
  const [header, setHeader] = useState("");
  const [showReportUpload, setShowReportUpload] = useState(false);
  const [fileData, setFileData] = useState<any>();
  const [totalDownloadRecords, setTotalDownloadRecords] = useState<number>();
  const [totalReportRecords, setTotalReportRecords] = useState<number>();
  const [totalVideoRecords, setTotalVideoRecords] = useState<number>();
  const [first, setFirst] = useState<any>({
    reports: 0,
    downloads: 0,
    videos: 0,
  });
  const [skip, setSkip] = useState<any>({
    reports: 0,
    downloads: 0,
    videos: 0,
  });
  const [pageRows, setPageRows] = useState<any>({
    reports: 10,
    downloads: 10,
    videos: 10,
  });
  const [pageNo, setPageNo] = useState<any>({ reports: 0, downloads: 0 });
  const [deleteType, setDeleteType] = useState<any>("");
  const [deleteRow, setDeleteRow] = useState<any>({});
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [filterDeliverables, setFilterDeliverables] = useState<any>({});
  const [propertyImageAccordion, setPropertyImageAccordion] = useState(false);
  const [upload, setUpload] = useState<any>({});
  const [showLogout, setShowLogout] = useState(false);
  const [showPropertyImageUpload, setShowPropertyImageUpload] = useState(false);
  const [propertyImageUploadInprogress, setPropertyImageUploadInprogress] =
    useState(false);
  const [type, setType] = useState("");
  const [UploadVideo, setUploadVideo] = useState<any>(false);
  const [videoUploadId, setVideoUploadId] = useState<any>(0);
  const [uploadedDetails, setUploadedDetails] = useState<any>([]);
  const uploadVideoColumn = Projects.Videos;

  useEffect(() => {
    setSpinner(true);
    getSurveys();
  }, []);

  const handleSurveyChange = (e: any) => {
    setActiveIndex("");
    setInprogress(false);
    setSurvey(e.value);
    setSpinner(true);

    let selectedSurvey = surveys.find((obj: any) => obj.id === e.value.id);

    deliverablesApi
      .get("project/find/" + selectedSurvey.projectId)
      .then((res: any) => {
        setProject(res.response);
      });

    getReports(selectedSurvey, "?page=0&limit=10");
    getDownloads(selectedSurvey, "?page=0&limit=10");
    getFilterOptions(selectedSurvey, "?page=0&limit=10");
    fetchVideoUploads(selectedSurvey.id, "&page=0&limit=10&type=video");

    if (!selectedSurvey?.fileName || selectedSurvey?.fileName === "") {
      setVolumeAccordion(false);
    } else if (selectedSurvey?.fileName?.toLowerCase() === "failed") {
      if (selectedSurvey?.fileName?.toLowerCase() === "failed")
        setInprogress(true);
      setVolumeAccordion(true);
      setShowVolumeUpload(true);
    } else if (selectedSurvey?.fileName?.toLowerCase() === "completed")
      setShowVolumeUpload(false);
    else if (selectedSurvey?.fileName?.toLowerCase() === "inprogress") {
      setShowVolumeUpload(false);
      setInprogress(true);
      getSurveyByID(selectedSurvey);
    }

    if (!selectedSurvey?.PropertyFileStatus) {
      setPropertyImageAccordion(false);
      setShowPropertyImageUpload(true);
    } else if (selectedSurvey?.PropertyFileStatus?.toLowerCase() === "failed") {
      if (selectedSurvey?.PropertyFileStatus?.toLowerCase() === "failed")
        setPropertyImageUploadInprogress(true);
      setPropertyImageAccordion(true);
      setShowPropertyImageUpload(true);
    } else if (
      selectedSurvey?.PropertyFileStatus?.toLowerCase() === "completed"
    )
      setShowPropertyImageUpload(false);
    else if (
      selectedSurvey?.PropertyFileStatus?.toLowerCase() === "inprogress"
    ) {
      setShowPropertyImageUpload(false);
      setPropertyImageUploadInprogress(true);
      getSurveyByID(selectedSurvey);
    }
  };

  const getSurveys = () => {
    deliverablesApi.get(`survey/get?projectId=${id}`).then((resp) => {
      if (resp && resp?.response?.rows) setSurveys(resp?.response?.rows);
      else if (resp === 401) setShowLogout(true);
      setSpinner(false);
    });
  };

  const getReports = (selectedSurvey: any, queryParams: any) => {
    deliverablesApi
      .get("report/get/" + selectedSurvey.id + queryParams)
      .then((resp: any) => {
        if (resp.response.rows) {
          resp.response.rows.map((row: any) => {
            row.fileName = row.fileName && row.key ? row.fileName : "-";
          });
          let volume = resp.response.rows.find(
            (row: any) => row.deliverableName === "Volume Assessment"
          );
          if (volume) setVolumeAccordion(true);
          let property = resp.response.rows.find(
            (row: any) => row.deliverableName === "Property Image"
          );
          if (property) setPropertyImageAccordion(true);
          setReportRows(
            resp.response.rows.filter(
              (row: any) =>
                row.deliverableName !== "Volume Assessment" &&
                row.deliverableName !== "Property Image"
            )
          );
          setTotalReportRecords(resp.response.totalCount);
          setSpinner(false);
        }
      });
  };

  const getFilterOptions = (selectedSurvey: any, queryParams: any) => {
    deliverablesApi
      .get("download/get/" + selectedSurvey.id + queryParams)
      .then((resp: any) => {
        if (resp.rows) {
          resp.rows.map((row: any) => {
            row.fileName = row.fileName ? row.fileName : "-";
          });
          let arr = uniqBy(
            resp.rows.filter(
              (row: any) =>
                row.deliverableName !== "Volume Assessment" &&
                row.deliverableName !== "Property Image"
            ),
            "deliverableName"
          );
          setFilterDeliverables(arr);
          setSpinner(false);
        }
      });
  };

  const getDownloads = (selectedSurvey: any, queryParams: any) => {
    deliverablesApi
      .get("download/get/" + selectedSurvey.id + queryParams)
      .then((resp: any) => {
        if (resp?.response?.rows) {
          resp?.response?.rows.map((row: any) => {
            row.fileName = row.fileName ? row.fileName : "-";
            row.fileType = row.fileType ? row.fileType : "-";
          });
          setDeliverables(resp?.response?.rows);
          setDownloadRows(
            resp?.response?.rows.filter(
              (row: any) =>
                row.deliverableName !== "Volume Assessment" &&
                row.deliverableName !== "Property Image"
            )
          );
          setTotalDownloadRecords(resp?.response?.totalCount);
          setSpinner(false);
        }
      });
  };

  const onAddReport = () => {
    setHeader("Add Report");
    setFileData("");
    setFormState({});
    setShowAddReport(true);
    setDisableUpload(false);
  };

  useEffect(() => {
    if (data.deliverable || data.filetype) {
      getDownloads(
        survey,
        "?surveyid=" +
          survey.surveyid +
          "&" +
          (data.deliverable ? "deliverableName=" + data.deliverable : "") +
          (data.filetype && data.deliverable ? "&" : "") +
          (data.filetype ? "fileType=" + data.filetype : "")
      );
    } else if (survey) {
      getDownloads(survey, "?page=0&limit=10");
    }
  }, [data]);

  const handleTabChange = (e: any) => {
    setActiveIndex(e.index);
  };

  const onAddDownload = () => {
    setHeader("Add Download");
    setFileData("");
    setFormState({});
    setShowAddDownload(true);
    setDisableUpload(false);
  };
  const handleChange = (e: any, type: string) => {
    setData({ ...data, [type]: e });
  };

  const handleAddClose = () => {
    setShowAddDownload(false);
    setShowAddReport(false);
    setUploadVideo(false);
    setShowReportUpload(false);
    setDisableUpload(false);
    setFileData("");
    setUpload({});
    setFormState({});
    setHeader("");
  };
  const handleAddNewChange = (e: any, type: string) => {
    let obj = { ...formState };
    setFormState({ ...obj, [type]: e });
  };
  const handleUploadChange = (e: any, type: string) => {
    setUpload({ ...upload, [type]: e });
  };

  const reportSave = () => {
    setShowReportUpload(false);
    setSpinner(true);
    let projectname = project.projectName?.replace(" ", "_");
    let data = new FormData();
    data.append("id", formState.id);
    data.append("projectname", projectname);
    data.append("file", fileData);
    deliverablesApi.put("files/reportupload", data).then((resp: any) => {
      if (resp.status?.toLowerCase() === "success") {
        setDisableUpload(false);
        addToast(successMsg.FILE_UPLOAD, "success");
        getReports(
          survey,
          "?page=" + pageNo.reports + "&limit=" + pageRows.reports
        );
      } else {
        getReports(
          survey,
          "?page=" + pageNo.reports + "&limit=" + pageRows.reports
        );
        addToast(resp.msg, "error");
      }
    });
  };
  const uploadSave = () => {
    setShowReportUpload(false);
    setSpinner(true);
    setDisableUpload(false);
    let data = new FormData();
    data.append("id", formState.id);
    data.append("fileType", upload.fileType?.replace(".", "")?.toUpperCase());
    data.append("file", fileData);
    setFileData("");
    setUpload({});
    deliverablesApi.put("files/downloadsUpload", data).then((resp: any) => {
      if (resp.status?.toLowerCase() === "success") {
        addToast(successMsg.FILE_UPLOAD, "success");
        getDownloads(
          survey,
          "?page=" + pageNo.downloads + "&limit=" + pageRows.downloads
        );
      } else {
        setSpinner(false);
        addToast(resp.msg, "error");
      }
    });
  };
  const onFileUpload = (file: any) => {
    setDisableUpload(true);
    setFileData(file);
  };
  const handleFileDelete = () => {
    setDisableUpload(false);
  };

  const handleSave = (type: string) => {
    setShowAddReport(false);
    setShowAddDownload(false);
    setUploadVideo(false);
    setSpinner(true);
    setDisableUpload(false);

    let url = "";

    let data: any = new FormData();
    let name = "";
    if (type === "report") {
      url = "reports/addReportWithUpload";
      if (fileData.name) {
        let type = fileData.name?.split(".").pop();
        name = fileData.name + "." + type;
      }
      data.append("deliverableName", formState.deliverableName);
      data.append("surveyId", survey.id);
      data.append("file", fileData);
      setFileData("");

      deliverablesApi.post(url, data).then((resp: any) => {
        setDisableUpload(false);
        if (resp.status?.toLowerCase() === "success") {
          addToast(successMsg.REPORT_ADD, "success");
          getReports(
            survey,
            "?page=" + pageNo.reports + "&limit=" + pageRows.reports
          );
          setSpinner(false);
          return;
        }
        addToast(errorMsg.REPORT_ADD, "error");
        setSpinner(false);
      });
    }
    if (type === "download") {
      // url = "downloaddeliverablesupload";
      // let type = "";
      // if (fileData.name) {
      //   type = fileData.name?.split(".").pop();
      //   name = fileData.name + "." + type;
      // }
      // let data = {
      //   surveyId: survey.id,
      //   surveyName: survey.surveyName,
      //   projectName: project.projectName.replace(/\s+/g, ""),
      //   deliverableName: formState.deliverableName,
      //   fileType: formState.fileType.replace(".", "").toUpperCase(),
      // };
      // setFileData("");
      // deliverablesApi.post(url, data).then((resp: any) => {
      //   setDisableUpload(false);
      //   if (resp.status?.toLowerCase() === "success") {
      //     addToast(successMsg.DOWNLOAD_ADD, "success");
      getDownloads(
        survey,
        "?page=" + pageNo.downloads + "&limit=" + pageRows.downloads
      );
      setSpinner(false);
      //     return;
      //   }
      //   addToast(errorMsg.DOWNLOAD_ADD, "error");
      //   setSpinner(false);
      // });
    }
    if (type === "video") {
      //   setSpinner(true);
      //   url = "videos/upload";
      //   let data = {
      //     surveyId: survey.id,
      //     surveyName: survey.surveyName,
      //     projectName: project.projectName.replace(/\s+/g, ""),
      //     videoName: formState.videoName,
      //     type: "video",
      //   };
      //   setFileData("");
      //   deliverablesApi.post(url, data).then((resp: any) => {
      //     if (resp?.status?.toLowerCase() == "success") {
      //       addToast("Video uploaded successfully", "success");
      fetchVideoUploads(survey.id, "&page=0&limit=10&type=video");
      //       setSpinner(false);
      //     } else {
      //       addToast("Error while uploading", "error");
      setSpinner(false);
      //     }
      //   });
    }
    setShowAddDownload(false);
    setFormState({});
  };

  const fetchVideoUploads = (surveyId: any, query: any) => {
    deliverablesApi
      .get(`getsurveyvideouploads?surveyId=${surveyId}${query}`)
      .then((resp: any) => {
        setUploadedDetails(resp?.response?.rows);
        setTotalVideoRecords(resp?.response?.totalCount);
        setSpinner(false);
      });
  };

  const handleSaveDisable = (type: string) => {
    if (type === "report") {
      if (
        !formState.deliverableName ||
        formState.deliverableName?.trim() === "" ||
        !disableUpload
      )
        return true;
    } else if (type === "download") {
      if (
        !formState.deliverableName ||
        formState.deliverableName === "" ||
        !formState.fileType ||
        formState.fileType === "" ||
        !disableUpload
      )
        return true;
      return false;
    } else {
      if (!formState.videoName || !disableUpload) return true;
      return false;
    }
  };
  const handleClick = (type: string, rowData: any) => {
    setFileData("");
    if (type === "report") {
      setHeader("Upload Report");
      setShowReportUpload(true);
      setFormState(rowData);
      setDisableUpload(false);
    }
    if (type === "download") {
      setHeader("Upload Download");
      setShowReportUpload(true);
      setFormState(rowData);
      setDisableUpload(false);
    }
  };
  const handleDelete = (type: string, rowData: any) => {
    setDeleteType(type);
    setDeleteRow(rowData);
    setDeleteConfirmation(true);
  };
  const handleDeleteConfirmation = () => {
    setSpinner(true);
    setDeleteConfirmation(false);
    if (deleteType === "report") {
      let url =
        (!deleteRow.defaultDeliverable && deleteRow.fileName === "-"
          ? "reports/delete/"
          : "files/deleteReport/") + deleteRow.id;
      deliverablesApi.get(url).then((res: any) => {
        if (res.status?.toLowerCase() === "success") {
          addToast(
            !deleteRow.defaultDeliverable && deleteRow.fileName === "-"
              ? successMsg.REPORT_DELETE
              : successMsg.FILE_DELETE,
            "success"
          );
          getReports(
            survey,
            "?page=" + pageNo.reports + "&limit=" + pageRows.reports
          );
          return;
        }
        addToast(errorMsg.REPORT_DELETE, "error");
      });
    }
    // Omitted in aws upload
    // if (deleteType === "download") {
    //   let url =
    //   (!deleteRow.defaultDeliverable && deleteRow.fileName === "-"
    //     ? "download/delete/"
    //     : "files/deleteDownload/") + deleteRow.id;
    //   deliverablesApi.get(url).then((res: any) => {
    //     if (res.status?.toLowerCase() === "success") {
    //       addToast(
    //         !deleteRow.defaultDeliverable && deleteRow.fileName === "-"
    //           ? successMsg.DOWNLOAD_DELETE
    //           : successMsg.FILE_DELETE,
    //         "success"
    //       );
    //       getDownloads(
    //         survey,
    //         "?page=" + pageNo.downloads + "&limit=" + pageRows.downloads
    //       );
    //     } else {
    //       addToast(errorMsg.DOWNLOAD_DELETE, "error");
    //     }
    //   });
    // }

    // Aws approach
    if (deleteType === "download") {
      let url = "download/delete/" + deleteRow.id;
      deliverablesApi.get(url).then((res: any) => {
        if (res.status?.toLowerCase() === "success") {
          addToast(successMsg.DOWNLOAD_DELETE, "success");
          getDownloads(
            survey,
            "?page=" + pageNo.downloads + "&limit=" + pageRows.downloads
          );
        } else {
          addToast(errorMsg.DOWNLOAD_DELETE, "error");
        }
      });
    }
  };

  const getSurveyByID = (selectedSurvey: any) => {
    let surveyData = selectedSurvey === "" ? survey : selectedSurvey;
    let url = "survey/getbyId/" + surveyData.surveyid;
    deliverablesApi
      .get(url)
      .then((resp) => {
        setSpinner(false);
        if (resp?.PropertyFileStatus?.toLowerCase() === "success") {
          setShowPropertyImageUpload(false);
          setPropertyImageUploadInprogress(false);
        } else if (resp?.PropertyFileStatus?.toLowerCase() === "inprogress") {
          setShowPropertyImageUpload(false);
          setPropertyImageUploadInprogress(true);
          setTimeout(() => {
            getSurveyByID(survey);
          }, 30000);
        } else if (
          !resp?.PropertyFileStatus ||
          resp?.PropertyFileStatus?.toLowerCase() === "failed"
        ) {
          setShowPropertyImageUpload(true);
        }
        if (resp?.fileName?.toLowerCase() === "completed") {
          setShowVolumeUpload(false);
          setInprogress(false);
        } else if (resp?.fileName?.toLowerCase() === "inprogress") {
          setShowVolumeUpload(false);
          setInprogress(true);
          setTimeout(() => {
            getSurveyByID(survey);
          }, 30000);
        } else if (
          !resp?.fileName ||
          resp?.fileName?.toLowerCase() === "failed"
        ) {
          setShowVolumeUpload(true);
        }
      })
      .catch((err) => {
        addToast(errorMsg.SURVEY_GET, "error");
        setSpinner(false);
      });
  };
  const onCSVUpload = (file: any) => {
    setSpinner(true);
    let data: any = new FormData();
    data.append("surveyID", survey.surveyid);
    data.append("projectID", survey.projectid);
    data.append("file", file);
    deliverablesApi.post("miningUpload", data).then((resp) => {
      setSpinner(false);
      if (resp.status?.toLowerCase() === "success") {
        setShowVolumeUpload(false);
        setInprogress(true);
        getSurveyByID(survey);
        addToast(successMsg.UPLOAD_CSV, "success");
        return;
      }
      addToast(errorMsg.UPLOAD_CSV, "error");
    });
  };
  const onZIPUpload = (file: any) => {
    setSpinner(true);
    let data: any = new FormData();
    data.append("surveyid", survey.surveyid);
    data.append("file", file);
    deliverablesApi.put("files/propertyImages", data).then((resp) => {
      setSpinner(false);
      getSurveyByID(survey);
      addToast(successMsg.UPLOAD_ZIP, "success");
    });
  };

  const handleCSVDelete = () => {
    setType("csv");
    setDeleteConfirmation(true);
  };
  const handleZIPDelete = () => {
    setType("zip");
    setDeleteConfirmation(true);
  };
  const onCSVDelete = () => {
    setSpinner(true);
    setType("");
    setDeleteConfirmation(false);
    deliverablesApi
      .post("measurement/delete", {
        surveyID: survey.surveyid?.toString(),
        projectID: id,
      })
      .then((resp) => {
        if (resp.status?.toLowerCase() === "failed") {
          addToast(resp.message, "error");
          return;
        }
        addToast(successMsg.DELETE_CSV, "success");
        getSurveyByID(survey);
      });
  };
  const onZIPDelete = () => {
    setSpinner(true);
    setType("");
    setDeleteConfirmation(false);
    deliverablesApi
      .get(`files/deletePropertyImages/${survey.surveyid}`)
      .then((resp) => {
        if (resp.status?.toLowerCase() === "failed") {
          addToast(resp.message, "error");
          return;
        }
        addToast(successMsg.DELETE_ZIP, "success");
        getSurveyByID(survey);
      });
  };
  const onPageChange = (event: any, type: string) => {
    let pageUrl = `?page=${event.page}&limit=${event.rows}`;
    setSpinner(true);
    if (type === "reports") getReports(survey, pageUrl);
    if (type === "downloads") getDownloads(survey, pageUrl);
    setPageNo({ ...pageNo, [type]: event.page });
    setPageRows({ ...pageRows, [type]: event.rows });
    setFirst({ ...first, [type]: event.first });
    setSkip({ ...skip, [type]: event.skip });
  };
  const deliverableHide = () => {
    setDeleteRow({});
    setDeleteType("");
    setType("");
    setDeleteConfirmation(false);
  };
  const reportActionTemplate = (rowData: any, column: any) => {
    return (
      <div className="actionupload">
        {rowData.fileName === "-" ? (
          <Button
            className="btn-style btn-primary h-40"
            onClick={() => handleClick("report", rowData)}
            label="Upload"
          />
        ) : (
          <>
            <Tooltip target=".delete-button" />
            <i
              className="pi pi-trash clr-primary"
              onClick={() => handleDelete("report", rowData)}
              data-pr-tooltip="Delete"
            />
          </>
        )}
        {!rowData.defaultDeliverable && rowData.fileName === "-" && (
          <>
            <Tooltip target=".delete-button" />
            <i
              className="pi pi-trash clr-primary m-l-10 m-b-10"
              onClick={() => handleDelete("report", rowData)}
              data-pr-tooltip="Delete"
            />
          </>
        )}
      </div>
    );
  };
  const downloadActionTemplate = (rowData: any, column: any) => {
    return (
      <div className="actionupload">
        {rowData.fileName === "-" ? (
          <Button
            className="btn-style btn-primary h-40"
            onClick={() => handleClick("download", rowData)}
            label="Upload"
          />
        ) : (
          <span className="m-l-10 m-t-10">
            <Tooltip target=".delete-button" />
            <i
              className="pi pi-trash clr-primary delete-button pointer"
              onClick={() => handleDelete("download", rowData)}
              data-pr-tooltip="Delete"
            />
          </span>
        )}
        {!rowData.defaultDeliverable && rowData.fileName === "-" && (
          <>
            <Tooltip target=".delete-button" />
            <i
              className="pi pi-trash clr-primary m-l-10 m-b-10"
              onClick={() => handleDelete("download", rowData)}
              data-pr-tooltip="Delete"
            />
          </>
        )}
      </div>
    );
  };
  if (reportColumns && reportColumns.length > 0) {
    reportColumns[reportColumns.length - 1].body = reportActionTemplate;
  }
  if (downloadColumns && downloadColumns.length > 0) {
    downloadColumns[downloadColumns.length - 1].body = downloadActionTemplate;
  }

  const onUploadVideo = () => {
    setHeader("Upload Video");
    setUploadVideo(true);
    setDisableUpload(false);
  };

  return (
    <>
      <Loader loader={spinner} />
      <div className="p-25 content">
        <ToastContainer />
        <div className="m-b-20">
          {/* <Breadcrum /> */}
          <BreadCrumb
            title="Deliverables"
            pageTitle={JSON.parse(prevProjectRoute)?.name}
            pageLink={JSON.parse(prevProjectRoute)?.link}
            pageTitle1={JSON.parse(prevClientRoute)?.name}
            pageLink1={JSON.parse(prevClientRoute)?.link}
          />
        </div>
        <div className="m-b-30">
          <label>
            Select Survey
            <span className="mandatory"> *</span>
          </label>
          <br></br>
          <Dropdown
            value={survey}
            options={surveys}
            optionLabel="surveyName"
            className="w-75 border ht-50"
            onChange={(e) => handleSurveyChange(e)}
            placeholder="Select a Survey"
            filter
            filterBy="surveyName"
          />
        </div>
        <div className="d-flex w-75">
          <Accordion
            activeIndex={activeIndex}
            onTabChange={(e) => handleTabChange(e)}
            className={"mt-3 w-100" + (!survey ? " disabled" : "")}
          >
            <AccordionTab header="Reports">
              <div className="m-b-20">
                <div className="text-right">
                  <Button
                    className=" btn-style btn-primary"
                    onClick={onAddReport}
                  >
                    <i className="pi pi-plus-circle m-r-10" />
                    Add Report
                  </Button>
                </div>
              </div>
              <PrimaryTable
                rows={reportRows}
                columns={reportColumns}
                totalRecords={totalReportRecords}
                first={first.reports}
                className="primary-table"
                paginator={reportRows?.length > 0 ? true : false}
                pageRows={pageRows.reports}
                count={skip.reports}
                lazyLoad
                onPageChange={(e: any) => onPageChange(e, "reports")}
                emptyMessage="No Reports Added"
              />
            </AccordionTab>
            <AccordionTab header="Downloads">
              <div className="m-b-20 row">
                <div className="col-9">
                  <div className="row">
                    <div className="col-1 bold m-t-10">Filters: </div>
                    {/* <div className="col-4">
                      <Dropdown
                        value={data.deliverable}
                        options={filterDeliverables}
                        optionLabel="deliverableName"
                        optionValue="deliverableName"
                        className="w-100 border ht-50"
                        onChange={(e) =>
                          handleChange(e.target.value, "deliverable")
                        }
                        placeholder="Select a Deliverable"
                      />
                    </div> */}
                    <div className="col-4">
                      <Dropdown
                        value={data.filetype}
                        options={fileTypes}
                        appendTo="self"
                        optionLabel="label"
                        optionValue="label"
                        className="w-100 border ht-50"
                        onChange={(e) =>
                          handleChange(e.target.value, "filetype")
                        }
                        placeholder="Select a File Type"
                      />
                    </div>
                    <div className="col-6">
                      <Button
                        className="btn-style btn-primary"
                        label="Clear Filter"
                        onClick={() => setData({})}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="text-right">
                    <Button
                      className=" btn-style btn-primary"
                      onClick={onAddDownload}
                    >
                      <i className="pi pi-plus-circle m-r-10" />
                      Add New Record
                    </Button>
                  </div>
                </div>
              </div>
              <PrimaryTable
                rows={downloadRows}
                columns={downloadColumns}
                totalRecords={totalDownloadRecords}
                first={first.downloads}
                className="primary-table"
                paginator={downloadRows?.length > 0 ? true : false}
                pageRows={pageRows.downloads}
                count={skip.downloads}
                lazyLoad
                onPageChange={(e: any) => onPageChange(e, "downloads")}
                emptyMessage="No Downloads Added"
              />
            </AccordionTab>
            <AccordionTab header="Videos">
              <div className="m-b-20">
                <div className="text-right">
                  <Button
                    className=" btn-style btn-primary"
                    onClick={onUploadVideo}
                  >
                    <i className="pi pi-plus-circle m-r-10" />
                    Upload Video
                  </Button>
                </div>
              </div>
              <PrimaryTable
                rows={uploadedDetails}
                columns={uploadVideoColumn}
                totalRecords={totalVideoRecords || 0}
                first={first.videos}
                className="primary-table"
                paginator={uploadedDetails?.length > 0 ? true : false}
                pageRows={pageRows.videos}
                count={skip.videos}
                lazyLoad
                onPageChange={(e: any) => onPageChange(e, "videos")}
                emptyMessage="No Videos Added"
              />
            </AccordionTab>
            {/* {volumeAccordion && (
              <AccordionTab header="Volume">
                {showVolumeUpload ? (
                  <>
                    {inprogress && (
                      <div className="red d-flex justify-content-center">
                        File upload failed. Please Re-Upload
                      </div>
                    )}
                    <div className="m-t-10">
                      <span>Upload CSV File:</span>
                      <FileUploadDemo
                        onChaneImage={onCSVUpload}
                        accept=".csv"
                        maxFileSize={5e9}
                        icon="excel"
                        disabled={props.disable}
                      />
                    </div>
                  </>
                ) : (
                  <div className="row w-100">
                    <div className="col-8 m-t-10">
                      {inprogress ? "CSV upload is inprogress" : "CSV uploaded"}
                    </div>
                    {!inprogress && (
                      <div className="col-4 d-flex justify-content-end">
                        <Button
                          className=" btn-style btn-primary"
                          onClick={handleCSVDelete}
                        >
                          Delete CSV File
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </AccordionTab>
            )}
            {propertyImageAccordion && (
              <AccordionTab header="Property Image">
                {showPropertyImageUpload ? (
                  <>
                    {propertyImageUploadInprogress && (
                      <div className="red d-flex justify-content-center">
                        File upload failed. Please Re-Upload
                      </div>
                    )}
                    <div className="m-t-10">
                      <span>Upload ZIP File:</span>
                      <FileUploadDemo
                        onChaneImage={onZIPUpload}
                        value={""}
                        accept=".zip"
                        maxFileSize={5e9}
                        icon="zip"
                        disabled={props.disable}
                      />
                    </div>
                  </>
                ) : (
                  <div className="row w-100">
                    <div className="col-8 m-t-10">
                      {propertyImageUploadInprogress
                        ? "ZIP upload is inprogress"
                        : "ZIP uploaded"}
                    </div>
                    {!propertyImageUploadInprogress && (
                      <div className="col-4 d-flex justify-content-end">
                        <Button
                          className=" btn-style btn-primary"
                          onClick={handleZIPDelete}
                        >
                          Delete ZIP File
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </AccordionTab>
            )} */}
          </Accordion>
        </div>
      </div>
      {(showAddDownload || showAddReport || UploadVideo) && (
        <AddDeliverables
          visible={showAddReport || showAddDownload || UploadVideo}
          onHide={handleAddClose}
          header={header}
          data={formState}
          survey={survey}
          project={project}
          handleChange={handleAddNewChange}
          onFileUpload={onFileUpload}
          disableUpload={disableUpload}
          handleFileDelete={handleFileDelete}
          handleSave={handleSave}
          handleSaveDisable={handleSaveDisable}
          fileTypes={fileTypes}
          deliverables={filterDeliverables}
        />
      )}

      {showReportUpload && (
        <UploadsModal
          visible={showReportUpload}
          onHide={handleAddClose}
          header={header}
          data={formState}
          onFileUpload={onFileUpload}
          disable={disableUpload}
          handleFileDelete={handleFileDelete}
          disableReportSave={!disableUpload}
          disableDownloadSave={
            !disableUpload || !upload.fileType || upload.fileType === ""
          }
          reportSave={reportSave}
          fileTypes={fileTypes}
          handleChange={handleAddNewChange}
          uploadSave={uploadSave}
          handleUploadChange={handleUploadChange}
          upload={upload}
        />
      )}
      {deleteConfirmation && (
        <DeleteDeliverable
          visible={deleteConfirmation}
          onHide={deliverableHide}
          header={"Delete confirmation"}
          data={deleteRow}
          type={type}
          handleSave={
            type === "csv"
              ? onCSVDelete
              : type === "zip"
              ? onZIPDelete
              : handleDeleteConfirmation
          }
        />
      )}
      {showLogout && (
        <UnAuthDialog
          visible={showLogout}
          onHide={() => setShowLogout(false)}
        />
      )}
    </>
  );
};

export default Deliverables;
