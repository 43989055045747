import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

import DashboardChart from "./DashboardChart";
import DashboardCard from "./DashboardCard";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { checkFeatures } from "../../CommonComponent/CommonFunctions";
import { Header } from "../../header/Header";
import Loader from "../../CommonComponent/Loader";

const AssetDashboard = () => {
  const navigate = useNavigate();
  const siteObj = JSON.parse(sessionStorage.getItem("site"));
  const userPriv = useAppSelector((state) => state.user)?.selectedFeatures;
  const [loading, setLoading] = useState(false)

  return (
    <div className="page-content ps-0">
      <Loader loader={loading} />
      <Header/>
      <div className="content pt-0 pb-4 ps-5 pe-5">
        <div className="py-4 fs-18">
          <span
            className="pointer primary"
            onClick={() => navigate("/client-dashboard")}
          >
            <i className=" ri-arrow-left-s-line fs-20 align-bottom "></i>
            Dashboard /&nbsp;
          </span>
          <span className="breadcrumb-site-name"> {siteObj?.projectName} </span>
        </div>
        {checkFeatures("Analytical Dashboard - Oil and Gas", userPriv) && (
          <Row className="mb-4">
            <Col lg={12}>
              <DashboardChart setLoading={setLoading} loading={loading} />
            </Col>
          </Row>
        )}

          <Row>
            <Col lg={12}>
              <DashboardCard  setLoader={setLoading} loader={loading}/>
            </Col>
          </Row>

      </div>
    </div>
  );
};

export default AssetDashboard;
