import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const pageDataSlice = createSlice({
  name: "pageData",
  initialState,
  reducers: {
    pageData: (state, action) => {
      return action.payload;
    },
  },
});

export const { pageData } = pageDataSlice.actions;
export default pageDataSlice.reducer;
