import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectsArr: {},
};

export const userSlice = createSlice({
  name: "projectsArr",
  initialState,
  reducers: {
    projectsArrTxn: (state, action) => {
      return action.payload;
    },
  },
});

export const { projectsArrTxn } = userSlice.actions;
export default userSlice.reducer;
