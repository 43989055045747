import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  Capitalize,
  numberRegex,
  alphabets,
  coodinateRegex,
} from "../../commons/functions";
import * as Yup from "yup";
import Select from "react-select";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import * as api from "../../../api/global.api";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../CommonComponent/Loader";

const CreateSource = (props: any) => {
  const id = useParams().id;

  const [loading, setLoading] = useState<any>(false);
  const [towerOption, setTowerOption] = useState<any>([
    { label: "AC", value: "AC" },
    { label: "DC", value: "DC" },
  ]);
  const [towerTypeOption, setTowerTypeOption] = useState<any>([
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "A+0", value: "A+0" },
    { label: "A+3", value: "A+3" },
    { label: "A+6", value: "A+6" },
    { label: "A+9", value: "A+9" },
    { label: "A+18", value: "A+18" },
    { label: "A+25", value: "A+25" },
    { label: "A+31", value: "A+31" },
    { label: "B+0", value: "B+0" },
    { label: "B+3", value: "B+3" },
    { label: "B+6", value: "B+6" },
    { label: "B+9", value: "B+9" },
    { label: "C+0", value: "C+0" },
    { label: "C+3", value: "C+3" },
    { label: "C+6", value: "C+6" },
    { label: "C+9", value: "C+9" },
    { label: "D+0", value: "D+0" },
    { label: "D+3", value: "D+3" },
    { label: "D+6", value: "D+6" },
    { label: "D+9", value: "D+9" },
    { label: "D+18", value: "D+18" },
    { label: "D+25", value: "D+25" },
    { label: "D+31", value: "D+31" },
    { label: "D-4.5", value: "D-4.5" },
  ]);
  const [structureOption, setStructureOption] = useState<any>([
    { label: "Monopole", value: "Monopole" },
    { label: "Lattice", value: "Lattice" },
  ]);
  const [phaseOption, setPhaseOption] = useState<any>([
    { label: "R", value: "R" },
    { label: "Y", value: "Y" },
    { label: "B", value: "B" },
  ]);
  const [circuitOption, setCircuitOption] = useState<any>([]);

  const [selectedTower, setSelectedTower] = useState<any>(null);
  const [selectedTowerType, setSelectedTowerType] = useState<any>(null);
  const [selectedStructure, setSelectedStructure] = useState<any>(null);
  const [selectedPhase, setSelectedPhase] = useState<any>(null);
  const [selectedCircuit, setSelectedCircuit] = useState<any>(null);

  const handleTower = (val: any) => {
    setSelectedTower(val);
    formik.setFieldValue("towerCategory", val.label);
  };
  const handleTowerType = (val: any) => {
    setSelectedTowerType(val);
    formik.setFieldValue("towerType", val.label);
  };
  const handleStructure = (val: any) => {
    setSelectedStructure(val);
    formik.setFieldValue("structure", val.label);
  };
  // const handlePhase = (val: any) => {
  //   setSelectedPhase(val);
  //   formik.setFieldValue("phase", val.label);
  // };
  const handleCircuit = (val: any) => {
    setSelectedCircuit(val);
    formik.setFieldValue("circuit", val.label);
  };
  const handleAcceptedFiles = (files: any) => {
    let ext = files[0]?.path?.split(".")[1]?.toLowerCase();
    if (ext === "jpg" || ext === "png" || ext === "jpeg") {
      files.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: file.size,
        })
      );
      const formData = new FormData();
      formData.append("file", files[0]);
      setLoading(true);
      api
        .post("fileUpload", formData)
        .then((res: any) => {
          if (res?.status === "Failed") {
            formik.setFieldValue("coverImage", null);
            props.setLoading(false);
          } else {
            formik.setFieldValue("coverImage", res?.url);
            setLoading(false);
          }
        })
        .catch((err) => {
          formik.setFieldValue("coverImage", null);
          props.setLoading(false);
        });
    } else {
      toast.error("Please upload .jpg, .png or .jpeg file only");
    }
  };

  const formik = useFormik({
    initialValues: {
      lineName: "",
      towerNo: "",
      towerCategory: null,
      towerType: null,
      voltage: "",
      structure: null,
      location: "",
      // phase: null,
      circuit: null,
      angleOfDeviation: "",
      span: "",
      coverImage: null,
      towerRef: "",
    },
    validationSchema: Yup.object({
      lineName: Yup.string().required("Required"),
      towerNo: Yup.string()
        .matches(/^[1-9][0-9]*$/, "Please enter valid number")
        .required("Required"),
      towerCategory: Yup.string().required("Required"),
      towerType: Yup.string().required("Required"),
      voltage: Yup.string()
        .matches(/^[1-9][0-9]*$/, "Please enter valid number")
        .required("Required"),
      structure: Yup.string().required("Required"),
      location: Yup.string()
        .matches(coodinateRegex, "Please enter valid location")
        .required("Required"),
      // phase: Yup.string().required("Required"),
      circuit: Yup.string().required("Required"),
      // angleOfDeviation: Yup.string()
      //   .matches(/^[1-9][0-9]*$/, "Please enter valid number")
      //   .notRequired(),

      span: Yup.string()
        .matches(
          /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/,
          "Please enter valid number"
        )
        .required("Required"),
      coverImage: Yup.string().notRequired().nullable(),
    }),
    onSubmit: (values) => {
      let obj = {};
      if (props.mode === "Add Tower") {
        obj = { ...values, corridorId: Number(id) };
      } else {
        obj = {
          ...values,
          corridorId: Number(id),
          id: Number(props?.editData?.id),
        };
      }
      props?.mode === "Add Tower" ? addTowerApi(obj) : updateTowerApi(obj);
    },
  });

  const addTowerApi = (data: any) => {
    setLoading(true);
    api.post("addTower", data).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        props?.getTowers(1);
        setLoading(true);
        toast.success("Tower added successfully");
        props.setOpen(false);
      } else {
        setLoading(true);
        toast.error(res?.errormessage);
        props.setOpen(false);
      }
    });
  };

  const updateTowerApi = (data: any) => {
    setLoading(true);
    api.put("updateTower", data).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        props?.getTowers(1);
        setLoading(false);
        props.setOpen(false);
        toast.success("Tower updated successfully");
        props.handleEdit(null);
      } else {
        setLoading(false);
        toast.error(res?.errormessage);
        props.setOpen(false);
        props.handleEdit(null);
      }
    });
  };

  useEffect(() => {
    if (props.mode === "Edit Tower") {
      formik.setFieldValue("lineName", props?.editData?.lineName);
      formik.setFieldValue("towerNo", props?.editData?.towerNo);
      formik.setFieldValue("towerCategory", props?.editData?.towerCategory);
      formik.setFieldValue("towerType", props?.editData?.towerType);
      formik.setFieldValue("voltage", props?.editData?.voltage);
      formik.setFieldValue("structure", props?.editData?.structure);
      formik.setFieldValue("location", props?.editData?.location);
      // formik.setFieldValue("phase", props?.editData?.phase);
      formik.setFieldValue("circuit", props?.editData?.circuit);
      formik.setFieldValue(
        "angleOfDeviation",
        props?.editData?.angleOfDeviation
      );
      formik.setFieldValue("span", props?.editData?.span);
      formik.setFieldValue("coverImage", props?.editData?.coverImage);
      setSelectedTower({
        label: props?.editData?.towerCategory,
        value: props?.editData?.towerCategory,
      });
      setSelectedTowerType({
        label: props?.editData?.towerType,
        value: props?.editData?.towerType,
      });
      setSelectedStructure({
        label: props?.editData?.structure,
        value: props?.editData?.structure,
      });
      setSelectedPhase({
        label: props?.editData?.phase,
        value: props?.editData?.phase,
      });
      setSelectedCircuit({
        label: props?.editData?.circuit,
        value: props?.editData?.circuit,
      });
    }
    api.get("getEnums?enumType=TOWER_CIRCUIT").then((res: any) => {
      if (res) {
        let data = res?.appEnum?.map((item: any) => {
          return { label: item?.enumValue, value: item?.enumValue };
        });
        setCircuitOption(data);
      }
    });
  }, []);

  return (
    <>
      <Loader loader={loading} />
      <ToastContainer />
      <Modal isOpen={props?.open}>
        <ModalHeader>
          <span className="mb-2 text-light">{props?.mode}</span>
          <span
            className="cross mb-2 text-light"
            onClick={() => {
              props?.setOpen(false);
              props.handleEdit(null);
            }}
          >
            X
          </span>
        </ModalHeader>
        <Form
          onSubmit={(e: any) => {
            e.preventDefault();
            formik.handleSubmit();
            return;
          }}
        >
          <ModalBody>
            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <Label htmlFor="lineName" className="form-label text-muted">
                    Line Name
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="lineName"
                    name="lineName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lineName}
                    invalid={
                      formik.touched.lineName && formik.errors.lineName
                        ? true
                        : false
                    }
                  />
                  {formik.touched.lineName && formik.errors.lineName ? (
                    <FormFeedback type="invalid">
                      {formik.errors.lineName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Label htmlFor="towerNo" className="form-label text-muted">
                    Tower Number
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="towerNo"
                    name="towerNo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={Capitalize(formik.values.towerNo) || ""}
                    invalid={
                      formik.touched.towerNo && formik.errors.towerNo
                        ? true
                        : false
                    }
                  />
                  {formik.touched.towerNo && formik.errors.towerNo ? (
                    <FormFeedback type="invalid">
                      {formik.errors.towerNo}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>{" "}
              <Col md={4}>
                <div className="mb-3">
                  <Label className="form-label text-muted">
                    Tower Category
                  </Label>
                  <span className="mandatory"> *</span>
                  <Select
                    options={towerOption}
                    value={selectedTower}
                    onChange={(val) => handleTower(val)}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Label className="form-label text-muted">Tower Type</Label>
                  <span className="mandatory"> *</span>
                  <Select
                    options={towerTypeOption}
                    value={selectedTowerType}
                    onChange={(val) => handleTowerType(val)}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Label htmlFor="voltage" className="form-label text-muted">
                    Voltage (in kV)
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="voltage"
                    name="voltage"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.voltage || ""}
                    invalid={
                      formik.touched.voltage && formik.errors.voltage
                        ? true
                        : false
                    }
                  />
                  {formik.touched.voltage && formik.errors.voltage ? (
                    <FormFeedback type="invalid">
                      {formik.errors.voltage}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Label className="form-label text-muted">Structure</Label>
                  <span className="mandatory"> *</span>
                  <Select
                    options={structureOption}
                    value={selectedStructure}
                    onChange={(val) => handleStructure(val)}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Label htmlFor="location" className="form-label text-muted">
                    Location (Lat,Long)
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="location"
                    name="location"
                    value={formik.values.location || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched.location && formik.errors.location
                        ? true
                        : false
                    }
                  />
                  {formik.touched.location && formik.errors.location ? (
                    <FormFeedback type="invalid">
                      {formik.errors.location}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              {/* <Col md={4}>
                <div className="mb-3">
                  <Label className="form-label text-muted">Phase</Label>
                  <span className="mandatory"> *</span>
                  <Select
                    options={phaseOption}
                    value={selectedPhase}
                    onChange={(val) => handlePhase(val)}
                  />
                </div>
              </Col> */}
              <Col md={4}>
                <div className="mb-3">
                  <Label className="form-label text-muted">Circuit</Label>
                  <span className="mandatory"> *</span>
                  <Select
                    options={circuitOption}
                    value={selectedCircuit}
                    onChange={(val) => handleCircuit(val)}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Label htmlFor="location" className="form-label text-muted">
                    Angle of Deviation
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="angleOfDeviation"
                    name="angleOfDeviation"
                    value={formik.values.angleOfDeviation || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched.angleOfDeviation &&
                      formik.errors.angleOfDeviation
                        ? true
                        : false
                    }
                  />
                  {formik.touched.angleOfDeviation &&
                  formik.errors.angleOfDeviation ? (
                    <FormFeedback type="invalid">
                      {formik.errors.angleOfDeviation}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Label htmlFor="location" className="form-label text-muted">
                    Span
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="span"
                    name="span"
                    value={formik.values.span || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched.span && formik.errors.span ? true : false
                    }
                  />
                  {formik.touched.span && formik.errors.span ? (
                    <FormFeedback type="invalid">
                      {formik.errors.span}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Label htmlFor="profilePic" className="form-label">
                    Upload
                  </Label>
                  {formik.values.coverImage === null && (
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone dz-clickable form-control p-0">
                          <div className="needsclick" {...getRootProps()}>
                            <input {...getInputProps()} accept="images/*" />
                            <div className="mx-4 d-flex align-items-center">
                              <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />
                              <span className="text-muted fs-13   ">
                                Upload Image
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  {formik.values.coverImage !== null && (
                    <Card>
                      <div className="d-flex">
                        <img
                          data-dz-thumbnail=""
                          height="70"
                          className="avatar-sm rounded bg-light"
                          alt={"img"}
                          src={formik.values.coverImage}
                        />
                        <span className="p-3">Tower Image</span>
                        <i
                          className="ri-delete-bin-6-line fs-3  pointer red p-2"
                          onClick={() =>
                            formik.setFieldValue("coverImage", null)
                          }
                        ></i>
                      </div>
                    </Card>
                  )}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ width: "100px" }}
              onClick={() => {
                props.setOpen(false);
                props.handleEdit(null);
              }}
              color="success"
              outline
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" style={{ width: "100px" }}>
              {props.editData === null ? "Save" : "Update"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default CreateSource;
