import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Input } from "reactstrap";
import Pagination from "../commons/pagination";

const PrivilegeDataTable = ({
  data,
  handleDeleteClickId,
  handleOpenModal,
  editRoleData,
}) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(data?.length);
  const [newData, setNewData] = useState(data.slice(0, 5));

  useEffect(() => {
    setTotalCount(data.length);
    let id = (currentPage - 1) * 5;
    setNewData(data.slice(id, id + 5));
  }, [data]);

  const handlePageChange = (val) => {
    setCurrentPage(val);
    let id = (val - 1) * 5;
    setNewData(data.slice(id, id + 5));
  };

  useEffect(() => {
    let arr = data?.map((ele) => {
      return ele?.isSelected ? ele?.id : null;
    });

    setSelectedRow(arr);
  }, [data]);

  const handleSelect = (val) => {
    let result = [...selectedRow];
    let idx = result.indexOf(val.id);

    if (idx > -1) {
      result.splice(idx, 1);
      setSelectedRow(result);
      val.isSelected = false;
    } else {
      setSelectedRow([...selectedRow, val.id]);
      val.isSelected = true;
    }
  };

  const handleSelectAll = () => {
    let result = [];
    setCheckedAll(!checkedAll);
    if (!checkedAll) {
      data.map((ele) => result.push(ele.id));
      setSelectedRow(result);
    } else {
      setSelectedRow([]);
    }
  };

  const handleDeleteClick = (row) => {
    handleDeleteClickId(row);
  };

  const handleEditClick = (row) => {
    handleOpenModal(true);
    editRoleData(row);
  };
  const columns = [
    {
      name: (
        <>
          <Input
            className="form-check-input fs-15 me-5"
            type="checkbox"
            name="checkAll"
            value="option1"
            onChange={() => handleSelectAll()}
          />
          <span className="font-weight-bold fs-14">Role ID</span>
        </>
      ),
      selector: (row) => {
        return (
          <>
            <input
              className="form-check-input fs-15 me-5"
              checked={selectedRow.includes(row.id)}
              type="checkbox"
              name="checkAll"
              value="option1"
              onChange={() => handleSelect(row)}
            />
            <span>{row.id}</span>
          </>
        );
      },
      sortable: true,
      width: "200px",
    },

    {
      name: <span className="font-weight-bold fs-14">Role Type</span>,
      selector: (row) => row.roleType,
      sortable: true,
      width: "300px",
    },
    {
      name: <span className="font-weight-bold fs-14">Role Name</span>,
      selector: (row) => row.roleName,
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="font-weight-bold fs-14">Module Name</span>,
      selector: (row) => {
        let arr =
          row?.privilliges?.create?.map((ele) => ele.subModule) ||
          row?.privilliges?.map((ele) => ele.subModule);
        return <span>{arr.join(", ")}</span>;
      },
      sortable: true,
      width: "200px",
    },

    {
      name: <span className="font-weight-bold fs-14">Role Status</span>,
      selector: (row) => row.roleStatus,
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="font-weight-bold fs-14">Action</span>,
      sortable: true,
      width: "300px",
      cell: (row) => {
        return (
          <span>
            <Button
              onClick={() => handleEditClick(row)}
              disabled={!row.isSelected && row.roleStatus === "Default"}
              style={{
                border: "none",
                background: "none",
                pointer: row.isSelected ? "cursor" : "none",
              }}
            >
              <i
                className="bx bx-edit fs-3 pointer"
                style={{ color: "#2AA992" }}
                disabled={true}
              ></i>
            </Button>

            {row.roleStatus !== "Default" && (
              <Button
                onClick={() => handleDeleteClick(row)}
                style={{
                  border: "none",
                  background: "none",
                }}
              >
                <i
                  className="ri-delete-bin-6-line fs-3  pointer"
                  style={{ color: "red" }}
                ></i>
              </Button>
            )}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ height: "36vh" }}>
        <DataTable
          columns={columns}
          data={newData}
          striped
          noDataComponent="No Data Found"
        />
      </div>
      {data.length > 5 && (
        <Pagination
          itemsPerPage={5}
          count={totalCount}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default PrivilegeDataTable;
