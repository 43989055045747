import React, { useEffect, useState } from "react";
import * as api from "../../../api/global.api";
import { error } from "console";
import { ToastContainer } from "react-toastify";
import { Container, Row, Col, Card, CardBody, CardFooter } from "reactstrap";
import Loader from "../../CommonComponent/Loader";
import { formatDate } from "../../commons/functions";
import { center } from "@turf/turf";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../header/Header";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { transmissionLinesTsx } from "../../../store/transmissionLinesStore";
import CorridorCover from "../../../assets/images/logo-sm.png";
//import { projectTsx } from "../../../store/projectreducer";
import Pagination from "../../commons/pagination";

const PilotDashboard = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<any>(false);
  const [tableData, setTableData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>();
  const [corridorLogo, setCorridorLogo] = useState<any>();
  const [stateImage, setStateImage] = useState(false);
  const [search, setSearch] = useState<any>("");
  const projectId = useParams().projectId;

  const [pageNumber, setPageNumber] = useState<any>(0);
  const [date, setDate] = useState(null);
  const maxDate = new Date();
  const navigate = useNavigate();

  const getAllCorridors = (val: any) => {
    //  console.log(projectId, "corrdor project")
    setLoading(true);
    api
      .get(
        `getCorridor/?projectId=${projectId}&towerStatus=Yet To Start,Data Captured,Inspection Done,In Progress,Completed,Save As Draft`
      )
      .then((res) => {
        setCorridorLogo(res.response.rows[0].sources.project.client.clientLogo);
        setTableData(res.response.rows);
        setTotalCount(res?.response?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handlePageChange = (val: any) => {
    setPageNumber(val - 1);
    getAllCorridors(`page=${val - 1}&limit=12`);
  };
  useEffect(() => {
    getAllCorridors(`page=0&limit=12`);
  }, []);

  useEffect(() => {
    let delayDebounce = setTimeout(() => {
      getAllCorridors(`search=${search}`);
    }, 1000);
    return () => clearTimeout(delayDebounce);
  }, [search]);

  const handleClick = (corridor: any) => {
    // inspectionfunction(project.subModule);
    dispatch(transmissionLinesTsx(corridor));
    navigate(`/pilot-dashboard/${corridor.id}`);
  };
  //console.log(tableData, "table data")
  return (
    <div className="content oy">
      <Loader loader={loading} />
      <ToastContainer />
      <Container fluid>
        <br />
        <div
          onClick={() => navigate(`/pilot-projects`)}
          className="fs-20 py-4 primaryColor pointer"
        >
          <i className=" ri-arrow-left-circle-line fs-22 me-2 align-bottom "></i>
          Back
        </div>
        <div className={tableData?.length < 9 ? "contentarea2" : ""}>
          {tableData.length > 0 ? (
            <Row>
              {tableData?.map((ele: any, index: any) => {
                return (
                  <Col xxl={4} md={6} sm={12} className="project-card">
                    <Card className="card-height-100 custom-card">
                      <CardBody
                        className="cardbody-client"
                        style={{
                          paddingTop: "30px",
                          paddingBottom: "20px",
                        }}
                        onClick={(e) => handleClick(ele)}
                      >
                        <div className="d-flex mb-2">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm">
                              <span
                                className={"avatar-title rounded p-2 bg-soft-"}
                              >
                                <img
                                  src={
                                    corridorLogo === ""
                                      ? CorridorCover
                                      : corridorLogo
                                  }
                                  alt=""
                                  className="avatar-sm"
                                />
                              </span>
                            </div>
                          </div>
                          <div className="flex-grow-1 card-head">
                            <h5 className="card-title">
                              {`${ele?.corridorName}`}
                            </h5>
                            <i className="mdi mdi-google-maps me-1 align-bottom fs-13"></i>
                            <span className="text-muted card-address">
                              {ele.address}
                            </span>
                          </div>
                        </div>

                        <div>
                          <Row className="mt-3">
                            <Col lg={4} xs={4} sm={4}>
                              <h5
                                className="mb-1 card-count-title"
                                style={{ fontSize: "14px" }}
                              >
                                Total No.Of Towers
                              </h5>
                              <span style={{ color: "#30B9CC" }}>
                                {ele.totalTowers || 0}
                              </span>
                            </Col>
                            <Col>
                              <h5
                                className="mb-1 card-count-title"
                                style={{ fontSize: "14px" }}
                              >
                                Completed
                              </h5>
                              <span style={{ color: "#39C717" }}>
                                {ele.completedTowers || 0}
                              </span>
                            </Col>
                            <Col>
                              <h5
                                className="mb-1 card-count-title"
                                style={{ fontSize: "14px" }}
                              >
                                Pending
                              </h5>
                              <span style={{ color: "#CC3042" }}>
                                {ele.inProgressTowers || 0}
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>

                      <CardFooter className="cardfooter-client">
                        <Row>
                          <Col>
                            <div className="text-muted">
                              <i className="ri-calendar-event-fill me-1 align-bottom fs-6"></i>{" "}
                              {formatDate(ele.createdAt)}
                            </div>
                          </Col>
                          <Col className="me-1">
                            <h6 className="mb-1 fs-15 ">
                              Status :{" "}
                              <span
                                className="badge status_text fs-13"
                                style={{
                                  color:
                                    ele.corridorStatus === "Inspection Done"
                                      ? "#FF6402"
                                      : ele.corridorStatus === "In Progress"
                                      ? "#D6AC20"
                                      : ele.corridorStatus === "Yet To Start"
                                      ? "#A5ABAC"
                                      : ele.corridorStatus === "Completed"
                                      ? "#13C56B"
                                      : ele.corridorStatus === "Data Captured"
                                      ? "#30B9CC"
                                      : "",
                                }}
                              >
                                {ele.corridorStatus}
                              </span>
                            </h6>
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <div className="text-center">
              <h4>Corridors data not available</h4>
            </div>
          )}
        </div>
      </Container>
      {totalCount > 9 && (
        <Pagination
          itemsPerPage={9}
          count={totalCount}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default PilotDashboard;
