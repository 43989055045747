import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../commons/functions";
import { InputSwitch } from "primereact/inputswitch";

const AssetTable = ({ data, handleEdit, setOpen, handleDelete, setMode,onActivate }) => {
  const navigate = useNavigate();
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Asset Id</span>,
      selector: (row) => row.assetRef,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Asset Type</span>,
      sortable: true,
      selector: (row) => row.assetType,
    },
    {
      name: <span className="font-weight-bold fs-13">Asset Name</span>,
      selector: (row) => row.assetName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Location</span>,
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Asset Status</span>,
      selector: (row) => row.assetStatus,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Uploaded By</span>,
      selector: (row) => row.uploadedBy,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Uploaded Date</span>,
      selector: (row) => (row.uploadedDate ? formatDate(row.uploadedDate) : ""),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Active</span>,
      cell: (row) => {
        return (
          <InputSwitch
            onChange={() => onActivate(row)}
            checked={row.isActive}
            disabled={row.assetStatus !== "Completed"}
          />
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Actions</span>,
      sortable: true,

      cell: (row) => {
        return (
          <span>
            <i
              className="bx bx-edit fs-4 me-2 pointer"
              style={{ color: "green" }}
              onClick={() => {
                handleEdit(row);
                setMode("Edit Asset");
                setOpen(true);
              }}
            ></i>
            <i
              className="ri-delete-bin-6-line fs-4 me-2 pointer"
              style={{ color: "red" }}
              onClick={() => {
                handleDelete(row);
              }}
            ></i>
          </span>
        );
      },
    },
  ];
  return <DataTable columns={columns} data={data} />;
};

export default AssetTable;
