const locationOptions = [
  { label: "Top", value: "Top" },
  { label: "Bottom", value: "Bottom" },
  { label: "Middle", value: "Middle" },
  { label: "East", value: "East" },
  { label: "West", value: "West" },
  { label: "North", value: "North" },
  { label: "South", value: "South" },
  { label: "Right", value: "Right" },
  { label: "Left", value: "Left" },
  { label: "Center", value: "Center" },
];

const severityOptions = [
  { value: "High Risk", label: "High Risk" },
  { value: "Low Risk", label: "Low Risk" },
  { value: "Medium Risk", label: "Medium Risk" },
  { value: "Safe", label: "Safe" },
];

const healthOptions = [
  // { value: "All", label: "All" },pdf-icon.svg
  { value: "High Risk", label: "High Risk" },
  { value: "Medium Risk", label: "Medium Risk" },
  { value: "Low Risk", label: "Low Risk" },
  { value: "Safe", label: "Safe" },
];

const vegetationOptions = [
  { value: "Tree", label: "Tree" },
  { value: "Grass", label: "Grass" },
  { value: "Crops", label: "Crops" },
];

const insulatorConditionOptions = [
  { label: "Broken Sheds", value: "Broken Sheds" },
  { label: "Flash Marks", value: "Flash Marks" },
  {
    label: "Puncture Insulators",
    value: "Puncture Insulators",
  },
  { label: "Dust Deposition", value: "Dust Deposition" },
  { label: "Bird Shit", value: "Bird Shit" },
];

const testingOptions = [
  {
    label: "Thermo-scanning of Insulators",
    value: "Thermo-scanning of Insulators",
  },
  {
    label: "CC & Grading rings",
    value: "CC & Grading rings",
  },
  {
    label: "Mid-span Joints",
    value: "Mid-span Joints",
  },
  {
    label: "Repair Sleeves on Conductor & Earth-wire ",
    value: "Repair Sleeves on Conductor & Earth-wire ",
  },
  { label: "Corona Detection", value: "Corona Detection" },
  { label: "Jumper Core", value: "Jumper Core" },
  { label: "Clamp Connectors", value: "Clamp Connectors" },
];

const stringingOption = [
  {
    label: "Corrosion on Sag Adjusters Plate in Tension Hardware Fittings",
    value: "Corrosion on Sag Adjusters Plate in Tension Hardware Fittings",
  },
  {
    label: "Broken/loose Space Damper & Rigd Spacers",
    value: "Broken/loose Space Damper & Rigd Spacers",
  },
  {
    label: "Broken/Loose Vibration dampers in Earthwire & OPGW",
    value: "Broken/Loose Vibration dampers in Earthwire & OPGW",
  },
  {
    label: "Position & condition of CC rings & Grading rings",
    value: "Position & condition of CC rings & Grading rings",
  },
  {
    label: "Detection of armoring of suspension clamps",
    value: "Detection of armoring of suspension clamps",
  },
  {
    label: "OPGW/Earth bond missing or disconnected",
    value: "OPGW/Earth bond missing or disconnected",
  },
  {
    label: "Damaged & broken strands of conductors between Span & Jumper",
    value: "Damaged & broken strands of conductors between Span & Jumper",
  },
  {
    label: "Condition of Earth-wire & OPGW",
    value: "Condition of Earth-wire & OPGW",
  },
  {
    label: "Physical condition of OPGW joint Boxes",
    value: "Physical condition of OPGW joint Boxes",
  },

  {
    label: "Line & Jumper spacer- damaged, wrong position, missing or loose",
    value: "Line & Jumper spacer- damaged, wrong position, missing or loose",
  },
  {
    label: "Position & condition of arching horn",
    value: "Position & condition of arching horn",
  },
];

const defectOptions = [
  { label: "Defect", value: "Defect" },
  { label: "Defect Rectified", value: "Defect Rectified" },
];

const onlyAlphabets = /^[A-Za-z ]*$/;
const alphabetwithcomma = /^[A-Za-z, ]*$/;
const noZeroDecimal = /^[0-9]*?[.][0-9]{1,3}|^[1-9]\d*?[.]\d{1,3}$|[1-9]\d*/;
const nodecimal = /^[1-9]\d*$/;
export {
  locationOptions,
  severityOptions,
  vegetationOptions,
  insulatorConditionOptions,
  testingOptions,
  stringingOption,
  onlyAlphabets,
  alphabetwithcomma,
  nodecimal,
  noZeroDecimal,
  healthOptions,
  defectOptions
};
