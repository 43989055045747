import React from "react";
import { useNavigate } from "react-router-dom";
import error500 from "../../assets/error500.png";
import store from "../../store/store";
import { Header } from "../header/Header";

const Error500 = () => {
  let navigate = useNavigate();
  let user = store.getState().user;
  document.title = "500 Error | GroundGame Health ";
  const onHome = () => {
    document.title = "Land Survey web visualizer";
    navigate("/home");
  };
  return (
    <>
      {user?.role?.roleCode !== "ADMIN" &&
        user?.role?.roleCode !== "PROCESSING TEAM" &&
        user?.role?.roleCode !== "PILOT" && <Header />}
      <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="auth-page-content overflow-hidden p-0">
          <div className="row justify-content-center">
            <div className="text-center">
              <div className="error-500 position-relative">
                <img
                  src={error500}
                  alt=""
                  className="img-fluid error-500-img error-img"
                />
                <h1 className="title text-muted">500</h1>
              </div>
              <div>
                <h3>Internal Server Error!</h3>
                <p className="text-muted w-75 mx-auto">
                  Server Error 500. We're not exactly sure what happened, but
                  our servers say something is wrong.
                </p>
                <div onClick={onHome} className="btn btn-success m-t-10">
                  <i className="mdi mdi-home me-1"></i>Back to home
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error500;
