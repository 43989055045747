import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const Pagination = (props) => {
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0)

  const {
    itemsPerPage,
    count,
    handlePageChange,
    previousLabel,
    nextLabel,
    pageRange,
    forcePage
  } = props;

  useEffect(() => {
    if(forcePage){
      setCurrentPage(forcePage)
    }
    setPageCount(Math.ceil(count / itemsPerPage));
  }, [itemsPerPage, count]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
    handlePageChange(event.selected + 1);
  };
  return (
    <div className={`${props.className} d-flex justify-content-end m-1`}>
      <ReactPaginate
        nextLabel={nextLabel ? nextLabel : "Next >"}
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRange === 0 ? pageRange : 3}
        marginPagesDisplayed={props.marginPagesDisplayed === 0 ? 0 : 2}
        pageCount={pageCount}
        previousLabel={previousLabel ? previousLabel : "< Previous"}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        forcePage={currentPage}
      />
    </div>
  );
};

export default Pagination;
