import React from "react";
import drogoLogo from "../../assets/svg-new/drogo_light_2.png";
import ProfileDropdown from "../../components/Common/ProfileDropdown";
import { useNavigate } from "react-router-dom";

const SMEHeader = () => {
  const navigate = useNavigate()
  return (
    <div className="sme-header">
      <div className="drogo-logo">
        <img src={drogoLogo} alt="drogo-logo" width="100%" onClick={()=> navigate("/sme-dashboard")} className="pointer"/>
      </div>
      <ProfileDropdown />
    </div>
  );
};

export default SMEHeader;
