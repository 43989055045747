import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { DistributedColumn, SimpleDonut } from "./Charts";
import "../../../assets/scss/dashboard.css";

const OverallDashboard = (props: any) => {
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={8} className="admin-overall-dashboard">
            <Card className="card-border-none mb-2">
              <CardHeader className="header-card">
                <div className="d-flex justify-content-between align-items-center admin-card-head">
                  Number of Projects based on States
                </div>
              </CardHeader>
              <CardBody>
                <DistributedColumn
                  data={props?.dataState?.data}
                  label={props?.dataState?.label}
                  textY={"No. of Projects"}
                  textX={"States"}
                  custom={{ width: 30, borderRadius: 0 }}
                  height={280}
                  dataColors='["#8a3ffc", "#33b1ff", "#007d79", "#ff7eb6", "#fa4d56", "#6fdc8c", "#4589ff", "#d12771", "# 08bdba", "#bae6ff" ]'
                />
              </CardBody>
            </Card>
            <Card className="card-border-none mb-0">
              <CardHeader className="header-card">
                <div className="d-flex justify-content-between align-items-center admin-card-head">
                  Number of Projects based on Clients
                </div>
              </CardHeader>
              <CardBody>
                <DistributedColumn
                  data={props?.dataClient?.data}
                  label={props?.dataClient?.label}
                  textY={"No. of Projects"}
                  textX={"States"}
                  custom={{ width: 30, borderRadius: 0 }}
                  height={280}
                  dataColors='["#8a3ffc", "#33b1ff", "#007d79", "#ff7eb6", "#fa4d56", "#6fdc8c", "#4589ff", "#d12771", "# 08bdba", "#bae6ff" ]'
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} className="admin-overall-dashboard">
            <Card className="admin-donut card-border-none mb-0">
              <CardHeader className="header-card">
                <div className="d-flex justify-content-between align-items-center admin-card-head">
                  Number of Clients based on Industry
                </div>
              </CardHeader>
              <CardBody>
                <div className="d-flex align-items-center justify-content-center pi-margin">
                  <SimpleDonut
                    series={props?.data?.data}
                    labelName={props?.data?.label}
                    dataColors='["#d12771","#bae6ff" , "#007d79", "#ff7eb6", "#fa4d56", "#6fdc8c","#8a3ffc", "#4589ff", "#33b1ff", "# 08bdba"  ]'
                    height={480}
                    labelCenter={"Total Clients"}
                    selectedData={() => {}}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default OverallDashboard;
