import React, { useState } from "react";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
import { validation } from "./validation";

const PasswordComponent = (props: any) => {
  const [error, setError] = useState<any>({});
  const onHandleChange = (ev: any, field: any, type: any) => {
    let chkValid = validation(
      type,
      ev.target.value,
      field,
      props?.maxLength,
      props.checkMatched
    );
    setError(chkValid);
    props.handleChange(ev.target.value, field, type, chkValid);
  };
  const header = <h6>Pick a password</h6>;
  const footer = (
    <React.Fragment>
      <Divider />
      <p className="mt-2">Suggestions</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>At least one special character</li>
        <li>Minimum 8 characters</li>
      </ul>
    </React.Fragment>
  );
  return (
    <>
      {props.inputLabel && (
        <label>
          {props?.inputLabelText}
          <span className="mandatory">{props?.mandatory ? " *" : ""}</span>
        </label>
      )}
      <Password
        value={props.value}
        onChange={(e) => onHandleChange(e, props?.name, props?.validator)}
        header={header}
        footer={footer}
        placeholder={props?.placeholder}
        strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[#?!@$%^&*-])(?=.{8,})"
        disabled={props.disabled}
        className="w-100"
      />
      {props.value === props.matchPassword &&
        props.value &&
        props.value !== "" && (
          <p className="form-error mt-1">{`${"New Password should not be same as Old Password"}`}</p>
        )}
    </>
  );
};

export default PasswordComponent;
