import React from "react";
import { Spinner } from "reactstrap";

import "react-toastify/dist/ReactToastify.css";

const Loader = (props: any) => {
  return (
    props.loader && (
      <div className="loader-main">
        <div className="loader-box">
          <div className="d-flex justify-content-center mx-2 mt-2">
            <Spinner color="primary"> Loading... </Spinner>
          </div>
          {/* {toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })} */}
        </div>
      </div>
    )
  );
};

export default Loader;
