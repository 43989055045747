import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import prevIcon from "../../assets/svg-new/prev-img.svg";
import nextIcon from "../../assets/svg-new/next-img.svg";
import { Tooltip, convertDateToISO } from "../Components/Utils";
import Select from "react-select";
import { defectOptions } from "../Components/Constants";
import Dropzone from "react-dropzone";
import imgScreen from "../../assets/svg-new/screensot-img.svg";
import * as api from "../../api/global.api";
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";

const ComponentCard = ({ data, setLoading, did, getAllRecords }) => {
  let componentName = data[0];
  let details = data[1];
  const [img, setImg] = useState(null);
  const [isOffcanvas, setIsOffcanvas] = useState(false);
  const [isOffcanvas2, setIsOffcanvas2] = useState(false);
  const [id, setId] = useState(0);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [isLightbox, setIsLightbox] = useState(false);
  const [idx, setIdx] = useState(0);
  const [formState, setFormState] = useState({
    actionStatus: null,
    clientComments: "",
    recommendation: "",
    clientVerifiedImages: [],
  });

  useEffect(() => {
    let imgsrc = details[id]?.towerComponentDetails[0]?.modifiedFile;
    setImg(imgsrc);
  }, [id]);

  const acceptFile = (files) => {
    setLoading(true);
    let img = [];
    files?.map(async (file) => {
      let ext = file?.name?.split(".")?.pop()?.toLowerCase();
      if (ext === "png" || ext === "jpg" || ext === "jpeg") {
        let formData = new FormData();
        formData.append("file", file);
        let res = await api.post("fileUpload", formData);
        if (res?.status?.toLowerCase() === "failed") {
          setLoading(false);
          toast.error(res?.message);
        } else {
          img.push(res.url);
          setLoading(false);
        }
      } else {
        toast.error("Please upload png, jpg or jpeg format");
        setLoading(false);
      }
      if (formState?.clientVerifiedImages?.length > 0) {
        setFormState({
          ...formState,
          clientVerifiedImages: [...formState?.clientVerifiedImages, ...img],
        });
      } else {
        setFormState({ ...formState, clientVerifiedImages: [...img] });
      }
    });
  };

  const handleUpdateAction = async () => {
    setLoading(true);
    let obj;
    if (formState?.actionStatus?.value === "Defect") {
      obj = {
        id: details[id]?.id,
        clientComments: formState?.clientComments,
        recommendation: formState?.recommendation,
        clientVerifiedImages: formState?.clientVerifiedImages?.join("#"),
        towerId: details[id]?.towerId,
      };
    } else {
      obj = {
        id: details[id]?.id,
        clientComments: formState?.clientComments,
        recommendation: formState?.recommendation,
        clientVerifiedImages: formState?.clientVerifiedImages?.join("#"),
        towerId: details[id]?.towerId,
        actionClosedDate: convertDateToISO(new Date()),
        severityClosedDate: convertDateToISO(new Date()),
        actionStatus: "Closed",
      };
    }

    let res = await api.post("updatetowercomponent", obj);
    if (res?.status?.toLowerCase() === "success") {
      toast.success("Action Status updated successfully");
      setIsOffcanvas2(false);
      setFormState({});
      getAllRecords(details[id]?.towerId);
    }
  };

  const updateFrom = (val, name) => {
    setFormState({ ...formState, [name]: val });
  };

  const handleUpdateStatus = () => {
    setFormState({
      actionStatus:
        details[id]?.actionStatus === "Closed"
          ? defectOptions[1]
          : defectOptions[0],
      clientComments: details[id]?.clientComments,
      recommendation: details[id]?.recommendation,
      clientVerifiedImages: details[id]?.clientVerifiedImages?.split("#"),
    });
    setIsOffcanvas2(true);
  };

  const removeImage = (idx) => {
    let images = formState?.clientVerifiedImages;
    let afterRemove = images?.filter((img, id) => id !== idx);
    setFormState({ ...formState, clientVerifiedImages: afterRemove });
  };

  const showImages = (images) => {
    let img = images?.split("#");
    setLightboxImages(img);
    setIsLightbox(true);
  };

  const checkformDisable = () => {
    if (
      formState?.actionStatus === null ||
      formState?.clientComments === null ||
      formState?.clientComments === "" ||
      formState?.recommendation === "" ||
      formState?.recommendation === null ||
      formState?.clientVerifiedImages?.length === 0 ||
      formState?.clientVerifiedImages === undefined
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {isLightbox && (
        <>
          {lightboxImages?.length > 1 ? (
            <Lightbox
              mainSrc={lightboxImages[idx]}
              imageTitle={lightboxImages[idx]?.split("_")?.pop()}
              imageCaption={idx + 1}
              onCloseRequest={() => setIsLightbox(false)}
              onMovePrevRequest={() =>
                setIdx(
                  (idx - 1 + lightboxImages?.length) % lightboxImages?.length
                )
              }
              onMoveNextRequest={() =>
                setIdx((idx + 1) % lightboxImages?.length)
              }
              nextSrc={lightboxImages[(idx + 1) % lightboxImages?.length]}
              prevSrc={
                lightboxImages[
                  (idx - 1 + lightboxImages?.length) % lightboxImages?.length
                ]
              }
            />
          ) : (
            <Lightbox
              mainSrc={lightboxImages[idx]}
              imageTitle={lightboxImages[idx]?.split("_")?.pop()}
              imageCaption={idx + 1}
              onCloseRequest={() => setIsLightbox(false)}
            />
          )}
        </>
      )}
      <Card className="mb-3">
        <div className="comp-img1">
          <img src={img} alt={img} className="main-img-src1" />
          <i
            className="bx bx-fullscreen fs-1 pointer pos-fullscreen"
            onClick={() => showImages(img)}
          ></i>
          {details?.length > 1 && (
            <>
              <div className="prev-arrow1">
                <img
                  src={prevIcon}
                  onClick={() => {
                    setId((id - 1 + details?.length) % details?.length);
                    setImg("");
                  }}
                />
              </div>
              <div className="next-arrow1">
                <img
                  src={nextIcon}
                  onClick={() => {
                    setId((id + 1) % details?.length);
                    setImg("");
                  }}
                />
              </div>
            </>
          )}
          {details?.length > 1 && (
            <>
              <div className="dot-img">
                {details?.map((e, idx) => {
                  return idx !== id ? (
                    <i className="bx bx-radio-circle fs-4"></i>
                  ) : (
                    <i className="bx bxs-circle text-danger fs-5"></i>
                  );
                })}
              </div>
              <div className="text-light total-img">
                {id + 1} / {details?.length}
              </div>
            </>
          )}
          <div className="comp-name">
            Component : <span className="fs-16"> {componentName}</span>
          </div>
          <div className="comp-name mt-4">
            Location : <span className="fs-16"> {details[id]?.location}</span>
          </div>
          <div
            className={`${
              details[id]?.actionStatus === "Closed"
                ? "dark-green"
                : details[id]?.componentStatus === "Low Risk"
                ? "light-green"
                : details[id]?.componentStatus === "High Risk"
                ? "color-danger"
                : details[id]?.componentStatus === "Medium Risk"
                ? "light-orange"
                : "dark-green"
            } comp-status1`}
          >
            {details[id]?.actionStatus === "Closed"
              ? "Safe"
              : details[id]?.componentStatus}
          </div>
        </div>
        <div className="p-3">
          <Row>
            <Col>
              <div className="color-grayl fs-14">Observation : </div>
              <span className="text-dark fw-500 fs-14">
                {details[id]?.observation}
              </span>
            </Col>
            <Col>
              <div className="color-grayl fs-14">Review Comment : </div>
              <div className="text-dark fw-500 fs-14" id={`c_comment_${did}`}>
                {details[id]?.clientComments ? (
                  details[id]?.clientComments
                ) : (
                  <span className="text-center ms-5">-</span>
                )}
                {details[id]?.clientComments &&
                  Tooltip(
                    `c_comment_${did}`,
                    details[id]?.clientComments,
                    "top"
                  )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="color-grayl wrap-text fs-14">Comments :</div>
              <span className="text-dark fw-500 fs-14" id={`comment_${did}`}>
                {details[id]?.towerComponentDetails[0]?.comments}
              </span>
              {Tooltip(
                `comment_${did}`,
                details[id]?.towerComponentDetails[0]?.comments,
                "top"
              )}
            </Col>
            <Col className="">
              <div className="color-grayl wrap-text fs-14">
                Client Recommendation :
              </div>
              <span
                className="text-dark fw-500 fs-14"
                id={`c_recommendation${did}`}
              >
                {details[id]?.recommendation ? (
                  details[id]?.recommendation
                ) : (
                  <span className="ms-5">-</span>
                )}
              </span>
              {details[id]?.recommendation &&
                Tooltip(
                  `c_recommendation${did}`,
                  details[id]?.recommendation,
                  "top"
                )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="color-grayl wrap-text fs-14">
                Recommendation :
              </div>
              <span
                className="text-dark fw-500 fs-14"
                id={`recommendation${did}`}
              >
                {details[id]?.towerComponentDetails[0]?.recommendation ? (
                  details[id]?.towerComponentDetails[0]?.recommendation
                ) : (
                  <span className="ms-5">-</span>
                )}
              </span>
              {details[id]?.towerComponentDetails[0]?.recommendation &&
                Tooltip(`recommendation${did}`, details[id]?.comments, "top")}
            </Col>
            <Col className="">
              <span
                className={`text-center ${
                  details[id]?.clientVerifiedImages ? "pointer" : "auto"
                }`}
                onClick={() =>
                  details[id]?.clientVerifiedImages
                    ? showImages(details[id]?.clientVerifiedImages)
                    : ""
                }
              >
                <img src={imgScreen} className="pointer me-2" />
                <span className="text-low fw-500">
                  {details[id]?.clientVerifiedImages
                    ? details[id]?.clientVerifiedImages?.split("#")?.length +
                      " " +
                      "Rectified Images"
                    : "0 Rectified Images"}
                </span>
              </span>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <Button
                className="w-5 me-2"
                color="success"
                outline
                onClick={() => {
                  setIsOffcanvas(true);
                }}
              >
                <span> View Details</span>
              </Button>
              {details[id]?.actionStatus !== "Closed" && (
                <Button
                  className="w-5 detail-comp bg-success"
                  onClick={() => {
                    handleUpdateStatus();
                  }}
                >
                  <span> Update Status</span>
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Card>
      {isOffcanvas && (
        <Offcanvas
          isOpen={isOffcanvas}
          direction="end"
          toggle={() => setIsOffcanvas(false)}
        >
          <OffcanvasHeader className="bg-success">
            <span className="text-light">Observation Details</span>
            <i
              className="bx bx-x text-light fs-2 pointer"
              onClick={() => setIsOffcanvas(false)}
            ></i>
          </OffcanvasHeader>
          <OffcanvasBody>
            <div className="off-img mb-3">
              <img src={details[id]?.towerComponentDetails[0]?.modifiedFile} />
            </div>
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Component :
              </Col>
              <Col sm={6} className="fw-500 text-green-off fs-16">
                {componentName}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Location :
              </Col>
              <Col sm={6} className="fw-500 text-green-off fs-16">
                {details[id].location}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Observation :
              </Col>
              <Col sm={6} className="fw-500 text-green-off fs-16">
                {details[id].observation}
              </Col>
            </Row>
            {details[id]?.observationData?.partName && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Part Name :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.partName}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.size && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Size :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.size}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.vegetationType && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Type of Vegetation :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.vegetationType}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.height && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Height :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.height}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.girth && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Girth :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.girth}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.quantity && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Quantity :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.quantity}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.jumpToTower && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Jumper to Jumper :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.jumpToTower}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.phaseToPhase && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Phase to Phase :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.phaseToPhase}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.groundClearence && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Ground Clearence :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.groundClearence}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.overHead && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Overhead/Underneath Crossing :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.overHead}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.insulatorsCondition && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Insulators Condition :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.insulatorsCondition}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.testing && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Testing :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.testing}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.observationName && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Observation Name :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.observationName}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.legA && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Leg A :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.legA}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.legB && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Leg B :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.legB}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.legC && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Leg C :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.legC}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.legD && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Leg D :
                </Col>
                <Col sm={6} className="fw-500 text-green-off fs-16">
                  {details[id]?.observationData?.legD}
                </Col>
              </Row>
            )}
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Severity :
              </Col>
              <Col sm={6} className="fw-500 text-green-off fs-16">
                {details[id].componentStatus}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Comment :
              </Col>
              <Col sm={6} className="fw-500 text-green-off fs-16">
                {details[id]?.towerComponentDetails[0]?.comments
                  ? details[id]?.towerComponentDetails[0]?.comments
                  : "-"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Recommendation :
              </Col>
              <Col sm={6} className="fw-500 text-green-off fs-16">
                {details[id]?.towerComponentDetails[0]?.recommendation
                  ? details[id]?.towerComponentDetails[0]?.recommendation
                  : "-"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Review Comment :
              </Col>
              <Col sm={6} className="fw-500 text-green-off fs-16">
                {details[id]?.clientComments
                  ? details[id]?.clientComments
                  : "-"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Client Recommendation :
              </Col>
              <Col sm={6} className="fw-500 text-green-off fs-16">
                {details[id]?.recommendation
                  ? details[id]?.recommendation
                  : "-"}
              </Col>
            </Row>
          </OffcanvasBody>
        </Offcanvas>
      )}

      {isOffcanvas2 && (
        <Offcanvas
          isOpen={isOffcanvas2}
          direction="end"
          toggle={() => setIsOffcanvas2(false)}
          backdrop="static"
        >
          <OffcanvasHeader className="bg-success">
            <span className="text-light">Action Status</span>
            <i
              className="bx bx-x light fs-2 pointer"
              onClick={() => setIsOffcanvas2(false)}
            ></i>
          </OffcanvasHeader>
          <OffcanvasBody>
            <div className="mb-3">
              <Label>
                Status <span className="required">*</span>
              </Label>
              <Select
                options={defectOptions}
                value={formState?.actionStatus}
                onChange={(val) => updateFrom(val, "actionStatus")}
              />
            </div>
            <div className="mb-3">
              <Label>
                Comments <span className="required">*</span>
              </Label>
              <textarea
                className="form-control"
                rows={5}
                placeholder="Enter comments"
                value={formState?.clientComments}
                onChange={(e) => updateFrom(e.target.value, "clientComments")}
              />
            </div>
            <div className="mb-3">
              <Label>
                Upload Images <span className="required">*</span>
              </Label>
              <Dropzone onDrop={(files) => acceptFile(files)}>
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone dz-clickable form-control p-0">
                    <div className="needsclick" {...getRootProps()}>
                      <input {...getInputProps()} accept="images/*" />
                      <div className="m-3 d-flex align-items-center justify-content-center">
                        <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />
                        <span className="text-muted">
                          Drop files here or click to upload
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
              {formState?.clientVerifiedImages?.length > 0 && (
                <Row>
                  {formState?.clientVerifiedImages?.map((image, idx) => (
                    <Col
                      md={12}
                      className="d-flex align-items-center border-bottom"
                      key={idx}
                    >
                      <div className="client-img-offcanvas">
                        <img src={image} />
                        <i
                          className="bx bx-x remove-img fs-5"
                          onClick={() => removeImage(idx)}
                        ></i>
                      </div>
                      <span className="fs-14">{image?.split("_")?.pop()}</span>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
            <div className="mb-3">
              <Label>
                Recommendation <span className="required">*</span>
              </Label>
              <textarea
                className="form-control"
                rows={5}
                placeholder="Enter recommendation"
                value={formState?.recommendation}
                onChange={(e) => updateFrom(e.target.value, "recommendation")}
              />
            </div>
          </OffcanvasBody>
          <div className="m-2 text-end">
            <Button
              className="w-5 me-2"
              color="success"
              outline
              onClick={() => setIsOffcanvas2(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              className="w-5 detail-comp bg-success"
              onClick={() => handleUpdateAction()}
              disabled={checkformDisable()}
            >
              <span>Save</span>
            </Button>
          </div>
        </Offcanvas>
      )}
    </>
  );
};

export default ComponentCard;
