import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/style.css";
import "./styles/user.css";
import './styles/Admin.css';
import './styles/Admin2.css';
import './styles/Common.css';
import './styles/olmap.css';
import "./assets/scss/themes.scss";
import Routing from "./components/routing/Routing";


function App(props: any) {
  return (
    <div className="h-100">
      <Routing user={props.user} />
    </div>
  );
}

export default App;
