import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, Row } from "reactstrap";
import Loader from "../../CommonComponent/Loader";
import * as api from "../../../api/global.api";
import { useParams } from "react-router-dom";
import Pagination from "../../commons/pagination";
import { formatDateTime } from "../../commons/functions";

const SmeAssetList = () => {
  const navigate = useNavigate();
  const projectId = useParams().id;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const sele = JSON.parse(sessionStorage.getItem("assetZipName"));
  useEffect(() => {
    setLoading(true);
    getAssets();
  }, [page]);

  const getAssets = () => {
    api
      .post(
        `getAssets?projectId=${projectId}&page=${page}&limit=12`,
        {assetStatus: ["Inspection Done", "Completed"]}
      )
      .then((res) => {
        if (res.status === "failed") {
          toast.error(res.message);
          setLoading(false);
        } else {
          setData(res?.response?.assetList);
          setCount(res?.response?.count);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Asset ID</span>,
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Asset Name</span>,
      selector: (row) => row.assetName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Location</span>,
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      sortable: true,
      selector: (cell) => {
        switch (cell.assetStatus) {
          case "Yet To Start":
            return (
              <span className="badge badge-soft-secondary text-dark">
                {cell.assetStatus}
              </span>
            );
          case "Data Captured":
            return (
              <span className="badge badge-soft-info text-dark">
                {cell.assetStatus}
              </span>
            );
          case "In Progress":
            return (
              <span className="badge badge-soft-warning text-dark">
                {cell.assetStatus}
              </span>
            );
          case "Inspection Done":
            return (
              <span className="badge badge-soft-success text-dark">
                {cell.assetStatus}
              </span>
            );
          case "Completed":
            return (
              <span className="badge badge-soft-success text-dark">
                {cell.assetStatus}
              </span>
            );
          default:
            return (
              <span className="badge badge-soft-success">
                {" "}
                {cell.assetStatus}{" "}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">File Name</span>,
      selector: (row) =>
        row.assetZipName ? (
          <p>{row.assetZipName}</p>
        ) : (
          <p className="ms-5 mt-2">-</p>
        ),
    },
    {
      name: <span className="font-weight-bold fs-13">Date & Time</span>,
      selector: (row) => formatDateTime(row.createdAt),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: (data) => (
        <div className="pointer primaryColor">
          {data.assetStatus !== "Completed" ? (
            <div
              onClick={() => {
                if (data.assetStatus !== "Yet To Start") {
                  navigateToAssetComponent(data);
                }
              }}
            >
              + Add Component
            </div>
          ) : (
            <div onClick={() => navigateToAssetComponent(data)}>
              View Component
            </div>
          )}
        </div>
      ),
    },
  ];

  const navigateToAssetComponent = (obj) => {
    navigate(`/sme-projects/assets/asset-componet/${obj.id}`);
    let data = {
      assetZipName: obj.assetZipName,
      assetName: obj.assetName,
      assetStatus: obj.assetStatus,
      severityStatus: obj.severityStatus,
    };
    sessionStorage.setItem("Asset", JSON.stringify(data));
  };

  return (
    <div className="content p-3">
      <Loader loader={loading} />
      <ToastContainer />
      {data?.length > 0 ? (
        <Row>
          <div
            className="fs-20 primaryColor "
            
          >
            <i className=" ri-arrow-left-circle-line fs-22 me-2 align-bottom pointer" onClick={() => navigate(-1)}></i>
            Back
          </div>
          <DataTable data={data} columns={columns} />
        </Row>
      ) : (
        <Card className="text-center py-2">No Data Found</Card>
      )}
      {count > 12 && (
        <Pagination
          itemsPerPage={12}
          count={count}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default SmeAssetList;
