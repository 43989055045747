import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Home from "../../assets/home.svg";

const BreadCrumb = (props) => {
  return (
    <React.Fragment>
      <Row className="m-0">
        <Col xs={12}>
          <div className="d-sm-flex align-items-center justify-content-between bg-none">
            <div className="page-title-right">
              <ol className="breadcrumb m-0 d-flex align-items-center">
                <li>
                  <Link to="/#">
                    <img src={Home} alt="" className="pointer mb-1" />
                    <span style={{ color: "rgb(51, 153, 102)" }}>
                      &nbsp;/&nbsp;
                    </span>
                  </Link>
                </li>
                {props.pageTitle2 && (
                  <li>
                    <Link to={props.pageLink2}>
                      <span style={{ color: "rgb(51, 153, 102)" }}>
                        {props.pageTitle2}
                        &nbsp;/&nbsp;
                      </span>
                    </Link>
                  </li>
                )}
                {props.pageTitle1 && (
                  <li>
                    <Link to={props.pageLink1}>
                      <span style={{ color: "rgb(51, 153, 102)" }}>
                        {props.pageTitle1}
                        &nbsp;/&nbsp;
                      </span>
                    </Link>
                  </li>
                )}
                {props.pageTitle && (
                  <li>
                    <Link to={props.pageLink}>
                      <span style={{ color: "rgb(51, 153, 102)" }}>
                        {props.pageTitle}
                        &nbsp;/&nbsp;
                      </span>
                    </Link>
                  </li>
                )}

                <li className="breadcrumb-item active">{props.title}</li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
