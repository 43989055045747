import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as api from "../../../api/global.api";

const AddFeatures = ({ isModal, handleModal, data, updateModule }: any) => {
  let date: any = new Date();
  const [featuresList, setFeaturesList] = useState<any>(data?.features);
  const [selectedFeatures, setSelectedFeatures] = useState<any>([]);

  const handleAddFeatures = (val: any) => {
    let idx = featuresList.map((e: any) => e.featureName).indexOf(val);
    let index = selectedFeatures?.indexOf(val);
    if (index === -1) {
      setSelectedFeatures([...selectedFeatures, val]);
      featuresList[idx].isSelected = true;
      setFeaturesList(featuresList);
    } else {
      let data1 = [...selectedFeatures];
      data1.splice(index, 1);
      setSelectedFeatures(data1);
      featuresList[idx].isSelected = false;
      setFeaturesList(featuresList);
    }
  };

  const handleSave = () => {
    let value = {
      ...data,
      features: {
        create: featuresList,
      },
    };
    updateModule(value);
    handleModal(false);
  };

  useEffect(() => {
    let selected = data?.features?.filter((e: any) => e.isSelected === true);

    setSelectedFeatures(selected?.map((e: any) => e.featureName));
  }, []);



  return (
    <Modal isOpen={isModal} className="modal-dialog-top">
      <ModalHeader className="d-flex justify-content-between bg-success">
        <div className="modal-title">Feature List</div>
      </ModalHeader>
      <ModalBody>
        <div className="text-muted fs-18 mb-3">
          Add features to the Module by selecting features from the list
        </div>
        {data?.features?.map((item: any, key: any) => {
          return (
            <div className="d-mb-2 " key={key}>
              <Input
                className="form-check-input me-4"
                type="checkbox"
                checked={selectedFeatures.includes(item?.featureName)}
                id="cardtableCheck01"
                onClick={() => handleAddFeatures(item?.featureName)}
              />
              <Label className="form-label fs-16">{item?.featureName}</Label>
            </div>
          );
        })}
        <div className="d-flex justify-content-end m-2">
          <Button
            className="me-3 wd-10"
            onClick={() => {
              handleModal(false);
            }}
            color="success"
            outline
          >
            Cancel
          </Button>
          <Button color="success" className="wd-10" onClick={() => handleSave()}>
            Save
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddFeatures;
