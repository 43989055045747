import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import UserTable from "./UserTable";
import * as api from "../../../api/global.api";
import Loader from "../../CommonComponent/Loader";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Common/DeleteModal";
import SearchOption from "../../Common/SearchOption";
import Pagination from "../../commons/pagination";
const UserManagement = () => {
  const navigate = useNavigate();
  const [result, setResult] = useState<any>({ data: [], count: 0 });
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [loading, setLoading] = useState<Boolean>(false);
  const [deleteUserId, setDeleteUserId] = useState<any>();
  const [isDelete, setIsDelete] = useState<Boolean>(false);
  const [search, setSearch] = useState<any>("");

  const handleSearch = (val: any) => {
    setSearch(val);
    if (val === "") getUser("page=0&limit=10");
  };

  const getUser = (val: any) => {
    api
      .get(`user/get?${val}`)
      .then((data) => {
        if (data.status === "Success") {
          setResult({
            ...result,
            data: data?.response?.rows,
            count: data?.response?.totalCount,
          });
          setLoading(false);
        } else {
          toast.error(data.errormessage);
          setResult([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };

  const handleAddUser = () => {
    sessionStorage.removeItem("editUserData");
    navigate("/user");
  };

  const handlePageChange = (val: any) => {
    setPageNumber(val - 1);
    getUser(`page=${val - 1}&limit=10`);
  };

  const deleteUser = (val: any) => {
    setDeleteUserId(val);
    setIsDelete(true);
  };

  const handleDeleteClick = () => {
    setLoading(true);
    let url = "deleteuser/" + deleteUserId?.id;
    api
      .get(url)
      .then((res) => {
        if (res.status === "Success") {
          getUser(`page=${pageNumber}&limit=10`);
          setIsDelete(false);
          toast.success("User deleted successfully");
        } else {
          toast.error(res.errormessage);
          setLoading(false);
          setIsDelete(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
        setIsDelete(false);
      });
  };

  const deleteModalClose = () => {
    setDeleteUserId(null);
    setIsDelete(false);
  };

  useEffect(() => {
    setLoading(true);
    getUser("page=0&limit=10");
  }, []);

  useEffect(() => {
    let delayDebounce = setTimeout(() => {
      if (search !== "") getUser(`search=${search}`);
    }, 1000);
    return () => clearTimeout(delayDebounce);
  }, [search]);

  return (
    <div className="px-3 mt-3 ptable">
      {loading && <Loader loader={loading} />}
      <ToastContainer />
      <Card className="card-border-none">
        <CardHeader className="header-card">
          <div className="d-flex justify-content-between align-items-center admin-card-head">
            <div>All Users</div>
            <Button
              color="success"
              onClick={() => {
                handleAddUser();
              }}
              className="align-items-center"
            >
              <span className="me-2">+</span>
              <span className="admin-card-head">Add User</span>
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <SearchOption handleSearch={handleSearch} />
          <UserTable data={result} deleteUser={deleteUser} />
        </CardBody>
        {result?.count > 10 && (
          <CardFooter className="footer-card card-border-none">
            <Pagination
              itemsPerPage={10}
              count={result?.count}
              handlePageChange={handlePageChange}
            />
          </CardFooter>
        )}
      </Card>

      <DeleteModal
        show={isDelete}
        onDeleteClick={handleDeleteClick}
        onCloseClick={deleteModalClose}
      />
    </div>
  );
};

export default UserManagement;
