import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrum from "../../commons/Breadcrum";
import * as ProjectSchema from "../ProjectManagementSchema";
import "../ProjectManagement.css";
import { Button } from "reactstrap";
import AddButton from "../../actionsComponent/AddButton";

import * as globalApi from "../../../api/global.api";
import { addToast, getMode, GetMonths } from "../../commons/common";
import Loader from "../../CommonComponent/Loader";
import { ToastContainer } from "react-toastify";
import { errorMsg, successMsg } from "../../commons/constant";
import UnAuthDialog from "../../commons/UnAuthDialog";
import { Tooltip } from "primereact/tooltip";
import ActionButtons from "../../actionsComponent/ActionButtons";
import { surveyTxn } from "../../../store/surveyReducer";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Card, CardBody, Col, Container, Row, Modal } from "reactstrap";
import BasicDataTable from "../../commons/BasicDataTable";
import BreadCrumb from "../../Common/BreadCrumb";
const Surveys = () => {
  let prevProjectRoute: any = sessionStorage.getItem("prevProjectRoute");
  let displayProjectID: any = useParams().id;
  let prevClientRoute: any = sessionStorage.getItem("prevRoute");
  let navigate = useNavigate();
  let formType = getMode(window.location.hash);
  let id = formType.id;
  const [spinner, setSpinner] = useState(false);
  const [columns, setColumns] = useState<any>(
    ProjectSchema.Projects.SurveysList
  );
  const [surveyMod, setsurveyMod] = useState(false);
  let project = useAppSelector((state) => state.project);
  const [rows, setRows] = useState<any>([]);
  const [data, setData] = useState<any>({});
  const [showLogout, setShowLogout] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [row, setRow] = useState<any>({});
  const months = GetMonths();
  const dispatch = useAppDispatch();
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState<number>(0);
  const [pageRows, setPageRows] = useState(10);
  const [first, setFirst] = useState<number>(0);

  useEffect(() => {
    getSurveys(`projectId=${id}`);
    localStorage.removeItem("projectid");
  }, []);

  const getSurveys = (query: string) => {
    setSpinner(true);
    let url = "survey/get" + "?" + query;
    globalApi
      .get(url)
      .then((resp) => {
        if (resp?.status?.toLowerCase() === "failed") {
          addToast(errorMsg.SURVEY_GET, "error");
        } else if (resp === 401) {
          setShowLogout(true);
        } else {
          setRows(resp?.response?.rows);
        }
        setSpinner(false);
      })
      .catch((err) => {
        addToast(errorMsg.SURVEY_GET, "error");
        setSpinner(false);
      });
  };
  // const onAddSurvey = () => {
  //   localStorage.setItem("projectid", id);
  //   navigate("/delivers/surveys/create");
  //   setData({});
  // };

  const onEditSurvey = (rowData: any) => {
    sessionStorage.setItem(
      "prevSurveyRoute",
      JSON.stringify({
        name: rowData.surveyName,
        link: `/delivers/surveys/${rowData?.projectId}`,
      })
    );
    localStorage.setItem("projectid", id);
    navigate("/delivers/surveys/edit/" + rowData.id);
    setData(rowData);
  };

  const onDeleteSurvey = () => {
    setsurveyMod(false);
    setSpinner(true);
    setShowDelete(false);
    globalApi
      .get("survey/delete/" + row.id)
      .then((resp) => {
        if (resp?.status?.toLowerCase() === "failed") {
          addToast(errorMsg.SURVEY_DELETE, "error");
        } else if (data === 401) {
          setShowLogout(true);
        } else {
          addToast(successMsg.SURVEY_DELETE, "success");
          getSurveys(`page=0&limit=100&projectId=${id}`);
        }
        setSpinner(false);
      })
      .catch((err) => {
        addToast(errorMsg.SURVEY_DELETE, "error");
        setSpinner(false);
      });
  };

  const onClickDelete = (rowData: any) => {
    setRow(rowData);
    setShowDelete(true);
  };
  const handleDetails = (rowData: any) => {
    localStorage.setItem("projectid", rowData.projectId);
    dispatch(surveyTxn(rowData));
    navigate("/delivers/survey/surveydetails/" + rowData.id, {
      state: displayProjectID,
    });
  };
  const actionsTemplate = (rowData: any) => {
    return (
      <>
        <span className="ml-2">
          <Tooltip target=".edit-button" position="left" />
          <i
            className="pi pi-pencil clr-primary edit-button pointer"
            onClick={() => onEditSurvey(rowData)}
            data-pr-tooltip="Edit"
          />
        </span>
        <span className="ml-3">
          <Tooltip target=".details-button" position="left" />
          <i
            className="pi pi-database clr-primary details-button pointer"
            onClick={() => handleDetails(rowData)}
            data-pr-tooltip="Details"
          />
        </span>
        <span className="ml-3">
          <Tooltip target=".delete-button" position="left" />
          <i
            className="pi pi-trash clr-primary delete-button pointer"
            onClick={() => onClickDelete(rowData)}
            data-pr-tooltip="Archive"
          />
        </span>
      </>
    );
  };
  const handleDeleteCancel = () => {
    setRow({});
    setShowDelete(false);
  };

  const onPageChange = (event: any) => {
    let pageUrl = `page=${event.page}&limit=${event.rows}`;
    setSpinner(true);
    getSurveys(pageUrl);
    setPageRows(event.rows);
    setFirst(event.first);
    setSkip(event.skip);
  };

  const renderFooter = () => {
    return (
      <ActionButtons
        buttons={["yes", "no"]}
        onSave={onDeleteSurvey}
        onCancel={handleDeleteCancel}
      />
    );
  };
  if (columns && columns.length > 0) {
    columns[columns.length - 1].body = actionsTemplate;
  }

  return (
    <>
      <Loader loader={spinner} />
      <div className="mt-3">
        <ToastContainer />
        <div className="m-b-20">
          {/* <Breadcrum /> */}
          <BreadCrumb
            title="Surveys"
            pageTitle={JSON.parse(prevProjectRoute)?.name}
            pageLink={JSON.parse(prevProjectRoute)?.link}
            pageTitle1={JSON.parse(prevClientRoute)?.name}
            pageLink1={JSON.parse(prevClientRoute)?.link}
          />
        </div>
        <Container fluid>
          <div className="m-b-20">
            <div className="text-right">
              <AddButton
                label="Add Survey"
                icon="pi pi-plus-circle label-icon align-middle "
                routerLink={`/delivers/surveys/create/${id}`}
                prevClient={"Survey"}
                prevLink={`/delivers/surveys/${id}`}
              />
            </div>
          </div>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <BasicDataTable
                    type="SURVEY"
                    data={rows}
                    onClickDelete={onClickDelete}
                    handleEdit={onEditSurvey}
                    handleDetails={handleDetails}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {showDelete && (
          <Modal
            isOpen={showDelete}
            //  toggle={() => {
            //      tog_center();
            //  }}
            centered
          >
            <div className="modal-body text-center">
              <h4 className="mb-3">
                Are you sure you want to Archive {row.id + "-" + row.surveyName}
                ?
              </h4>

              <div className="modal-footer">
                <ActionButtons
                  buttons={["yes", "no"]}
                  onSave={onDeleteSurvey}
                  onCancel={handleDeleteCancel}
                />
              </div>
            </div>
          </Modal>
          // {/* <Dialog
          //   onHide={handleDeleteCancel}
          //   visible={showDelete}
          //   header={"Delete Confirmation"}
          //   draggable={false}
          //   footer={renderFooter()}
          // >

          // </Dialog> */}
        )}
        {showLogout && (
          <UnAuthDialog
            visible={showLogout}
            onHide={() => setShowLogout(false)}
          />
        )}
      </div>
    </>
  );
};

export default Surveys;
