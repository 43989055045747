import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import "../deleteModal/DeleteModal.css";
import ActionButtons from "../actionsComponent/ActionButtons";

const DeleteModal = (props: any) => {
  const [visible, setVisible] = useState(props?.visible);

  useEffect(() => {
    if (props?.visible) {
      setVisible(props?.visible);
    }
  }, [props]);

  const onClickYes = () => {
    setVisible(false);
    props.closeModal(false);
    props.modalActionYes();
  };

  const onClickNo = () => {
    setVisible(false);
    props.closeModal(false);
  };

  const renderFooter = () => {
    return (
      <ActionButtons
        buttons={["Yes", "No"]}
        onSave={onClickYes}
        onCancel={onClickNo}
      />
    );
  };

  return (
    <div>
      <Dialog
        draggable={false}
        header="Confirmation"
        visible={visible}
        onHide={() => onClickNo()}
        breakpoints={{ "960px": "75vw" }}
        className="w-50vw"
        footer={renderFooter()}
      >
        <p className="fs-6">
          Are you sure you want to
          {props.type === "droneStatus"
            ? " make " +
              props.rowData.id +
              " " +
              (props.rowData.droneStatus ? "In-Active ?" : "Active ?")
            : props.type === "defaultMap"
            ? (props.rowData.defaultMap
                ? " remove " + props.rowData.mapid + " as "
                : " make " + props.rowData.mapid + " a ") + "default map"
            : props.type === "visibility"
            ? props.rowData.visibility
              ? " make " +
                (props.rowData.sourceAttributeID
                  ? props.rowData.sourceAttributeID
                  : props.rowData.id) +
                " In-Visible"
              : " make " +
                (props.rowData.sourceAttributeID
                  ? props.rowData.sourceAttributeID
                  : props.rowData.id) +
                " Visible"
            : props.type === "isActive"
            ? " make " +
              props.rowData.userId +
              " " +
              (props.rowData.isActive ? "In-Active ?" : "Active ?")
            : (props.rowData.projectid ? " Archieve " : " delete ") +
              (props.rowData.droneid
                ? props.rowData.droneid + "-" + props.rowData.dronename
                : props.rowData.projectid
                ? props.rowData.projectid + "-" + props.rowData.projectname
                : props.rowData.clientid
                ? props.rowData.clientid + "-" + props.rowData.clientname
                : props.rowData.userId
                ? props.rowData.userId + "-" + props.rowData.firstName
                : props.rowData.roleId
                ? props.rowData.roleId + "-" + props.rowData.roleName
                : props.rowData.type
                ? props.rowData.type + " - " + props.rowData.id
                : props.rowData.sourceAttributeID
                ? props.rowData.sourceAttributeID
                : "") +
              " ?"}
        </p>
      </Dialog>
    </div>
  );
};

export default DeleteModal;
