import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import ActionButtons from "../../actionsComponent/ActionButtons";
import { FileUploadDemo } from "../../commons/FileUpload";

const UploadsModal = (props: any) => {
  return (
    <Dialog
      visible={props.visible}
      onHide={props.onHide}
      header={props.header}
      draggable={false}
      className="w-50vw"
    >
      {props.header === "Upload Report" && (
        <div>
          <span>
            <label>Report Name : </label>
            {props.data?.deliverableName}
          </span>
          <FileUploadDemo
            onChaneImage={props.onFileUpload}
            value={props.data?.imageUrl}
            accept="application/pdf"
            maxFileSize={5e9}
            icon="pdf"
            disabled={props.disable}
            handleDelete={true}
            handleFileDelete={props.handleFileDelete}
          />
          <ActionButtons
            buttons={["save", "cancel"]}
            onSave={props.reportSave}
            onCancel={props.onHide}
            disabled={props.disableReportSave}
          />
        </div>
      )}
      {props.header === "Upload Download" && (
        <div>
          <div className="row">
            <div className="col-6">
              <label>
                File Type
                <span className="mandatory"> *</span>
              </label>
              <Dropdown
                value={props.upload.fileType}
                options={props.fileTypes}
                optionLabel="label"
                className="w-100 border ht-50"
                onChange={(e) => props.handleUploadChange(e.value, "fileType")}
                placeholder="Select a File Type"
              />
            </div>
            <div className="col-6">
              <span>
                <label>Download Name : </label>
                {props.data?.deliverableName}
              </span>
              <FileUploadDemo
                onChaneImage={props.onFileUpload}
                value={props.data?.imageUrl}
                maxFileSize={5e9}
                icon="excel"
                disabled={props.disable}
                handleDelete={true}
                handleFileDelete={props.handleFileDelete}
              />
            </div>
            <ActionButtons
              buttons={["save", "cancel"]}
              onSave={props.uploadSave}
              onCancel={props.onHide}
              disabled={props.disableDownloadSave}
            />
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default UploadsModal;
