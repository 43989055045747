import React, { useState } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import AddComponentOffcanvas from "./AddComponentOffcanvas";

import * as api from "../../../../api/global.api";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import { useEffect } from "react";
import uploadImg from "../../../../assets/images/uploadImg.svg";
import { useFormik } from "formik";
import DeleteModal from "../../../Common/DeleteModal";
import PaginationForList from "../../../Common/Pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import { Navigation, Pagination, Scrollbar } from "swiper";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { smeCompleted } from "../../../commons/functions";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks";

const AddAssetCard = (props) => {
  const user = useAppSelector((state) => state.user);
  //offcanvas
  const [assetDetails, setAssetDetails] = useState(
    JSON.parse(sessionStorage.getItem("Asset"))
  );
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [enums, setEnums] = useState([]);
  const [observations, setObservations] = useState([]);
  const [severities, setSeverities] = useState([]);
  const [operabilities, setOperabilities] = useState([]);
  const [locations, setLocations] = useState([]);
  //pagination
  const [count, setCount] = useState(0);
  const [countTwo, setCountTwo] = useState(0);
  const [page, setPage] = useState(1);
  const [pageTwo, setPageTwo] = useState(1);
  const [error, setError] = useState("");

  //deletemodal
  const [deleteModal, setDeleteModal] = useState(false);
  const [lightboxImage, setLightboxImage] = useState({
    image: "",
    comment: "",
  });

  //uploadmodal
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState();
  const [uploadUrl, setUploadUrl] = useState("");
  const [uploadComment, setUploadComment] = useState("");
  const [uploadTime, setUploadTime] = useState("");

  //data
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [imageCount, setImageCount] = useState(1);

  //form
  const [selectedAssetStatus, setSelectedAssetStatus] = useState("");
  const [assetStatusList, setAssetStatusList] = useState([]);
  const [observationName, setObservationName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [comment, setComment] = useState({ comment: "", commentId: 0 });
  const [time, setTime] = useState({ time: "", timeId: 0 });
  const [disabledSave, setDisableSave] = useState(true);
  const [scrollPage, setScrollPage] = useState(1);
  const [scrollCount, setScrollCount] = useState(0);
  // assetImagesList
  const [loader, setLoader] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [assetImageList, setAssetImagesList] = useState([]);
  const [dropItems, setDropItems] = useState([{}]);
  const [isCommentModal, setIsCommentModal] = useState(false);
  const [isCommentEdit, setIsCommentEdit] = useState(false);
  const [selectedImagesList, setselectedImagesList] = useState([]);
  const [token, setToken] = useState(null);
  const [isImageTab, setIsImageTab] = useState("image");
  const [currPage, setCurrPage] = useState(1);
  const [commentErr, setCommentErr] = useState(false);
  const [timeErr, setTimeErr] = useState("");
  const [state, setState] = useState({
    selectedDefectives: {},
    defectiveImagesList: [],
    data: [],
    comments: "",
    selectedImage: {},
    selectedImagesList: [],
    selDefeImagesList: [],
  });

  //useEffect
  useEffect(() => {
    props.setLoading(true);
    getImagesList();
    getEnums();
    getAssetComponent();
    getSelectedImagesList();
  }, [props.assetId]);

  const getEnums = () => {
    api
      .get("getEnums")
      .then((res) => {
        if (res?.status === "failed") {
          toast.error(res?.message);
          props.setLoading(false);
        } else {
          let list = res.appEnum.map((ele) => {
            return {
              value: ele.enumName,
              label: ele.enumValue,
              type: ele.enumType,
            };
          });
          setObservations(
            list?.filter((ele) => ele.type === "GAIL_OBSERVATION")
          );
          setSeverities(list?.filter((ele) => ele.type === "SEVERITY_STATUS"));
          setOperabilities(list?.filter((ele) => ele.type === "OPERABILITY"));
          setLocations(list?.filter((ele) => ele.type === "LOCATION"));
          setAssetStatusList(list?.filter((ele) => ele.type === "OPERABILITY"));
          setEnums(list);
          props.setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        props.setLoading(false);
      });
  };

  const updateEnums = (enumName, enumValue) => {
    api
      .post("addEnum", {
        enumName: enumName,
        enumType: "GAIL_OBSERVATION",
        enumValue: enumValue,
      })
      .then((res) => {
        if (res?.status === "Success") {
          getEnums();
        }
      });
  };

  const maxPageNumber = Math.ceil(scrollCount / 50);

  //apiAssetComponent
  const getAssetComponent = (number) => {
    let pageNo = number ? number : page;
    api
      .get(
        `getAssetComponents?assetId=${props.assetId}&page=${pageNo}&limit=10`
      )
      .then((res) => {
        if (res?.status?.toLowerCase() === "failed") {
          props.setLoading(false);
          toast.error(res.errormessage);
          setState({ ...state, data: [] });
          setCount(0);
        } else {
          setState({ ...state, data: res.response.assetComponentList });
          setCount(res.response.count);
          props.setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        props.setLoading(false);
      });
  };

  const deleteAssetComponent = (id) => {
    props?.setLoading(true);
    api
      .deleteApi(`deleteAssetComponent/${id}`)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 3000 });
          //props.setLoading(false);
        } else {
          toast.success("Component Deleted Successfully", { autoClose: 3000 });
          getAssetComponent();
        }
      })
      .catch((err) => {
        toast.error(err, { autoClose: 3000 });
        props?.setLoading(false);
      });
  };

  const addAssetComponent = (values) => {
    props?.setLoading(true);
    const newValues = { ...values, assetId: +props.assetId };
    api
      .post("addAssetComponent", newValues)
      .then((res) => {
        if (res?.status?.toLowerCase() === "failed") {
          toast.error(res.message, { autoClose: 3000 });
          props.setLoading(false);
        } else {
          toast.success("Component Added Successfully", { autoClose: 3000 });
          getAssetComponent();
          // window.location.reload()
        }
      })
      .catch((error) => {
        toast.error(error, { autoClose: 3000 });
        props.setLoading(false);
      });
  };

  const updateAssetComponent = (values) => {
    const newValues = { ...values, assetId: +props.assetId };
    props?.setLoading(true);
    api
      .put("updateAssetComponent", newValues)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 3000 });
          props?.setLoading(false);
        } else {
          toast.success("Component Updated Successfully", { autoClose: 3000 });
          setObservationName("");
          getAssetComponent();
        }
      })
      .catch((err) => {
        toast.error(err, { autoClose: 3000 });
        props?.setLoading(false);
      });
  };

  //handleClick
  const handleDeleteClick = (id) => {
    setDeleteModal(true);
    setDeleteId(id);
  };

  const handleDeleteConfirm = () => {
    deleteAssetComponent(deleteId);
    setDeleteModal(false);
  };

  const handleEditClick = (item) => {
    setEditId(item.id);
    formik.setFieldValue("componentName", item.componentName);
    formik.setFieldValue("observation", item.observation);
    formik.setFieldValue("severity", item.severity);
    formik.setFieldValue("operability", item.operability);
    formik.setFieldValue("location", item.location);
    setComment({
      ...comment,
      comment: item.componentDetails[0]?.comments,
    });
  };

  //formik

  const initialValues = {
    componentName: "",
    observation: "",
    severity: "",
    operability: "",
    location: "",
  };

  const handleError = (item) => {
    const err = item.componentDetails.filter((a) => a.comments.trim() === "");
    return err?.length > 0 ? true : false;
  };

  const handleSubmit = (values) => {
    if (observationName !== "") {
      const newValues = {
        ...values,
        componentName: formik.values.componentName,
        observation: observationName.trim().toUpperCase().replaceAll(" ", "_"),
        severity: formik.values.severity,
        operability: formik.values.operability,
        location: formik.values.location,
        id: editId,
        componentDetails: {
          create: values.componentDetails,
        },
      };
      const enumName = observationName
        .trim()
        .toUpperCase()
        .replaceAll(" ", "_");
      const enumValue = observationName.trim();
      updateEnums(enumName, enumValue);
      updateAssetComponent(newValues);
    } else {
      const newValues = {
        ...values,
        componentName: formik.values.componentName,
        observation: formik.values.observation,
        severity: formik.values.severity,
        operability: formik.values.operability,
        location: formik.values.location,
        id: editId,
        componentDetails: {
          create: values.componentDetails,
        },
      };
      updateAssetComponent(newValues);
    }
    setEditId("");
  };

  const changeCommentHandler = (e, item) => {
    let objIndex = state.data.findIndex((obj) => obj.id === item.id);
    if (objIndex !== -1) {
      let itemIndex = state.data[objIndex].componentDetails.findIndex(
        (obj) => obj.id === comment?.commentId
      );
      if (itemIndex !== -1) {
        state.data[objIndex].componentDetails[itemIndex].comments =
          e.target.value;
      } else {
        state.data[objIndex].componentDetails[0].comments = e.target.value;
      }
    }
    setComment({ comment: e.target.value, commentId: comment?.commentId });
    setState({ ...state, data: state.data });
  };
  const changeTimeHandler = (e, item) => {
    let objIndex = state.data.findIndex((obj) => obj.id === item.id);
    if (objIndex !== -1) {
      let itemIndex = state.data[objIndex].componentDetails.findIndex(
        (obj) => obj.id === time?.timeId
      );
      if (itemIndex !== -1) {
        state.data[objIndex].componentDetails[itemIndex].videoTimeStamp =
          e.target.value;
      } else {
        state.data[objIndex].componentDetails[0].videoTimeStamp =
          e.target.value;
      }
    }
    setTime({ time: e.target.value, timeId: time?.timeId });
    setState({ ...state, data: state.data });
  };

  const alphabetRegex = /^[^\s]+[-a-zA-Z0-9 ]+([-a-zA-Z0-9 ]+)*$/;
  const validationSchema = Yup.object({
    componentName: Yup.string()
      .matches(alphabetRegex, "Please enter alphanumeric character")
      .max(50, "component name exceeded 50 character")
      .required("required"),
    observation: Yup.string().required(),
    severity: Yup.string().required(),
    operability: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    handleSubmit,
    validationSchema,
  });

  //offcanvas
  const toggleOffcanvas = () => {
    setOpen(!open);
  };

  const handlePageChange = (value) => {
    setPage(value);
    const getImagesList2 = () => {
      api
        .get(`getUploads?fileType=video&assetId=${+props.assetId}`)
        .then((res) => console.log(res));
    };
    getAssetComponent(value);
  };

  const handlePageChangeTwo = (value) => {
    setPageTwo(value);
    getImagesList(isImageTab, value);
  };

  const handleCommentValidation = () => {
    if (uploadComment === "") setCommentErr(true);
    else setCommentErr(false);
  };

  const handleTimeValidation = () => {
    if (uploadTime == "") setTimeErr("Required");
    else if (!rex.test(uploadTime)) setTimeErr("Please enter time as HH:MM:SS");
    else setTimeErr("");
  };
  const handleObservationValue = (item) => {
    if (item.id === editId) {
      return formik?.values?.observation;
    } else {
      return item?.observation;
    }
  };

  const handleSeverityValue = (item) => {
    if (item.id === editId) {
      return formik?.values?.severity;
    } else {
      return item.severity;
    }
  };

  const handleOperabilityValue = (item) => {
    if (item.id === editId) {
      return formik?.values?.operability;
    } else {
      return item.operability;
    }
  };

  const handleLocationValue = (item) => {
    if (item.id === editId) {
      return formik?.values?.location;
    } else {
      return item.location;
    }
  };

  const showComment = (item) => {
    if (comment.commentId === 0) {
      return item?.componentDetails[0]?.comments;
    } else if (
      comment?.commentId === undefined &&
      comment?.commentCustomId !== undefined
    ) {
      return item?.componentDetails[comment?.commentCustomId]?.comments;
    } else {
      let component = item?.componentDetails.filter(
        (obj) => obj.id === comment?.commentId
      );
      return component[0]?.comments;
    }
  };
  const showTime = (item) => {
    if (time.timeId === 0) {
      return item?.componentDetails[0]?.videoTimeStamp;
    } else if (
      time.timeId === undefined &&
      time?.commentCustomId !== undefined
    ) {
      return item?.componentDetails[time?.commentId]?.videoTimeStamp;
    } else {
      let component = item?.componentDetails.filter(
        (obj) => obj.id === time?.timeId
      );
      return component[0]?.videoTimeStamp;
    }
  };

  const uploadImage = (event) => {
    let file = event.target.files[0];
    setUploadedFileName(event.target.files[0].name);
    const formData = new FormData();
    formData.append("file", file);
    toast.success("Please wait while logo is uploading", {
      autoClose: 3000,
    });
    props.setLoading(true);
    api
      .post("fileUpload", formData)
      .then((res) => {
        setUploadUrl(res.url);
        toast.success("Image Uploaded Successfully", {
          autoClose: 3000,
        });
        props.setLoading(false);
      })
      .catch((error) => {
        toast.error(error, { autoClose: 3000 });
        props.setLoading(false);
      });
  };

  const handleUploadCancel = () => {
    setModal(false);
    setUploadUrl("");
    setUploadComment("");
    setUploadTime("");
  };

  const handleUploadSave = () => {
    let component = modalData.componentDetails;

    const values = {
      ...modalData,
      componentDetails: {
        create: [
          ...component,
          {
            defectFile: uploadUrl,
            comments: uploadComment,
            videoTimeStamp: uploadTime,
            assetId: +props.assetId,
          },
        ],
      },
    };
    updateAssetComponent(values);
    setModal(false);
    setUploadUrl("");
    setUploadComment("");
    setUploadTime("");
  };

  const toggle = (data) => {
    setModalData(data);
    setModal(!modal);
  };

  useEffect(() => {
    if (uploadUrl !== "" && uploadComment !== "" && uploadTime !== "") {
      setDisableSave(false);
    }
  }, [uploadUrl, uploadComment, uploadTime]);

  /*getting AssetList*/
  const getImagesList = (fileType, page) => {
    let params = "";
    if (token) {
      params = `key=${assetDetails?.assetZipName}&limit=12&page=${
        page ? page : pageTwo
      }&assetId=${+props.assetId}&fileType=${fileType ? fileType : isImageTab}`;
    } else {
      params = `key=${assetDetails?.assetZipName}&limit=12&page=${
        page ? page : pageTwo
      }&assetId=${+props.assetId}&fileType=${
        fileType ? fileType : isImageTab
      }&videoCheck=true`;
    }
    api
      .get(`getUploads?${params}`)
      .then((res) => {
        if (res?.status === "failed") {
          setAssetImagesList([]);
          toast.error(res?.errormessage);
          props.setLoading(false);
        } else {
          setAssetImagesList(res?.contents);
          res?.token ? setToken(res?.token) : setToken(null);
          setCountTwo(res.count);
          props.setLoading(false);
          if (res?.videoFileStatus === "Available") {
            setIsVideo(true);
          }
        }
      })
      .catch((err) => {
        toast.error(err);
        props.setLoading(false);
      });
  };

  const handleDragStart = (event, file) => {
    // event.dataTransfer.setData("defectFile", file);
    event.dataTransfer.setData("defectFile", JSON.stringify(file));
  };

  const handleOnDrop = (event, assetData) => {
    if (editId) {
      let drop = JSON.parse(event.dataTransfer.getData("defectFile"));

      if (drop) {
        state.data.map((asset) => {
          if (asset.id === assetData.id) {
            delete drop.id;
            if (asset?.componentDetails?.length > 0)
              asset?.componentDetails.push(drop);
            else asset["componentDetails"] = [drop];
          }
        });
        setComment({ ...comment, comment: drop.comment });
      }
      setState({
        ...state,
        data: state.data,
      });
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const selectedDefectiveImage = (event, item) => {
    if (event.target.checked) {
      item["isSelectedImages"] = true;
      item["assetId"] = +props.assetId;
      if (state.defectiveImagesList?.length > 0) {
        let itemIndex = state.defectiveImagesList.indexOf(item);
        if (itemIndex === -1) state.defectiveImagesList.push(item);
      } else state.defectiveImagesList.push(item);
      state.selectedDefectives[event.target.value] = true;
    } else {
      if (state.defectiveImagesList?.length > 0) {
        let itemIndex = state.defectiveImagesList.indexOf(item);
        state.defectiveImagesList[itemIndex].isSelectedImages = false;
        if (itemIndex !== -1) state.defectiveImagesList.splice(itemIndex, 1);
      }
      state.selectedDefectives[event.target.value] = false;
    }
    setState({
      ...state,
      selectedDefectives: state.selectedDefectives,
      defectiveImagesList: state.defectiveImagesList,
    });
  };

  const onChangeSelectedDefectiveImages = (event, item) => {
    if (event.target.checked) {
      //item['isSelectedImages'] = true;
      if (selectedImagesList?.length > 0) {
        let objIndex = selectedImagesList.findIndex(
          (obj) => obj.id === item.id
        );
        selectedImagesList[objIndex].isSelectedImages = true;

        if (state.selDefeImagesList?.length > 0) {
          let itemIndex = state.selDefeImagesList.indexOf(item);
          if (itemIndex !== -1) state.selDefeImagesList.splice(itemIndex, 1);
        }
      }
    } else {
      if (selectedImagesList?.length > 0) {
        let objIndex = selectedImagesList.findIndex(
          (obj) => obj.id === item.id
        );
        selectedImagesList[objIndex].isSelectedImages = false;

        if (state.selDefeImagesList?.length > 0) {
          let itemIndex = state.selDefeImagesList.findIndex(
            (obj) => obj.id === item.id
          );
          if (itemIndex !== -1)
            selectedImagesList[itemIndex].isSelectedImages = false;
          else {
            item.isSelectedImages = false;
            state.selDefeImagesList.push(item);
          }
        } else {
          item.isSelectedImages = false;
          state.selDefeImagesList.push(item);
        }
      }
    }
    setselectedImagesList(selectedImagesList);
    setState({
      ...state,
      selDefeImagesList: state.selDefeImagesList,
    });
  };

  const openCommentsmodal = (data, action) => {
    if (action === "ADD") {
      setState({ ...state, selectedImage: data, comments: "" });
    }
    if (action === "EDIT") {
      setIsCommentEdit(true);
      setState({
        ...state,
        selectedImage: data,
        comments: data.comments,
      });
    }
    if (isCommentModal) {
      setIsCommentModal(false);
      setIsCommentEdit(false);
    } else {
      setIsCommentModal(true);
    }
  };

  const saveOrupdtaeImageComments = () => {
    if (isCommentEdit) updateImageComments();
    else saveImageComments();
  };

  const saveImageComments = () => {
    let data = {
      assetId: +props.assetId,
      comments: state.comments,
      defectFile: state.selectedImage?.defectFile,
      imageId: state.selectedImage?.imageId,
    };
    api
      .post("addComponentDetail", data)
      .then((res) => {
        if (res.status === "Success") {
          getImagesList();
          toast.success("Comment Added Successfully!");
          setIsCommentModal(false);
        }
      })
      .catch((err) => {
        setIsCommentModal(false);
        toast.error(err);
      });
  };

  const updateImageComments = (value, type) => {
    let data;
    if (type === "DELETE_COMMENTS") {
      data = [
        {
          assetId: +props.assetId,
          comments: "",
          defectFile: value?.defectFile,
          imageId: value?.imageId,
        },
      ];
    } else {
      data = [
        {
          assetId: +props.assetId,
          comments: state.comments,
          defectFile: state.selectedImage?.defectFile,
          imageId: state.selectedImage?.imageId,
        },
      ];
    }
    api
      .put("updateComponentDetail", { componentDetails: data })
      .then((res) => {
        if (res?.status === "failed") {
          toast.error(res?.message);
        } else {
          getSelectedImagesList();
          getImagesList();
          toast.success("Comment Updated Successfully!");
          setIsCommentEdit(false);
          setIsCommentModal(false);
        }
      })
      .catch((err) => {
        setIsCommentModal(false);
        toast.error(err);
      });
  };

  const deleteComments = (item, type) => {
    api
      .deleteApi(`deleteComponentDetail?id=${item.defectFile}`)
      .then((res) => {
        if (res.status?.toLowerCase() === "success") {
          type ? getAssetComponent() : getImagesList();
          toast.success("Comment Deleted Successfully!");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const saveSelectedImages = (list) => {
    api
      .put("updateComponentDetail", { componentDetails: list })
      .then((res) => {
        if (res?.status?.toLowerCase() === "failed") {
          toast.error(res?.errormessagemessage);
        } else {
          getSelectedImagesList();
          getImagesList();
          setState({
            ...state,
            defectiveImagesList: [],
            selDefeImagesList: [],
          });
          toast.success("Selected Defective Images Updated Successfully!");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const getSelectedImagesList = (val) => {
    let value = val ? val : scrollPage;
    api
      .get(
        `getComponentDetails?assetId=${props.assetId}&page=${value}&limit=50`,
        {}
      )
      .then((res) => {
        if (res?.status?.toLowerCase() === "failed") {
          toast.error(res?.errormessage);
          props.setLoading(false);
        } else {
          setselectedImagesList(res.response.componentDetailList);
          setScrollCount(res?.count);
          props.setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        props.setLoading(false);
      });
  };

  const handleSlideChange = (swiper, list) => {
    const activeSlideIndex = swiper.activeIndex;
    const activeSlide = list[activeSlideIndex];
    setImageCount(activeSlideIndex + 1);

    setComment({
      comment: activeSlide.comments,
      commentId: activeSlide.id,
      commentCustomId: activeSlideIndex,
    });
    setTime({
      time: activeSlide.videoTimeStamp,
      timeId: activeSlide.id,
      commentCustomId: activeSlideIndex,
    });
  };

  const finalSubmitofAsset = () => {
    let data = {
      assetStatus: smeCompleted,
      severityStatus: selectedAssetStatus.value,
      inspectionBy: user.loginName,
      id: +props.assetId,
    };

    api.get(`componentDetailsCheck/${+props.assetId}`).then((res) => {
      if (res?.status?.toLowerCase() === "failed") {
        toast.error(res.message);
      } else {
        api
          .put("updateAsset", data)
          .then((res) => {
            if (res?.status?.toLowerCase() === "failed") {
              toast.error(res?.errormessage);
              props.setLoading(false);
            } else {
              toast.success("Asset Components Submitted Successfully");
              setAssetDetails(res?.response);
              props.setLoading(false);
              navigate(-1);
            }
          })
          .catch((error) => {
            toast.error(error);
            props.setLoading(false);
          });
      }
    });
  };

  const rex = /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/;

  const handleLogoDelete = (obj) => {
    const data = obj.defectFile;
    api.deleteApi(`deleteUpload?key=${data}`);
  };

  const handleLogoDeleteTwo = (data) => {
    api.deleteApi(`deleteUpload?key=${data}`);
  };

  const getAssetValue = (val) => {
    const value = enums.filter((a) => a.value === val);

    return value[0]?.label;
  };

  const handleNext = (val) => {
    setCurrPage(val);
    getSelectedImagesList(val);
  };
  const handlePrev = (val) => {
    setCurrPage(val);
    getSelectedImagesList(val);
  };

  const handleObservationNameChange = (val) => {
    setObservationName(val);
    const newVal = enums.filter(
      (a) => a.label.toLowerCase() === val.toLowerCase()
    );
    newVal?.length > 0
      ? setError("Observation Name already exist")
      : setError("");
  };
  return (
    <React.Fragment>
      <ToastContainer />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteConfirm()}
        onCloseClick={() => setDeleteModal(false)}
      />
      {lightboxImage.image !== "" && (
        <Lightbox
          mainSrc={lightboxImage.image}
          imageCaption={lightboxImage.comment}
          onCloseRequest={() => setLightboxImage({ image: "", comment: "" })}
        />
      )}
      {open && (
        <AddComponentOffcanvas
          open={open}
          toggleOffcanvas={toggleOffcanvas}
          assetId={props.assetId}
          title="ADD NEW"
          enums={enums}
          addAssetComponent={addAssetComponent}
          updateEnums={updateEnums}
          getEnums={getEnums}
          observations={observations}
          severities={severities}
          operabilities={operabilities}
          setLoading={props.setLoading}
          locations={locations}
          isVideo={isVideo}
        />
      )}
      <Col md={6}>
        <Card className="asset-component-card">
          <CardHeader className="header">
            <h3 className="fs-16">{assetDetails.assetName}</h3>
            <div className="status">
              <Label className="form-label fs-16 text-muted me-2">
                Asset Status
              </Label>
              {assetDetails.assetStatus !== smeCompleted ? (
                <Select
                  className="fs-13"
                  id="assetStatus"
                  name="assetStatus"
                  value={selectedAssetStatus}
                  options={assetStatusList}
                  onChange={(e) => setSelectedAssetStatus(e)}
                />
              ) : (
                <input
                  value={getAssetValue(assetDetails.severityStatus)}
                  style={{
                    border: "none",
                    background: "white",
                    padding: "5px 10px",
                    width: "100px",
                    fontSize: "13px",
                  }}
                  disabled
                />
              )}
            </div>
          </CardHeader>
          <CardBody className="body mt-3">
            {assetDetails.assetStatus !== smeCompleted && (
              <div className="button mb-3">
                <Button
                  className="fs-14"
                  color="success"
                  onClick={() => {
                    toggleOffcanvas();
                  }}
                >
                  + Component
                </Button>
              </div>
            )}
            <div className="scrollVeticalTwo">
              {state.data?.length > 0 &&
                state.data.map((item) => {
                  return (
                    <Card key={item.id} className="mb-3 me-2">
                      {assetDetails.assetStatus !== smeCompleted && isVideo && (
                        <CardHeader>
                          <div className="img pointer fs-13">
                            <img
                              src={uploadImg}
                              alt=""
                              className="avatar-xxs me-2"
                            />
                            <span
                              className="uploadText"
                              onClick={() => toggle(item)}
                            >
                              Upload
                            </span>
                          </div>
                        </CardHeader>
                      )}

                      <CardBody className="p-0">
                        <Row className="m-0">
                          <Col xs={4}>
                            <Card className="explore-box me-3 cardImg">
                              <div
                                className="cardImges"
                                onDragOver={(e) => onDragOver(e)}
                                style={{ border: "1px solid #ddd" }}
                                onDrop={(e) => handleOnDrop(e, item)}
                              >
                                {item?.componentDetails && (
                                  <Swiper
                                    slidesPerView={1}
                                    spaceBetween={8}
                                    mousewheel={true}
                                    navigation={true}
                                    modules={[
                                      Navigation,
                                      Pagination,
                                      Scrollbar,
                                    ]}
                                    pagination={{ clickable: true }}
                                    onSlideChange={(slide) =>
                                      handleSlideChange(
                                        slide,
                                        item?.componentDetails
                                      )
                                    }
                                    className="swiper product-thumbnail-slider p-2 rounded bg-light"
                                  >
                                    {item?.componentDetails.map((obj, key) => {
                                      return (
                                        <SwiperSlide key={key} data-id="1">
                                          <img
                                            src={obj?.defectFile}
                                            alt=""
                                            className="card-img-top defectImg"
                                          />
                                          <div
                                            className="bookmark-icon position-absolute top-0 start-0 p-2 pointer"
                                            onClick={() =>
                                              setLightboxImage({
                                                image: obj?.defectFile,
                                                comment: obj?.comments,
                                              })
                                            }
                                          >
                                            <i className=" bx bx-fullscreen bx-sm text-white"></i>
                                          </div>
                                          {assetDetails.assetStatus !==
                                            smeCompleted && (
                                            <i
                                              className="position-absolute top-0 end-0 p-2 las la-trash-alt pointer la-lg blackColor fs-22"
                                              onClick={() => {
                                                deleteComments(
                                                  obj,
                                                  "IMAGE_DELETE"
                                                );
                                                handleLogoDelete(obj);
                                              }}
                                            ></i>
                                          )}
                                        </SwiperSlide>
                                      );
                                    })}
                                  </Swiper>
                                )}
                              </div>
                              {item?.componentDetails?.length > 1 && (
                                <div className="ImageCount d-flex align-item-end justify-content-center mt-5 pt-2">
                                  {imageCount}/{item.componentDetails?.length}
                                </div>
                              )}
                            </Card>
                          </Col>
                          <Col xs={8}>
                            <div className="info">
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                              >
                                <Row className="info_title me-4 d-flex align-items-center mb-0 pb-0">
                                  <Col xs={10}>
                                    {item.id === editId ? (
                                      <>
                                        <Input
                                          type="text"
                                          id="componentName"
                                          name="componentName"
                                          {...formik.getFieldProps(
                                            "componentName"
                                          )}
                                        />
                                        {formik.touched.componentName &&
                                        formik.errors.componentName ? (
                                          <div className="error">
                                            {formik.errors.componentName}
                                          </div>
                                        ) : null}
                                      </>
                                    ) : (
                                      <h4
                                        className="fz-16 bold pt-4"
                                        style={{ fontWeight: "500" }}
                                      >
                                        {item.componentName}
                                      </h4>
                                    )}
                                  </Col>
                                  {assetDetails.assetStatus !== smeCompleted &&
                                    editId !== item.id && (
                                      <Col xs={2}>
                                        <div className="d-flex">
                                          <button
                                            type="button"
                                            className="btn avatar-xs"
                                          >
                                            <span>
                                              <i
                                                className="las la-edit fs-4 primary"
                                                onClick={() =>
                                                  handleEditClick(item)
                                                }
                                              ></i>
                                            </span>
                                          </button>
                                          <button
                                            type="button"
                                            className="btn avatar-xs"
                                          >
                                            <span className="fs-18">
                                              <i
                                                className="las la-trash-alt fs-4 text-danger"
                                                onClick={() =>
                                                  handleDeleteClick(item.id)
                                                }
                                              ></i>
                                            </span>
                                          </button>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row className="mb-0 pb-0">
                                  <Col xs={4}>
                                    <div>
                                      <Label className="form-label fs-14 text-muted">
                                        Observation
                                      </Label>

                                      <Input
                                        name="observation"
                                        type="select"
                                        className="bg-light border-0 fs-13"
                                        id="choices-payment-status"
                                        disabled={!(item.id === editId)}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={handleObservationValue(item)}
                                      >
                                        {observations?.map((item, key) => (
                                          <option
                                            value={item.value}
                                            key={key}
                                            className="fs-14"
                                          >
                                            {item.label}
                                          </option>
                                        ))}
                                      </Input>
                                    </div>
                                  </Col>
                                  <Col xs={4}>
                                    <div>
                                      <Label className="form-label fs-14 text-muted">
                                        Severity
                                      </Label>
                                      <Input
                                        name="severity"
                                        type="select"
                                        className="bg-light border-0 fs-13"
                                        id="choices-payment-status"
                                        disabled={!(item.id === editId)}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={handleSeverityValue(item)}
                                      >
                                        {severities?.map((item, key) => (
                                          <option
                                            value={item.value}
                                            key={key}
                                            className="fs-14"
                                          >
                                            {item.label}
                                          </option>
                                        ))}
                                      </Input>
                                    </div>
                                  </Col>

                                  <Col xs={4}>
                                    <div>
                                      <Label className="form-label fs-14 text-muted">
                                        Operability
                                      </Label>
                                      <Input
                                        name="operability"
                                        type="select"
                                        className="bg-light border-0 fs-13"
                                        id="choices-payment-status"
                                        disabled={!(item.id === editId)}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={handleOperabilityValue(item)}
                                      >
                                        {operabilities?.map((item, key) => (
                                          <option
                                            value={item.value}
                                            key={key}
                                            className="fs-14"
                                          >
                                            {item.label}
                                          </option>
                                        ))}
                                      </Input>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mb-0 pb-0">
                                  {formik.values.observation === "OTHERS" &&
                                    item.id === editId && (
                                      <Col xs={6}>
                                        <div>
                                          <Label
                                            htmlFor="observationName"
                                            className="form-label text-muted me-3 fs-14"
                                          >
                                            Observation Name
                                          </Label>
                                          <Input
                                            className="form-control fs-13"
                                            type="text"
                                            value={observationName}
                                            onChange={(e) =>
                                              handleObservationNameChange(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        {formik.values.observation ===
                                          "OTHERS" &&
                                          observationName === "" && (
                                            <div className="error">
                                              Required
                                            </div>
                                          )}
                                        <div className="error">{error}</div>
                                      </Col>
                                    )}
                                  {item?.location !== "" && (
                                    <Col xs={6}>
                                      <div>
                                        <Label className="form-label fs-14 text-muted">
                                          Location
                                        </Label>

                                        <Input
                                          name="location"
                                          type="select"
                                          className="bg-light border-0 fs-13"
                                          id="choices-payment-status"
                                          disabled={!(item.id === editId)}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={handleLocationValue(item)}
                                        >
                                          {locations?.map((item, key) => (
                                            <option
                                              value={item.value}
                                              key={key}
                                              className="fs-13"
                                            >
                                              {item.label}
                                            </option>
                                          ))}
                                        </Input>
                                      </div>
                                    </Col>
                                  )}
                                  {item?.componentDetails?.length > 0 &&
                                    showTime(item) && (
                                      <Col xs={6}>
                                        <div>
                                          <Label className="form-label fs-14 text-muted me-4">
                                            Time (HH:MM:SS)
                                          </Label>
                                          <Input
                                            className="form-control fs-13"
                                            type="text"
                                            value={showTime(item)}
                                            onChange={(e) => {
                                              changeTimeHandler(e, item);
                                            }}
                                            disabled
                                          ></Input>
                                        </div>
                                      </Col>
                                    )}

                                  {item?.componentDetails?.length > 0 && (
                                    <Col xs={12}>
                                      <div>
                                        <Label className="form-label fs-14 text-muted">
                                          Comments
                                        </Label>
                                        {item.id === editId && (
                                          <span className="astriek">*</span>
                                        )}
                                        <textarea
                                          className="form-control fs-13"
                                          rows={1}
                                          value={showComment(item)}
                                          onChange={(e) =>
                                            changeCommentHandler(e, item)
                                          }
                                          disabled={!(item.id === editId)}
                                        ></textarea>
                                        {comment.comment === "" &&
                                          item.id === editId && (
                                            <div className="error">
                                              Required
                                            </div>
                                          )}
                                      </div>
                                    </Col>
                                  )}
                                </Row>

                                {item.id === editId && (
                                  <div className="d-flex justify-content-end mt-4 me-5">
                                    <Button
                                      color="success"
                                      className="me-4"
                                      outline
                                      onClick={() => {
                                        getAssetComponent();
                                        setEditId(null);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      color="success"
                                      type="submit"
                                      disabled={
                                        handleError(item) ||
                                        formik.values.componentName === "" ||
                                        error !== "" ||
                                        (formik.values.observation ===
                                          "OTHERS" &&
                                          observationName === "")
                                      }
                                      onClick={() => handleSubmit(item)}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                )}
                              </Form>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  );
                })}
            </div>

            {count > 10 && (
              <PaginationForList
                itemsPerPage={10}
                count={count}
                handlePageChange={handlePageChange}
              />
            )}
          </CardBody>
          {assetDetails.assetStatus !== smeCompleted && count > 0 && (
            <CardFooter>
              <Button
                color="success"
                disabled={selectedAssetStatus === ""}
                onClick={() => finalSubmitofAsset()}
              >
                Complete
              </Button>
            </CardFooter>
          )}
        </Card>

        <Modal isOpen={modal} id="event-modal" centered className="uploadModal">
          <ModalHeader
            toggle={toggle}
            tag="h5"
            className="p-3 primaryBg  modal-title"
            style={{ color: "#fff" }}
          >
            {assetDetails.assetName}
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col xs={12}>
                  <div className="mb-3 d-flex align-center">
                    <label
                      htmlFor="file"
                      className="btn browse_btn"
                      style={{ border: "1px solid gray" }}
                    >
                      Upload
                    </label>
                    <input
                      hidden
                      id="file"
                      type="file"
                      accept="image/*"
                      placeholder="Browse"
                      onChange={(ev) => uploadImage(ev)}
                      onClick={(ev) => (ev.target.value = null)}
                    />
                    <div className="col-md-10 pointer table-col-link p-ellipsis">
                      <div className="p-2">
                        {uploadUrl !== "" && (
                          <>
                            <img
                              className="avatar-xs"
                              src={uploadUrl}
                              alt="image"
                            />
                            <span>{uploadUrl.split("_")[1]}</span>
                            <span className="ms-5">
                              <i
                                className="las la-trash-alt pointer success"
                                onClick={() => {
                                  handleLogoDeleteTwo(uploadUrl);
                                  setUploadUrl("");
                                }}
                              ></i>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="mb-3">
                  <Label className="form-label fz-16 text-muted ">
                    Comments
                  </Label>
                  <span className="astriek">*</span>
                  <textarea
                    className="me-3 form-control"
                    rows={3}
                    value={uploadComment}
                    onChange={(e) => setUploadComment(e.target.value)}
                    onBlur={handleCommentValidation}
                  ></textarea>
                  {commentErr ? <div className="error">Required</div> : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label fz-16 text-muted">
                    Time(HH:MM:SS)
                  </Label>
                  <span className="astriek">*</span>
                  <Input
                    className="me-3 form-control"
                    type="text"
                    value={uploadTime}
                    onChange={(e) => setUploadTime(e.target.value)}
                    onBlur={handleTimeValidation}
                  ></Input>
                  {timeErr ? <div className="error pb-5">{timeErr}</div> : null}
                </div>
              </Row>
              <div className="hstack gap-2 justify-content-end">
                <button
                  className="btn btn-success"
                  id="btn-save-event"
                  onClick={handleUploadCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success"
                  id="btn-save-event"
                  disabled={
                    uploadUrl !== "" &&
                    uploadComment !== "" &&
                    uploadTime !== "" &&
                    rex.test(uploadTime)
                      ? false
                      : true
                  }
                  onClick={handleUploadSave}
                >
                  Add Image
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </Col>

      {/* assetImage Display */}
      <Col md={6}>
        <Row className="mb-2">
          {assetDetails.assetStatus !== smeCompleted &&
            selectedImagesList?.length > 0 && (
              <Row className="mb-3">
                <Col lg={8} xs={6} sm={8}></Col>
                <Col lg={4} xs={6} sm={4} className="text-align-right">
                  <Button
                    color="success"
                    type="submit"
                    disabled={!state.selDefeImagesList?.length > 0}
                    onClick={() => saveSelectedImages(state.selDefeImagesList)}
                  >
                    Update Selected Images
                  </Button>
                </Col>
              </Row>
            )}

          {selectedImagesList?.length > 0 && (
            <Card>
              <Row className="mx-0">
                {scrollCount > 50 && (
                  <Col xs={1} className="p-0 m-0">
                    <i
                      style={{ cursor: currPage !== 1 ? "pointer" : "auto" }}
                      className="bx bx-chevron-left bx-md"
                      onClick={() => {
                        currPage > 1 && handlePrev(scrollPage - 1);
                      }}
                    ></i>
                  </Col>
                )}
                <Col className="scrollContainer p-0 m-0">
                  <div className="scrollHorizontalCard">
                    {(selectedImagesList || []).map((item, key) => (
                      <div className="scrollContent" key={key}>
                        <Card className="explore-box">
                          <div className="">
                            <div
                              key={item.imageId}
                              draggable
                              onDragStart={(e) => {
                                handleDragStart(e, item);
                              }}
                            >
                              <img
                                src={item.defectFile}
                                alt=""
                                className="card-img-top sliderImg"
                              />
                            </div>
                          </div>

                          {assetDetails.assetStatus !== smeCompleted && (
                            <div className="bookmark-icon position-absolute top-0 start-0 p-2">
                              <div className="form-check form-check-success mb-3 fs-15 flex-grow-1">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={item.imageId}
                                  id={item.imageId}
                                  checked={item.isSelectedImages}
                                  onChange={(ev) =>
                                    onChangeSelectedDefectiveImages(ev, item)
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div
                            className="bookmark-icon position-absolute top-0 end-0 p-2 pointer"
                            onClick={() =>
                              setLightboxImage({
                                image: item.defectFile,
                                comment: item.comments,
                              })
                            }
                          >
                            <i className=" bx bx-fullscreen bx-sm text-white"></i>
                          </div>
                          <CardBody className={!item.comments ? "p-2" : ""}>
                            {item?.comments && (
                              <div>
                                <div className="text-ellipses">
                                  {item?.comments}
                                </div>
                              </div>
                            )}
                          </CardBody>
                        </Card>
                      </div>
                    ))}
                  </div>
                </Col>
                {scrollCount > 50 && (
                  <Col xs={1} className="p-0 m-0">
                    <i
                      style={{
                        cursor: currPage !== maxPageNumber ? "pointer" : "auto",
                      }}
                      className="bx bx-chevron-right bx-md  pointer"
                      onClick={() => {
                        scrollPage < maxPageNumber &&
                          handleNext(scrollPage + 1);
                      }}
                    ></i>
                  </Col>
                )}
              </Row>
            </Card>
          )}

          <Row className=" mb-2 align-items-center my-3 mb-4">
            <Col lg={3} xs={6} sm={3}>
              <div className="d-flex">
                <div className="form-check form-radio-outline form-radio-info mb-3">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="formradiocolor14"
                    id="formradioRight18"
                    value="image"
                    checked={isImageTab === "image" ? true : false}
                    onChange={() => {
                      setIsImageTab("image");
                      getImagesList("image");
                    }}
                  />
                  <Label className="form-check-label" for="formradioRight18">
                    Images
                  </Label>
                </div>
                <div className="form-check form-radio-outline form-radio-info ms-3 mb-3">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="formradiocolor14"
                    id="formradioRight18"
                    value="video"
                    checked={isImageTab === "video" ? true : false}
                    onChange={() => {
                      setIsImageTab("video");
                      getImagesList("video");
                    }}
                  />
                  <Label className="form-check-label" for="formradioRight18">
                    Video
                  </Label>
                </div>
              </div>
            </Col>
            {assetDetails.assetStatus !== smeCompleted &&
              assetImageList?.length > 0 && (
                <Col lg={3} xs={6} sm={3}>
                  <Button
                    color="success"
                    type="submit"
                    disabled={!state.defectiveImagesList?.length > 0}
                    onClick={() =>
                      saveSelectedImages(state.defectiveImagesList)
                    }
                  >
                    Save Selected Images
                  </Button>
                </Col>
              )}

            <Col>
              {countTwo > 12 && (
                <PaginationForList
                  itemsPerPage={12}
                  count={countTwo}
                  handlePageChange={handlePageChangeTwo}
                />
              )}
            </Col>
          </Row>
          <Row
            className={
              selectedImagesList?.length > 0
                ? "scrollVertical"
                : "scrollVerticalThree"
            }
          >
            {isImageTab === "image" ? (
              <>
                {assetImageList?.length > 0 ? (
                  <>
                    {(assetImageList || []).map((item, key) => (
                      <Col
                        xxl={4}
                        xl={4}
                        sm={6}
                        lg={4}
                        className="element-item project designing development"
                        key={key}
                      >
                        <Card className="explore-box">
                          <div>
                            <img
                              draggable="false"
                              src={item.defectFile}
                              alt=""
                              className="card-img-top explore-img"
                            />
                          </div>
                          {assetDetails.assetStatus !== smeCompleted && (
                            <div className="bookmark-icon position-absolute top-0 start-0 p-2">
                              <div className="form-check form-check-success mb-3 fs-15 flex-grow-1">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={item.imageId}
                                  id={item.imageId}
                                  disabled={!item.comments}
                                  checked={item.isSelectedImages}
                                  onChange={(ev) =>
                                    selectedDefectiveImage(ev, item)
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div
                            className="bookmark-icon position-absolute top-0 end-0 p-2 pointer"
                            onClick={() =>
                              setLightboxImage({
                                image: item.defectFile,
                                comment: item.comments,
                              })
                            }
                          >
                            <i className=" bx bx-fullscreen bx-sm text-white"></i>
                          </div>
                          <CardBody className={!item.comments ? "p-2" : ""}>
                            {item?.comments === null ? (
                              <div
                                className="center primaryColor pointer d-flex align-items-center"
                                onClick={() => openCommentsmodal(item, "ADD")}
                              >
                                <i className="bx bx-comment-dots fs-16"></i>
                                <span className="ps-2">Add Comments</span>
                              </div>
                            ) : (
                              <div>
                                {item?.comments === "" ? (
                                  <div
                                    className="center primaryColor pointer"
                                    onClick={() =>
                                      openCommentsmodal(item, "EDIT")
                                    }
                                  >
                                    <i className="bx bx-comment-dots fs-16"></i>
                                    <span className="ps-2">Add Comments</span>
                                  </div>
                                ) : (
                                  <div className="text-center">
                                    <div className="text-ellipses">
                                      {item?.comments}
                                    </div>
                                    {assetDetails.assetStatus !==
                                      smeCompleted && (
                                      <div className="primaryColor">
                                        <span
                                          onClick={() => {
                                            openCommentsmodal(item, "EDIT");
                                          }}
                                          className="pointer"
                                        >
                                          <i className="bx bx-edit fs-16 primary"></i>
                                        </span>
                                        <span
                                          className="pointer"
                                          onClick={() => {
                                            updateImageComments(
                                              item,
                                              "DELETE_COMMENTS"
                                            );
                                          }}
                                        >
                                          <i className="bx bx-trash fs-16 text-danger"></i>
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </>
                ) : (
                  <>
                    <CardBody className="chartBodyHt text-center">
                      <h4>Images are not available.</h4>
                    </CardBody>
                  </>
                )}
              </>
            ) : (
              <>
                {assetImageList?.length > 0 ? (
                  <>
                    {(assetImageList || []).map((item, key) => (
                      <Col
                        xxl={4}
                        xl={4}
                        sm={6}
                        lg={4}
                        className="element-item project designing development"
                        key={key}
                      >
                        <div>
                          <video
                            width="250"
                            height="240"
                            controls
                            src={item.defectFile}
                            type="video/mp4"
                          >
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </Col>
                    ))}
                  </>
                ) : (
                  <>
                    <CardBody className="chartBodyHt text-center">
                      <h4>Videos are not available.</h4>
                    </CardBody>
                  </>
                )}
              </>
            )}
          </Row>
        </Row>
        {/* Comments Modal */}
        <Modal
          isOpen={isCommentModal}
          id="event-modal"
          centered
          className="uploadModal"
        >
          <ModalHeader
            toggle={openCommentsmodal}
            tag="h5"
            className="p-3 primaryBg  modal-title"
            style={{ color: "#fff" }}
          >
            {!!isCommentEdit ? "Edit Comments" : "Add Comments"}
          </ModalHeader>
          <ModalBody>
            <Form
              className="needs-validation"
              name="event-form"
              id="form-event"
              onSubmit={(e) => {
                e.preventDefault();
                saveOrupdtaeImageComments();
                return false;
              }}
            >
              <Row className="event-form">
                <Col xs={12}>
                  <div className="mb-3">
                    <Label className="form-label">Comments</Label>
                    <span className="astriek">*</span>
                    <textarea
                      className="form-control d-block"
                      id="event-description"
                      name="description"
                      placeholder="Enter Comments"
                      rows="3"
                      onChange={(event) => {
                        setState({
                          ...state,
                          comments: event.target.value,
                        });
                      }}
                      value={state.comments || ""}
                    ></textarea>
                  </div>
                </Col>
              </Row>
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="submit"
                  className="btn btn-success"
                  id="btn-save-event"
                  disabled={!state.comments.trim()}
                >
                  {!!isCommentEdit ? "Update" : "Save"}
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </Col>
    </React.Fragment>
  );
};

export default AddAssetCard;
