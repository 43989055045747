import * as api from "./api";

const getData = (url: string) => {
  return api
    .getReq(url)
    .then((res: any) => {
      if (!!res?.data.contents) {
        return { data: res?.data.contents };
      } else if (res?.data?.status === "Success") {
        return { data: res?.data?.response };
      } else if (res?.data) {
        return { data: res?.data };
      } else if (
        res?.data?.status?.toLowerCase() === "failed" ||
        res?.status?.toLowerCase() === "failed"
      ) {
        return { errorMessage: res?.data?.errormessage || res?.errormessage };
      } else {
        return { data: res?.data };
      }
    })
    .catch((error) => {
      return { errorMessage: error };
    });
};

const addData = (url: string, data: any) => {
  return api
    .postReq(url, data)
    .then((res: any) => {
      if (res?.data?.status?.toLowerCase() === "success") {
        return { data: res?.data?.response || res?.data };
      } else if (res?.data?.status?.toLowerCase() === "failed") {
        return { errorMessage: res?.data?.errormessage || res?.data?.message };
      } else {
        return { data: res?.data };
      }
    })
    .catch((error) => {
      return { errorMessage: error };
    });
};

const updateData = (url: string, data: any) => {
  return api
  .putReq(url, data)
  .then((res: any) => {
    if (res?.data?.status?.toLowerCase() === "success") {
      return { data: res?.data?.response || res?.data };
    } else if (res?.data?.status?.toLowerCase() === "failed") {
      return { errorMessage: res?.data?.errormessage || res?.data?.message };
    } else {
      return { data: res?.data };
    }
  })
  .catch((error) => {
    return { errorMessage: error };
  });
};

const deleteData = (url: any) => {
  return api
    .deleteReq(url)
    .then((res: any) => {
      if (res?.data?.status?.toLowerCase() === "success") {
        return { data: res?.data?.response };
      } else if (res?.data?.status?.toLowerCase() === "failed") {
        return { errorMessage: res?.data?.errormessage || res?.data?.message };
      } else {
        return { data: res?.data };
      }
    })
    .catch((error) => {
      return { errorMessage: error };
    });
};

export { getData, addData, updateData, deleteData };
