import React, { useState, useEffect } from "react";
import {
  OffcanvasHeader,
  OffcanvasBody,
  Offcanvas,
  Container,
  Form,
  Label,
  Input,
  Button,
  Card,
  Row,
  Col,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import * as api from "../../../../api/global.api";

const AddComponentOffcanvas = (props) => {
  const { observations, severities, operabilities, locations } = props;
  const [selectedObservation, setSelectedObservation] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedSeverity, setSelectedSeverity] = useState(null);
  const [selectedOperability, setSelectedOperability] = useState(null);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [observationName, setObservationName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [comment, setComment] = useState("");
  const [time, setTime] = useState("");
  const [error, setError] = useState("");
  const [commentErr, setCommentErr] = useState(false);
  const [timeErr, setTimeErr] = useState("");

  const initialValues = {
    componentName: "",
    observation: "",
    severity: "",
    operability: "",
    location: "",
  };

  const onSubmit = (values) => {
    if (values.observation === "OTHERS" && fileUrl === "") {
      const newValues = {
        ...values,
        observation: observationName.trim().toUpperCase().replaceAll(" ", "_"),
      };
      const enumName = observationName
        .trim()
        .toUpperCase()
        .replaceAll(" ", "_");
      const enumValue = observationName.trim();
      props.addAssetComponent(newValues);
      props.updateEnums(enumName, enumValue);
    } else if (values.observation === "OTHERS" && fileUrl !== "") {
      const newValues = {
        ...values,
        observation: observationName.trim().toUpperCase().replaceAll(" ", "_"),
        componentDetails: {
          create: [
            {
              assetId: +props.assetId,
              comments: comment,
              defectFile: fileUrl,
              videoTimeStamp: time,
            },
          ],
        },
      };
      const enumName = observationName
        .trim()
        .toUpperCase()
        .replaceAll(" ", "_");
      const enumValue = observationName.trim();
      props.addAssetComponent(newValues);
      props.updateEnums(enumName, enumValue);
    } else if (values.observation !== "OTHERS" && fileUrl !== "") {
      const newValues = {
        ...values,
        componentDetails: {
          create: [
            {
              assetId: +props.assetId,
              comments: comment,
              defectFile: fileUrl,
              videoTimeStamp: time,
            },
          ],
        },
      };

      props.addAssetComponent(newValues);
    } else {
      props.addAssetComponent(values);
    }

    props.toggleOffcanvas();
    setSelectedObservation(null);
    setSelectedSeverity(null);
    setSelectedOperability(null);
    setSelectedLocation(null);
  };
  const phoneRegEx = /^[0-9]+$/;
  const alphabetRegex = /^[A-Za-z0-9 ]+$/;

  const validationSchema = Yup.object({
    componentName: Yup.string()
      .matches(alphabetRegex, "Please enter alphanumeric character")
      .max(50, "component name exceeded 50 character")
      .required("required"),
    observation: Yup.string().required(),
    severity: Yup.string().required(),
    operability: Yup.string().required(),
    observationName: Yup.string().when("observation", {
      is: Yup.string(),
      then: Yup.string().required("required"),
    }),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const handleSelectObservation = (obs) => {
    formik.setFieldValue("observation", obs.value);
    setSelectedObservation(obs);
  };

  const handleSelectSeverity = (obs) => {
    formik.setFieldValue("severity", obs.value);
    setSelectedSeverity(obs);
  };

  const handleSelectOperability = (obs) => {
    formik.setFieldValue("operability", obs.value);
    setSelectedOperability(obs);
  };

  const handleSelectLocation = (obs) => {
    formik.setFieldValue("location", obs.value);
    setSelectedLocation(obs);
  };

  const handleCancel = () => {
    props.toggleOffcanvas();
    formik.resetForm();
    setSelectedObservation(null);
    setSelectedSeverity(null);
    setSelectedOperability(null);
    setSelectedLocation(null);
  };

  const handleAcceptedFiles = (files) => {
    props.setLoading(true);
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    const formData = new FormData();
    formData.append("file", files[0]);
    api
      .post("fileUpload", formData)
      .then((res) => {
        if (res?.status === "failed") {
          props.setLoading(false);
          toast.error(res?.message);
        } else {
          setFileUrl(res.url);
          toast.success("Image Uploaded Successfully");
          setselectedFiles(files);
          props.setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        props.setLoading(false);
      });
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const formValidCheck = () => {
    if (fileUrl !== "" && comment !== "" && time !== "") {
      return true;
    }
    return false;
  };
  const rex = /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/;

  const handleLogoDelete = () => {
    const data = fileUrl;
    api.deleteApi(`deleteUpload?key=${data}`);
  };

  const handleObservationNameChange = (val) => {
    setObservationName(val);
    const newVal = observations.filter(
      (a) => a.label.toLowerCase() === val.toLowerCase()
    );
    newVal.length > 0
      ? setError("Observation Name already exist")
      : setError("");
  };

  const handleCommentValidation = () => {
    if (fileUrl !== "" && comment === "") setCommentErr(true);
    else setCommentErr(false);
  };

  const handleTimeValidation = () => {
    if (fileUrl !== "" && time == "") setTimeErr("Required");
    else if (fileUrl !== "" && !rex.test(time))
      setTimeErr("Please enter time as HH:MM:SS");
    else setTimeErr("");
  };
  return (
    <>
      <ToastContainer />
      <Offcanvas
        isOpen={props?.open}
        toggle={props?.toggleOffcanvas}
        direction="end"
        className="offcanvas-end border-0"
        backdrop={false}
      >
        <OffcanvasHeader
          className="d-flex align-items-center bg-success bg-gradient p-3 offcanvas-header-dark"
          toggle={props?.toggleOffcanvas}
        >
          <span className="m-0 me-2 text-white">{props?.title}</span>
        </OffcanvasHeader>
        <OffcanvasBody className="p-0 mt-4">
          <Container fluid>
            <Form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Label
                  htmlFor="componentName"
                  className="form-label text-muted"
                >
                  Component Name
                </Label>
                <span className="astriek">*</span>
                <Input
                  type="text"
                  className="form-control"
                  id="componentName"
                  name="componentName"
                  {...formik.getFieldProps("componentName")}
                />
                {formik.touched.componentName && formik.errors.componentName ? (
                  <div className="error">{formik.errors.componentName}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <Label htmlFor="observation" className="form-label text-muted">
                  Observation
                </Label>
                <span className="astriek">*</span>
                <Select
                  id="observation"
                  name="observation"
                  value={selectedObservation}
                  onChange={handleSelectObservation}
                  options={observations}
                />
              </div>
              {selectedObservation?.label === "Others" && (
                <div className="mb-3">
                  <Label
                    htmlFor="observationName"
                    className="form-label text-muted"
                  >
                    Observation Name
                  </Label>
                  <span className="astriek">*</span>
                  <Input
                    id="observationName"
                    value={observationName}
                    onChange={(e) =>
                      handleObservationNameChange(e.target.value)
                    }
                  />
                  {observationName === "" ? (
                    <div className="error">Required</div>
                  ) : null}
                  {error !== "" && <div className="error">{error}</div>}
                </div>
              )}
              <div className="mb-3">
                <Label htmlFor="severity" className="form-label text-muted">
                  Severity
                </Label>
                <span className="astriek">*</span>
                <Select
                  id="severity"
                  name="severity"
                  value={selectedSeverity}
                  onChange={handleSelectSeverity}
                  options={severities}
                />
              </div>
              <div className="mb-3">
                <Label htmlFor="operability" className="form-label text-muted">
                  Operability
                </Label>
                <span className="astriek">*</span>
                <Select
                  id="operability"
                  name="operability"
                  value={selectedOperability}
                  onChange={handleSelectOperability}
                  options={operabilities}
                />
              </div>
              <div className="mb-3">
                <Label htmlFor="operability" className="form-label text-muted">
                  Location
                </Label>
                <Select
                  id="location"
                  name="location"
                  value={selectedLocation}
                  onChange={handleSelectLocation}
                  options={locations}
                />
              </div>
              {props.isVideo && (
                <div className="mb-5">
                  <Label htmlFor="upload" className="form-label text-muted">
                    Upload
                  </Label>
                  <Card>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                      accept="image/*"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone dz-clickable">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps({ refKey: "innerRef" })}
                          >
                            <input {...getInputProps()} />
                            {selectedFiles.length === 0 && (
                              <h4>Drop files here or click to upload.</h4>
                            )}
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {selectedFiles.length < 2 ? (
                      <div className="list-unstyled mb-0" id="file-previews">
                        {selectedFiles?.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="d-flex align-center justify-content-between me-3">
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                                <div
                                  onClick={() => {
                                    handleLogoDelete();
                                    setFileUrl("");
                                    setselectedFiles([]);
                                  }}
                                >
                                  <i className="las la-trash-alt la-lg pointer"></i>
                                </div>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="error">Please Upload one File</p>
                    )}
                  </Card>
                </div>
              )}
              {selectedFiles.length > 0 && (
                <React.Fragment>
                  <div className="mb-3">
                    <Label htmlFor="comments" className="form-label text-muted">
                      Comments
                    </Label>
                    <span className="astriek">*</span>
                    <textarea
                      className="form-control"
                      id="comments"
                      rows="3"
                      values={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      onBlur={handleCommentValidation}
                    ></textarea>
                    {commentErr ? <div className="error">Required</div> : null}
                  </div>
                  <div className="mb-5">
                    <Label htmlFor="time" className="form-label text-muted">
                      Time <span>(HH:MM:SS)</span>
                    </Label>
                    <span className="astriek">*</span>
                    <Input
                      type="text"
                      className="form-control"
                      id="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      onBlur={handleTimeValidation}
                    />
                    {timeErr ? (
                      <div className="error pb-5">{timeErr}</div>
                    ) : null}
                  </div>
                </React.Fragment>
              )}

              <div className="mb-3 d-flex justify-content-end offcanvas-footer me-4">
                <Button
                  className="btn-outline-success me-3"
                  color="success"
                  outline
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  type="submit"
                  disabled={
                    fileUrl === ""
                      ? formik.values.observation !== "OTHERS"
                        ? !(formik.dirty && formik.isValid)
                        : !(
                            formik.dirty &&
                            formik.isValid &&
                            observationName !== ""
                          )
                      : formik.values.observation !== "OTHERS"
                      ? !(formik.dirty && formik.isValid && formValidCheck())
                      : !(
                          formik.dirty &&
                          formik.isValid &&
                          formValidCheck() &&
                          observationName !== ""
                        )
                  }
                >
                  Save
                </Button>
              </div>
            </Form>
          </Container>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default AddComponentOffcanvas;
