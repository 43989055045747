import React, { useEffect, useState } from "react";
import UserDataTable from "../../tables/UserDataTable";

const UserTable = ({ data, handlePageChange, deleteUser }: any) => {


  return (
    <React.Fragment>
      <UserDataTable data={data?.data} deleteUser={deleteUser} />
    </React.Fragment>
  );
};

export default UserTable;
