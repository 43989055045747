import axios from "axios";
import store from "../store/store";

const getReq = (url: string, headers = {}) => {
  headers = makeDefaultHeaders(headers);
  return axios({
    headers: headers,
    method: "get",
    url: getFullURL(url),
  }).catch((error) => {
    if (error.response.status === 400) {
      return error.response.data;
    }
    if (error.response.status !== 400) {
      //handleError(error.response.status);
      return error.response.status;
    }
  });
};

const loginReq = (url: string, data = {}, headers = {}) => {
  return axios({
    headers: headers,
    method: "post",
    data: data,
    url: getFullURL(url),
  });
};
const postReq = (url: string, data = {}, headers = {}) => {
  headers = makeDefaultHeaders(headers);
  return axios({
    headers: headers,
    method: "post",
    data: data,
    url: getFullURL(url),
  }).catch((error) => {
    if (error.response.status === 400) {
      return error.response.data;
    }
    if (error.response.status !== 400) {
      return error.response.status;
    }
  });
};

const putReq = (url: string, data = {}, headers = {}) => {
  headers = makeDefaultHeaders(headers);
  return axios({
    headers: headers,
    method: "put",
    data: data,
    url: getFullURL(url),
  }).catch((error) => {
    if (error.response.status === 400) {
      return error.response.data;
    }
    if (error.response.status !== 400) {
      handleError(error.response.status);
      return error.response.status;
    }
  });
};

const deleteReq = (url: string, headers = {}) => {
  headers = makeDefaultHeaders(headers);
  return axios({
    headers: headers,
    method: "delete",
    url: getFullURL(url),
  }).catch((error) => {
    if (error.response.status === 400) {
      return error.response.data;
    }
    if (error.response.status !== 400) {
      handleError(error.response.status);
      return error.response.status;
    }
  });
};

const deleteFileReq = (url: string, data = {}, headers = {}) => {
  headers = makeDefaultHeaders(headers);
  return axios({
    headers: headers,
    method: "delete",
    data: data,
    url: getFullURL(url),
  }).catch((error) => {
    if (error.response.status === 400) {
      return error.response.data;
    }
    if (error.response.status !== 400) {
      handleError(error.response.status);
      return error.response.status;
    }
  });
};

const download = (url: string, data = {}, headers = {}, params = {}) => {
  headers = makeDefaultHeaders(headers);
  return axios({
    headers: headers,
    method: "post",
    data: data,
    params: params,
    url: getFullURL(url),
    responseType: "blob",
  }).catch((error) => {
    if (error.response) {
      handleError(error.response.status);
      return error.response.status;
    }
  });
};
const downloadGet = (url: string, data = {}, headers = {}) => {
  headers = makeDefaultHeaders(headers);
  return axios({
    headers: headers,
    method: "get",
    data: data,
    url: getFullURL(url),
    responseType: "blob",
  }).catch((error) => {
    if (error.response) {
      handleError(error.response.status);
      return error.response.status;
    } else {
      return {
        errormessage: error.response.data,
      };
    }
  });
};

const getFullURL = (path: string) => {
  return process.env.REACT_APP_API_URL + path;
};

const makeDefaultHeaders = (headers: any) => {
  let user = store.getState().user;
  var token = "";
  if (user && user.selectedRole) {
    token = "Basic " + window.btoa(user.token);
  }
  headers.Authorization = token;
  // headers['X-SESSION-TOKEN']= sessionToken;
  return headers;
};

const handleError = (status: any) => {
  if (status === 404) {
    window.location.replace("/Error404");
  } else if (status === 500) {
    window.location.replace("/Error500");
  }
};

const postSegReq = (data = {}, headers = {}) => {
  console.log("data", data);
  headers = makeDefaultHeaders(headers);
  return axios({
    headers: headers,
    method: "post",
    data: data,
    url: "https://ai.novaplus.in/process_and_upload_images",
    // url:"http://192.168.21.225:5000/process_and_upload_images"
  }).catch((error) => {
    console.log(error);
    if (error?.response?.status === 400) {
      return error?.response?.data;
    }
    if (error?.response?.status !== 400) {
      return error?.response?.status;
    }
  });
};

const getFullUrl = (url: string) => {
  return process.env.REACT_APP_VOL_URL + url;
};

const calculateVolume = (url: string, data = {}, headers = {}) => {
  headers = makeDefaultHeaders(headers);
  return axios({
    headers: headers,
    method: "post",
    data: data,
    url: getFullUrl(url),
  }).catch((error) => {
    console.log(error);
    if (error?.response?.status === 400) {
      return error?.response?.data;
    }
    if (error?.response?.status !== 400) {
      return error?.response?.status;
    }
  });
};

export {
  loginReq,
  getReq,
  putReq,
  download,
  downloadGet,
  postReq,
  deleteReq,
  deleteFileReq,
  postSegReq,
  calculateVolume,
};
