import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  project: {},
};

export const userSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    projectTsx: (state, action) => {
      return action.payload;
    },
  },
});

export const { projectTsx } = userSlice.actions;
export default userSlice.reducer;
