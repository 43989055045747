import React, { useEffect, useState } from "react";
import { ModalBody, ModalHeader } from "react-bootstrap";
import { Button, Col, Input, Label, Modal, ModalFooter, Row } from "reactstrap";
import * as api from "../../../api/global.api";
import { toast } from "react-toastify";

const Add3DSource = ({
  show,
  onClose,
  editData,
  get3DSources,
  surveyId,
  projectId,
}) => {
  const [formData, setFormData] = useState(null);
  useEffect(() => {
    if (editData) {
      setFormData(editData);
    }
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    let response, obj;
    if (editData) {
      obj = {
        id: formData?.id,
        fileName: formData?.fileName,
        fileLink: formData?.fileLink,
        surveyId: +surveyId,
        projectId: +projectId,
      };
      response = await api.post("source3d/update", obj);
    } else {
      obj = {
        ...formData,
        surveyId: +surveyId,
        projectId: +projectId,
      };
      response = await api.post("source3d/add", obj);
    }
    if (response?.status === "Success") {
      editData
        ? toast.success("3D Source updated successfully")
        : toast.success("3D Source added successfully");
      get3DSources();
      onClose(false);
    } else {
      toast.error(response?.errormessage);
    }
  };

  const handleFormValid = () => {
    if (
      !formData?.fileName ||
      !formData?.fileLink ||
      formData?.fileName?.trim() === "" ||
      formData?.fileLink?.trim() === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <Modal isOpen={show} toggle={() => onClose(false)} backdrop={false}>
        <ModalHeader>
          <span className="text-light fw-500">
            {editData ? "Edit 3D Source" : "Add 3D Source"}
          </span>
          <i
            className="bx bx-x fs-2 text-light pointer"
            onClick={() => onClose(false)}
          ></i>
        </ModalHeader>
        <ModalBody className="p-3">
          <Row>
            <Col md={12} className="mb-3">
              <Label>File Name</Label>
              <Input
                type="text"
                name="fileName"
                value={formData?.fileName}
                onChange={handleInputChange}
              />
            </Col>

            <Col md={12}>
              <Label>File Link</Label>
              <Input
                type="text"
                name="fileLink"
                value={formData?.fileLink}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button
                color="danger"
                outline
                className="me-3"
                onClick={() => onClose(false)}
              >
                Cancel
              </Button>
              <Button
                className="btn-primary"
                onClick={handleSave}
                disabled={handleFormValid()}
              >
                {editData ? "Update" : "Submit"}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Add3DSource;
