import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "reactstrap";
import videoimg from "../../assets/svg-new/videoBg.png";
const VideoCard = ({ data, records, status }) => {
  const location = useLocation().state;
  const navigate = useNavigate();
  let fileName = data?.fileName?.split(".")[0];

  return (
    <React.Fragment>
      <Card className="img-card-div">
        <img
          className="pointer"
          src={videoimg}
          onClick={() =>
            navigate("/sme-tower-component-video", {
              state: { data, records, location, status },
            })
          }
        />
        <span className="p-2 text-center">{fileName}</span>
      </Card>
    </React.Fragment>
  );
};

export default VideoCard;
