import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem, Card, Col, Collapse, Row } from "reactstrap";
import classnames from "classnames";
import DataTable from "react-data-table-component";
import { getDate, getDateOnly } from "../Components/Utils";
import Dropzone from "react-dropzone";
import pdfimg from "../../assets/svg-new/pdficon.svg";
import calimg from "../../assets/svg-new/CalanderImg.svg";
import * as api from "../../api/rest.api";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteModal from "../../components/Common/DeleteModal";

const ReportCard = (props) => {
  const location = useLocation().state;
  const [fileName, setFileName] = useState(null);
  const [date, setDate] = useState(null);

  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(true);
  const [files, setFiles] = useState([]);
  const [rows, setRows] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const t_col1 = () => {
    setcol1(!col1);
  };
  const t_col2 = () => {
    setcol2(!col2);
  };

  const columns = [
    { name: "File Name", selector: "fileName", grow: 2 },
    { name: "Uploaded Date", cell: (row) => getDateOnly(row?.createdDate) },
    {
      name: "Action",
      cell: (row) => (
        <i
          className="bx bx-show fs-4 pointer"
          onClick={() => hanldeViewPdf(row)}
        ></i>
      ),
    },
  ];

  const data = [
    {
      fileName: fileName,
      createdDate: date,
    },
  ];

  const hanldeViewPdf = (data) => {
    let url = data.defectFile
      ? data.defectFile
      : props?.towerReport?.mergeReport;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = `${url}`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const acceptFile = async (files) => {
    let finalFiles = [];
    files.map((file) => {
      let ext = file?.name?.split(".")?.pop()?.toLowerCase();
      if (ext === "pdf" || ext === "doc" || ext === "docx") {
        finalFiles.push(file);
      }
    });
    if (finalFiles.length > 0) {
      props?.setLoading(true);
      const formData = new FormData();
      formData.append("id", location?.data?.id);
      formData.append("fileType", "report");
      for (let i = 0; i < finalFiles?.length; i++) {
        formData.append("file", finalFiles[i]);
      }
      let res = await api.addData("thermaluploads", formData);
      if (res?.data?.status === "Success") {
        toast.success("Report uploaded successfully");
        props?.getThermal();
      } else {
        toast.error(res.errorMessage);
        props?.setLoading(false);
      }
    } else {
      toast.error("Please upload .pdf, .doc or .docx files only");
    }
  };

  const deleteUpload = (file) => {
    setRows(file);
    setIsDelete(true);
  };

  const handleDelete = async () => {
    props.setLoading(true);
    let res = await api.addData(
      `deleteThermalReportFiles?id=${props?.records?.towerId}&fileType=report&fileName=${rows?.filename}`,
      {}
    );
    if (res?.data) {
      setIsDelete(false);
      toast.success("Report deleted successfully");
      props.getThermal();
    } else {
      toast.error(res.errorMessage);
    }
  };

  useEffect(() => {
    setFiles(props?.records?.uploadJsonData);
  }, [props?.records]);

  useEffect(() => {
    setFileName(props?.towerReport?.mergeReport?.split("/").pop());
    setDate(props?.towerReport?.mergeReportDate);
  }, [props.towerReport]);
  return (
    <div className="thermal">
      <DeleteModal
        show={isDelete}
        onCloseClick={() => setIsDelete(false)}
        onDeleteClick={handleDelete}
      />
      <Accordion id="default-accordion-example">
        <AccordionItem>
          <h2 className="accordion-header" id="headingOne">
            <button
              className={classnames("accordion-button fw-semifw-500", {
                collapsed: !col1,
              })}
              type="button"
              onClick={t_col1}
              style={{ cursor: "pointer" }}
            >
              Thermal Reports
            </button>
          </h2>

          <Collapse
            isOpen={col1}
            className="accordion-collapse"
            id="collapseOne"
          >
            <div className="accordion-body">
              {props?.status !== "Completed" && (
                <Row className="mb-4">
                  <div className="color-grayl mb-2">
                    Upload File <span className="astriek">*</span>
                  </div>
                  <Dropzone onDrop={(files) => acceptFile(files)}>
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone dz-clickable form-control p-0">
                        <div className="needsclick" {...getRootProps()}>
                          <input {...getInputProps()} accept="images/*" />
                          <div className="m-4 d-flex align-items-center justify-content-center">
                            <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />
                            <span className="text-muted">
                              Drop files here or click to upload
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </Row>
              )}
              {files?.length > 0 && (
                <Row>
                  {files?.map((file) => (
                    <Col md={6}>
                      <Card>
                        <div className="d-flex align-items-center p-2">
                          <img
                            src={pdfimg}
                            alt="file"
                            className="me-3"
                            style={{
                              border: "1px solid #AAADAF",
                              padding: "10px",
                              borderRadius: "8px",
                              cursor: "pointer",
                            }}
                            onClick={() => hanldeViewPdf(file)}
                          />
                          <div>
                            <div className="fs-18">{file?.filename}</div>
                            {file?.uploadedDate && (
                              <div className="fs-16">
                                <img
                                  src={calimg}
                                  width="20px"
                                  className="me-2"
                                />
                                {getDate(file?.uploadedDate)}
                              </div>
                            )}
                          </div>
                          {props?.status !== "Completed" && (
                            <div className="icon-delete-file">
                              <i
                                className="bx bx-trash fs-4 text-danger pointer"
                                onClick={() => deleteUpload(file)}
                              ></i>
                            </div>
                          )}
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </Collapse>
        </AccordionItem>
        <AccordionItem>
          <h2 className="accordion-header" id="headingTwo">
            <button
              className={classnames("accordion-button fw-semifw-500", {
                collapsed: !col2,
              })}
              type="button"
              onClick={t_col2}
              style={{ cursor: "pointer" }}
            >
              Generated Reports
            </button>
          </h2>

          <Collapse isOpen={col2} className="accordion-collapse">
            <div className="accordion-body">
              <DataTable columns={columns} data={fileName ? data : []} />
            </div>
          </Collapse>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default ReportCard;
