import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ModuleCard from "./ModuleCard";
import AddFeatures from "./AddFeatures";
import * as api from "../../../api/global.api";

import { toast, ToastContainer } from "react-toastify";
import Loader from "../../CommonComponent/Loader";
import Pagination from "../../commons/pagination";
import "../../../assets/scss/module.css";

const ModuleManagement = () => {
  const navigate = useNavigate();
  //switch table and card
  const [isModal, setIsModal] = useState<any>(false);
  //loading
  const [loading, setLoading] = useState<any>(false);
  //data
  const [data, setData] = useState<any>([]);
  const [showFeatures, setShowFeatures] = useState<any>();
  //pagination
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [totalCount, setTotalCount] = useState<any>();

  const getModules = (val: any) => {
    setLoading(true);
    api
      .get(`getModules?${val}`)
      .then((res) => {
        if (res?.status === "Success") {
          setData(res?.response?.rows);
          setTotalCount(res?.response.totalCount);
          setLoading(false);
        } else {
          toast.error(res?.errormessage);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const deleteModuleApi = (id: any) => {
    setLoading(true);
    api
      .deleteApi(`deleteModule/${id}`)
      .then((res) => {
        if (res?.status === "Success") {
          getModules(`page=${currentPage}&limit=12`);
          toast.success("Module deleted successfully");
        } else {
          setLoading(false);
          toast.error(res?.errormessage);
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const updateModule = (val: any) => {
    setLoading(true);
    api
      .put("updateModule", val)
      .then((res) => {
        if (res?.status === "Success") {
          getModules(`page=${currentPage}&limit=12`);
          toast.success("Standard feature checklist is updated successfully");
        } else {
          setLoading(false);
          toast.error(res?.errormessage);
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const handlePageChange = (val: any) => {
    setCurrentPage(val);
    getModules(`page=${val}&limit=12`);
  };

  const handleModal = (val: any) => {
    setIsModal(val);
  };

  const handleAddFeatures = (val: any) => {
    setShowFeatures(val);
  };

  const handleAddModule = () => {
    sessionStorage.removeItem("editModule");
    navigate("/module");
  };

  useEffect(() => {
    getModules("page=1&limit=12");
  }, []);

  return (
    <div className="px-3 mt-3 ptable">
      <Loader loader={loading} />
      <ToastContainer />

      <Card className="card-border-none">
        <CardHeader className="header-card">
          <div className="d-flex justify-content-between align-items-center admin-card-head">
            <div>All Modules</div>
            <Button
              color="success"
              onClick={() => {
                handleAddModule();
              }}
            >
              <span className="me-2">+</span>
              <span className="admin-card-head">Add Module</span>
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <ModuleCard
            data={data}
            addFeatures={handleAddFeatures}
            deleteModuleApi={deleteModuleApi}
            handleModal={handleModal}
          />
        </CardBody>
        {totalCount > 12 && (
          <CardFooter className="footer-card card-border-none">
            <Pagination
              itemsPerPage={12}
              count={totalCount}
              handlePageChange={handlePageChange}
            />
          </CardFooter>
        )}
      </Card>
      {isModal && (
        <AddFeatures
          isModal={isModal}
          handleModal={handleModal}
          data={showFeatures}
          updateModule={updateModule}
        />
      )}
    </div>
  );
};

export default ModuleManagement;
