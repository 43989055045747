import React from "react";
import { useNavigate } from "react-router-dom";
import error400cover from "../../assets/404-error.png";
import store from "../../store/store";
import { Header } from "../header/Header";

const Error404 = () => {
  let navigate = useNavigate();
  let user = store.getState().user;
  document.title = "404 Error Cover | GroundGame Health ";
  const handleHome = () => {
    document.title = "Land Survey web visualizer";
    navigate("/client-projects");
  };
  return (
    <>
      {user?.role?.roleCode !== "ADMIN" &&
        user?.role?.roleCode !== "PROCESSING TEAM" &&
        user?.role?.roleCode !== "PILOT" && user?.selectedModule?.toLowerCase() !== 'oil and gas' && <Header />}
      <div className="auth-page-content">
        <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
          <div className="auth-page-content overflow-hidden p-0">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="text-center">
                  <img
                    src={error400cover}
                    alt="error img"
                    className="img-fluid"
                  />  
                  <div className="mt-3">
                    <h3 className="text-uppercase">Sorry, Page not Found 😭</h3>
                    <p className="text-muted mb-4">
                      The page you are looking for not available!
                    </p>
                    <div onClick={handleHome} className="btn btn-success">
                      <i className="mdi mdi-home me-1"></i>Back to home
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error404;
