import React, { useState } from "react";
import { Header } from "./Header";
import Sidebar from "./Sidebar";

const AdminHeader = ({ children }) => {
  const [isActive, setIsActive] = useState(true);
  const handleToggle = () => {
    setIsActive(!isActive);
  };
  return (
    <div className="app-content">
      <Header />
      <div className="d-flex page-content ps-0">
        <Sidebar isActive={isActive} handleToggle={handleToggle} />
        <div className={isActive ? "activeSideBar" : "inActiveSidebar"}>
          {children}
        </div> 
      </div>
    </div>
  );
};

export default AdminHeader;
