import { useFormik } from "formik";
import { useEffect } from "react";
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { Capitalize } from "../../commons/functions";

const AddFeatureModel = ({
  isModal,
  handleModal,
  handleNewFeature,
  handleUpdateFeature,
  editFeature,
}: any) => {
  const formik = useFormik({
    initialValues: { featureName: "" },
    validationSchema: Yup.object({
      featureName: Yup.string().required("required"),
    }),
    onSubmit: (values: any) => {
      editFeature === null
        ? handleNewFeature(values)
        : handleUpdateFeature(values);
      handleModal(false);
    },
  });

  const clearForm = () => {
    handleModal(false);
  };

  useEffect(() => {
    if (editFeature) {
      formik.setFieldValue("featureName", editFeature?.featureName);
    }
  }, []);

  return (
    <Modal isOpen={isModal} backdrop="static" className="modal-dialog-top w-30">
      <ModalHeader className="bg-success pb-3">
        <span className="text-light">
          {editFeature === null ? "Add Feature" : "Edit Feature"}
        </span>
      </ModalHeader>
      <Form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <Row className="mb-3 ">
            <Col lg={12}>
              <div className="mb-2">
                <Label htmlFor="featureName" className="form-label fs-16">
                  Feature Name
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="featureName"
                  name="featureName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={Capitalize(formik.values.featureName) || ""}
                  invalid={
                    formik.touched.featureName && formik.errors.featureName
                      ? true
                      : false
                  }
                />
                {formik.touched.featureName && formik.errors.featureName ? (
                  <FormFeedback type="invalid">
                    {formik.errors.featureName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            className="wd-10"
            color="success"
            onClick={() => clearForm()}
            outline
          >
            Cancel
          </Button>
          {!editFeature && (
            <Button
              type="submit"
              color="success"
              className="wd-10"
              disabled={!formik.isValid}
            >
              Save
            </Button>
          )}
          {editFeature && (
            <Button
              type="submit"
              color="success"
              className="wd-10"
              disabled={!formik.isValid}
            >
              Update
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddFeatureModel;
