import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import {
  DefectCharts,
  HealthCharts,
  ObservationCountCharts,
} from "./WidgetsCharts";
import * as api from "../../api/rest.api";
import { convertDateToISO } from "../Components/Utils";
import { useNavigate } from "react-router-dom";

const TxCharts = ({ project }) => {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [defectData, setDefectData] = useState([0, 0]);
  const [obsData, setObsData] = useState([]);
  const [obsValue, setObsValue] = useState([]);
  const [healthData, setHealthData] = useState([0, 0, 0, 0]);
  const [health, setHealth] = useState(null);

  let healthLabels = ["High Risk", "Low Risk", "Safe", "Medium Risk"];

  const getCharts = async (id, date) => {
    let res = await api.getData(
      `clientDashboardByProject?projectId=${id}&actionDate=${date}&observationDate=${date}&severityClosedDate=${date}`
    );
    setDefectData([res?.data?.openActionCount, res?.data?.closedActionCount]);
    setHealthData([
      res?.data?.towerStatusDetails["High Risk"],
      res?.data?.towerStatusDetails["Low Risk"],
      res?.data?.towerStatusDetails["Safe"],
      res?.data?.towerStatusDetails["Medium Risk"],
    ]);
    let obs = res?.data?.observationDetails;
    let series = obs.map((e) => e.observation);
    let value = obs.map((e) => e.count);
    setObsData(series);
    setObsValue(value);
  };

  useEffect(() => {
    const today = convertDateToISO(date);
    getCharts(project.id, today);
  }, [date]);

  const handleChartClick = (idx) => {
    if (idx !== "all") {
      let hels = healthLabels[idx];
      setHealth(hels);
    } else {
      setHealth(idx);
    }
  };

  useEffect(() => {
    if (health) {
      navigate("/client-tx-towers", { state: { project, health: health } });
    }
  }, [health]);


  return (
    <div className="tx-charts">
      <Row className="mx-3">
        <Col className="fs-18 fw-500">OverView</Col>
        <Col>
          <div className="d-flex justify-content-end">
            <Calendar
              value={date}
              onChange={(e) => setDate(e.value)}
              showIcon
              placeholder="Select Date"
              maxDate={new Date()}
              dateFormat="dd/mm/yy"
              className="w-3"
            />
          </div>
        </Col>
      </Row>
      {obsData?.length > 0 && (
        <Row className="mx-3">
          <Col md={3}>
            <Card className="br-0">
              <CardHeader className="bg-light">
                <span className="fs-16">Defect Status at Project Level</span>
              </CardHeader>
              <CardBody className="p-3">
                <DefectCharts
                  data={defectData}
                  dataColors='[ "#CED4DA", "#29EDAB"]'
                />
                <Row>
                  <Col className="d-flex align-items-center justify-content-center">
                    <i class="bx bxs-circle color-open me-2"></i>
                    <div className="text-close me-4">
                      Closed{" "}
                      <span className="text-dark ms-1">({defectData[1]})</span>
                    </div>
                    <i class="bx bxs-circle color-close me-2"></i>
                    <div className="text-close me-4">
                      Open{" "}
                      <span className="text-dark ms-1">({defectData[0]})</span>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={5}>
            <Card className="br-0">
              <CardHeader>Count of Observation at Project Level</CardHeader>
              <CardBody className="p-3">
                <ObservationCountCharts
                  dataColors='["#4C9DE3", "#EF7D34", "#3FE3AC", "#D9E352", "#60A0C1", "#9191F5", "#F151FC", "#FCBA51"]'
                  data={obsData}
                  values={obsValue}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="br-0">
              <CardHeader className="d-flex justify-content-between w-100">
                <div>Project Level Tower Health Status</div>
                <div
                  className="pointer underline text-low"
                  onClick={() => handleChartClick("all")}
                >
                  View All
                </div>
              </CardHeader>
              <CardBody className="p-3">
                <HealthCharts
                  data={healthData}
                  healthLabels={healthLabels}
                  dataColors='["#FF0202", "#086ADD", "#3FC304", "#EBC505"]'
                  selectedIdx={(idx) => {
                    handleChartClick(idx);
                  }}
                />
                <Row className="ms-4">
                  <Col className="d-flex align-items-center ms-5">
                    <i class="bx bxs-circle text-safe me-2"></i>
                    <div className="text-close">
                      Safe{" "}
                      <span className="text-dark ms-1">({healthData[2]})</span>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-center">
                    <i class="bx bxs-circle text-medium me-2"></i>
                    <div className="text-close me-4">
                      Medium Risk{" "}
                      <span className="text-dark ms-1">({healthData[3]})</span>
                    </div>
                  </Col>
                </Row>
                <Row className="ms-4">
                  <Col className="d-flex align-items-center ms-5">
                    <i class="bx bxs-circle text-high me-2"></i>
                    <div className="text-close me-4">
                      High Risk{" "}
                      <span className="text-dark ms-1">({healthData[0]})</span>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-center">
                    <i class="bx bxs-circle text-low me-2"></i>
                    <div className="text-close me-4">
                      Low Risk{" "}
                      <span className="text-dark ms-1">({healthData[1]})</span>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TxCharts;
