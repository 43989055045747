import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { DistributedColumn1, Donut } from "./Charts";
import { formatDate } from "../../commons/functions";
import { Calendar } from "primereact/calendar";

const ModuleDashboard = (props: any) => {
  const { data, setDate, date, setDefDate } = props;
  const navigate = useNavigate();
  const [filter, setFilter] = useState<any>("");
  const [distData, setDistData] = useState<any>([]);

  const selectedDistData = (ele: any, val: any) => {
    setFilter(ele);
    let filterEle = data?.projectWise?.all?.filter(
      (ele: any) => ele?.client?.clientName === val
    );
    setDistData(filterEle);
  };
  return (
    <Container fluid>
      <Row>
        <Col lg={3}>
          <Card className="card-border-none module-card-admin">
            <CardHeader className="header-card">
              <div className="d-flex justify-content-between align-items-center admin-card-head">
                Projects
              </div>
            </CardHeader>
            <CardBody>
              <Donut
                dataColors='["#8a3ffc", "#33b1ff", "#007d79", "#ff7eb6", "#fa4d56"]'
                labelName={data.statusWise.label || []}
                series={data.statusWise.data || []}
                labelCenter={"Total"}
                height={330}
                size="80%"
                selectedData={(label: any, name: any) => setFilter(name)}
                legends={true}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={5}>
          <Card className="card-border-none module-card-admin">
            <CardHeader className="header-card" >
              <div className="d-flex justify-content-between align-items-center admin-card-head">
                Total no. of Projects based on Client
              </div>
            </CardHeader>
            <CardBody >
              <DistributedColumn1
                height={310}
                data={data.clientWise.data || []}
                label={data.clientWise.label || []}
                custom={{ width: 30, borderRadius: 0 }}
                textX=""
                textY=""
                selectedData={selectedDistData}
                dataColors='["#8a3ffc", "#33b1ff", "#007d79", "#ff7eb6", "#fa4d56", "#6fdc8c", "#4589ff", "#d12771", "# 08bdba", "#bae6ff" ]'
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} className="">
          <Card className="card-border-none module-card-right-admin mb-0">
            <CardHeader className="header-card">
              <div className="d-flex justify-content-between">
                <div className="align-items-center admin-card-head">
                  All Project Status
                </div>
                <Calendar
                  value={date}
                  onChange={(e) => {
                    setDate(e.value);
                    setDefDate(false);
                  }}
                  view="month"
                  dateFormat="mm/yy"
                  showIcon
                  className="admin-select"
                />
              </div>
            </CardHeader>
            <CardBody className="module-card-body-admin px-2">
              {data?.projectWise?.completed?.length > 0 && filter === "" && (
                <>
                  <div className="text-muted mb-3 fs-13" style={{ marginTop: '10px' }}>
                    Completed Projects
                  </div>
                  {data?.projectWise?.completed?.map((e: any) => (
                    <div className="d-flex align-items-center mb-3 border">
                      <div className="avatar-sm flex-shrink-0 d-flex">
                        <img
                          src={e?.client?.clientLogo}
                          className="rounded img-fluid mx-1 "
                          style={{ height: "60px" }}
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="d-flex justify-content-end">
                          <p className="card-status-completed1 mb-0">
                            {"Completed"}
                          </p>
                        </span>
                        <div className="fs-13">{e?.projectName}</div>
                        <p className="text-muted fs-12 mb-0 d-flex align-items-center">
                          <i className="ri-map-pin-2-fill me-2 ri-xl"></i>
                          {e?.location}
                        </p>
                        <p className="d-flex justify-content-end fs-12 me-2">
                          <i className="mdi mdi-circle-medium align-middle mx-1 fs-13"></i>
                          {formatDate(e?.createdAt)}
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {data?.projectWise?.inProgress?.length > 0 && filter === "" && (
                <>
                  <div className="text-muted mb-3 fs-13">
                    In Progress Projects
                  </div>
                  {data?.projectWise?.inProgress?.map((e: any) => (
                    <div className="d-flex align-items-center mb-3  border">
                      <div className="avatar-sm flex-shrink-0 d-flex">
                        <img
                          src={e?.client?.clientLogo}
                          className="rounded img-fluid mx-1"
                          style={{ height: "60px" }}
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="d-flex justify-content-end">
                          <p
                            className="card-status-completed1 mb-0"
                            style={{
                              backgroundColor:
                                e?.projectStatus === "In Progress"
                                  ? "orange"
                                  : e?.projectStatus === "Data Captured"
                                    ? "Yellow"
                                    : e?.projectStatus === "Completed"
                                      ? "#74b72e"
                                      : "gray",
                            }}
                          >
                            {e?.projectStatus}
                          </p>
                        </span>
                        <div className="fs-13">{e?.projectName}</div>
                        <p className="text-muted fs-12 mb-0 d-flex align-items-center">
                          <i className="ri-map-pin-2-fill me-2 ri-xl"></i>
                          {e?.location}
                        </p>
                        <p className="d-flex justify-content-end fs-12 me-2">
                          <i className="mdi mdi-circle-medium align-middle mx-1 fs-13"></i>
                          {formatDate(e?.createdAt)}
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              )}

              {filter === "Completed" &&
                data?.projectWise?.completed?.length > 0 && (
                  <>
                    <div className="text-muted mb-3 fs-13">
                      Completed Projects
                    </div>
                    {data?.projectWise?.completed?.map((e: any) => (
                      <div className="d-flex align-items-center mb-3 border">
                        <div className="avatar-sm flex-shrink-0 d-flex">
                          <img
                            src={e?.client?.clientLogo}
                            className="rounded img-fluid mx-1 "
                            style={{ height: "60px" }}
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <span className="d-flex justify-content-end">
                            <p className="card-status-completed1 mb-0">
                              {"Completed"}
                            </p>
                          </span>
                          <div className="fs-13">{e?.projectName}</div>
                          <p className="text-muted fs-12 mb-0 d-flex align-items-center">
                            <i className="ri-map-pin-2-fill me-2 ri-xl"></i>
                            {e?.location}
                          </p>
                          <p className="d-flex justify-content-end fs-12 me-2">
                            <i className="mdi mdi-circle-medium align-middle mx-1 fs-13"></i>
                            {formatDate(e?.createdAt)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                )}

              {data?.projectWise?.inprogress?.length > 0 &&
                filter === "In Progress" && (
                  <>
                    <div className="text-muted mb-3 fs-13">
                      In Progress Projects
                    </div>
                    {data?.projectWise?.inprogress?.map((e: any) => (
                      <div className="d-flex align-items-center mb-3  border">
                        <div className="avatar-sm flex-shrink-0 d-flex">
                          <img
                            src={e?.client?.clientLogo}
                            className="rounded img-fluid mx-1 "
                            style={{ height: "60px" }}
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <span className="d-flex justify-content-end">
                            <p
                              className="card-status-completed1 mb-0"
                              style={{
                                backgroundColor:
                                  e?.projectStatus === "In Progress"
                                    ? "orange"
                                    : e?.projectStatus === "Data Captured"
                                      ? "Yellow"
                                      : e?.projectStatus === "Completed"
                                        ? "#74b72e"
                                        : "gray",
                              }}
                            >
                              {e?.projectStatus}
                            </p>
                          </span>
                          <div className="fs-13">{e?.projectName}</div>
                          <p className="text-muted fs-12 mb-0 d-flex align-items-center">
                            <i className="ri-map-pin-2-fill me-2 ri-xl"></i>
                            {e?.location}
                          </p>
                          <p className="d-flex justify-content-end fs-12 me-2">
                            <i className="mdi mdi-circle-medium align-middle mx-1 fs-13"></i>
                            {formatDate(e?.createdAt)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                )}

              {data?.projectWise?.yettostart?.length > 0 &&
                filter === "Yet To Start" && (
                  <>
                    <div className="text-muted mb-3 fs-13">
                      Yet to Start Projects
                    </div>
                    {data?.projectWise?.yettostart?.map((e: any) => (
                      <div className="d-flex align-items-center mb-3  border">
                        <div className="avatar-sm flex-shrink-0 d-flex">
                          <img
                            src={e?.client?.clientLogo}
                            className="rounded img-fluid mx-1 "
                            style={{ height: "60px" }}
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <span className="d-flex justify-content-end">
                            <p
                              className="card-status-completed1 mb-0"
                              style={{
                                backgroundColor:
                                  e?.projectStatus === "In Progress"
                                    ? "orange"
                                    : e?.projectStatus === "Data Captured"
                                      ? "Yellow"
                                      : e?.projectStatus === "Completed"
                                        ? "#74b72e"
                                        : "gray",
                              }}
                            >
                              {e?.projectStatus}
                            </p>
                          </span>
                          <div className="fs-13">{e?.projectName}</div>
                          <p className="text-muted fs-12 mb-0 d-flex align-items-center">
                            <i className="ri-map-pin-2-fill me-2 ri-xl"></i>
                            {e?.location}
                          </p>
                          <p className="d-flex justify-content-end fs-12 me-2">
                            <i className="mdi mdi-circle-medium align-middle mx-1 fs-13"></i>
                            {formatDate(e?.createdAt)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                )}

              {data?.projectWise?.inspectiondone?.length > 0 &&
                filter === "Inspection Done" && (
                  <>
                    <div className="text-muted mb-3 fs-13">
                      Yet to Start Projects
                    </div>
                    {data?.projectWise?.inspectiondone?.map((e: any) => (
                      <div className="d-flex align-items-center mb-3  border">
                        <div className="avatar-sm flex-shrink-0 d-flex">
                          <img
                            src={e?.client?.clientLogo}
                            className="rounded img-fluid mx-1 "
                            style={{ height: "60px" }}
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <span className="d-flex justify-content-end">
                            <p
                              className="card-status-completed1 mb-0"
                              style={{
                                backgroundColor:
                                  e?.projectStatus === "In Progress"
                                    ? "orange"
                                    : e?.projectStatus === "Data Captured"
                                      ? "Yellow"
                                      : e?.projectStatus === "Completed"
                                        ? "#74b72e"
                                        : "gray",
                              }}
                            >
                              {e?.projectStatus}
                            </p>
                          </span>
                          <div className="fs-13">{e?.projectName}</div>
                          <p className="text-muted fs-12 mb-0 d-flex align-items-center">
                            <i className="ri-map-pin-2-fill me-2 ri-xl"></i>
                            {e?.location}
                          </p>
                          <p className="d-flex justify-content-end fs-12 me-2">
                            <i className="mdi mdi-circle-medium align-middle mx-1 fs-13"></i>
                            {formatDate(e?.createdAt)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                )}

              {distData.length > 0 && filter === "Dist" && (
                <>
                  <div className="text-muted mb-3 fs-13">All Projects</div>
                  {distData?.map((e: any) => (
                    <div className="d-flex align-items-center mb-3  border">
                      <div className="avatar-sm flex-shrink-0 d-flex">
                        <img
                          src={e?.client?.clientLogo}
                          className="rounded img-fluid mx-1 "
                          style={{ height: "60px" }}
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="d-flex justify-content-end">
                          <p
                            className="card-status-completed1 mb-0"
                            style={{
                              backgroundColor:
                                e?.projectStatus === "In Progress"
                                  ? "orange"
                                  : e?.projectStatus === "Data Captured"
                                    ? "Yellow"
                                    : e?.projectStatus === "Completed"
                                      ? "#74b72E"
                                      : "gray",
                            }}
                          >
                            {e?.projectStatus}
                          </p>
                        </span>
                        <div className="fs-13">{e?.projectName}</div>
                        <p className="text-muted fs-12 mb-0 d-flex align-items-center">
                          <i className="ri-map-pin-2-fill me-2 ri-xl"></i>
                          {e?.location}
                        </p>
                        <p className="d-flex justify-content-end fs-12 me-2">
                          <i className="mdi mdi-circle-medium align-middle mx-1 fs-13"></i>
                          {formatDate(e?.createdAt)}
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </CardBody>
            <CardFooter className="footer-card">
              <div className="d-flex justify-content-center align-items-center">
                <h6 className="lh-base ">
                  <Button
                    style={{
                      border: "none",
                      color: "#0D3399",
                      background: "transparent",
                    }}
                    onClick={() =>
                      navigate("/allProjects", {
                        state: {
                          completed: data?.projectWise?.completed,
                          inprogress: data?.projectWise?.inprogress,
                          yettostart: data?.projectWise?.yettostart,
                          inspectiondone: data?.projectWise?.inspectiondone,
                        },
                      })
                    }
                  >
                    <span className="admin-card-head">View More</span>
                  </Button>
                </h6>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ModuleDashboard;
