import { feature } from "@turf/turf";
import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

const AddFeatures = ({ isModal, setIsModal, data, updateModule }: any) => {
  const [selected, setSelected] = useState<any>(data);

  const handleAddFeatures = (item: any) => {
    let data = [...selected.features];
    let filterdata = data.map((ele: any) => {
      if (ele.featureName === item.featureName) {
        return { ...ele, isSelected: !ele.isSelected };
      } else {
        return { ...ele };
      }
    });
    setSelected({ ...selected, features: filterdata });
  };

  const onSave = () => {
    updateModule(selected);
    setIsModal(false);
  };
  return (
    <Modal isOpen={isModal} className="modal-dialog-top">
      <ModalHeader className="d-flex justify-content-between bg-success">
        <div className="modal-title">Select Feature</div>
      </ModalHeader>
      <ModalBody>
        <div className="text-muted fs-18 mb-3">
          Add features to the Module by selecting features from the list
        </div>
        {selected?.features?.map((item: any, key: any) => {
          return (
            <div className="d-mb-2 " key={key}>
              <Input
                className="form-check-input me-4"
                type="checkbox"
                checked={
                  selected?.features?.findIndex(
                    (ele: any) =>
                      ele?.featureName === item?.featureName &&
                      ele?.isSelected === true
                  ) !== -1
                }
                id="cardtableCheck01"
                onChange={() => handleAddFeatures(item)}
              />
              <Label className="form-label fs-16">{item?.featureName}</Label>
            </div>
          );
        })}
        <div className="d-flex justify-content-end m-2">
          <Button
            className="me-3 wd-10"
            onClick={() => {
              setIsModal(false);
            }}
            outline
          >
            Cancel
          </Button>
          <Button className="wd-10" onClick={() => onSave()}>
            Save
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddFeatures;
