import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const DeleteAssignPilotModal = (props: any) => {
  return (
    <Dialog
      visible={props.visible}
      onHide={props.onHide}
      header="Delete Assign Pilot File"
      draggable={false}
    >
      <div onClick={props.deleteFile} className="m-b-10">
        Are you sure you want to delete the XML file uploaded?
      </div>
      <Button className="float-right btn-primary " onClick={props.deleteFile}>
        Yes
      </Button>
      <Button
        className="float-right btn-secondary m-r-10"
        onClick={props.onHide}
      >
        No
      </Button>
    </Dialog>
  );
};

export default DeleteAssignPilotModal;
