import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import "../../styles/style.css";
import { formatDateTime } from "../commons/functions";
const UserDataTable = ({ data, deleteUser }) => {
  const navigate = useNavigate();

  const handleEditClick = (row) => {
    let data = row?.roles?.map((ele) => {
      return { ...ele, privilliges: { create: [...ele.privilliges] } };
    });

    sessionStorage.setItem(
      "editUserData",
      JSON.stringify({ ...row, roles: data })
    );
    navigate("/user");
  };

  const handleDeleteClick = (row) => {
    deleteUser(row);
  };

  const columns = [
    {
      name: <span className="font-weight-bold column-title">User ID</span>,
      width: "100px",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold column-title">User Name</span>,
      sortable: true,
      width: "200px",
      selector: (row) => row.firstName + " " + row.lastName,
    },
    {
      name: <span className="font-weight-bold column-title">Contact Number</span>,
      selector: (row) => row.contactNumber,
      width: "200px",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold column-title">Email ID</span>,
      selector: (row) => row.email,
      width: "200px",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold column-title">Role</span>,
      selector: (row) => {
        let a = row?.roles?.map((e) => e.roleName);
        return <span>{a.join(", ")}</span>;
      },
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="font-weight-bold column-title">Sub Module</span>,
      selector: (row) => row.subModuleName,
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="font-weight-bold column-title">Creation Date</span>,
      selector: (row) => {
        return formatDateTime(row.createdAt);
      },
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="font-weight-bold column-title">Actions</span>,
      sortable: true,
      width: "200px",
      cell: (row) => {
        return (
          <span>
            <i
              className="bx bx-edit fs-4 me-2 pointer"
              style={{ color: "green" }}
              onClick={() => handleEditClick(row)}
            ></i>
            <i
              className="ri-delete-bin-6-line fs-4 me-2 pointer"
              style={{ color: "red" }}
              onClick={() => handleDeleteClick(row)}
            ></i>
          </span>
        );
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      striped
      noDataComponent="No Data Found"
      highlightOnHover={true}
    />
  );
};

export default UserDataTable;
