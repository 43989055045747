import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../CommonComponent/Loader";
import * as api from "../../../api/global.api";
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Capitalize,
  alphabets,
  alphanumbericRegex,
} from "../../commons/functions";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";

const CreateAsset = (props: any) => {
  const projectId = useParams().id;

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      assetType: "",
      assetName: "",
      address: "",
      assetLogo: "",
    },
    validationSchema: Yup.object({
      assetType: Yup.string()
        .matches(alphabets, "Please enter alphabets only")
        .required("Required"),
      assetName: Yup.string()
        .matches(alphanumbericRegex, "Please enter alphanumeric value only")
        .required("Required"),
      address: Yup.string().required("Required"),
      assetLogo: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      let obj = {};
      props?.mode === "Add Asset"
        ? (obj = { ...values, projectId: Number(projectId) })
        : (obj = {
            ...values,
            projectId: Number(props.editData.projectId),
            id: props.editData.id,
          });
      props?.mode === "Add Asset" ? addAssets(obj) : updateAssets(obj);
    },
  });

  function handleAcceptedFiles(files: any) {
    setLoading(true);
    let fs = files[0]?.path.split(".")[1]?.toLowerCase();
    if (fs === "jpeg" || fs === "png" || fs === "jpg") {
      files.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: file.size,
        })
      );
      const formData = new FormData();
      formData.append("file", files[0]);

      api.post("fileUpload", formData).then((res: any) => {
        if (res?.status === "Failed") {
          formik.setFieldValue("assetLogo", "");
          setLoading(false);
        } else {
          formik.setFieldValue("assetLogo", res?.url);
          setLoading(false);
        }
      });
    } else {
      toast.error("Please upload .jpeg, .png or .jpg format file only");
      setLoading(false);
    }
  }

  const addAssets = (data: any) => {
    setLoading(true);
    api.post("addAsset", data).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        toast.success("Asset added successfully");
        props.setOpen(false);
        props.getAssets(1);
        setLoading(false);
      } else {
        props.setOpen(false);
        setLoading(false);
        toast.error(res?.errormessage);
      }
    });
  };

  const updateAssets = (data: any) => {
    setLoading(true);
    api.put("updateAsset", data).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        toast.success("Asset updated successfully");
        props.setOpen(false);
        props.getAssets(1);
        setLoading(false);
      } else {
        props.setOpen(false);
        setLoading(false);
        toast.error(res?.errormessage);
      }
    });
  };

  useEffect(() => {
    if (props.mode === "Edit Asset") {
      formik.setFieldValue("assetType", props.editData.assetType);
      formik.setFieldValue("assetName", props.editData.assetName);
      formik.setFieldValue("address", props.editData.address);
      formik.setFieldValue("assetLogo", props.editData.assetLogo);
    }
  }, []);
  return (
    <>
      <ToastContainer />
      <Loader loader={loading} />
      <Modal isOpen={props.open}>
        <ModalHeader>
          <span className="mb-2 text-light">{props?.mode}</span>
          <span
            className="cross mb-2 text-light"
            onClick={() => props?.setOpen(false)}
          >
            X
          </span>
        </ModalHeader>
        <Form
          onSubmit={(e: any) => {
            e.preventDefault();
            formik.handleSubmit();
            return;
          }}
        >
          <ModalBody>
            <Row className="m-3">
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="moduleName" className="form-label text-muted">
                    Asset Type
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="assetType"
                    name="assetType"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.assetType || ""}
                    invalid={
                      formik.touched.assetType && formik.errors.assetType
                        ? true
                        : false
                    }
                  />
                  {formik.touched.assetType && formik.errors.assetType ? (
                    <FormFeedback type="invalid">
                      {formik.errors.assetType}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="moduleName" className="form-label text-muted">
                    Asset Name
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="assetName"
                    name="assetName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.assetName || ""}
                    invalid={
                      formik.touched.assetName && formik.errors.assetName
                        ? true
                        : false
                    }
                  />
                  {formik.touched.assetName && formik.errors.assetName ? (
                    <FormFeedback type="invalid">
                      {formik.errors.assetName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="moduleName" className="form-label text-muted">
                    Location
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={Capitalize(formik.values.address) || ""}
                    invalid={
                      formik.touched.address && formik.errors.address
                        ? true
                        : false
                    }
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <FormFeedback type="invalid">
                      {formik.errors.address}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="profilePic" className="form-label">
                    Upload Image
                  </Label>
                  {formik.values.assetLogo === "" && (
                    <Dropzone
                      onDrop={(files) => {
                        handleAcceptedFiles(files);
                      }}
                      maxFiles={1}
                      onDropRejected={(files) => {
                        toast.error(files[0]?.errors[0]?.message);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone dz-clickable form-control p-0">
                          <div className="needsclick" {...getRootProps()}>
                            <input {...getInputProps()} accept={"image/*"} />
                            <div className="mx-4 d-flex align-items-center">
                              <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />
                              <span className="text-muted">Upload Image</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  {formik.values.assetLogo !== "" && (
                    <Card>
                      <div className="d-flex">
                        <img
                          data-dz-thumbnail=""
                          height="70"
                          className="avatar-sm rounded bg-light"
                          alt={"image"}
                          src={formik.values.assetLogo}
                        />
                        <span className="p-3">Uploaded Image</span>
                        <i
                          className="ri-delete-bin-6-line fs-3  pointer red p-2"
                          onClick={() => formik.setFieldValue("assetLogo", "")}
                        ></i>
                      </div>
                    </Card>
                  )}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              outline
              style={{ width: "100px" }}
              onClick={() => {
                props.setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" style={{ width: "100px" }}>
              {props.mode === "Add Asset" ? "Save" : "Update"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default CreateAsset;
