import React, { useEffect, useRef, useState } from "react";
import Map from "ol/Map";
import { OSM, XYZ, Vector as VectorSource } from "ol/source";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import { fromLonLat } from "ol/proj";
const MapTile = (props) => {
  const mapElement = useRef();
  const mapRef = useRef();

  const [map, setMap] = useState();
  mapRef.current = map;

  const satelliteLayer = () => {
    const key = "dnSalYNT9GcjMQFNzx4G";
    const attributions =
      '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
      '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';
    const aerialLayer = new TileLayer({
      source: new XYZ({
        attributions: attributions,
        url:
          "https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=" + key,
        maxZoom: 20,
      }),
      visible: true,
    });
    return aerialLayer;
  };

  const osmLayer = new TileLayer({
    source: new OSM(),
  });


  useEffect(() => {
    const initialMap = new Map({
      target: mapElement.current,
      layers: [osmLayer, satelliteLayer()],
      view: new View({
        center: fromLonLat(props.coordinate),
        zoom: 15,
        constrainResolution: true,
        resolution: 7,
      }),
      controls: [],
      interactions:[]
    });
    setMap(initialMap);
  }, []);

  return <div ref={mapElement} style={{ height: "180px", width: "100%" }}></div>;
};

export default MapTile;
