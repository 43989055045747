import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../primaryTable/PrimaryTable.css";
import { Button } from "primereact/button";
import DeleteModal from "../deleteModal/DeleteModal";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { FilterConfig } from "./TableFilterConfig";
import { Tooltip } from "primereact/tooltip";
import { GetMonths } from "../commons/common";

const PrimaryTable = (props: any) => {
  let history = useNavigate();
  const [selectedColumns, setColumns] = useState(props.columns);
  const [rows, setRows] = useState(props.rows);
  const [modalVisible, setModalVisible] = useState(false);
  const [type, setType] = useState("delete");
  const [filterKeys, setFilterKeys] = useState(props.filterKeys);
  const [state, setState] = useState<any>({
    selectedRow: {},
  });
  const [first, setFirst] = useState(0);
  const [rowsCount, setRowsCount] = useState(10);
  let dt = useRef<any>();
  const months = GetMonths();

  useEffect(() => {
    setColumns(props.columns);
    setRows(props.rows);
  }, [props]);

  const onDeleteRow = (rowData: any, column: any) => {
    setModalVisible(true);
    setState({ ...state, selectedRow: rowData });
  };
  
  const prepareActionTemplate = (rowData: any, column: any, index: any) => {
    return (
      <div>
        {column.edit && (
          <>
            <Tooltip target=".edit-button" position="left" />
            <Button
              icon="pi pi-pencil"
              className="icon-edit p-button-rounded p-button-text clr-primary mr-2 edit-button"
              onClick={() => onClickAction(rowData, column, "edit", index)}
              data-pr-tooltip="Edit"
            />
          </>
        )}
        {column.delete && (
          <>
            <Tooltip target=".delete-button" position="left" />
            <Button
              icon="pi pi-trash"
              className="icon-delete p-button-rounded p-button-text clr-primary mr-2 delete-button"
              onClick={() => onClickAction(rowData, column, "delete", index)}
              data-pr-tooltip="Delete"
            />
          </>
        )}
        {column.upload && (
          <>
            <Tooltip target=".upload-button" />
            <Button
              icon="pi pi-reply"
              className="icon-delete p-button-rounded p-button-text clr-primary mr-2 upload-button"
              onClick={() => onClickAction(rowData, column, "upload", index)}
              data-pr-tooltip="Upload"
            />
          </>
        )}
        {column.setting && (
          <>
            <Tooltip target=".setting-button" />
            <Button
              icon="pi pi-cog"
              className="icon-delete p-button-rounded p-button-text clr-primary mr-2 setting-button"
              onClick={() => onClickAction(rowData, column, "setting", index)}
              data-pr-tooltip="Report"
            />
          </>
        )}
        {column.comment && rowData.comments && (
          <>
            <Tooltip target=".comment-button" />
            <Button
              icon="pi pi-comments"
              className="icon-delete p-button-rounded p-button-text clr-primary mr-2 comment-button"
              onClick={() => onClickAction(rowData, column, "comment", index)}
              data-pr-tooltip="Comments"
            />
          </>
        )}
      </div>
    );
  };

  const onClickAction = (
    rowData: any,
    column: any,
    action: any,
    index: any
  ) => {
    switch (action) {
      case "edit":
        if (column.routerLink === "popup") {
          props.handleRowEdit(rowData);
        } else {
          if (column.routerID)
            history(column.routerLink + "/" + rowData[column.routerID]);
          else history(column.routerLink + "/" + rowData.id);
        }
        break;
      case "delete":
        onDeleteRow(rowData, column);
        break;
      case "view":
        if (column.routerLink === "popup") {
          props.handleRowView(rowData);
        } else if (column.routerID) {
          history(column?.routerLink + "/" + rowData[column.routerID]);
        } else history(column?.routerLink + "/" + rowData.id);
        break;
      case "setting":
        props.handleSetting(rowData);
        break;
      case "upload":
        props.handleUploadClick(rowData);
        break;
      case "comment":
        props.handleCommentClick(rowData);
    }
  };

  const onClickCheckBox = (e: any, column: any, rowData: any, index: any) => {
    let editRows = rows;
    editRows[index.rowIndex][column.field] = e.target.checked;
    let updateRows = [...editRows];
    setRows(updateRows);
    if (props?.isUpdateInParent) {
      props.updateInparent(e, rowData, "checkbox", column, index);
      return;
    }
  };
  const onClickSwitch = (e: any, column: any, rowData: any, index: any) => {
    setState({ ...state, selectedRow: rowData });
    setType(column.field);
    setModalVisible(true);
  };

  const prepareBody = (rowData: any, column: any, index: any) => {
    let body = undefined;
    switch (column.fieldType) {
      case "actions":
        body = prepareActionTemplate(rowData, column, index);
        break;
      case "view":
        body = (
          <span
            className="cursor-pointer view-link"
            onClick={() => onClickAction(rowData, column, "view", index)}
          >
            {rowData[column.field]}
          </span>
        );
        break;
      case "checkbox":
        body = (
          <span>
            <Checkbox
              onChange={(e) => onClickCheckBox(e, column, rowData, index)}
              checked={rowData[column.field]}
              disabled={props.mode === "view"}
              className={props.mode === "view" ? "pointer-none" : "pointer"}
            ></Checkbox>
          </span>
        );
        break;
      case "switch":
        body = (
          <span>
            <InputSwitch
              checked={rowData[column.field]}
              onChange={(e) => onClickSwitch(e, column, rowData, index)}
              disabled={
                column.field === "statusofdrone" &&
                rowData?.statusofdrone?.toLowerCase() === "assigned"
              }
            />
          </span>
        );
        break;
      case "popup":
        body = (
          <span
            className="cursor-pointer view-link"
            onClick={() => onClickAction(rowData, column, "edit", index)}
          >
            {rowData[column.field]}
          </span>
        );
        break;
      case "tooltip":
        body = (
          <>
            <Tooltip target=".disabled-button" />
            <span
              className={
                "disabled-button p-ellipsis " +
                (column.showColor ? rowData.statusColor : "")
              }
              data-pr-tooltip={rowData[column.field]}
            >
              {rowData[column.field]}
            </span>
          </>
        );
        break;
      case "download":
        body = column.validatorKey ? (
          rowData[column.validatorKey]?.toLowerCase() ===
          column.validatorValue ? (
            <div
              className="download pointer"
              onClick={() => props.handleDownload(rowData)}
            >
              {rowData[column.field]}
            </div>
          ) : (
            <div>{rowData[column.field]}</div>
          )
        ) : (
          <div
            className="download pointer"
            onClick={() => props.handleDownload(rowData)}
          >
            {rowData[column.field]}
          </div>
        );
        break;
      case "date":
        let date = new Date(rowData[column.field]);
        body = (
          <>
            <Tooltip target=".date-button" />
            <span
              className="date-button p-ellipsis"
              data-pr-tooltip={
                date.getDate() +
                "-" +
                months[date.getMonth()] +
                "-" +
                date.getFullYear()
              }
            >
              {date.getDate() +
                "-" +
                months[date.getMonth()] +
                "-" +
                date.getFullYear()}
            </span>
          </>
        );
        break;
      case "dropdown":
        body = (
          <div className="w-100">
            <Dropdown
              value={rowData[column.field]}
              options={props[column.options]}
              optionLabel={column.optionLabel}
              className="w-100 border ht-50"
              onChange={(e) =>
                props.handledDropDownChange(
                  e,
                  rowData.assignmentId,
                  "assignPilot"
                )
              }
              placeholder="Select an Pilot"
            />
          </div>
        );
    }
    return body;
  };

  const setOptions = (options: any) => {
    let obj = {};
    let optionsArr: any[] = [];
    if (options?.length > 0 && !FilterConfig[options]) {
      options.forEach((ele: any) => {
        obj = { label: ele, value: ele, id: ele };
        optionsArr.push(obj);
      });
    }
    return FilterConfig[options] ? FilterConfig[options] : optionsArr;
  };

  const onDropDownChange = (e: any, column: any) => {
    // dt.current.filter(e.value, column.field, 'equals');
    setState({ ...state, ["selected" + column.field]: e.value });
    props.lazyLoad && onChangeSearch(e, column);
  };
  const setFilterElement = (column: any) => {
    let filterElement = undefined;
    switch (column.filterElement) {
      case "dropdown":
        filterElement = (
          <Dropdown
            className="w-100"
            placeholder={column.filterPlaceholder}
            // appendTo={document.self}
            value={state["selected" + column.field]}
            filter={true}
            resetFilterOnHide={true}
            filterPlaceholder="Search"
            filterBy="label"
            options={
              setOptions(column.filterOptions).length > 0
                ? setOptions(column.filterOptions)
                : [
                    {
                      label: "No results found",
                      value: "No results found",
                      disabled: true,
                    },
                  ]
            }
            onChange={(e) => onDropDownChange(e, column)}
            showClear
          />
        );
        break;
      case "search":
        filterElement = (
          <InputText
            className="filter-input"
            value={
              filterKeys[column.field]
                ? filterKeys[column.field]?.contains
                  ? filterKeys[column.field]?.contains
                  : filterKeys[column.field]?.equals
                : state["search" + column.field]
            }
            placeholder={column.filterPlaceholder}
            onChange={(e: any) => onChangeSearch(e, column)}
            maxLength={column.maxLength ? column.maxLength : "50"}
          />
        );
        break;
      default:
        break;
    }
    return filterElement;
  };

  const renderColumns = (column: any, ind: any) => {
    return (
      <Column
        key={ind}
        field={column?.field}
        header={column?.header}
        filter={column?.filter}
        filterMatchMode={column.filterMatchMode}
        filterPlaceholder={column?.filterPlaceholder}
        showFilterMenu={false}
        filterElement={
          column?.filterElement !== null
            ? props.lazyLoad
              ? setFilterElement(column)
              : column.filterElement === "search"
              ? null
              : setFilterElement(column)
            : column?.filterElement
        }
        bodyStyle={{ textAlign: column?.bodyStyle }}
        body={
          column.fieldType
            ? (e, index) => prepareBody(e, column, index)
            : column?.body
        }
        style={{
          width: column.width,
          maxWidth: column.maxWidth,
          overflow: "hidden",
        }}
      />
    );
  };

  const closeModal = (res: boolean) => {
    setModalVisible(res);
    setState({ ...state, selectedRow: {} });
  };

  const modalActionYes = (res: any) => {
    switch (type) {
      case "delete":
        props.onClickDelete(state?.selectedRow);
        setState({ ...state, selectedRow: {} });
        setType("delete");
        break;
      case "defaultMap":
      case "droneStatus":
      case "isActive":
      case "visibility":
        props.onClickSwitch(state.selectedRow);
        setState({ ...state, selectedRow: {} });
        setType("delete");
    }
  };

  const onChangeSearch = (e: any, column: any) => {
    let filterData = filterKeys;
    filterData[column.field] = e.target.value;
    props.lazyLoad && props.searchRecord(e, column, filterData);
  };

  const onPage = (page: any) => {
    props?.lazyLoad && props?.onPageChange(page);
  };

  const onPageWithoutLazyLoad = (event: any) => {
    setFirst(event.first);
    setRowsCount(event.rows);
  };
  return (
    <div>
      <div className={props.className ? props.className : "primary-table"}>
        <DataTable
          lazy={props.lazyLoad ? props.lazyLoad : false}
          value={rows}
          stripedRows
          scrollable
          resizableColumns
          scrollHeight={props.scrollHeight ? props.scrollHeight : ""}
          responsiveLayout="scroll"
          showGridlines
          paginator={props.paginator ? props.paginator : false}
          first={props.lazyLoad ? props?.first : first}
          onPage={props.lazyLoad ? onPage : onPageWithoutLazyLoad}
          totalRecords={props?.totalRecords}
          rows={props.paginator && props.lazyLoad ? props.pageRows : rowsCount}
          filterDisplay={props.filterDisplay && "row"}
          rowsPerPageOptions={[10, 20, 50]}
          emptyMessage={props?.emptyMessage ? props?.emptyMessage : ""}
          loading={props?.loading ? props?.loading : false}
        >
          {selectedColumns.length > 0
            ? selectedColumns.map((column: any, ind: any) =>
                renderColumns(column, ind)
              )
            : []}
        </DataTable>
        {props.showAddNew && (
          <div className="addnew" onClick={() => props.handleAddNew()}>
            + Add New
          </div>
        )}
      </div>
      <DeleteModal
        type={type}
        rowData={state.selectedRow}
        visible={modalVisible}
        closeModal={closeModal}
        modalActionYes={modalActionYes}
      />
    </div>
  );
};

export default PrimaryTable;
