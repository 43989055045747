import React, { useEffect, useState } from "react";
import { Modal, ModalFooter, Button, Label } from "reactstrap";
import { Calendar } from "primereact/calendar";
import { ModalBody, ModalHeader } from "react-bootstrap";
import { InputTextarea } from "primereact/inputtextarea";
import { ToastContainer, toast } from "react-toastify";
import {
  convertDateToISO,
  formatDate,
} from "../../CommonComponent/CommonFunctions";
import * as api from "../../../api/global.api";
import { useAppSelector } from "../../../store/hooks";
import { InputText } from "primereact/inputtext";
import DeleteModal from "../../Common/DeleteModal";

const UploadFileModal = (props: any) => {
  const user = useAppSelector((state) => state.user);
  const today = new Date();
  const [date, setDate] = useState<any>(today);
  const [zip, setZip] = useState<any>(null);
  const [comment, setComment] = useState<any>("");
  const [file, setFile] = useState<any>();
  const [deleteModal, setDeleteModal] = useState(false);

  // const handleFile = (e: any) => {
  //   props?.setLoader(true);

    // Update the formData object

    // let formData = {
    //   id: props.data.id,
    //   projectName: props.data.project.projectName,
    //   assetRef: props.data.assetRef,
    // };

    // setFile(formData);
    // props?.setLoader(false);
    // uploadZIPFile(formData);
  // };

  const handleFile = (event: any) => {
    props?.setLoader(true);
    // Update the formData object
    let file = event?.target?.files[0];
    let fileExt = event?.target?.files[0]?.name?.split(".")?.pop();
    if (fileExt === "zip") {
      const formData = new FormData();
      // Update the formData object
      formData.append("file", file);
      formData.append("id", props.data.id);
 
 
    // let formData = {
    //   id: props.data.id,
      // projectName: props.data.project.projectName,
      // assetRef: props.data.assetRef,
    // };
 
    setFile(formData);
    props?.setLoader(false);
    uploadZIPFile(formData);
  };
}

  const handleUpload = () => {
    if (props?.data?.assetZipName) {
      updateAssetList({
        id: props?.data?.id,
        capturedDate: convertDateToISO(date),
        assetComments: comment,
      });
    } else {
      uploadZIPFile(file);
    }
  };

  const updateAssetList = (data: any) => {
    props?.setLoader(true);
    api
      .put("updateAsset", data)
      .then((res) => {
        if (res?.status?.toLowerCase() === "failed") {
          toast.error(res.errormessage);
          props?.setLoader(false);
        } else {
          props?.getAssets();
          props?.close(false);
          toast.success("File Uploaded Successfully.");
          props?.setLoader(false);
        }
      })
      .catch((err) => {
        props?.close(false);
        props?.setLoader(false);
      });
  };

  const uploadZIPFile = (data: any) => {
    props?.setLoader(true);
    api
      // .post("assetawsuploads", data)
      .post("zipUpload", data)
      .then((res: any) => {
        if (res?.status?.toLowerCase() === "failed") {
          toast.error(res.errormessage);
          props?.setLoader(false);
        } else if (res?.status?.toLowerCase() === "success") {
          updateAssetList({
            id: props?.data?.id,
            capturedDate: convertDateToISO(date),
            assetComments: comment,
            uploadedBy: user.firstName + " " + user.lastName,
          });
        } else {
          toast.error("Something went wrong please try again");
          props?.setLoader(false);
        }
      })
      .catch((err) => {
        props?.setLoader(false);
        toast.error(err);
      });
  };

  const deleteConfirmation = () => {
    setDeleteModal(true);
  };

  const deleteUpload = () => {
    console.log("---props?.data?--", props?.data);
    props?.setLoader(true);
    let id = `?id=${props?.data?.assetZipName}&zipFile=true&assetId=${props?.data?.id}`;
    api
      .deleteApi(`deleteUploads${id}`)
      .then((res) => {
        if (res?.status?.toLowerCase() === "failed") {
          props?.setLoader(false);
          setDeleteModal(false);
          props?.getAssets();
          toast.error(res?.errormessage);
        } else {
          props?.getAssets();
          setZip(null);
          toast.success("File Is Deleted Successfully.");
          setDeleteModal(false);
          props?.setLoader(false);
        }
      })
      .catch((err) => {
        props?.setLoader(false);
        toast.error(err);
      });
  };

  useEffect(() => {
    if (!!props?.data?.capturedDate) {
      setZip(props?.data?.assetZipName);
      setComment(props?.data?.assetComments);
      setDate(new Date(props?.data?.capturedDate));
    }
  }, []);
  return (
    <>
      <ToastContainer />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => deleteUpload()}
        onCloseClick={() => setDeleteModal(false)}
      />

      <Modal isOpen={props?.isOpen}>
        <ModalHeader className="pb-2">
          {!!props?.data?.assetZipName ? "Edit Upload" : "Add Upload"}
          <i
            className=" bx bx-x fs-4 pointer"
            onClick={() => props?.close(false)}
          ></i>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <Label>Inspection Date : </Label>
            <Calendar
              value={date}
              onChange={(e) => setDate(e.value)}
              maxDate={today}
              className="w-full"
            />
          </div>
          <div className="mb-3">
            <Label>Upload : </Label>
            {!!zip ? (
              <div className="p-inputgroup">
                <InputText value={zip} type="text" />
                <i
                  className="pi pi-times fs-4 d-flex align-items-center bg-danger text-light px-3"
                  onClick={() => deleteConfirmation()}
                ></i>
              </div>
            ) : (
              <label htmlFor="upload" className="customUpload text-muted">
                Select File
              </label>
            )}
            <input
              id="upload"
              type="file"
              placeholder="Browse"
              hidden
              //accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
              onChange={(e) => handleFile(e)}
            ></input>
          </div>
          <div className="mb-3">
            <Label>Comments : </Label>
            <InputTextarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows={4}
              cols={30}
              style={{ width: "100%" }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            style={{ width: "100px" }}
            onClick={() => props.close(false)}
            outline
          >
            Cancel
          </Button>
          <Button
            color="success"
            style={{ width: "100px" }}
            onClick={() => handleUpload()}
            disabled={!zip || !comment || !date}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UploadFileModal;
