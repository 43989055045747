import * as api from "./api";

const addDrawData = async (data: any) => {
  var url = "addDynamicLayers";
  return api
    .postReq(url, data, {})
    .then((data) => {
      if (data && data.status == "Failed") {
        return data;
      }
      return data.data;
    })
    .catch((err) => {
      return err;
    });
};

const generateXLS =(data:any) =>{
  var url = "volumetricExcel";
  return api
    .postReq(url, data, {})
    .then((data) => {
      if (data && data.status == "Failed") {
        return data;
      }
      return data.data;
    })
    .catch((err) => {
      return err;
    });
}
const updateDrawData = async (data: any) => {
  var url = "updateDynamicLayers";
  return api
    .putReq(url, data, {})
    .then((data) => {
      if (data && data.status == "Failed") {
        return data;
      }
      return data.data;
    })
    .catch((err) => {
      return err;
    });
};

export { addDrawData, updateDrawData, generateXLS };
