const validation = (
  validator: string,
  value: string,
  field: string,
  maxLength: number,
  checkMatched: any
) => {
  let pattern;
  let isValid: boolean = false;
  let retObj = { [field]: false };
  switch (validator) {
    case "EMAIL":
      pattern = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
      isValid = pattern.test(value);
      retObj[field] = isValid ? false : true;
      break;
    case "MOBILE":
      pattern = new RegExp(/[0-9]{10}/g);
      isValid = pattern.test(value);
      let lengthChk = isValid && value.length === maxLength && value !== "";
      retObj[field] = lengthChk ? false : true;
      break;
    case "ALPHABET":
      pattern = new RegExp(/^[a-zA-Z ]*$/g);
      isValid = pattern.test(value);
      if (isValid && value !== "") {
        retObj[field] = false;
      } else retObj[field] = true;
      break;
    case "ALPHANUMERIC":
      pattern = new RegExp(/^[A-Za-z0-9 ]+$/g);
      isValid = pattern.test(value);
      if (isValid && value !== "") {
        retObj[field] = true;
      } else retObj[field] = false;
      break;
    case "password":
      pattern = new RegExp(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      );
      let patternArray = pattern.test(value);
      if (
        !patternArray ||
        value === "" ||
        value === "Null" ||
        value === "null" ||
        value === "" ||
        (value.length > 0 && value.trim().length === 0)
      ) {
        retObj[field] = true;
      } else retObj[field] = false;
      break;
    case "newpassword":
      if (checkMatched !== value) retObj[field] = true;
      else retObj[field] = false;
      break;
    case "PRIORITY":
      pattern = new RegExp(/^[0-9]\d*$/);
      isValid = pattern.test(value);
      if (isValid && value !== "") {
        retObj[field] = false;
      } else retObj[field] = true;
  }
  return retObj;
};

export { validation };
