import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Badge } from "primereact/badge";

import Loader from "../../CommonComponent/Loader";

import ActionButtons from "../../actionsComponent/ActionButtons";
import { FileUploadDemo } from "../../commons/FileUpload";
import InputComponent from "../../inputComponent/inputComponent";
import * as deliverablesApi from "../../../api/global.api";

const AddDeliverables = (props: any) => {
  const [spinner, setSpinner] = useState(false);
  const [fileData, setFileData] = useState();
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleDownload = async (event: any) => {
    setSpinner(true);

    let url = "downloaddeliverablesupload";
    let type = "";

    let data = {
      surveyId: props.survey.id,
      surveyName: props.survey.surveyName,
      projectName: props.project.projectName,
      deliverableName: props.data.deliverableName,
      fileType: props.data.fileType.replace(".", "").toUpperCase(),
    };
    let filename: any =
      data.projectName +
      "_" +
      data.surveyName +
      "_" +
      data.deliverableName +
      "_" +
      data.fileType;

    deliverablesApi.post(url, data).then((resp: any) => {
      if (resp.status?.toLowerCase() === "success") {
        setUploadSuccess(true);
        setFileData(filename);

        toast.success("File uploaded successfully");

        setSpinner(false);
      } else {
        toast.error(resp.errormessage);
        setSpinner(false);
      }
    });
  };
  const handleVideo = async (event: any) => {
    setSpinner(true);
    let url = "videos/upload";

    let data = {
      surveyId: props.survey.id,
      surveyName: props.survey.surveyName,
      projectName: props.project.projectName,
      videoName: props.data.videoName,
      type: "video",
    };
    //setFileData("");
    let filename: any =
      data.projectName + "_" + data.surveyName + "_" + data.videoName;
    deliverablesApi.post(url, data).then((resp: any) => {
      if (resp?.status?.toLowerCase() == "success") {
        setUploadSuccess(true);
        setFileData(filename);
        toast.success("File uploaded successfully");

        setSpinner(false);
      } else {
        toast.error(resp.errormessage);

        setSpinner(false);
      }
    });
  };

  return (
    <Dialog
      visible={props.visible}
      onHide={props.onHide}
      header={props.header}
      draggable={false}
    >
      <Loader loader={spinner} />

      <ToastContainer />

      {props.header === "Add Report" && (
        <>
          <div className="row m-t-10">
            <div className="col-6">
              <InputComponent
                inputLabel={true}
                inputLabelText="Deliverable Name"
                mandatory={true}
                name="deliverableName"
                placeholder="Enter Deliverable Name"
                value={props.data.deliverableName}
                required={true}
                type="text"
                onKeyChange={props.handleChange}
              />
            </div>
            <div className="col-6">
              <label>
                Upload Report
                <span className="mandatory"> *</span>
              </label>
              <FileUploadDemo
                onChaneImage={props.onFileUpload}
                value={props.data.imageUrl}
                accept="application/pdf"
                maxFileSize={5e9}
                icon="pdf"
                disabled={props.disableUpload}
                handleDelete={true}
                handleFileDelete={props.handleFileDelete}
              />
            </div>
          </div>
          <ActionButtons
            buttons={["Save", "Cancel"]}
            onSave={() => props.handleSave("report")}
            onCancel={props.onHide}
            disabled={props.handleSaveDisable("report")}
          />
        </>
      )}
      {props.header === "Upload Video" && (
        <>
          <div className="row m-t-10">
            <div className="col-6">
              <InputComponent
                inputLabel={true}
                inputLabelText="Video Name"
                mandatory={true}
                name="videoName"
                placeholder="Enter Video Name"
                value={props?.data?.videoName}
                required={true}
                type="text"
                onKeyChange={props?.handleChange}
              />
            </div>
            <div className="col-6">
              <label>
                Upload Video
                <span className="mandatory"> *</span>
              </label>
              {!uploadSuccess ? (
                <>
                  <input
                    hidden
                    id="file2"
                    type="button"
                    value={fileData}
                    //accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                    placeholder="Browse"
                    onClick={(event) => handleVideo(event)}
                  />

                  <label htmlFor="file2">
                    <div className="mx-4 d-flex align-items-center">
                      <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />

                      <span className="text-muted">Upload Video</span>
                    </div>
                  </label>
                </>
              ) : (
                <>
                  <span>
                    {uploadSuccess ? (
                      <span>
                        {fileData}

                        {/* <span
                          onClick={() => {
                            deleteConfirmation(selectedTower, "image");
                          }}
                          style={{ position: "relative", top: "-10px" }}
                        >
                          <Badge
                            className="ms-1 pointer"
                            value="X"
                            severity="info"
                          ></Badge>
                        </span> */}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </>
              )}

              {/* <FileUploadDemo
                onChaneImage={props.onFileUpload}
                value={props.data.imageUrl}
                accept="application/zip"
                maxFileSize={5e9}
                icon="zip"
                disabled={props.disableUpload}
                handleDelete={true}
                handleFileDelete={props.handleFileDelete}
               /> */}
            </div>
          </div>
          <ActionButtons
            buttons={["Save", "Cancel"]}
            onSave={() => props.handleSave("video")}
            onCancel={props.onHide}
            disabled={!props.data.videoName || fileData === ""}
          />
        </>
      )}
      {props.header === "Add Download" && (
        <>
          <div className="row m-t-10">
            <div className="col-6">
              {/* <label>
                Deliverable Name
                <span className="mandatory"> *</span>
              </label> */}
              {/* <Dropdown
                value={props.data.deliverableName}
                options={props.deliverables}
                optionLabel="deliverableName"
                optionValue="deliverableName"
                className="w-100 border ht-50"
                onChange={(e) => props.handleChange(e.value, "deliverableName")}
                placeholder="Select a Deliverable"
                filter
                filterBy="deliverableName"
              /> */}
              <InputComponent
                inputLabel={true}
                inputLabelText="Deliverable Name"
                mandatory={true}
                name="deliverableName"
                placeholder="Enter Deliverable Name"
                value={props.data.deliverableName}
                required={true}
                type="text"
                onKeyChange={props.handleChange}
              />
            </div>
            <div className="col-6">
              <label>
                File Type
                <span className="mandatory"> *</span>
              </label>
              <Dropdown
                value={props.data.fileType}
                options={props.fileTypes}
                appendTo="self"
                optionLabel="label"
                className="w-100 border ht-50"
                onChange={(e) => props.handleChange(e.value, "fileType")}
                placeholder="Select a File Type"
              />
            </div>
            <div className="col-6 m-t-10">
              <div className="m-t-10">
                {!uploadSuccess ? (
                  <>
                    <input
                      hidden
                      id="file2"
                      type="button"
                      //accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                      placeholder="Browse"
                      onClick={(event) => handleDownload(event)}
                    />

                    <label htmlFor="file2">
                      <div className="mx-4 d-flex align-items-center">
                        <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />

                        <span className="text-muted">Upload File</span>
                        <span className="mandatory"> *</span>
                      </div>
                    </label>
                  </>
                ) : (
                  <>
                    <span>
                      {uploadSuccess ? (
                        <span>
                          {fileData}

                          {/* <span
                          onClick={() => {
                            deleteConfirmation(selectedTower, "image");
                          }}
                          style={{ position: "relative", top: "-10px" }}
                        >
                          <Badge
                            className="ms-1 pointer"
                            value="X"
                            severity="info"
                          ></Badge>
                        </span> */}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </>
                )}
              </div>
              {/* <FileUploadDemo
                onChaneImage={props.onFileUpload}
                value={props.data.imageUrl}
                maxFileSize={5e9}
                disabled={
                  !(props.data.deliverableName && props.data.fileType) ||
                  props.disableUpload
                }
                icon="zip"
                handleDelete={true}
                handleFileDelete={props.handleFileDelete}
              /> */}
            </div>
          </div>
          <ActionButtons
            buttons={["Save", "Cancel"]}
            onSave={() => props.handleSave("download")}
            onCancel={props.onHide}
            disabled={
              !props.data.deliverableName ||
              props.data.deliverableName === "" ||
              !props.data.fileType ||
              props.data.fileType === "" ||
              !fileData ||
              fileData === ""
            }
          />
        </>
      )}
    </Dialog>
  );
};

export default AddDeliverables;
