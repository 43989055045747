import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
  Label,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { formatDate } from "./functions";
import { Tooltip } from "primereact/tooltip";

const BasicDataTable = (props) => {
  const navigate = useNavigate();
  let columns;
  switch (props.type) {
    case "CLIENT":
      columns = [
        {
          name: <span className="font-weight-bold fs-13">Client ID</span>,
          selector: (row) => row.clientid,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Client Name</span>,
          selector: (row) => row.clientname,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Country</span>,
          selector: (row) => row.country,
          sortable: true,
        },

        {
          name: <span className="font-weight-bold fs-13">Client Admin</span>,
          selector: (row) => props.clientAdminObj(row.AssignClientAdminArr),
          sortable: true,
          cell: (data) => props.clientAdminObj(data.AssignClientAdminArr),
        },
        {
          name: <span className="font-weight-bold fs-13">Contact Number</span>,
          selector: (row) => row.contactNumber,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Action</span>,
          sortable: true,
          selector: (row) => row,
          cell: (data) => {
            return (
              <UncontrolledDropdown
                className="dropdown d-inline-block"
                direction="end"
              >
                <DropdownToggle
                  className="btn btn-soft-secondary btn-sm"
                  tag="button"
                >
                  <i className="ri-more-fill align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    href="#!"
                    onClick={() => props.handleRowView(data)}
                  >
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                    View
                  </DropdownItem>
                  <DropdownItem
                    className="edit-item-btn"
                    onClick={() => {
                      navigate(`/clients/edit/${data.clientid}`);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className="remove-item-btn"
                    onClick={() => props.onClickDelete(data)}
                  >
                    {" "}
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete{" "}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            );
          },
        },
      ];
      break;
    case "SURVEY":
      columns = [
        {
          name: <span className="font-weight-bold fs-13">Survey ID</span>,
          selector: (row) => row.id,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Survey Name</span>,
          selector: (row) => row.surveyName,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Latitude</span>,
          selector: (row) => row.long,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Longitude</span>,
          selector: (row) => row.lat,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Date of Survey</span>,
          selector: (row) => formatDate(row.dateOfSurvey),
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13 ">% Completion</span>,
          selector: (row) => row.completionPercent,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Actions</span>,
          sortable: true,
          selector: (row) => row,
          cell: (data) => {
            return (
              <UncontrolledDropdown
                className="dropdown d-inline-block"
                direction="end"
              >
                <DropdownToggle
                  className="btn btn-soft-secondary btn-sm"
                  tag="button"
                >
                  <i className="ri-more-fill align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem onClick={() => props.handleDetails(data)}>
                    <i className="ri-database-2-fill align-bottom me-2 text-muted"></i>
                    Details
                  </DropdownItem>
                  <DropdownItem
                    className="edit-item-btn"
                    onClick={() => props.handleEdit(data)}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className="remove-item-btn"
                    onClick={() => props.onClickDelete(data)}
                  >
                    {" "}
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete{" "}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            );
          },
        },
      ];
      break;
    case "ROLE":
      columns = [
        {
          name: <span className="font-weight-bold fs-13">Role ID</span>,
          selector: (row) => row.roleId,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Role Name</span>,
          selector: (row) => row.roleName,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Role Type</span>,
          selector: (row) => row.roleType,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Privileges</span>,
          selector: (row) => "Privileges",
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Actions</span>,
          sortable: true,
          selector: (row) => row,
          cell: (data) => {
            return (
              <UncontrolledDropdown
                className="dropdown d-inline-block"
                direction="end"
              >
                <DropdownToggle
                  className="btn btn-soft-secondary btn-sm"
                  tag="button"
                >
                  <i className="ri-more-fill align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem onClick={() => props.handleView(data)}>
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                    View
                  </DropdownItem>
                  {/* <DropdownItem
                    className="edit-item-btn"
                    onClick={() => props.handleEdit(data)}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className="remove-item-btn"
                    onClick={() => props.onClickDelete(data)}
                  >
                    {" "}
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete{" "}
                  </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
            );
          },
        },
      ];
      break;
    case "PROJECT":
      columns = [
        {
          name: <span className="font-weight-bold fs-13">Project ID</span>,
          selector: (row) => row.id,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Project Name</span>,
          selector: (row) => row.projectName,
          sortable: true,
        },
        {
          name: (
            <span
              className="font-weight-bold fs-13"
              style={{ textAlign: "center" }}
            >
              Industry
            </span>
          ),
          selector: (row) => row.industry,
          sortable: true,
        },
        {
          name: (
            <span
              className="font-weight-bold fs-13"
              style={{ textAlign: "center" }}
            >
              Module
            </span>
          ),
          selector: (row) => row.moduleCategory,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13 ">Sub Module</span>,
          selector: (row) => row.subModule,
          sortable: true,
        },
        {
          name: (
            <span
              className="font-weight-bold fs-13"
              style={{ textAlign: "center" }}
            >
              Created Date
            </span>
          ),
          selector: (row) => formatDate(row.createdAt),
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Location</span>,
          selector: (row) => row.location,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Active</span>,
          grow: 0.5,
          selector: (row) => row,
          cell: (data) => {
            return (
              <div className="flex-shrik-0 form-check form-switch form-switch-right form-switch-md">
                {data?.moduleCategory === "Survey" && (
                  <Input
                    className="form-check-input code-switcher "
                    type="checkbox"
                    disabled={data.totalSurvey === 0}
                    checked={data.isActive}
                    onChange={(e) => props.handleActive(e, data)}
                  />
                )}
                {data?.moduleCategory === "Inspection" && (
                  <Input
                    className="form-check-input code-switcher "
                    type="checkbox"
                    disabled={data.projectStatus !== "Completed"}
                    checked={data.isActive}
                    onChange={(e) => props.handleActive(e, data)}
                  />
                )}
              </div>
            );
          },
        },

        {
          name: <span className="font-weight-bold fs-13">Actions</span>,
          sortable: true,
          grow: 1.5,
          selector: (row) => row,
          cell: (data) => {
            console.log(data);
            return (
              <div className="d-flex align-items-center">
                <Tooltip target=".custom-target-icon-view" />
                <Tooltip target=".custom-target-icon-delete" />
                <Tooltip target=".custom-target-icon-edit" />
                <Tooltip target=".custom-target-icon-source" />
                <Tooltip target=".custom-target-icon-survey" />
                <Tooltip target=".custom-target-icon-deliver" />
                <Tooltip target=".custom-target-icon-asset" />
                <span>
                  <i
                    className="ri-eye-fill fs-4 me-1 pointer pointer custom-target-icon-view"
                    data-pr-tooltip="View"
                    data-pr-position="top"
                    style={{ color: "green" }}
                    onClick={() => props.view(data)}
                  ></i>
                  {(data?.industry === "Land Survey" ||
                    data?.industry === "Mining Survey") && (
                    <i
                      className="ri-database-2-fill fs-4 me-1 pointer custom-target-icon-survey"
                      data-pr-tooltip="Survey"
                      data-pr-position="top"
                      style={{ color: "green" }}
                      onClick={() => {
                        props.gotToSurvey(data);
                        sessionStorage.setItem(
                          "prevProjectRoute",
                          JSON.stringify({
                            name: data.projectName,
                            link: `/delivers/${data.clientId}`,
                          })
                        );
                      }}
                    ></i>
                  )}
                  {(data?.industry === "Land Survey" ||
                    data?.industry === "Mining Survey") && (
                    <i
                      className="ri-file-list-2-line fs-4 me-1 pointer custom-target-icon-deliver"
                      data-pr-tooltip="Deliverable"
                      data-pr-position="top"
                      style={{ color: "green" }}
                      onClick={() => {
                        navigate(`/delivers/deliverables/${data.id}`);
                        sessionStorage.setItem(
                          "prevProjectRoute",
                          JSON.stringify({
                            name: data.projectName,
                            link: `/delivers/${data.clientId}`,
                          })
                        );
                      }}
                    ></i>
                  )}
                  {data.industry === "Transmission Lines" && (
                    <i
                      className="ri-file-list-2-line fs-4 me-1 pointer custom-target-icon-source"
                      data-pr-tooltip="Source"
                      data-pr-position="top"
                      style={{ color: "green" }}
                      onClick={() => {
                        navigate(`/delivers/source/${data.id}`);
                        sessionStorage.setItem(
                          "prevProjectRoute",
                          JSON.stringify({
                            name: data.projectName,
                            link: `/delivers/${data.clientId}`,
                          })
                        );
                      }}
                    ></i>
                  )}
                  {data.industry === "Oil and Gas" && (
                    <i
                      className="ri-file-list-2-line fs-4 me-1 pointer custom-target-icon-source"
                      data-pr-tooltip="Assets"
                      data-pr-position="top"
                      style={{ color: "green" }}
                      onClick={() => {
                        navigate(`/delivers/assets/${data.id}`);
                        sessionStorage.setItem(
                          "prevProjectRoute",
                          JSON.stringify({
                            name: data.projectName,
                            link: `/delivers/${data.clientId}`,
                          })
                        );
                      }}
                    ></i>
                  )}
                  <i
                    className="bx bx-edit fs-4 me-1 pointer custom-target-icon-edit"
                    data-pr-tooltip="Edit"
                    data-pr-position="top"
                    style={{ color: "green" }}
                    onClick={() => {
                      navigate(`/delivers/edit/${data.id}`);
                      sessionStorage.setItem(
                        "editProjectData",
                        JSON.stringify(data)
                      );
                      sessionStorage.setItem(
                        "prevProjectRoute",
                        JSON.stringify({
                          name: data.projectName,
                          link: `/delivers/${data.clientId}`,
                        })
                      );
                    }}
                  ></i>
                  <i
                    className="ri-delete-bin-6-line fs-4 me-1 pointer custom-target-icon-delete"
                    data-pr-tooltip="Delete"
                    data-pr-position="top"
                    style={{ color: "red" }}
                    onClick={() => props.onClickDelete(data)}
                  ></i>
                </span>
              </div>
            );
          },
        },
      ];
      break;
    case "USERS":
      columns = [
        {
          name: <span className="font-weight-bold fs-13">User ID</span>,
          selector: (row) => row.userId,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">First Name</span>,
          selector: (row) => row.firstName,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Last Name</span>,
          selector: (row) => row.lastName,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Role Name</span>,
          selector: (row) => row.role,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Email ID</span>,
          selector: (row) => row.email,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Contact Number</span>,
          selector: (row) => row.contactNumber,
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">Is Active</span>,
          selector: (row) => row,
          sortable: true,
          cell: (data) => {
            let bool = true;
            return (
              <div className="form-check form-switch form-switch-success mb-3">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="SwitchCheck3"
                  onChange={() => {
                    props.onClickSwitch(data);
                  }}
                  checked={data?.isActive}
                />
              </div>
            );
          },
        },
        {
          name: <span className="font-weight-bold fs-13">Action</span>,
          sortable: true,
          selector: (row) => row,
          cell: (data) => {
            return (
              <UncontrolledDropdown
                className="dropdown d-inline-block"
                direction="end"
              >
                <DropdownToggle
                  className="btn btn-soft-secondary btn-sm"
                  tag="button"
                >
                  <i className="ri-more-fill align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem href="#!" onClick={() => props.view(data)}>
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                    View
                  </DropdownItem>
                  <DropdownItem
                    className="edit-item-btn"
                    onClick={() => navigate(`/users/edit/${data.userId}`)}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className="remove-item-btn"
                    onClick={() => props.onClickDelete(data)}
                  >
                    {" "}
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete{" "}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            );
          },
        },
      ];
      break;
    default:
      columns = [];
  }

  return (
    <DataTable
      fixedHeader
      fixedHeaderScrollHeight="600px"
      columns={columns}
      data={props.data}
      striped
      highlightOnHover
    />
  );
};

export default BasicDataTable;
