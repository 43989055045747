import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Loader from "../../CommonComponent/Loader";
import AddAssetCard from "./component/AddAssetCard";
const AssetComponentView = () => {
  const navigate = useNavigate();
  const assetId = useParams().id;
  const [loading, setLoading] = useState(false);

  return (
    <div className="content p-3">
      <Loader loader={loading} />
      <div
        className="content"
        style={{ marginTop: "20px", paddingBottom: "0px" }}
      >
        <React.Fragment>
          <Row>
            <div className="fs-20 primaryColor">
              <i
                className=" ri-arrow-left-circle-line fs-22 me-2 align-bottom pointer"
                onClick={() => navigate(-1)}
              ></i>
              Back
            </div>
          </Row>
          <Row>
            <AddAssetCard assetId={assetId} setLoading={setLoading} />
          </Row>
        </React.Fragment>
      </div>
    </div>
  );
};

export default AssetComponentView;
