import React, { useState, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

import { useSelector } from "react-redux";

import avatar from "../../assets/images/users/avatar-1.jpg";
import { getDate } from "../Components/Utils";
import NewBreadCrumb from "../Components/NewBreadCrumb";
import ClientHeader from "../Dashboard/ClientHeader";
const UserProfile = () => {
  const [email, setemail] = useState("");
  const [idx, setidx] = useState("1");

  const [userName, setUserName] = useState("");

  const { user } = useSelector((state) => ({
    user: state.user,
  }));

  console.log(user);
  return (
    <React.Fragment>
      {user.selectedRole === "Client" && <ClientHeader />}
      <div className="p-5 oy">
        <Container fluid>
          <NewBreadCrumb
            title={"Profile"}
            pageTitle={"Dashboard"}
            pageLink={
              user?.selectedRole === "Sme"
                ? "/sme-dashboard"
                : user?.selectedRole === "Client"
                ? "/client-dashboard"
                : user?.selectedRole === "Admin"
                ? "/admin-dashboard"
                : "/pilot-dashboard"
            }
          />
          <Row>
            <Col lg="12">
              <Card className="p-2">
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        src={user?.imageUrl ? user.imageUrl : avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{user?.loginName}</h5>
                        <p className="mb-1">Email Id : {user?.email}</p>
                        <p className="mb-1">Phone No : {user?.contactNumber}</p>
                        <p className="mb-0">
                          Last Login : {getDate(user?.lastLoginDate)}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Card className="p-2">
              <h4 className="card-title mb-2">Roles</h4>
              <Row>
                {user?.roles?.map((e) => (
                  <Col xs={3}>
                    <p className="mb-0 ms-3"> {e?.roleName}</p>
                  </Col>
                ))}
              </Row>
            </Card>
          </Row>
          <Row className="mb-4">
            <Card className="p-2">
              <h4 className="card-title mb-2">Modules</h4>
              <Row>
                {user?.subModuleName.split(",")?.map((e) => (
                  <Col xs={3}>
                    <p className="mb-0 ms-3"> {e}</p>
                  </Col>
                ))}
              </Row>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
