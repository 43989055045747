import React, { useEffect, useState } from "react";
import * as api from "../../../api/global.api";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../CommonComponent/Loader";
import { Button, Col, Row } from "reactstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import AssetTable from "../../tables/AssetTable";
import CreateAsset from "./CreateAsset";
import DeleteModal from "../../Common/DeleteModal";
import Pagination from "../../commons/pagination";

const Assets = () => {
  const id = useParams().id;
  let prevProjectRoute: any = sessionStorage.getItem("prevProjectRoute");
  let prevClientRoute: any = sessionStorage.getItem("prevRoute");

  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState(null);
  const [page, setPage] = useState<any>({ count: 0, curr: 1 });
  const { count, curr } = page;

  const getAssets = (val: any) => {
    setLoading(true);
    api
      .post(`getAssets?page=${val}&limit=10&projectId=${Number(id)}`, {})
      .then((res) => {
        if (res?.status?.toLowerCase() === "success") {
          setAssets(res?.response?.assetList);
          setPage({ ...page, count: res?.response?.count });
          setLoading(false);
        } else {
          toast.error(res?.errormessage);
          setLoading(false);
        }
      });
  };
  useEffect(() => {
    getAssets(curr);
  }, []);

  const handleEdit = (val: any) => {
    setData(val);
    setMode("Edit Asset");
    setOpen(true);
  };

  const handleDelete = (val: any) => {
    setData(val.id);
    setDeleteModal(true);
  };

  const handleAddButton = () => {
    setMode("Add Asset");
    setOpen(true);
  };

  const handleDeleteData = () => {
    setLoading(true);
    api.deleteApi(`deleteAsset/${data}`).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        getAssets(curr);
        setDeleteModal(false);
        setLoading(false);
        toast.success("Asset deleted successfully");
      } else {
        toast.error(res?.errormessage);
        setDeleteModal(false);
        setLoading(false);
      }
    });
  };

  const handlePageChange = (val: any) => {
    setPage({ ...page, curr: val });
  };

  const onActivate = (row: any) => {
    setLoading(true);
    console.log(row);
    api
      .put("updateAsset", { id: row?.id, isActive: !row?.isActive })
      .then((res: any) => {
        if (res?.status?.toLowerCase() === "success") {
          res?.response?.isActive
            ? toast.success("Asset activated successfully")
            : toast.success("Asset deactivated successfully");
          getAssets(page?.curr);
        } else {
          toast.error(res?.errormessage);
          setLoading(false);
        }
      });
  };
  return (
    <div className="ptable mx-2">
      <Loader loader={loading} />
      <ToastContainer />
      <Row>
        <Col>
          <BreadCrumb
            title="Assets"
            pageTitle={JSON.parse(prevProjectRoute)?.name}
            pageLink={JSON.parse(prevProjectRoute)?.link}
            pageTitle1={JSON.parse(prevClientRoute)?.name}
            pageLink1={JSON.parse(prevClientRoute)?.link}
          />
        </Col>
        <Col className="d-flex justify-content-end">
          <Button color="primary" onClick={handleAddButton}>
            {" "}
            + Add Assets
          </Button>
        </Col>
        <Col sm={12}>
          <AssetTable
            data={assets}
            setMode={setMode}
            handleEdit={handleEdit}
            setOpen={setOpen}
            handleDelete={handleDelete}
            onActivate={onActivate}
          />
          <Pagination
            itemsPerPage={12}
            count={count}
            handlePageChange={handlePageChange}
          />
        </Col>
      </Row>
      {open && (
        <CreateAsset
          open={open}
          mode={mode}
          editData={data}
          setOpen={setOpen}
          getAssets={getAssets}
        />
      )}
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteData}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default Assets;
