import React, { useEffect } from "react";
import { useState } from "react";
import ReactSelect from "react-select";
import { Button, Label } from "reactstrap";
import ModuleDashboard from "./ModuleDashboard";
import OverallDashboard from "./OverallDashboard";
import PrintComp from "./PrintComp";
import * as api from "../../../api/global.api";
import { Calendar } from "primereact/calendar";
import { convertDateToISO, formatDate } from "../../commons/functions";
import { useAppSelector } from "../../../store/hooks";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../CommonComponent/Loader";

const Dashboard = () => {
  const user = useAppSelector((state) => state.user);
  const subModules = user?.subModuleName?.split(", ");
  let yearArr = [];
  let start = new Date().getFullYear();
  let curr = 2020;
  while (start >= curr) {
    yearArr.push(start);
    start--;
  }
  let dateData = yearArr?.map((e: any) => {
    return { label: "FY " + e + " - " + "FY " + (+e + 1), value: e };
  });

  const [dateList, setDateList] = useState<any>(dateData);
  const [loading, setLoading] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState<any>("overall");
  const [date, setDate] = useState<any>(new Date());
  const [selectedDate, setSelectedDate] = useState<any>(dateData[0]);
  const [defDate, setDefDate] = useState<any>(true);
  const [basedOnStates, setBasedOnStates] = useState<any>({
    label: [],
    data: [],
  });
  const [basedOnClients, setBasedOnClients] = useState<any>({
    label: [],
    data: [],
  });
  const [basedOnIndustry, setBasedOnIndustry] = useState<any>({
    label: [],
    data: [],
  });

  const [selectedModule, setSelectedModule] = useState<any>({
    label: "Transmission Lines",
    value: "Transmission Lines",
  });
  const [moduleOptions, setModuleOptions] = useState<any>([]);

  const [data, setData] = useState<any>({
    statusWise: { label: [], data: [] },
    clientWise: { label: [], data: [] },
    projectWise: {
      completed: [],
      inProgress: [],
      inprogress: [],
      yettostart: [],
      inspectiondone: [],
      all: [],
    },
  });

  const getDashboardItems = (fromDate: any, toDate: any) => {
    setLoading(true);
    let from = `${fromDate}-04-01T00:00:00.000Z`;
    let to = `${toDate}-03-31T00:00:00.000Z`;
    let url: any = `getOverallDashboardData?from=${from}&to=${to}`;
    api.get(url).then((res: any) => {
      if (res?.status?.toLowerCase() === "success") {
        let result = res?.response;
        let industry: any = [];
        let number: any = [];
        let clients: any = [];
        let numberOne: any = [];
        let state: any = [];
        let numberTwo: any = [];
        result.clientsByIndustry.map((ele: any) =>
          industry?.push(ele?.industry)
        );
        result.clientsByIndustry.map((ele: any) =>
          number?.push(ele?.NumberofClients)
        );
        result.projectsByClient.map((ele: any) => clients?.push(ele?.client));
        result.projectsByClient.map((ele: any) =>
          numberOne?.push(ele?.projectcount)
        );
        result.projectsByState.map((ele: any) => state?.push(ele?.state));
        result.projectsByState.map((ele: any) =>
          numberTwo?.push(ele?.projectcount)
        );

        setBasedOnIndustry({
          ...basedOnIndustry,
          label: industry,
          data: number,
        });
        setBasedOnClients({
          ...basedOnClients,
          label: clients,
          data: numberOne,
        });
        setBasedOnStates({ ...basedOnStates, label: state, data: numberTwo });
        setLoading(false);
      } else {
        toast.error(res?.errormessage);
        setLoading(false);
      }
    });
  };

  const getModuleWiseDashboard = (fromDate: any, toDate: any, date: any) => {
    setLoading(true);
    let from = `${fromDate}-04-01T00:00:00.000Z`;
    let to = `${toDate}-03-31T00:00:00.000Z`;
    let module = selectedModule.value;
    let date1 = convertDateToISO(date);
    api
      .get(
        `modulewiseDashboardData?from=${from}&to=${to}&module=${module}&projectDate=${date1}&defaultDate=${defDate}`
      )
      .then((res) => {
        let clientLabel: any = [];
        let clientData: any = [];
        if (res?.status?.toLowerCase() === "success") {
          let statusLabel = Object.keys(res.response.prjctListByStatusWise);
          let statusData = Object.values(res.response.prjctListByStatusWise);
          res.response.projectsByClient.map((e: any) =>
            clientLabel.push(e.client)
          );
          res.response.projectsByClient.map((e: any) =>
            clientData.push(e.projectcount)
          );
          let completed = res.response?.getAllProjectsByStatus.filter(
            (e: any) => e.projectStatus === "Completed"
          );
          let inProgress = res.response?.getAllProjectsByStatus.filter(
            (e: any) => e.projectStatus !== "Completed"
          );

          let yettostart = res.response?.getAllProjectsByStatus.filter(
            (e: any) => e.projectStatus === "Yet To Start"
          );

          let inprogress = res.response?.getAllProjectsByStatus.filter(
            (e: any) => e.projectStatus === "In Progress"
          );
          let inspectiondone = res.response?.getAllProjectsByStatus.filter(
            (e: any) => e.projectStatus === "Inspection Done"
          );
          setData({
            statusWise: { label: statusLabel, data: statusData },
            clientWise: { label: clientLabel, data: clientData },
            projectWise: {
              completed: completed,
              inProgress: inProgress,
              inprogress: inprogress,
              yettostart: yettostart,
              inspectiondone: inspectiondone,
              all: res?.response?.getAllProjectsByStatus,
            },
          });
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res?.errormessage);
        }
      });
  };

  const handleSelectedDate = (val: any) => {
    setSelectedDate(val);
    setDefDate(false);
  };

  const getModule = () => {
    let modules = subModules.map((item: any) => {
      return { label: item, value: item };
    });
    setModuleOptions(modules);
  };

  useEffect(() => {
    getDashboardItems(selectedDate?.value, selectedDate?.value + 1);
    getModuleWiseDashboard(selectedDate?.value, selectedDate?.value + 1, date);
    localStorage?.getItem("dashboard")
      ? setSelectedBtn(localStorage?.getItem("dashboard"))
      : setSelectedBtn("overall");
  }, [selectedModule, date, selectedDate]);

  useEffect(() => {
    getModule();
  }, [selectedModule]);

  return (
    <div className="mt-3">
      {loading && <Loader loader={loading} />}
      <ToastContainer />
      {!loading && (
        <div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Button
              className="dashboard-buttons"
              style={{
                background: selectedBtn === "overall" ? "#F2F8FF" : "#023F8808",
                color: selectedBtn === "overall" ? "#42997E" : "#5D6263",
              }}
              onClick={() => {
                setSelectedBtn("overall");
                localStorage.setItem("dashboard", "overall");
              }}
            >
              <input
                type="radio"
                className="me-2"
                checked={selectedBtn === "overall"}
              />
              Overall Dashboard
            </Button>
            <>
              <Button
                className="dashboard-buttons"
                onClick={() => {
                  setSelectedBtn("module");
                  localStorage.setItem("dashboard", "module");
                }}
                style={{
                  background:
                    selectedBtn === "module" ? "#F2F8FF" : "#023F8808",
                  color: selectedBtn === "module" ? "#42997E" : "#5D6263",
                }}
              >
                <input
                  type="radio"
                  className="me-2"
                  checked={selectedBtn === "module"}
                />
                Module wise Dashboard
              </Button>
              {selectedBtn === "module" && (
                <Select
                  className="admin-select"
                  options={moduleOptions}
                  value={selectedModule}
                  onChange={(val) => setSelectedModule(val)}
                />
              )}
            </>
            <Select
              options={dateList}
              value={selectedDate}
              onChange={(val) => handleSelectedDate(val)}
              className="admin-select"
            />
          </div>
          <div>
            {selectedBtn === "overall" && (
              <OverallDashboard
                data={basedOnIndustry}
                dataClient={basedOnClients}
                dataState={basedOnStates}
              />
            )}
            {selectedBtn === "module" && (
              <ModuleDashboard
                data={data}
                setDate={setDate}
                date={date}
                setDefDate={setDefDate}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
