import { Route, Routes, Navigate, HashRouter } from "react-router-dom";
import { Header } from "../header/Header";
import AdminHeader from "../header/AdminHeader";

import SelectRoles from "../login/SelectRoles";
import Error404 from "../errorPages/Error404";
import Error500 from "../errorPages/Error500";

//client
// import ClientDashboard from "../Client/Dashboard";
// import TxCorridor from "../Client/Tx/TxCorridor";
// import TxTower from "../Client/Tx/TxTower";
// import TxComponent from "../Client/Tx/TxComponent";
import ReactPlayer from "../VideoPlayer/ReactPlayer";
import AssetDashboard from "../OilandGas/ClientDashboard/AssetDashboard";
import ClientAssets from "../OilandGas/ClientDashboard/ClientAssets";
// import PrintComp from "../../components/admin/Dashboard/PrintComp";
// import Comapre from "../Temporal/Temporal";

//admin
import AdminDashboard from "../admin/Dashboard/index";
import AllProjects from "../admin/Dashboard/AllProjects";
import AdminUserManagement from "../admin/Users/index";
import AddUser from "../admin/Users/AddUser";
import AdminClientManagement from "../admin/Client/index";
import AddClients from "../admin/Client/AddClient";
import ModuleManagement from "../admin/Module/index";
import AddModule from "../admin/Module/AddModule";
import Projects from "../ProjectManagement/Projects/Projects";
import CreateProject from "../ProjectManagement/Projects/CreateProject";
import Surveys from "../ProjectManagement/Projects/Surveys";
import SurveyDetails from "../ProjectManagement/Projects/SurveyDetails";
import AddProjectDetails from "../ProjectManagement/Projects/AddProjectDetails";
import Deliverables from "../ProjectManagement/Projects/Deliverables";
import Source from "../TransmissionLine/Source";
import Corridor from "../TransmissionLine/Corridor";
import Towers from "../TransmissionLine/Towers";
import Assets from "../OilandGas/Admin/Assets";

//pilot
import PilotDashboard from "../Pilot/Dashboard";
import AssestList from "../Pilot/OilandGas/AssetList";
import TxPilotCorridor from "../TransmissionLine/pilotDashboard/pilotdashboard";
import PilotDataTable from "../TransmissionLine/pilotDashboard/pilotDataTable";


//sme
import SmeAssets from "../SME/OilandGas/SmeAssets";
import SMEAsset from "../OilandGas/SmeDashboard/SmeAssetList";
import SMEAssetComponent from "../OilandGas/SmeDashboard/AssetComponentView";

// import TxDashboard from "../Client/Dashboard";
// import AssetsListView from "../OilandGas/PilotDashboard/AssetsListView";
// import ClientProjects from "../ClientProjects/ClientProjects";
// import UserListData from "../login/UserListData";
// import ObservationModal from "../TransmissionLine/Sme/observationModal";
// import CorridorsTable from "../TransmissionLine/Sme/CorridorsTable";

// import SmeProjects from "../TransmissionLine/Sme/SmeProjects";
// import ObservationCard from "../TransmissionLine/Sme/ObservationCard";
// import PilotPage from "../OilandGas/PilotDashboard";
// import SMEProject from "../OilandGas/SmeDashboard/index";
// import GailClient from "../OilandGas/ClientDashboard";
// import LoginVelzon from "../login/LoginVelzon";

// import Clientlist from "../TransmissionLine/Client/Clientlist";
// import CorridorsClient from "../TransmissionLine/Client/CoordiorsClient";
// import TransmissionMap from "../commons/TransmissionMap";
// import ClientDashboard from "../TransmissionLine/Client/ClientDashboard";
// import TowerDashboard from "../TransmissionLine/Client/TowerDashboard";
// import CarosuleComponent from "../TransmissionLine/Client/carousel";
// import DisplayVideo from "../ProjectManagement/Projects/DisplayVideo";
// import ThermalReport from "../Client/Tx/ThermalReport";

//new common
import UserProfile from "../../Pages/Profile/Profile";
import SMEDashboard from "../../Pages/Dashboard/SMEDashboard";
import ClientDashboard from "../../Pages/Dashboard/ClientDashboard";

//new client
import SurveyMap from "../../Pages/ClientSurvey/SurveyMap";
import Comparison from "../../Pages/ClientSurvey/Comparison";
import ProjectDashboard from "../../Pages/ClientTx/ProjectDashboard";
import TxTowers from "../../Pages/ClientTx/TxTowers";
import TxComponent from "../../Pages/ClientTx/TxComponent";
import TxVideo from "../../Pages/ClientTx/TxVideo";

//new sme
import SMEHeader from "../../Pages/Dashboard/SMEHeader";
import SMECorridor from "../../Pages/SMETx/Corridor";
import SMETower from "../../Pages/SMETx/Tower";
import SMETxComponent from "../../Pages/SMETx/TxComponent";
import TxComponentImage from "../../Pages/SMETx/TxComponentImage";
import TxComponentVideo from "../../Pages/SMETx/TxComponentVideo";
import ThreeDTool from "../../Pages/ClientSurvey/ThreeDTool";

const Routing = (props) => {
  return (
    <HashRouter>
      {props.user.selectedRole === "" ? (
        <>
          <Header />
          <Routes>
            <Route path="/select-role" element={<SelectRoles />} />
          </Routes>
        </>
      ) : props?.user?.selectedRole === "Admin" ? (
        <>
          <AdminHeader>
            <Routes>
              <Route path="/admin-dashboard" element={<AdminDashboard />} />
              <Route path="/allProjects" element={<AllProjects />} />
              <Route path="/users" element={<AdminUserManagement />} />
              <Route path="/user" element={<AddUser />} />
              <Route path="/clients" element={<AdminClientManagement />} />
              <Route path="/client" element={<AddClients />} />
              <Route path="/modules" element={<ModuleManagement />} />
              <Route path="/module" element={<AddModule />} />
              <Route path="/delivers/:id" element={<Projects />} />
              <Route path="/delivers/:type/:id" element={<CreateProject />} />

              <Route path="/delivers/surveys/:id" element={<Surveys />} />
              <Route
                path="/delivers/surveys/create/:id"
                element={<SurveyDetails />}
              />
              <Route
                path="/delivers/surveys/edit/:id"
                element={<SurveyDetails />}
              />
              <Route
                path="/delivers/survey/surveydetails/:id"
                element={<AddProjectDetails />}
              />
              <Route
                path="delivers/deliverables/:id"
                element={<Deliverables />}
              />

              <Route path="/delivers/source/:id" element={<Source />} />
              <Route
                path="/delivers/source/corridor/:id"
                element={<Corridor />}
              />
              <Route
                path="/delivers/source/corridor/tower/:id"
                element={<Towers />}
              />

              <Route path="/delivers/assets/:id" element={<Assets />} />
              <Route path="/error404" element={<Error404 />} />
              <Route path="/error500" element={<Error500 />} />
              <Route path="/select-role" element={<SelectRoles />} />
              <Route path="*" element={<Navigate to="/admin-dashboard" />} />
            </Routes>
          </AdminHeader>
        </>
      ) : props?.user?.selectedRole === "Client" ? (
        <>
          <Routes>
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/client-dashboard" element={<ClientDashboard />} />
            <Route path="/surveys" element={<SurveyMap />} />
            <Route path="/compares" element={<Comparison />} />
            <Route path="/client-tx-towers" element={<TxTowers />} />
            <Route path="/client-tx-component" element={<TxComponent />} />
            <Route path="/client-tx-video" element={<TxVideo />} />
            <Route path="/3dmodal" element={<ThreeDTool />} />
            <Route
              path="/client-project-dashboard"
              element={<ProjectDashboard />}
            />
            <Route
              path="/client-projects/assets/:id"
              element={<AssetDashboard />}
            />
            <Route
              path="/client-projects/:siteId/:id"
              element={<ClientAssets />}
            />
            <Route path="*" element={<Navigate to="/client-dashboard" />} />
          </Routes>
        </>
      ) : props?.user?.selectedRole === "Sme" ? (
        <>
          <SMEHeader />
          <Routes>
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/sme-dashboard" element={<SMEDashboard />} />
            <Route path="/sme-corridor" element={<SMECorridor />} />
            <Route path="/sme-tower" element={<SMETower />} />
            <Route path="/sme-tower-component" element={<SMETxComponent />} />
            <Route
              path="/sme-tower-component-details"
              element={<TxComponentImage />}
            />
            <Route
              path="/sme-tower-component-video"
              element={<TxComponentVideo />}
            />

            <Route path="/assets" element={<SmeAssets />} />
            <Route path="/sme-projects/assets/:id" element={<SMEAsset />} />
            <Route
              path="/sme-projects/assets/asset-componet/:id"
              element={<SMEAssetComponent />}
            />
            {/* <Route path="/reactPlayer" element={<ReactPlayer />} /> */}
            {/* <Route path="/observationCard" element={<ObservationCard />} /> */}
            <Route path="*" element={<Navigate to="/sme-dashboard" />} />
          </Routes>
        </>
      ) : (
        <>
          <Header />
          <Routes>
            <Route path="/pilot-dashboard" element={<PilotDashboard />} />
            <Route path="/assets" element={<AssestList />} />

            <Route
              path="/pilot/projects/:projectId"
              element={<TxPilotCorridor />}
            />

            <Route
              path="/pilot-dashboard/:corridorId"
              element={<PilotDataTable />}
            />

            <Route path="/pilot-projects" element={<PilotDashboard />} />

            <Route path="/pilotDataTable" element={<PilotDataTable />} />

            <Route
              path="/pilot-dashboard/:corridorId"
              element={<PilotDataTable />}
            />

            <Route path="/select-role" element={<SelectRoles />} />
            <Route path="*" element={<Navigate to="/pilot-dashboard" />} />
          </Routes>
        </>
      )}
    </HashRouter>
  );
};

export default Routing;
