import React from "react";
import { ModalBody } from "react-bootstrap";
import { Button, Modal } from "reactstrap";
import shareIcon from "../../assets/svg-new/shareicon.svg";
const MessageModal = (props) => {
  const { isOpen, onCloseClick, onConfirmClick, message } = props;
  return (
    <div>
      <Modal isOpen={isOpen} backdrop={false} size="md">
        <i
          className="bx bx-x pointer fs-3 croxx"
          onClick={() => onCloseClick()}
        ></i>
        <ModalBody className="text-center m-5">
          <img src={shareIcon} className="mb-4" />
          <div className="mb-4">{message}</div>
          <div>
            <Button
              className="w-sm me-3"
              color="success"
              outline
              onClick={() => onCloseClick()}
            >
              No
            </Button>
            <Button
              className="w-sm"
              color="success"
              onClick={() => onConfirmClick()}
            >
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MessageModal;
