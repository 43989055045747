import React, { useState } from "react";
import layerimg from "../../assets/svg-new/Path 24462.svg";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { layersList } from "../../store/layerreducer";
import { useSelector } from "react-redux";
import { checkStringInArray } from "../Components/Utils";

const BottomPanal = (props) => {
  const allLayer = useAppSelector((state) => state.layers);
  const user = useSelector((state) => state?.user);
  const { features } = user;
  const dispatch = useAppDispatch();
  const [isLayersDropdown, setIsLayersDropDown] = useState(false);
  const toggleLayersDropdown = () => {
    setIsLayersDropDown(!isLayersDropdown);
  };

  const makeLayerDropdown = (layer) => {
    return (
      <div className="mb-2 layer-dropdown pe-3">
        <div className="d-flex">
          <span className="me-3">
            <Input
              type="checkbox"
              checked={layer?.visibility}
              onChange={() => changeVisibilty(layer)}
              disabled = {!checkStringInArray("Manage layer visibility - Mining Survey", features)}
            />
          </span>
          <div>{layer?.displayname}</div>
        </div>
        <div>
          <i
            className="bx bx-trash pointer me-2"
            onClick={() => props.deletelayer(layer)}
          ></i>
        </div>
      </div>
    );
  };

  const changeVisibilty = (layer) => {
    let visibleLayer = allLayer?.map((e) => {
      if (e?.displayname === layer.displayname) {
        return { ...e, visibility: Number(!e.visibility) };
      } else {
        return e;
      }
    });
    dispatch(layersList(visibleLayer));
    let dynamicVisible = props?.dynamic?.map((e) => {
      if (e?.displayname === layer.displayname) {
        return { ...e, visibility: Number(!e.visibility) };
      } else {
        return e;
      }
    });
    props.setDynamic(dynamicVisible);
  };



  const changeAllVisibilty = () => {
    let data;
    if (props.selectAll) {
      data = props?.dynamic?.map((e) => ({ ...e, visibility: 0 }));
      props.setSelectAll(false);
    } else {
      data = props?.dynamic?.map((e) => ({ ...e, visibility: 1 }));
      props.setSelectAll(true);
    }
    props.setDynamic(data);
    let layerDetails = [...props?.layers, ...data];
    dispatch(layersList(layerDetails));
  };

  return (
    <div className="bottom-panal">
      <Dropdown
        className="card-header-dropdown p-0 m-0"
        isOpen={isLayersDropdown}
        toggle={toggleLayersDropdown}
        direction="end"
      >
        <DropdownToggle
          tag="a"
          className="text-reset dropdown-btn"
          role="button"
        >
          <div className="bottom-layers">
            <img src={layerimg} alt="layerimg" />
            <div className="text-center">Drawn Layers</div>
          </div>
        </DropdownToggle>
        <DropdownMenu className="tools-menu-top p-0">
          <Card className="p-0 m-0 br-0 brd-none">
            <CardHeader className="d-flex align-items-center justify-content-between bg-success br-0 m-0 ps-2 ms-0">
              <div className="text-light fs-6">
                <span className="me-3">
                  <Input
                    type="checkbox"
                    checked={props?.selectAll}
                    onChange={() => changeAllVisibilty()}
                    disabled = {!checkStringInArray("Manage layer visibility - Mining Survey", features)}
                  />
                </span>
                Layers
              </div>
              <i
                className="bx bx-x fs-4 pointer text-light"
                onClick={toggleLayersDropdown}
              ></i>
            </CardHeader>
            <CardBody className="p-2 max-height-100">
              {props?.dynamic?.length > 0 ? (
                <Row>
                  {props?.dynamic?.map((layer, key) => (
                    <Col key={key} sm={12}>
                      {makeLayerDropdown(layer)}
                    </Col>
                  ))}
                </Row>
              ) : (
                <div className="text-center">No Layers available</div>
              )}
            </CardBody>
          </Card>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default BottomPanal;
