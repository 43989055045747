import moment from "moment";
import { useAppSelector } from "../../store/hooks";

export const convertDateToISO = (date: any) => {
  let doo = new Date(date);
  let convertDate = new Date(
    doo.getTime() + Math.abs(doo.getTimezoneOffset() * 60000)
  ).toISOString();
  return convertDate;
};

export const formatDateTime = (date: any) => {
  const dateTime = moment(new Date(date)).format("D MMM YYYY, hh:mm A");
  return dateTime;
};

export const formatDate = (date: any) => {
  const NewDate = moment(new Date(date)).format("D MMM YYYY");
  return NewDate;
};

export const Capitalize = (str: any) => {
  let strArr = str?.split(" ");
  let string = [];
  for (let i = 0; i < strArr?.length; i++) {
    string.push(
      strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1).toLowerCase()
    );
  }
  return string?.join(" ");
};

export const checkFeatures = (str: string, arr: any) => {
  if (arr?.includes(str)) {
    return true;
  } else {
    return false;
  }
};
