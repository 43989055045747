import { Dialog } from "primereact/dialog";
import PrimaryTable from "../../primaryTable/PrimaryTable";
import { Projects } from "../ProjectManagementSchema";

const KmlViewModal = (props: any) => {
  const handleDownload = (rowData: any) => {
    var a = document.createElement("a");
    a.href = rowData.kmlFile;
    a.target = "_blank";
    a.download = `${rowData.fileName}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Dialog
      visible={props.visible}
      draggable={false}
      onHide={props.handleHide}
      header={"KML files uploaded by pilot"}
      className="w-50vw"
    >
      <PrimaryTable
        rows={props.rows}
        columns={Projects.AssignPilotView}
        handleDownload={handleDownload}
      />
    </Dialog>
  );
};

export default KmlViewModal;
