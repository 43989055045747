import React, { useEffect, useState } from "react";
import Loader from "../../CommonComponent/Loader";
import { ToastContainer, toast } from "react-toastify";
import BreadCrumb from "../../Common/BreadCrumb";
import { Button, Card, Col, Row } from "reactstrap";
import { TabView, TabPanel } from "primereact/tabview";
import { Accordion, AccordionTab } from "primereact/accordion";
import CorridorTable from "../../tables/CorridorTable";
import CreateCorridor from "./CreateCorridor";
import DeleteModal from "../../Common/DeleteModal";
import { useNavigate } from "react-router-dom";
import * as api from "../../../api/global.api";
import { useParams } from "react-router-dom";
import Pagination from "../../commons/pagination";

const index = () => {
  let prevProjectRoute: any = sessionStorage.getItem("prevProjectRoute");
  let prevClientRoute: any = sessionStorage.getItem("prevRoute");
  const navigate = useNavigate();
  let id = useParams().id;

  const [loading, setLoading] = useState<any>(false);
  const [activeIndex, setActiveIndex] = useState<any>(0);
  const [corridorData, setCorridorData] = useState<any>([]);
  const [mode, setMode] = useState<any>("Add Corridor");
  const [open, setOpen] = useState<any>(false);
  const [editData, setEditData] = useState<any>(null);
  const [deleteData, setDeleteData] = useState<any>(null);
  const [page, setPage] = useState<any>({ count: 0, curr: 1 });
  const { count, curr } = page;

  const handleAddCorridor = () => {
    setMode("Add Corridor");
    setOpen(true);
  };

  const handleDeleteData = () => {
    deleteCorridor(deleteData?.id);
    setDeleteData(null);
  };

  const handleDelete = (val: any) => {
    setDeleteData(val);
  };
  const handleEdit = (val: any) => {
    setEditData(val);
  };

  const getCorridor = (val: any) => {
    setLoading(true);
    api.get(`getCorridor?sourcesId=${id}&page=${val}&limit=10`).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        setCorridorData(res?.response?.rows);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.errormessage);
      }
    });
  };

  const deleteCorridor = (id: any) => {
    setLoading(true);
    api.deleteApi(`deleteCorridor/${id}`).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        getCorridor(curr);
        setLoading(false);
        toast.success("Corridor deleted successfully");
      } else {
        setLoading(false);
        toast.error(res?.errormessage);
      }
    });
  };

  const handlePageChange = (val: any) => {
    setPage({ ...page, curr: val });
  };

  useEffect(() => {
    getCorridor(curr);
  }, [curr]);

  useEffect(() => {
    getCorridor(curr);
  }, []);

  return (
    <div className="mt-3 px-2 oy">
      <Loader loader={loading} />
      <ToastContainer />
      <BreadCrumb
        title="Source"
        pageTitle={JSON.parse(prevProjectRoute)?.name}
        pageLink={JSON.parse(prevProjectRoute)?.link}
        pageTitle1={JSON.parse(prevClientRoute)?.name}
        pageLink1={JSON.parse(prevClientRoute)?.link}
      />
      <Card>
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Layers">
            <Accordion activeIndex={activeIndex}>
              <AccordionTab header="Overaly Sources">
                <Row>
                  <Col>
                    <i
                      className="fa-solid fa-arrow-left primary me-2 pointer"
                      onClick={() => navigate(-1)}
                    ></i>
                    <span>
                      Source Display Name :{" "}
                      <span style={{ color: "black" }}>
                        {sessionStorage.getItem("sourceDisplayName")}
                      </span>
                    </span>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button color="primary" onClick={handleAddCorridor}>
                      Add Corridor
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <CorridorTable
                    data={corridorData}
                    handleEdit={handleEdit}
                    setOpen={setOpen}
                    handleDelete={handleDelete}
                    setMode={setMode}
                  />
                  <Pagination
                    itemsPerPage={12}
                    count={count}
                    handlePageChange={handlePageChange}
                  />
                </Row>
              </AccordionTab>
              <AccordionTab header="Layer Management" disabled></AccordionTab>
            </Accordion>
          </TabPanel>
          <TabPanel header="Attributes" disabled></TabPanel>
        </TabView>
      </Card>
      {open && (
        <CreateCorridor
          mode={mode}
          open={open}
          setOpen={setOpen}
          editData={editData}
          handleEdit={handleEdit}
          getCorridor={getCorridor}
        />
      )}
      {deleteData !== null && (
        <DeleteModal
          show={deleteData !== null ? true : false}
          onDeleteClick={handleDeleteData}
          onCloseClick={() => setDeleteData(null)}
        />
      )}
    </div>
  );
};

export default index;
