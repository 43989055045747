import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Col, Row } from "reactstrap";

const ScreenshotModal = (props) => {
  const [isModal, setIsModal] = useState(false);
  const [id, setId] = useState(0);
  const handleOpenScreenShot = (id) => {
    setId(id);
    setIsModal(true);
  };

  const getHeader = () => {
    let display = props?.data?.result[id]?.split("/");
    return display?.pop();
  };
  const onDeleteScreenshot = (id) => {
    let obj = {
      fileLink: props?.data?.result[id],
      fileName: props?.data?.res?.fileName,
      type: "survey_screenshot",
      id: props?.data?.res?.surveyId,
    };
    props?.deleteScreenshot(obj);
  };


  const delIcon = () => {
    return (
      <div className="d-flex">
        <i
          class="bx bx-trash text-danger me-2 fs-3 pointer"
          onClick={() => onDeleteScreenshot(id)}
        ></i>
      </div>
    );
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center px-3">
        <span className="fs-16">
          Screenshots ({props?.data?.result?.length})
        </span>
        <i
          className="bx bx-x fs-2 pointer text-muted"
          onClick={() => {
            props.closeModal();
          }}
        ></i>
      </div>
      <div className="d-flex align-items-center">
        <Row className="w-100 mb-3 h-70">
          {props?.data?.result?.map((e, idx) => (
            <Col key={idx} xs={3}>
              <div className="img-screenshot mx-2">
                <img src={e} alt={e} width="100%" />
                <div className="del-screenshot">
                  <i
                    className="bx bx-trash text-danger pointer"
                    onClick={() => onDeleteScreenshot(idx)}
                  ></i>
                </div>
                <div className="full-sceenshot">
                  <i
                    className="bx bx-fullscreen fs-1 pointer"
                    onClick={() => {
                      handleOpenScreenShot(idx);
                    }}
                  ></i>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      {isModal && (
        <Lightbox
          enableZoom={true}
          mainSrc={props?.data?.result[id]}
          imageTitle={getHeader()}
          nextSrc={props?.data?.result[(id + 1) % props?.data?.result?.length]}
          prevSrc={
            props?.data?.result[
              (id + (props?.data?.result?.length - 1)) %
                props?.data?.result?.length
            ]
          }
          onCloseRequest={() => {
            setIsModal(false);
          }}
          onMovePrevRequest={() =>
            setId(
              (id + props?.data?.result?.length - 1) %
                props?.data?.result?.length
            )
          }
          onMoveNextRequest={() =>
            setId((id + 1) % props?.data?.result?.length)
          }
          toolbarButtons={[delIcon()]}
        />
      )}
    </>
  );
};

export default ScreenshotModal;
