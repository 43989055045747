import React, { useEffect, useState } from "react";
import Loader from "../../CommonComponent/Loader";
import { ToastContainer, toast } from "react-toastify";
import BreadCrumb from "../../Common/BreadCrumb";
import {
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { TabView, TabPanel } from "primereact/tabview";
import { Accordion, AccordionTab } from "primereact/accordion";
import TowerTable from "../../tables/TowerTable";
import CreateTower from "./CreateTower";
import DeleteModal from "../../Common/DeleteModal";
import BulkUpload from "./BulkUpload";
import { useNavigate, useParams } from "react-router-dom";
import * as api from "../../../api/global.api";
import Pagination from "../../commons/pagination";

const index = () => {
  let prevProjectRoute: any = sessionStorage.getItem("prevProjectRoute");
  let prevClientRoute: any = sessionStorage.getItem("prevRoute");
  const navigate = useNavigate();
  const id = useParams().id;

  const [loading, setLoading] = useState<any>(false);
  const [activeIndex, setActiveIndex] = useState<any>(0);
  const [towerData, setTowerData] = useState<any>([]);
  const [mode, setMode] = useState<any>("Add Tower");
  const [open, setOpen] = useState<any>(false);
  const [bulk, setBulk] = useState<any>(false);
  const [editData, setEditData] = useState<any>(null);
  const [deleteData, setDeleteData] = useState<any>(null);
  const [page, setPage] = useState<any>({ count: 0, curr: 1 });
  const { count, curr } = page;

  const handleAddTower = () => {
    setMode("Add Tower");
    setOpen(true);
  };

  const handleDeleteData = () => {
    deleteTower(deleteData?.id);
    setDeleteData(null);
  };

  const handleDelete = (val: any) => {
    setDeleteData(val);
  };
  const handleEdit = (val: any) => {
    setEditData(val);
  };

  const getTowers = (val: any) => {
    setLoading(true);
    api
      .get(`getTower?corridorId=${id}&page=${page.curr}&limit=12`)
      .then((res: any) => {
        if (res?.status?.toLowerCase() === "success") {
          setTowerData(res?.response?.rows);
          setPage({ ...page, count: res?.response?.totalCount });
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res?.errormessage);
        }
      });
  };

  const deleteTower = (id: any) => {
    setLoading(true);
    api.deleteApi(`deleteTower/${id}`).then((res: any) => {
      if (res?.status?.toLowerCase() === "success") {
        getTowers(curr);
        toast.success("Tower deleted successfully");
      } else {
        toast.error(res?.errormessage);
      }
      setLoading(false);
    });
  };

  const handlePageChange = (val: any) => {
    setPage({ ...page, curr: val });
  };

  useEffect(() => {
    getTowers(curr);
  }, [curr]);

  useEffect(() => {
    getTowers(curr);
  }, []);

  const onActivate = (row: any) => {
    setLoading(true);
    api
      .put(`updateTower`, { id: row.id, isActive: !row.isActive })
      .then((res: any) => {
        if (res?.status?.toLowerCase() === "success") {
          res?.response?.isActive
            ? toast.success("Tower activated successfully")
            : toast.success("Tower deactivated successfully");
          getTowers(curr);
        } else {
          toast.error(res?.errormessage);
          setLoading(false);
        }
      });
  };
  return (
    <div className="mt-3 px-2 oy">
      <Loader loader={loading} />
      <ToastContainer />
      <BreadCrumb
        title="Source"
        pageTitle={JSON.parse(prevProjectRoute)?.name}
        pageLink={JSON.parse(prevProjectRoute)?.link}
        pageTitle1={JSON.parse(prevClientRoute)?.name}
        pageLink1={JSON.parse(prevClientRoute)?.link}
      />
      <Card>
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Layers">
            <Accordion activeIndex={activeIndex}>
              <AccordionTab header="Overaly Sources">
                <Row>
                  <Col>
                    <i
                      className="fa-solid fa-arrow-left primary me-2 pointer"
                      onClick={() => navigate(-1)}
                    ></i>
                    <span className="me-2">
                      Source Display Name :{" "}
                      <span style={{ color: "black" }}>
                        {sessionStorage.getItem("sourceDisplayName")}
                      </span>
                    </span>
                    <span>
                      Corridor Name :{" "}
                      <span style={{ color: "black" }}>
                        {sessionStorage.getItem("corridorName")}
                      </span>
                    </span>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <a
                      href="https://drogo-images.s3.us-east-1.amazonaws.com/4455faea-3cb8-49c1-ab09-7119c01ff0fb_output"
                      download
                    >
                      <Button
                        color="primary"
                        className="d-flex align-items-center me-4"
                      >
                        <i className="text-muted ri-upload-cloud-fill fs-5 me-2" />
                        Download Template
                      </Button>
                    </a>

                    <Button
                      color="primary"
                      className="d-flex align-items-center me-4"
                      onClick={() => setBulk(true)}
                    >
                      <i className="text-muted ri-upload-cloud-fill fs-5 me-2" />
                      Upload Towers
                    </Button>

                    <Button color="primary" onClick={handleAddTower}>
                      Add Tower
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <TowerTable
                    data={towerData}
                    handleEdit={handleEdit}
                    setOpen={setOpen}
                    handleDelete={handleDelete}
                    setMode={setMode}
                    onActivate={onActivate}
                  />
                  <Pagination
                    itemsPerPage={12}
                    count={count}
                    handlePageChange={handlePageChange}
                  />
                </Row>
              </AccordionTab>
              <AccordionTab header="Layer Management" disabled></AccordionTab>
            </Accordion>
          </TabPanel>
          <TabPanel header="Attributes" disabled></TabPanel>
        </TabView>
      </Card>
      {open && (
        <CreateTower
          mode={mode}
          open={open}
          setOpen={setOpen}
          editData={editData}
          handleEdit={handleEdit}
          getTowers={getTowers}
        />
      )}
      {deleteData !== null && (
        <DeleteModal
          show={deleteData !== null ? true : false}
          onDeleteClick={handleDeleteData}
          onCloseClick={() => setDeleteData(null)}
        />
      )}
      {bulk && (
        <BulkUpload open={bulk} setOpen={setBulk} getTowers={getTowers} />
      )}
    </div>
  );
};

export default index;
