import { useNavigate } from "react-router-dom";
import { BreadcrumItems } from "./BreadcrumItems";
import Home from "../../assets/home.svg";

const Breadcrum = (props) => {
  let pathArr = window.location.hash.split("/").splice(1);
  let surveyIndex = pathArr.indexOf("surveys");
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    if (
      path !== "view" &&
      path !== "edit" &&
      path !== "create" &&
      path !== "surveydetails" &&
      path !== "deliverables"
    ) {
      if (
        path === "surveys" &&
        (window.location.pathname.includes("survey/surveydetails") ||
          window.location.pathname.includes("surveys/create") ||
          window.location.pathname.includes("surveys/edit"))
      ) {
        path = "delivers/" + path + "/" + localStorage.getItem("projectid");
      }
      if (path !== "surveys") navigate("/" + path);
    }
  };
  const handleChange = () => {
    navigate("/home");
  };
  return (
    <div>
      <span className="BreadcrumHome">
        {" "}
        <img
          src={Home}
          onClick={handleChange}
          alt="Home"
          className="pointer"
        />{" "}
      </span>
      <span className="primary">&nbsp;/&nbsp;</span>
      {pathArr &&
        pathArr.map((path, index) => {
          return index !== pathArr.length - 1 ? (
            <span
              key={path}
              className={
                path !== "view" &&
                path !== "edit" &&
                path !== "create" &&
                path !== "surveydetails" &&
                path !== "deliverables"
                  ? (path === "surveys"
                      ? Number(pathArr[surveyIndex + 1])
                        ? false
                        : true
                      : true) && "primary pointer"
                  : ""
              }
              onClick={() => handleNavigate(BreadcrumItems[path].link)}
            >
              {path !== "view" &&
              path !== "edit" &&
              path !== "create" &&
              path !== "surveydetails" &&
              path !== "deliverables"
                ? (
                    path === "surveys"
                      ? Number(pathArr[surveyIndex + 1])
                        ? false
                        : true
                      : true
                  )
                  ? BreadcrumItems[path].name + " / "
                  : BreadcrumItems[path].name
                : BreadcrumItems[path].name}
            </span>
          ) : (
            <span key={index}>
              {pathArr[pathArr.length - 2] !== "view" &&
              pathArr[pathArr.length - 2] !== "edit" &&
              pathArr[pathArr.length - 2] !== "create" &&
              pathArr[pathArr.length - 2] !== "surveydetails" &&
              pathArr[pathArr.length - 2] !== "surveys" &&
              pathArr[pathArr.length - 2] !== "deliverables"
                ? BreadcrumItems[path].name
                : ""}
            </span>
          );
        })}
    </div>
  );
};

export default Breadcrum;
