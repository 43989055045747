// import { Button } from "primereact/button";
import { Button } from "reactstrap";
const ActionButtons = (props: any) => {
  const buttonFunction = (btn: string, index: number, outline:boolean) => {
    let btnClass = "";
    switch (btn) {
      case "save":
      case "Save":
      case "update":
      case "Submit":
      case "ok":
        if (props.mode === "edit") btn = "Update";
        btnClass = "success";
        outline = false;
        break;
      case "next":
      case "yes":
      case "Yes":
      case "submit":
      case "accept":
      case "previous":
        btnClass = "success";
        outline = false;
        break;
      case "cancel":
      case "Cancel":
        outline = true;
        btnClass = "success";
        break;
      case "no":
      case "No":
        btnClass = "success";
        outline = true;
        break;
      case "reject":
        btnClass = "success";
        outline = true;
        break;
    }
    return (
      <Button
        key={index}
        color={btnClass}
        outline={outline}
        className={`btn-lg  ${props?.buttons?.length > 1 ? "ml-2" : ""}`}
        onClick={() =>
          btn === "save" ||
          btn === "Save" ||
          btn === "yes" ||
          btn === "Yes" ||
          btn === "ok" ||
          btn === "submit" ||
          btn === "Update" ||
          btn === "update"
            ? props.onSave()
            : btn === "next"
            ? props.onNext()
            : btn === "accept"
            ? props.onAccept()
            : btn === "reject"
            ? props.onReject()
            : btn === "previous"
            ? props.handelPrevious()
            : props.onCancel()
        }
        disabled={
          btn === "save" ||
          btn === "Save" ||
          btn === "submit" ||
          btn === "update" ||
          btn === "Update"
            ? props.disabled
            : btn === "next"
            ? props.disableNext
            : btn === "previous"
            ? props.disablePrevious
            : false
        }
      >
        {props.changeToSave
          ? props.pageIndex === props.changeToSave && btn === "next"
            ? "Save"
            : props?.pageIndex === 1 &&
              btn === "next" &&
              props?.changeToSaveForOnlyRasterSources
            ? "Save"
            : btn
          : btn}{" "}
      </Button>
    );
  };

  return (
    <div
      className={`mt-3 ${
        props.align === "left"
          ? "text-left"
          : props.align === "center"
          ? "text-center"
          : "text-right"
      }`}
    >
      {props.buttons.map((btn: string, index: number, outline:boolean) => {
        return buttonFunction(btn, index, outline);
      })}
    </div>
  );
};

export default ActionButtons;
